import * as yup from "yup";
import { emailRegExp, emailYup, phoneYup } from "./CommonConstants";
export const managerAccounts = (status?: number) => {
  return yup.object().shape({
    first_name: yup
      .string()
      .trim()
      .max(50, "Name must be less than 50 characters.")
      .required("Please enter manager name."),
    email: yup
      .string()
      .trim()
      .matches(emailRegExp, "Please enter a valid email.")
      .test("is-exist", "Manager email exist.", () =>
        !(status === 0) ? true : false
      )
      .email("Invalid email.")
      .required("Please enter email."),

    phone_number: phoneYup,
    assign_property_uuids: yup.array().of(yup.string()).notRequired(),
  });
};

// Assume emailYup and phoneYup are custom schemas for email and phone number
// const emailYup = yup
//   .string()
//   .email("Enter a valid email.")
//   .trim()
//   .max(100, "Email must be less than 100 characters.");

// const phoneYup = yup
//   .string()
//   .matches(/^\+?(\d{1,4}[-.\s]?)*\d{1,4}$/, "Enter a valid phone number.")
//   .nullable(); // Assuming phone number is optional

export const changeOwnerSchema = () => {
  return yup.object().shape({
    existing_manager_uuid: yup
      .mixed()
      .test(
        "is-array-or-object",
        "Please select an existing manager or fill out the admin details.",
        function (value) {
          // Check if other fields are filled
          const { first_name, last_name, email } = this.parent;
          const adminDetailsFilled = first_name || last_name || email;

          if (adminDetailsFilled) {
            // If any admin details are filled, skip `existing_manager_uuid` validation
            return true;
          }

          if (Array.isArray(value)) {
            // Validate as an array of objects
            return yup
              .array()
              .of(
                yup.object().shape({
                  value: yup
                    .string()
                    .nullable()
                    .typeError("Select valid data.")
                    .required("Please select data"),
                  label: yup.string().trim().required("Please select data"),
                })
              )
              .isValidSync(value);
          } else if (typeof value === "object" && value !== null) {
            // Validate as a single object
            return yup
              .object()
              .shape({
                value: yup
                  .string()
                  .nullable()
                  .typeError("Select valid data.")
                  .required("Please select data"),
                label: yup.string().trim().required("Please select data"),
              })
              .isValidSync(value);
          }

          return false;
        }
      )
      .test(
        "is-manager-or-admin",
        "Please select an existing manager or fill out the admin details.",
        function (value) {
          const { first_name, last_name, email } = this.parent;
          const isManagerSelected =
            (Array.isArray(value) && value.length > 0) ||
            (typeof value === "object" && value !== null);
          const isAdminDetailsFilled = first_name || last_name || email;

          if (!isManagerSelected && !isAdminDetailsFilled) {
            return false;
          }
          return true;
        }
      ),

    first_name: yup
      .string()
      .trim()
      .max(50, "Name must be less than 50 characters.")
      .when("existing_manager_uuid", {
        is: (val: any) => !val || val.length === 0,
        then: (schema) => schema.required("Please enter admin first name."),
        otherwise: (schema) => schema.notRequired(),
      }),

    last_name: yup
      .string()
      .trim()
      .max(50, "Name must be less than 50 characters.")
      .when("existing_manager_uuid", {
        is: (val: any) => !val || val.length === 0,
        then: (schema) => schema.required("Please enter admin last name."),
        otherwise: (schema) => schema.notRequired(),
      }),

    email: emailYup.when("existing_manager_uuid", {
      is: (val: any) => !val || val.length === 0,
      then: (schema) => schema.required("Please enter admin email."),
      otherwise: (schema) => schema.notRequired(),
    }),

    phone_number: phoneYup, // Assuming phone number is optional and always valid based on your custom schema
  });
};
