import React, { useEffect } from "react";
import ModalLayout from "../../../layouts/ModalLayout";
import { Col, Container, Row } from "react-bootstrap";
import InputField from "../../../theme/InputField";
import ThemeButton from "../../../theme/ThemeButton";
import { AddEditModalProps } from "../../../../types/modal/addEditModal";
import { UrlCodeToolTip } from "./AddPropertyServiceModal";
import { useFormik } from "formik";
import * as yup from "yup";
import useFetch from "../../../../hooks/useFetch";
import { toast } from "react-toastify";
import {
  notifyBugsnagError,
  showToast,
} from "../../../../common/utils/Functions";

interface prop {
  service: any;
  serviceApi: any;
  property_uuid?: string;
}
const EditPropertyService = ({
  handleClose,
  service,
  show,
  serviceApi,
  property_uuid,
}: AddEditModalProps & prop) => {
  /**
   * initialise property-EMPLOYEE add api
   */
  const [
    serviceEditApi,
    { response: serviceRes, error: serviceError, loading: serviceLoading },
  ] = useFetch(
    `propertyservices/update-custom-service/${service?.service_details?.uuid}`,
    {
      method: "put",
    }
  );

  const formik = useFormik({
    initialValues: { name: "" },
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .trim()
        .max(50, "Service name must be less than 50 characters.")
        .required("Please enter service name."),
    }),
    onSubmit(values: any) {
      try {
        serviceEditApi({
          ...values,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "serviceEditApi",
        });
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("name", service?.service_details?.name);
    formik.setFieldValue("url_code", service?.service_details?.url_code);
  }, [service]);

  /** Handle @PropertyEmployeeAddApi response */
  useEffect(() => {
    if (serviceRes) {
      showToast("Service updated successfully", "success");
      try {
        serviceApi({
          property_uuid,
          start: 0,
          limit: -1,
          search: "",
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "serviceApi",
        });
      }
      handleCloseModal();
    }
    if (serviceError) {
      showToast(serviceError.message as string, "error");
    }
  }, [serviceRes, serviceError]);

  const handleCloseModal = () => {
    formik.resetForm();
    handleClose();
  };
  return (
    <ModalLayout
      title={"Edit Services"}
      show={show}
      size={400}
      handleToggle={handleCloseModal}
    >
      <Container>
        <Row className="mb-4 g-3">
          <Col xs={12}>
            <InputField
              name="name"
              formik={formik}
              label="Service"
              placeholder="Enter Service name"
            />
          </Col>
          <Col xs={12}>
            <InputField
              name="url_code"
              formik={formik}
              disabled={true}
              label="Service URL Abbreviation"
              labelIcon={<UrlCodeToolTip />}
              style={{
                cursor: "not-allowed",
              }}
              placeholder="Enter Service name"
            />
          </Col>
        </Row>
        <Row className="mt-4 d-flex justify-content-center">
          <Col xs={4} className="d-flex justify-content-center">
            <ThemeButton variant="secondary" onClick={handleCloseModal}>
              Cancel
            </ThemeButton>
          </Col>
          <Col xs={4} className="d-flex justify-content-center">
            <ThemeButton
              loading={serviceLoading}
              onClick={() => formik.handleSubmit()}
            >
              Save
            </ThemeButton>
          </Col>
        </Row>
      </Container>
    </ModalLayout>
  );
};

export default EditPropertyService;
