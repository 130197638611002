import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import { countries } from "../../../../common/globalVariables";
import useFetch from "../../../../hooks/useFetch";
import { stepFormPropsType } from "../../../../types/form/stepForm";
import { initialValuesTypes } from "../../../../types/manageProperty";
import InputField from "../../../theme/InputField";
import LoadingBars from "../../../theme/LoadingBars";
import PhoneIntputField from "../../../theme/PhoneIntputField";
import ReactSelectField from "../../../theme/ReactSelectField";
import styles from "../AddProperty.module.scss";
import {
  notifyBugsnagError,
  removeInitialSpace,
  showToast,
} from "../../../../common/utils/Functions";

interface mediaLoader {
  setMediaLoader: React.Dispatch<React.SetStateAction<boolean>>;
  NameLoading?: boolean;
}

const Step1 = ({
  formik,
  allState,
  imagePreview,
  setImagePreview,
  setMediaLoader,
  NameLoading,
}: stepFormPropsType<initialValuesTypes> & mediaLoader) => {
  /** Api definition to get media response */
  const [mediaApi, { response, loading, error }] = useFetch(
    "/common/upload-media",
    {
      method: "post",
    },
    "formdata",
    true
  );

  /**
   * @param e :- React change event to handle image preview logic
   */
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const formdata = new FormData();
        formdata.append("files", file);
        formdata.append("type", "3");
        mediaApi(formdata);
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "mediaApi",
        });
      }
    }
  };

  /** Handle api response */
  useEffect(() => {
    if (response) {
      formik.setFieldValue("media_id", response.data[0]?.id);
      !!setImagePreview && setImagePreview(response.data[0]);
    }
    if (error) {
      showToast(error.message as string, "error");
      formik.setFieldValue("media_id", null);
    }
  }, [response, error]);

  useEffect(() => {
    setMediaLoader(loading);
  }, [loading]);
  return (
    <Row className="g-xl-4 g-2 mt-2">
      <Col xxl={6} xl={6}>
        <InputField
          type="text"
          name="name"
          placeholder="Enter Property Name"
          label="Property Name"
          formik={formik}
          onChange={(e) => {
            formik.setFieldValue("name", e.target.value);
            formik?.setFieldError("name", "");
            formik?.setFieldTouched("name", false);

            formik.setFieldValue("name", removeInitialSpace(e.target.value));
          }}
        >
          {NameLoading && (
            <div className="input_child">
              <LoadingBars color="#676d7a" />
            </div>
          )}
        </InputField>
      </Col>
      {/* <Col xxl={6} xl={6}>
        <InputField
          name="email"
          placeholder="Enter Email"
          label="Property Email"
          onChange={(e) =>
            formik.setFieldValue("email", removeInitialSpace(e.target.value))
          }
          formik={formik}
        ></InputField>
      </Col> */}
      <Col xxl={6} xl={6}>
        <PhoneIntputField
          label="Property Phone Number (Optional)"
          name="phone_number"
          formik={formik}
          phoneCodeId={(phoneId: string) =>
            formik.setFieldValue("phone_code_id", phoneId)
          }
        />
      </Col>
      <Col xxl={6} xl={6}>
        <ReactSelectField
          name="country_id"
          options={countries}
          label="Country"
          placeholder="Select Country"
          className="form_select"
          formik={formik}
          formikErrorMesg={formik.errors?.country_id}
        />
      </Col>
      <Col xxl={6} xl={6}>
        <InputField
          type="text"
          name="address"
          placeholder="Enter Street address"
          label="Street Address (Optional)"
          formik={formik}
        />
      </Col>
      <Col xxl={6} xl={6}>
        <InputField
          type="text"
          name="city"
          placeholder="Enter City "
          label="City (Optional)"
          formik={formik}
        />
      </Col>
      <Col xxl={6} xl={6}>
        <ReactSelectField
          name="state"
          className="form_select"
          options={allState}
          placeholder="Select State"
          label="State (Optional)"
          formik={formik}
        />
      </Col>{" "}
      <Col xxl={6} xl={6}>
        <InputField
          type="text"
          name="zip_code"
          placeholder="Enter Zip code"
          label="Zip Code (Optional)"
          formik={formik}
        />
      </Col>
      <Col xxl={6} xl={6}>
        <div className={styles.addCateg}>
          <label>Property Logo (Optional)</label>
          <label htmlFor="fileUpload" className={styles.uploadInput}>
            <input
              id="fileUpload"
              type="file"
              accept="image/png, image/jpeg"
              name="propertyLogo"
              placeholder="Upload property Logo"
              onChange={handleFileChange}
            />
            {formik.touched.media_id && formik.errors.media_id && (
              <div className={styles.error}>{formik.errors.media_id}</div>
            )}
            <h6>
              {" "}
              {imagePreview?.name === "" ? (
                <span className={styles.placeColor}>Upload Logo</span>
              ) : (
                imagePreview?.name
              )}{" "}
            </h6>
            <span
              className={`primary footerModalBtn commonBtn ${styles.btnBrowse}`}
            >
              {loading ? <LoadingBars /> : "Browse "}
            </span>
          </label>
        </div>

        {imagePreview?.url && (
          <Col xxl={6} xl={6} className="mt-3">
            <div className={`${styles.addCateg} ${styles.addCategBox}`}>
              <div
                className={styles.closeIcon}
                onClick={() => {
                  if (setImagePreview) {
                    setImagePreview({
                      name: "",
                      url: "",
                    });
                  }
                  formik.setFieldValue("media_id", null);
                }}
              >
                <AiOutlineClose />
              </div>
              <img
                src={imagePreview?.url}
                alt="Preview"
                style={{ maxWidth: "100%", padding: "4px" }}
              />
            </div>
          </Col>
        )}
      </Col>
    </Row>
  );
};

export default Step1;
