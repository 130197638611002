import * as yup from "yup";
const phoneRegExp = /^(?=.*?[1-9])[0-9() .-]+$/;
export const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const colorRegExp = /^#([0-9a-fA-F]{6})$/i;
export const twoDigisAfterCommaRegExp = /^\d+(\.\d{0,2})?$/;
const zipCodeRegExp = /^[0-9]{5}$/;

export const phoneYup = yup
  .string()
  .nullable()
  .matches(phoneRegExp, "Please enter valid phone number.")
  .test(
    "maxValidationError",
    "Please enter a 10-digit phone number.",
    (value) => {
      if (!value) return true; // Allow empty value
      const strippedValue = value.replace(/[^0-9]/g, ""); // Remove spaces and special characters
      return strippedValue.length == 10; // Define your maximum allowed length here
    }
  )
  .test(
    "minValidationError",
    "Please enter a 10-digit phone number.",
    (value) => {
      if (!value) return true; // Allow empty value
      const strippedValue = value.replace(/[^0-9]/g, ""); // Remove spaces and special characters
      return strippedValue.length == 10; // Define your minimum required length here
    }
  )
  // .matches(/^\d+(\.\d+)?$/, "Invalid number")
  .test(
    "no-leading-space",
    "Number should not start with a space.",
    (value) => {
      if (value && value.startsWith(" ")) {
        return false;
      }
      return true;
    }
  );

export const emailYup = yup
  .string()
  .trim()
  .email("Please enter a valid email.")
  .required("Please enter email.")
  .matches(emailRegExp, "Please enter a valid email.");

export const colorYup = (message: string) =>
  yup.string().matches(colorRegExp, "Invalid color code.").required(message);

export const zipCodeYup = yup
  .string()
  .nullable()
  .required("Please enter zip code.")
  .matches(/^[^\s]+$/, "Zip code cannot contain spaces.")
  .matches(/^[0-9]+$/, "Zip code must be in digits.")
  .matches(zipCodeRegExp, "Zip code must be 5 digits.")
  .test(
    "no-leading-space",
    "Zip code should not start with a space.",
    (value) => !value || !(value.startsWith(" ") || /\s/.test(value))
  )
  .test(
    "no-space",
    "Zip code cannot contain spaces.",
    (value) => !value || !/\s/.test(value)
  )
  .test(
    "no-alphabets",
    "Zip code cannot contain alphabets.",
    (value) => !value || !/[a-zA-Z]/.test(value)
  )
  .test(
    "exact-length",
    "Zip code must be 5 digits.",
    (value) => !value || (/^\d{5}$/.test(value) && !/\s/.test(value))
  );

export const amountSchema = (name: string) => {
  return yup
    .number()
    .min(
      0,
      `${name} should be greater than or equal to 0.0 should be allowed but not negative values.`
    )
    .positive(
      `${name} should be greater than or equal to 0.0 should be allowed but not negative values.`
    )
    .test(
      "maxDecimalPlaces",
      "Amount must have at most two decimal places.",
      (value) =>
        value === null ||
        value === undefined ||
        /^\d+(\.\d{1,2})?$/.test(value as any)
    )
    .max(9999999999, "Entered amount must be less than 10 digits.")
    .nullable();
};
