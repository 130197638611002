import { createSlice } from "@reduxjs/toolkit";

export const breadCrumbSlice = createSlice({
  name: "breadCrumbSlice",
  initialState: {
    breadCrumbData: {
      link: "",
      label: "",
    },
  },
  reducers: {
    handleBreadCrumb(state, { payload }) {
      state.breadCrumbData.label = payload.label;
    },
  },
});

export const { handleBreadCrumb } = breadCrumbSlice.actions;
export default breadCrumbSlice.reducer;
