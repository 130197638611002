import React from "react";
import Pagination from "react-bootstrap/Pagination";

const CommonPagination = ({
  itemPerPage,
  totalItems,
  paginate,
  currentPage,
}: {
  itemPerPage: number;
  totalItems: number;
  paginate: (page: number) => void;
  currentPage: number;
}) => {
  // Calculate the total number of pages needed for pagination
  const pageNumbers: number[] = [];

  for (let i = 0; i < Math.ceil(totalItems / itemPerPage); i++) {
    pageNumbers.push(i);
  }

  // Generate an array of pagination items based on the current page

  const paginationNumber = () => {
    const totalPageCount = pageNumbers.length;
    const displayCount = 5;

    if (totalPageCount <= displayCount) {
      return pageNumbers;
    }

    const midPoint = Math.floor(displayCount / 2);

    if (currentPage < midPoint) {
      return [
        ...pageNumbers.slice(0, displayCount - 1),
        "...",
        pageNumbers[totalPageCount - 1],
      ];
    }

    if (currentPage >= totalPageCount - midPoint) {
      return [
        pageNumbers[0],
        "...",
        ...pageNumbers.slice(totalPageCount - displayCount + 1),
      ];
    }

    return [
      pageNumbers[0],
      "...",
      ...pageNumbers.slice(currentPage - midPoint, currentPage + midPoint + 1),
      "...",
      pageNumbers[totalPageCount - 1],
    ];
  };

  let paginationItems = paginationNumber();

  // Navigate to the first page
  const firstPage = () => {
    paginate(0);
  };

  // Navigate to the last page
  const lastPage = () => {
    paginate(pageNumbers[pageNumbers.length - 1]);
  };

  // Navigate to the next page
  const nextPage = () => {
    paginate(currentPage + 1);
  };

  // Navigate to the previous page
  const prevPage = () => {
    let page = currentPage === 0 ? 0 : currentPage - 1;

    paginate(page);
  };

  return (
    <Pagination>
      <Pagination.First
        disabled={currentPage === 0}
        onClick={(ev) => {
          ev.preventDefault();
          firstPage();
        }}
      />
      <Pagination.Prev
        disabled={currentPage === 0}
        onClick={(ev) => {
          ev.preventDefault();
          prevPage();
        }}
      />
      {paginationItems &&
        paginationItems.length > 0 &&
        paginationItems.map((item: any) => {
          return (
            <Pagination.Item
              disabled={isNaN(item)}
              className={`d-none d-sm-block ${
                currentPage === item ? "active" : ""
              }`}
              key={item}
              onClick={(ev) => {
                ev.preventDefault();
                paginate(item);
              }}
            >
              {item + 1} {/* Adjust the display to start from 1 */}
            </Pagination.Item>
          );
        })}
      <Pagination.Next
        disabled={currentPage === pageNumbers[pageNumbers.length - 1]}
        onClick={(ev) => {
          ev.preventDefault();
          nextPage();
        }}
      />
      <Pagination.Last
        disabled={currentPage === pageNumbers[pageNumbers.length - 1]}
        onClick={(ev) => {
          ev.preventDefault();
          lastPage();
        }}
      />
    </Pagination>
  );
};

export default CommonPagination;
