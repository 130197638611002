import { FormikProps } from "formik";
import { ReactNode, useState } from "react";
import { Form, FormControlProps } from "react-bootstrap";
import { Visibility, VisibilityOff } from "../../assests/Icons/icon";
import { removeInitialSpace } from "../../common/utils/Functions";

interface IProps extends FormControlProps {
  rows?: number;
  name: string;
  label?: string;
  formik?: FormikProps<any>;
  icon?: string;
  labelIcon?: ReactNode;
  children?: ReactNode;
  type?: string;
}

const InputField: React.FC<
  IProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({
  labelIcon,
  rows,
  name,
  formik,
  label,
  icon,
  children,
  type = "text",
  ...rest
}) => {
  const formikValue = formik?.values[name];
  const formikError = formik?.touched[name] ? formik?.errors[name] : null;

  const [isType, setIsType] = useState(type);
  const handleEyeToggle = () => {
    setIsType(isType === "text" ? "password" : "text");
  };

  const changepasswordError =
    "Minimum 8 Characters (1 upper & lower case, number, and special character).";
  return (
    <Form.Group className={`field_wrap ${rest.className}`} controlId={name}>
      {label ? (
        <Form.Label>
          {label} {labelIcon && labelIcon}
        </Form.Label>
      ) : null}

      <div className={`input_wrap`}>
        {icon && <img className={`field_icon`} src={icon} alt="email" />}
        <Form.Control
          type={isType === "color" ? "text" : isType}
          value={formikValue}
          rows={rows}
          onChange={
            formik
              ? (e) => {
                  if (type === "text") {
                    e.target.value = removeInitialSpace(e.target.value);
                    formik?.handleChange(e);
                  } else if (type === "number") {
                    const sanitizedValue = e.target.value.replace(/\D/g, "");
                    // Limit the input to 6 digits
                    const limitedValue = sanitizedValue.slice(0, 6);
                    // Update the state with the limited value
                    formik.setFieldValue(name, limitedValue);
                  } else {
                    formik?.handleChange(e);
                  }
                }
              : () => {}
          }
          {...rest}
          className={`${formikError ? "has_error" : ""} ${icon ? "icon" : ""}`}
        />
        {children}
        {type === "password" && (
          <span className="password_icon" onClick={handleEyeToggle}>
            {isType === "password" ? VisibilityOff : Visibility}
          </span>
        )}

        {type === "color" && (
          <input
            className="colorsFeild"
            type="color"
            onChange={(e) => formik?.setFieldValue(name, e.target.value)}
            value={formikValue ? formikValue : "#f9f9f9"}
          />
        )}
      </div>
      {formikError ? (
        <span
          className={
            formikError == changepasswordError ? "changeError" : "error"
          }
        >
          {typeof formikError === "string" ? formikError : ""}
        </span>
      ) : null}
    </Form.Group>
  );
};

export default InputField;
