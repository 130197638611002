interface TableData {
  columnWidths: number[];
  name: string;
  data: (string | number)[][];
  boldColumns: [0, 1, 2];
}

const sheetName = ["Properties", "Services and Rooms", "Employees"];
const transformDataToTableFormat = (
  tableData: any[],
  isQrCode?: boolean
): TableData[] => {
  // const tables: TableData[] = [];
  const properties = tableData[0]?.properties ? tableData[0]?.properties : [];
  const services_rooms = tableData[1]?.services_rooms
    ? tableData[1]?.services_rooms
    : [];
  const employees = tableData[2]?.employees ? tableData[2]?.employees : [];

  const table: TableData[] = [
    {
      name: sheetName[0],
      data: [
        isQrCode
          ? ["Property ID", "Name", "Link", "QR Code"]
          : [
              "Property ID",
              "Name",
              "Phone",
              "Link",
              "QR Code",
              "Address",
              "Status",
            ],
        ...properties,
      ],
      columnWidths: [],
      boldColumns: [0, 1, 2],
    },
    {
      name: sheetName[1],
      data: [
        isQrCode
          ? ["Property Name", "Type", "ID", "Name", "Link", "QR Code"]
          : ["Property Name", "Type", "ID", "Name", "Link", "QR Code"],
        ...services_rooms,
      ],
      columnWidths: [],
      boldColumns: [0, 1, 2],
    },
    {
      name: sheetName[2],
      data: [
        isQrCode
          ? ["Property Name", "Employee ID", "Name", "Link", "QR Code"]
          : [
              "Property Name",
              "Employee ID",
              "Name",
              "Phone",
              "Email",
              "Link",
              "QR Code",
            ],
        ...employees,
      ],
      columnWidths: [],
      boldColumns: [0, 1, 2],
    },
  ];

  return table;
};

export default transformDataToTableFormat;

/** Old way for showing the data  */
// interface TableData {
//   columnWidths: number[];
//   name: string;
//   data: (string | number)[][];
//   boldColumns: [0, 1, 2];
// }
// const transformDataToTableFormat = (
//   tableData: any[],
//   isQrCode?: boolean
// ): TableData[] => {
//   const tables: TableData[] = [];
//   tableData?.forEach((data: any, index: number) => {
//     const table: TableData = {
//       name: `Property ${index + 1}`,
//       data: [
//         isQrCode
//           ? ["Property ID", "Name", "Link", "QR Code"]
//           : [
//               "Property ID",
//               "Name",
//               "Email",
//               "Link",
//               "QR Code",
//               "Address",
//               "Active",
//             ],
//         isQrCode
//           ? [data?.id, data?.name, data?.link, data?.qr_code]
//           : [
//               data?.id,
//               data?.name,
//               data?.email,
//               data?.link,
//               data?.qr_code,
//               data?.address,
//               data?.is_active ? "Yes" : "No",
//             ],
//         [],
//         [],
//         ["Services"],
//         ["ID", "Name", "Link", "Qr Code"],
//         ...data?.property_services_details?.map(
//           (service: { id: any; name: any; url_code: any; qr_code: string }) => [
//             service?.id,
//             service?.name,
//             service?.url_code,
//             service?.qr_code,
//           ]
//         ),
//         [],
//         [],
//         ["Rooms"],
//         ["ID", "Name", "Link", "Qr Code"],
//         ...data?.property_rooms_details?.map(
//           (room: { id: any; name: any; link: string; qr_code: string }) => [
//             room?.id,
//             room?.name,
//             room?.link,
//             room?.qr_code,
//           ]
//         ),
//         [],
//         [],
//         ["Employees"],
//         isQrCode
//           ? ["ID", "Name", "Link", "Qr Code"]
//           : ["ID", "Name", "Phone", "Email", "Link", "Qr Code"],
//         ...data?.property_employees_details?.map(
//           (employee: {
//             id: any;
//             name: any;
//             email: any;
//             link: string;
//             qr_code: string;
//             phone: any;
//           }) =>
//             isQrCode
//               ? [
//                   employee?.id,
//                   employee?.name,
//                   employee?.link,
//                   employee?.qr_code,
//                 ]
//               : [
//                   employee?.id,
//                   employee?.name,
//                   employee?.phone,
//                   employee?.email,
//                   employee?.link,
//                   employee?.qr_code,
//                 ]
//         ),
//       ],
//       columnWidths: [],
//       boldColumns: [0, 1, 2],
//     };
//     tables.push(table);
//   });
//   return tables;
// };
// export default transformDataToTableFormat;
