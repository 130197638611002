import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { editIcon, tick } from "../../../../assests/Icons/icon";
import useFetch from "../../../../hooks/useFetch";
import { AddEditModalProps } from "../../../../types/modal/addEditModal";
import { propertyRoomSchema } from "../../../../validations/propertySchema";
import ModalLayout from "../../../layouts/ModalLayout";
import InputField from "../../../theme/InputField";
import ThemeButton from "../../../theme/ThemeButton";
import styles from "../AddProperty.module.scss";
import { propertyRoom } from "../initialValues";
import LoadingBars from "../../../theme/LoadingBars";
import {
  notifyBugsnagError,
  showToast,
} from "../../../../common/utils/Functions";

interface roomType {
  name: string;
  edit: boolean;
  edited?: boolean;
}

interface Props {
  title: string;
  show: boolean;
  handleClose: () => void;
  property_uuid: string;
  roomsCount: number;
}

const RoomModal = ({
  title,
  show,
  handleClose,
  property_uuid,
  roomsCount,
}: AddEditModalProps & Props) => {
  const [roomList, setRoomList] = useState<Array<roomType>>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const [roomError, setRoomError] = useState("");
  const [roomNameError, setRoomNameError] = useState<boolean[]>([]);
  const [submitError, setSubmitError] = useState("");
  const [editedFields, setEditedFields] = useState<Set<number>>(new Set()); // State to track edited fields

  /**
   * initialise property-EMPLOYEE add api
   */
  const [
    PropertyRoomAddApi,
    {
      response: PropertyRoomRes,
      error: PropertyRoomError,
      loading: PropertyRoomLoading,
    },
  ] = useFetch("propertyrooms/add", {
    method: "post",
  });

  const createRooms = (numberOfRooms: number) => {
    const roomCount = roomList?.length;
    const rooms: Array<roomType> = [];
    if (numberOfRooms < roomCount && numberOfRooms <= 100) {
      const getRoomList = [...roomList];
      Array.from({ length: roomCount - numberOfRooms }, (_, index) =>
        getRoomList.pop()
      );
      setRoomList([...getRoomList]);
    }
    if (roomCount > 0 && numberOfRooms > roomCount && numberOfRooms <= 100) {
      Array.from({ length: numberOfRooms - roomCount }, (_, index) =>
        rooms.push({
          name: `Room${roomsCount + roomCount + (index + 1)}`,
          edit: false,
        })
      );
      setRoomList([...roomList, ...rooms]);
      setRoomNameError([]);
    }
    if (roomList?.length <= 0 && numberOfRooms <= 100) {
      const roomsError: Array<boolean> = [];
      Array.from({ length: numberOfRooms }, (_, index) =>
        rooms.push({ name: `Room${roomsCount + (index + 1)}`, edit: false })
      );
      Array.from({ length: numberOfRooms }, (_, index) =>
        roomsError.push(false)
      );
      setRoomList(rooms);
      setRoomError("");
      setSubmitError("");
      setRoomNameError([...roomsError]);
    } else if (numberOfRooms > 100) {
      setRoomError("Room number must be less than 100");
    }
  };

  const handleModalClose = () => {
    formik.resetForm();
    if (!PropertyRoomLoading) {
      handleClose();
      setRoomError("");
      setSubmitError("");
      setRoomList([]);
      setRoomNameError([]);
    }
  };

  const checkIsEdit = (index: number) => {
    // inputRefs.current[index]?.focus(); // Remove this line

    setEditedFields((prevEditedFields) => new Set(prevEditedFields.add(index))); // Add the index to edited fields
    const updatedRooms = [...roomList];
    updatedRooms[index].edit = true;
    setRoomList(updatedRooms);
    // Focus on the input field when edit button is clicked
    setTimeout(() => {
      inputRefs.current[index]?.focus();
      inputRefs.current[index]?.setSelectionRange(
        inputRefs.current[index]?.value.length as number,
        inputRefs.current[index]?.value.length as number
      );
    }, 0);
  };

  const handleSaveChanges = (index: number) => {
    const updatedRooms = [...roomList];
    const roomsError = [...roomNameError];

    // If the room name is empty, restore the previous value
    if (updatedRooms[index].name.trim() === "") {
      inputRefs.current[index]?.focus();
      roomsError[index] = true;
      setRoomNameError([...roomsError]);
      // Check for unique room names
    } else if (
      roomList?.some(
        (item, roomListIndex) =>
          roomListIndex !== index &&
          item.name?.toLocaleLowerCase() ===
            roomList[index].name?.toLocaleLowerCase()
      )
    ) {
      roomsError[index] = true;
      setRoomNameError([...roomsError]);
    } else {
      roomsError[index] = false;
      setRoomNameError([...roomsError]);
      updatedRooms[index].edit = false;
      updatedRooms[index].edited = true;
    }
    setRoomList(updatedRooms);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    const updatedRooms = [...roomList];
    const roomsError = [...roomNameError];
    updatedRooms[index].name = value;
    setRoomList(updatedRooms);
    roomsError[index] = false;
    setRoomNameError([...roomsError]);
  };

  const formik = useFormik({
    initialValues: propertyRoom,
    validationSchema: propertyRoomSchema,
    onSubmit(values: any) {
      const formikValue = roomList.map((item) => {
        return item.name;
      });
      if (!roomNameError?.includes(true)) {
        if (formikValue?.length > 0) {
          try {
            PropertyRoomAddApi({
              rooms_name: [...formikValue],
              property_uuid,
            });
          } catch (e: any) {
            notifyBugsnagError(e, {
              api: "PropertyRoomAddApi",
            });
          }
        } else {
          setRoomError("Please enter number of rooms");
        }
      } else {
        setRoomNameError([...roomNameError]);
      }
    },
  });

  /** Handle @PropertyEmployeeAddApi response */
  useEffect(() => {
    if (PropertyRoomRes) {
      showToast(PropertyRoomRes.message as string, "success");
      setRoomList([]);
      formik.resetForm();
      handleModalClose();
    }
    if (PropertyRoomError) {
      const roomsError: Array<boolean> = [];
      (PropertyRoomError as any)?.data?.forEach((item: boolean) => {
        roomsError.push(!item);
      });
      setRoomNameError([...roomsError]);
      showToast(PropertyRoomError.message as string, "error");
    }
  }, [PropertyRoomRes, PropertyRoomError]);

  useEffect(() => {
    if (roomList.length > 0 && inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, [roomList]);

  return (
    <ModalLayout
      title={title}
      show={show}
      size={500}
      handleToggle={handleModalClose}
    >
      <Container className="">
        <Row className="mb-4">
          <Col xs={12}>
            <InputField
              name="rooms_no"
              max={20}
              type="number"
              label="Number of Rooms"
              disabled={PropertyRoomLoading}
              formik={formik}
              placeholder="Enter Number of Rooms"
              onBlur={(e) => {
                setLoader(true);
                setTimeout(() => {
                  createRooms(+e.target.value);
                  setLoader(false);
                }, 1000);
              }}
            >
              {loader && (
                <div className="input_child">
                  <LoadingBars color="#676d7a" />
                </div>
              )}
            </InputField>
          </Col>
          <Col
            style={{
              position: "relative",
              marginBottom: "5px",
            }}
          >
            {roomError && (
              <span
                className={styles.error}
                style={{
                  position: "absolute",
                  right: "14px",
                  color: "red",
                  fontSize: "12px",
                  // top: "-18px",
                }}
              >
                {roomError}
              </span>
            )}
            {submitError && (
              <span
                className={styles.error}
                style={{
                  position: "absolute",
                  right: "14px",
                  color: "red",
                  fontSize: "12px",
                  // top: "-18px",
                }}
              >
                {submitError}
              </span>
            )}
          </Col>
        </Row>
        {roomList.length > 0 && (
          <Container className={styles.roomList}>
            <h1 className={styles.head1}>Rooms</h1>
            {roomList.map((item, index: number) => {
              const isEdited = editedFields.has(index); // Check if the field is edited
              return (
                <Row
                  className={"mt-1 g-3"}
                  style={{
                    position: "relative",
                  }}
                >
                  <Col
                    key={index}
                    className={styles.room}
                    xs={12}
                    style={{
                      display: "flex",
                      gap: "5px",
                      borderColor: roomNameError[index] ? "red" : "",
                    }}
                  >
                    <span
                      onClick={(e) => {
                        // e.stopPropagation();
                        if (!PropertyRoomLoading && !item.edit) {
                          checkIsEdit(index);
                        }
                      }}
                    >
                      {!item.edit && editIcon}
                    </span>
                    <input
                      style={{
                        flex: 1,
                        backgroundColor: "transparent",
                      }}
                      ref={(el) => (inputRefs.current[index] = el)}
                      value={item.name}
                      disabled={!item.edit}
                      onChange={(e) => handleChange(e, index)}
                      maxLength={50}
                      onBlur={() => handleSaveChanges(index)} // Save changes when input loses focus
                    />
                    {item.edited && (
                      <span
                        style={{
                          display: "inline-block",
                          textAlign: "end",
                          padding: "0px 5px",
                          cursor: "text",
                        }}
                      >
                        {isEdited && tick}{" "}
                        {/* Render tick if the field is edited */}
                      </span>
                    )}
                  </Col>
                  {roomNameError[index] && (
                    <Col
                      style={{
                        padding: 0,
                      }}
                    >
                      <span
                        className={styles.error}
                        style={{
                          position: "absolute",
                          right: 0,
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        {"Room name already exist"}
                      </span>
                    </Col>
                  )}
                </Row>
              );
            })}
          </Container>
        )}
        <Row className="mt-4 d-flex justify-content-center">
          <div className="d-flex gap-3 py-4 justify-content-center">
            <ThemeButton
              variant="secondary"
              onClick={handleModalClose}
              disabled={PropertyRoomLoading}
            >
              Cancel
            </ThemeButton>
            <ThemeButton
              loading={PropertyRoomLoading}
              onClick={() => formik.handleSubmit()}
            >
              Done
            </ThemeButton>
          </div>
        </Row>
      </Container>
    </ModalLayout>
  );
};

export default RoomModal;
