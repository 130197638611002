import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
  name: "user",
  initialState: {
    data: {
      role_id: null,
      uuid: "",
      admin_uuid: "",
    },
    reloadManagerList: false,
  },

  reducers: {
    setUserData(state: any, action: any) {
      state.data = action.payload;
    },
    setReloadManagerList(state: any, { payload }) {
      state.reloadManagerList = payload;
    },
  },
});
export const { setUserData, setReloadManagerList } = commonSlice.actions;
export default commonSlice.reducer;
