import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import EmployeeModal from "../../../../components/pagesComponents/manageProperty/employeeModal/EmployeeModal";
import ListEmployess from "../../../../components/pagesComponents/manageProperty/employeeModal/ListEmployess";
import ListPropertyBox from "../../../../components/pagesComponents/manageProperty/listPropertyBox/ListPropertyBox";
import SelectPropertyService from "../../../../components/pagesComponents/manageProperty/propertyServiceModal/AddPropertyServiceModal";
import ServiceList from "../../../../components/pagesComponents/manageProperty/propertyServiceModal/ServiceList";
import RoomList from "../../../../components/pagesComponents/manageProperty/roomEmployee/RoomList";
import RoomModal from "../../../../components/pagesComponents/manageProperty/roomEmployee/RoomModal";
import useFetch from "../../../../hooks/useFetch";
import { propertyServiceListType } from "../../../../types/manageProperty";
interface props {
  uuid?: string;
  orgId?: number;
}
const PropertyServices = ({ uuid, orgId }: props) => {
  const isOrg = window.location.href.includes("organizations");
  const [openServiceSelecModal, setOpenServiceSelecModal] =
    useState<boolean>(false);
  const [openEmployeeModal, setOpenEmployeeModal] = useState<boolean>(false);
  const [employeeValues, setEmployeeValues] = useState<any>({});
  const [isEditEmployee, setIsEditEmployee] = useState(false);
  const [openRoomModal, setOpenRoomModal] = useState<boolean>(false);
  const [propertyServiceList, setPropertyServiceList] =
    useState<propertyServiceListType[]>();
  const [propertyEmployeeList, setPropertyEmployeeList] = useState<any[]>();

  /**
   * initialise property-employees list api
   */
  const [ServiceListApi, { response: serviceListRes, error: serviceListErr }] =
    useFetch("propertyservices/list", {
      method: "post",
    });

  /**
   * initialise property-employees list api
   */
  const [
    PropertyEmployeeListApi,
    { response: PropertyEmployeeListRes, error: PropertyEmployeeListErr },
  ] = useFetch("propertyemployees/list", {
    method: "post",
  });

  /**
   * initialise property-room list api
   */
  const [
    PropertyRoomListApi,
    { response: listRoomRes, error: listRoomError, loading: listRoomLoading },
  ] = useFetch("propertyrooms/list", {
    method: "post",
  });

  /** Call @ServiceListApi api */
  useEffect(() => {
    const timer = setTimeout(() => {
      ServiceListApi({
        property_uuid: uuid,
        start: 0,
        limit: -1,
        search: "",
      });
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  /** Call @PropertyEmployeeListApi api */
  useEffect(() => {
    const timer = setTimeout(() => {
      PropertyEmployeeListApi({
        property_uuid: uuid,
        start: 0,
        limit: -1,
      });
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  /** Call @PropertyRoomListApi api */
  useEffect(() => {
    const timer = setTimeout(() => {
      PropertyRoomListApi({
        property_uuid: uuid,
        start: 0,
        limit: -1,
        search: "",
      });
    }, 500);
    return () => clearTimeout(timer);
  }, [openRoomModal]);

  useEffect(() => {
    setPropertyServiceList(serviceListRes?.data?.list);
  }, [serviceListRes]);

  useEffect(() => {
    setPropertyEmployeeList(PropertyEmployeeListRes?.data?.list);
  }, [PropertyEmployeeListRes]);

  return (
    <>
      <Row className="mt-5 g-4">
        <Col lg={6}>
          <ListPropertyBox
            label="Services"
            buttonTitle={
              serviceListRes?.data?.list?.length > 0
                ? "Add New Services"
                : "Add Services"
            }
            content={
              isOrg
                ? "There is no data in the services to show."
                : "You have not added any service yet. To add services click on below button.            "
            }
            onClick={() => setOpenServiceSelecModal(true)}
          >
            {serviceListRes?.data?.list?.length > 0 ? (
              <ServiceList
                serviceList={propertyServiceList}
                setPropertyServiceList={setPropertyServiceList}
                property_uuid={uuid}
                serviceApi={ServiceListApi}
              />
            ) : null}
          </ListPropertyBox>
        </Col>

        <Col
          lg={6}
          style={{
            position: "relative",
          }}
        >
          <ListPropertyBox
            customClass="employeeCustom"
            label="Employees"
            buttonTitle={
              serviceListRes?.data?.list?.length > 0
                ? "Add New Employees"
                : "Add Employees"
            }
            content={
              isOrg
                ? "There is no data of employees to show."
                : "You have not added any employee yet. To add employees click on below button.            "
            }
            onClick={() => {
              setIsEditEmployee(false);
              setOpenEmployeeModal(true);
            }}
          >
            {PropertyEmployeeListRes?.data?.list?.length > 0 ? (
              <ListEmployess
                setEmployeeValues={setEmployeeValues}
                setIsEditEmployee={setIsEditEmployee}
                propertyEmployeeListRes={propertyEmployeeList}
                setPropertyEmployeeList={setPropertyEmployeeList}
                propertyEmployeeListErr={PropertyEmployeeListErr}
                PropertyEmployeeListApi={PropertyEmployeeListApi}
                property_uuid={uuid}
                isModal={false}
                openEditModal={() => {
                  setOpenEmployeeModal(true);
                }}
              />
            ) : null}
          </ListPropertyBox>
        </Col>
        <Col lg={6}>
          <ListPropertyBox
            label="Rooms"
            buttonTitle={
              serviceListRes?.data?.list?.length > 0
                ? "Add New Rooms"
                : "Add Rooms"
            }
            content={
              isOrg
                ? "There is no data in the rooms to show."
                : "You have not added any room yet. To add rooms click on below button.            "
            }
            onClick={() => setOpenRoomModal(true)}
          >
            {listRoomRes?.data?.list?.length > 0 ? (
              <RoomList
                roomList={listRoomRes?.data?.list}
                property_uuid={uuid}
                roomsApi={PropertyRoomListApi}
              />
            ) : null}
          </ListPropertyBox>
        </Col>
        <SelectPropertyService
          serviceList={serviceListRes?.data?.list}
          property_uuid={uuid}
          orgId={orgId}
          handleClose={() => setOpenServiceSelecModal(false)}
          show={openServiceSelecModal}
          title="Select Services"
          serviceApi={ServiceListApi}
        />
        <EmployeeModal
          serviceOption={serviceListRes?.data?.list}
          PropertyEmployeeListApi={PropertyEmployeeListApi}
          isEditEmployee={isEditEmployee}
          employeeValues={employeeValues}
          property_uuid={uuid ?? ""}
          handleClose={() => {
            setEmployeeValues(null);
            setOpenEmployeeModal(false);
          }}
          show={openEmployeeModal}
          title={isEditEmployee ? "Edit Employee" : "Add Employees"}
        />
        <RoomModal
          roomsCount={listRoomRes?.data?.list?.length}
          property_uuid={uuid ?? ""}
          handleClose={() => setOpenRoomModal(false)}
          show={openRoomModal}
          title="Add Rooms"
        />
      </Row>
    </>
  );
};
export default PropertyServices;
