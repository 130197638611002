import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeOwner,
  changePassword,
  hamburger,
  logout,
  paymentManagement,
  profile,
  reportingAutomation,
  sideMenuClose,
} from "../../assests/Icons/icon";
import arrow from "../../assests/chevron-left.svg";
import userProfile from "../../assests/images/user.png";
import { MANAGER, ORGANIZATION } from "../../common/globalVariables";
import {
  clearUserLocalData,
  getCurrentUserData,
} from "../../common/utils/Functions";
import styles from "./topBar.module.scss";
import BreadCrumb from "../breadcrumb/CustomBreadCrumb";
import ChangeOwnerModal from "../modals/changeOwnerModal/changeOwnerModal";
import ChangeOwnerConfirmationModal from "../changeOwnerConfirmationModal/changeOwnerConfirmationModal";

interface IProps {
  toggleSidebar: () => void;
  sidebarOpen: boolean;
}

const TopBar = ({ toggleSidebar, sidebarOpen }: IProps) => {
  const [showChangeOwner, setShowChangeOwner] = useState<boolean>(false);
  const [confirmChangeOwner, setConfirmChangeOwner] = useState<boolean>(false);

  // const userData = useSelector((state: any) => state?.userData);
  const navigate = useNavigate();
  const { uuid } = useParams();
  const userData = getCurrentUserData(`${uuid}`);
  const handleClose = () => {
    setShowChangeOwner(false);
    setConfirmChangeOwner(false);
  };

  const TopBarInfo = [
    {
      icon: profile,
      title: "Profile",
      role: userData?.role_id === 2 ? ORGANIZATION : MANAGER,
      onClick: () => {
        navigate(uuid ? "/" + uuid + "/profile" : "/profile");
      },
    },
    {
      icon: changePassword,
      title: "Change Password",
      role: userData?.role_id === 2 ? ORGANIZATION : MANAGER,
      onClick: () => {
        navigate(uuid ? "/" + uuid + "/change-password" : "/change-password");
      },
    },
    {
      icon: paymentManagement,
      title: "Payment Management",
      role: ORGANIZATION,
      onClick: () => {
        navigate(
          uuid ? "/" + uuid + "/payment-management" : "/payment-management"
        );
      },
    },
    {
      icon: reportingAutomation,
      title: "Reporting Automation",
      role: userData?.role_id === 2 ? ORGANIZATION : MANAGER,
      onClick: () => {
        navigate(
          uuid ? "/" + uuid + "/reporting-automation" : "/reporting-automation"
        );
      },
    },
    {
      icon: changeOwner,
      title: "Change Owner",
      role: ORGANIZATION,
      onClick: () => {
        setConfirmChangeOwner(true);
        // navigate(
        //   uuid ? "/" + uuid + "/reporting-automation" : "/reporting-automation"
        // );
      },
    },
    {
      icon: logout,
      title: "Logout",
      role: 0,
      onClick: () => {
        clearUserLocalData(`${uuid}`);
        navigate("/");
      },
    },
  ];

  const checkRole = (item: any) => {
    if (item?.role_id === 1 || item?.role_id === 3) {
      return (
        <span>
          {" "}
          {item?.first_name} {item?.last_name ?? ""}{" "}
        </span>
      );
    } else {
      return (
        <span>
          {" "}
          {item?.organization_details?.name ??
            item?.first_name + " " + item?.last_name ??
            ""}
        </span>
      );
    }
  };

  const renderDropDown = (
    item: {
      role: number;
      onClick: () => void;
      icon: JSX.Element;
      title: string;
    },
    index: number
  ) => {
    if (item.role === userData?.role_id || !item?.role) {
      return (
        <React.Fragment>
          <Dropdown.Item onClick={item?.onClick}>
            {item?.icon}
            {item?.title}
          </Dropdown.Item>
          {index !== TopBarInfo.length - 1 && <Dropdown.Divider />}
        </React.Fragment>
      );
    }
  };
  return (
    <div className={styles.topBar}>
      <div className={styles.topBar_search}>
        <div className={styles.urlNames}>
          <button onClick={toggleSidebar}>
            {sidebarOpen ? sideMenuClose : hamburger}
          </button>
        </div>
        <BreadCrumb />
      </div>
      <div
        className={styles.login_user}
        style={{
          position: "relative",
        }}
      >
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <div>
              <img
                src={
                  userData?.organization_details?.organization_media_details
                    ?.url ||
                  userData?.media_details?.url ||
                  userProfile
                }
                className="logo-img"
                alt="profile img"
                style={{
                  backgroundColor: userData?.organization_details?.theme_color,
                  // objectFit: "contain",
                }}
              />
              <span>{checkRole(userData)}</span>
              <img src={arrow} alt="arrow" className={styles.arrowBottom} />
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="mnuDrop">
            {TopBarInfo.map((item, index) => renderDropDown(item, index))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <ChangeOwnerModal
        handleClose={handleClose}
        show={showChangeOwner}
        title={"Change Owner"}
        // setOrgUuid={setOrgUuid}
      />

      <ChangeOwnerConfirmationModal
        show={confirmChangeOwner}
        handleToggle={handleClose}
        title="This action will deactivate the existing organization admin account."
        confirm={() => {
          setShowChangeOwner(true);
          setConfirmChangeOwner(false);
        }}
      />
    </div>
  );
};
export default TopBar;
