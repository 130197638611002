import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { iIcon } from "../../assests/Icons/icon";

const UrlCodeToolTip = ({ text }: { text: string }) => {
  /**
   *
   * @returns  custom tooltip for Url extention field
   */
  const customTooltip = (
    <Tooltip
      style={{
        maxWidth: 250,
        backgroundColor:"#EDEDED"
      }}
    >
      {text}
    </Tooltip>
  );
  return (
    <OverlayTrigger placement="top-start" overlay={customTooltip} >
      <span>{iIcon}</span>
    </OverlayTrigger>
  );
};

export default UrlCodeToolTip;
