import Pagination from "rc-pagination";
import { nextIcon, prevIcon } from "../../assests/Icons/icon";

interface IProps {
  current: number;
  total: number;
  pageSize: number;
  setCurrentPage: (current: number) => void;
}

const ReactPagination = ({
  current,
  total,
  pageSize,
  setCurrentPage,
}: IProps) => {
  return (
    <Pagination
      className="pagination"
      prevIcon={prevIcon}
      nextIcon={nextIcon}
      current={current}
      total={total}
      jumpNextIcon={"..."}
      jumpPrevIcon={"..."}
      pageSize={pageSize}
      showTitle={false}
      onChange={(current: number) => setCurrentPage(current)}
    />
  );
};

export default ReactPagination;
