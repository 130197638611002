import { useEffect, useState } from "react";
import AuthLayout from "../../../components/layouts/AuthLayout";
import styles from "./resetpassword.module.scss";

import { useFormik } from "formik";
import { useLocation, useParams } from "react-router-dom";
import password from "../../../assests/password.svg";
import {
  notifyBugsnagError,
  parseJwt,
  showToast,
} from "../../../common/utils/Functions";
import InputField from "../../../components/theme/InputField";
import ThemeButton from "../../../components/theme/ThemeButton";
import useFetch from "../../../hooks/useFetch";
import { resetValSchema } from "../../../validations/AuthSchema";
import EmailSent from "../emailSent";

const ResetPassword = () => {
  const { token } = useParams();
  const { pathname } = useLocation();
  const isSetPassword = pathname.split("/").includes("set-password");

  const [uuid, setUuid] = useState("");
  const [sentEmail, setSentEmail] = useState(false);
  const isAdmin = !window.location.href.includes("mydashboard");

  /** Api definition */
  const [resetPasswordApi, { response, loading, error }] = useFetch(
    `/auth/reset-password/${uuid}/${token}`,
    {
      method: "post",
    }
  );

  /** Api definition */
  const [
    setPasswordApi,
    { response: setResponse, loading: setLoading, error: setError },
  ] = useFetch(`/organization/set-password/${token}`, {
    method: "post",
  });

  /** Handle api response */
  useEffect(() => {
    if (response) {
      showToast(response.message as string, "success");
      setSentEmail(true);
    }
    if (error) {
      showToast(error.message as string, "error");

      setSentEmail(false);
    }
  }, [response, error]);

  /** Handle api response */
  useEffect(() => {
    if (setResponse) {
      showToast(setResponse.message as string, "success");
      setSentEmail(true);
    }
    if (setError) {
      showToast(setError.message as string, "error");

      setSentEmail(false);
    }
  }, [setResponse, setError]);

  useEffect(() => {
    if (token && parseJwt(token)?.uuid) {
      setUuid(parseJwt(token).uuid);
    } else {
      showToast("Invalid token", "error");
    }
  }, [token]);

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: resetValSchema(false),
    onSubmit: (values) => {
      try {
        isSetPassword
          ? setPasswordApi({
              password: values.newPassword,
              role_id: isAdmin ? 1 : 2,
            })
          : resetPasswordApi({
              new_password: values.newPassword,
              role_id: isAdmin ? 1 : 2,
              // role_id: 2,
            });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "setPasswordApi || resetPasswordApi",
        });
      }
    },
  });

  return sentEmail ? (
    <EmailSent
      title={`Password ${isSetPassword ? "Set" : "Reset"} Successfully`}
      description={
        <p>
          Your password has been {isSetPassword ? "set" : "reset"} successfully,
          Please click below button to login.
        </p>
      }
      buttonTitle="Login"
    />
  ) : (
    <AuthLayout>
      <div className={styles.login}>
        <div className={styles.login_ByGoogle}>
          <div className={styles.formTitle}>
            <h2>{isSetPassword ? "Set" : "Reset"} Your Password</h2>
          </div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className={styles.formFields}
          autoComplete="off"
        >
          <InputField
            formik={formik}
            name="newPassword"
            label="New Password"
            placeholder="Enter New Password"
            icon={password}
            type="password"
            onKeyDown={(e) => {
              if (e.key === " ") {
                e.preventDefault();
              }
            }}
          />
          <InputField
            formik={formik}
            name="confirmPassword"
            label="Confirm Password"
            placeholder="Enter Confirm Password"
            icon={password}
            type="password"
            onKeyDown={(e) => {
              if (e.key === " ") {
                e.preventDefault();
              }
            }}
          />
          <div className={styles.btnWrap}>
            <ThemeButton
              loading={loading || setLoading}
              disabled={loading}
              type="submit"
              // onClick={() => formik.handleSubmit()}
            >
              {isSetPassword ? "Set" : "Reset"}
            </ThemeButton>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;
