// custom hook imports
import useFetch from "../../../hooks/useFetch";

// components import
import { RxCross1 } from "react-icons/rx";
import { toast } from "react-toastify";
import DashboardLayout from "../../../components/layouts/DashboardLayout";
import ConfirmationModal from "../../../components/modals/ConfirmationModal/ConfirmationModal";
import InactiveActiveModal from "../../../components/modals/InactiveActiveModal/InactiveActiveModal";
import AddEditPartnersModal from "../../../components/modals/addEditPartners/addEditPartnersModal";
import PageHeading from "../../../components/pageHeading";
import ReactTable from "../../../components/reactTable/ReactTable";
import ThemeButton from "../../../components/theme/ThemeButton";
// react and react-bootstrap imports
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import searchIcon from "../../../assests/svg/searchIcon.svg";
// type and style imports
import { crossIcon, deleteIcon, editIcon } from "../../../assests/Icons/icon";
import { partnersType } from "../../../types/partners";
import styles from "./partners.module.scss";

// yup , toastify and formik imports
import { useFormik } from "formik";
import * as yup from "yup";
import { notifyBugsnagError, showToast } from "../../../common/utils/Functions";

const Partners = () => {
  /**
   * @State to store searched value
   */
  const [searchValue, setSearchValue] = useState("");

  /**
   * @Action states to open @Active @Delete @Add_Edit Modals
   */
  const [showActive, setShowActive] = useState(false); //  It's use to open "In-active/Active" modal
  const [showDelete, setShowDelete] = useState(false); //  It's use to open "Delete" modal
  const [show, setShow] = useState(false); //  It's use to open "Add/Edit" modal
  const [editModal, setEditModal] = useState(false); //  It's use to change edit-form condition

  /**
   * @functions  to handle above ⬆️  @action states
   * 1. toggleActive   :- Used to handle "In-active/Active" modal
   * 2. toggleDelete   :- Used to handle "Delete" modal
   * 3. toggleAddEdit  :- Used to handle "Add/Edit" modal
   */
  const toggleActive = () => setShowActive(!showActive);
  const toggleDelete = () => setShowDelete(!showDelete);
  const toggleAddEdit = () => setShow(!show);

  /**
   * @Data_list states  these below states are used to store data which will render in React-table
   * 1. partnerList  :- Array of partners data list
   * 2. selectedItem :- Object of selceted partner data
   */
  const [partnerList, setParnterList] = useState<partnersType[]>();
  const [selectedItem, setSelectedItem] = useState<partnersType>();

  /**
   * @Pagination states
   * 1. pageLimit    :- store limit of data that will render on current page
   * 2. totalRecords :- store total number of data in partners api
   * 3. currentPage  :- store current page number
   */
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  /**
   * @Api_Definition variables
   * 1. listApi        :- Constant to handle list all partners data;
   * 2. addPartnerApi  :- Constant to handle Add partner
   * 3. editPartnerApi :- Constant to handle Edit partner
   * 4. statusApi      :- Constant to handle status of partner
   * 5. deleteApi      :- Constant to handle delete partner
   */

  /** ------- 1. @listApi ------ */
  const [
    listApi,
    { response: listResponse, error: listError, loading: partnerListLoading },
  ] = useFetch("/partners/list", {
    method: "post",
  });

  /** -------2. @addPartnerApi ------ */
  const [
    addPartnerApi,
    {
      response: addPartnerResponse,
      loading: addPartnerLoading,
      error: addPartnerError,
    },
  ] = useFetch("/partners/add", {
    method: "post",
  });

  /** -------3. @editPartnerApi ------ */
  const [
    editPartnerApi,
    {
      response: editPartnerResponse,
      loading: editPartnerLoading,
      error: editPartnerError,
    },
  ] = useFetch(`/partners/update/${selectedItem?.uuid}`, {
    method: "put",
  });

  /** -------4. @statusApi ------ */
  const [
    statusApi,
    { response: statusResponse, loading: statusLoading, error: statusError },
  ] = useFetch(`/partners/active-inactive/${selectedItem?.uuid}`, {
    method: "post",
  });

  /** -------5. @deleteApi ------ */
  const [
    deleteApi,
    { response: deleteResponse, loading: deleteLoading, error: deleteError },
  ] = useFetch(`/partners/delete/${selectedItem?.uuid}`, {
    method: "delete",
  });

  /**
   * @Call_apis by Use-Effect hook
   */

  /**
   * @Call all partner data using @listApi constant
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        listApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          search: searchValue,
        });
      } catch (e: any) {
        notifyBugsnagError(e, { api: "listApi" });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchValue, currentPage, pageLimit]);

  /**
   * @handle_Response of above api call using @listApi constant
   * => in this effect
   * 1. @setTotalRecords setState updates with total records
   * 2. @setParnterList  setState updates with incoming partner-data
   * 3. @if_statement    to handle error if api not responded
   */
  useEffect(() => {
    if (listResponse) {
      setTotalRecords(listResponse.data?.totalRecords);
      const updatedList = listResponse.data.list.map((item: any) => ({
        id: item.id,
        name: item.name,
        allow_third_party_integration: item?.allow_third_party_integration,
        no_of_services: item.services_count,
        is_active: item.is_active,
        uuid: item.uuid,
      }));
      setParnterList(updatedList);
    }
    if (listError) {
      showToast(listError.message as string, "error");
    }
  }, [listResponse, listError]);

  /**
   * @handle_AddApi_Response of form-submition api call using @addPartnerApi constant
   * => in this effect
   * 1. @if_statement  that it responded ok then show toast success message
   * 2. @toggleAddEdit this handler use to close Add/Edit Modal
   * 3. @if_statement  to handle error if api not responded
   */
  useEffect(() => {
    if (addPartnerResponse) {
      showToast(addPartnerResponse.message as string, "success");
      try {
        listApi({
          start: 0,
          limit: 10,
          search: searchValue,
        });
      } catch (e: any) {
        notifyBugsnagError(e, { api: "listApi" });
      }
      setCurrentPage(1);
      formik.resetForm();
      toggleAddEdit();
    }
    if (addPartnerError) {
      showToast(addPartnerError.message as string, "error");
    }
  }, [addPartnerResponse, addPartnerError]);

  /**
   * @handle_DeleteApi_Response on delete partner api call using @deleteApi constant
   * => in this effect
   * 1. @if_statement   that it responded ok then show toast success message
   * 2. @toggleDelete   this handler use to close Delete Modal
   * 3. @setParnterList update partner object that matched with deleted item id
   * 4. @if_statement   to handle error if api not responded
   */
  useEffect(() => {
    if (deleteResponse) {
      showToast(deleteResponse.message as string, "success");
      toggleDelete();
      try {
        listApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          search: searchValue,
        });
      } catch (e: any) {
        notifyBugsnagError(e, { api: "listApi" });
      }
    }
    if (deleteError) {
      showToast(deleteError.message as string, "error");
    }
  }, [deleteResponse, deleteError]);

  /**
   * @handle_EditApi_Response on form-submition partner api call using @editPartnerApi constant
   * => in this effect
   * 1. @if_statement   that it responded ok then show toast success message
   * 2. @toggleAddEdit  this handler use to close Add/Edit Modal
   * 3. @setParnterList update partner object that matched with edited item id
   * 4. @if_statement   to handle error if api not responded
   */
  useEffect(() => {
    if (editPartnerResponse) {
      showToast(editPartnerResponse.message as string, "success");
      toggleAddEdit();
      const updatedList = partnerList?.map((item: partnersType) =>
        selectedItem?.id === item.id
          ? {
              ...item,
              name: editPartnerResponse.data?.name,
              allow_third_party_integration:
                editPartnerResponse?.data?.allow_third_party_integration,
            }
          : item
      );
      setParnterList(updatedList);
    }
    if (editPartnerError) {
      showToast(editPartnerError.message as string, "error");
    }
  }, [editPartnerResponse, editPartnerError]);

  /**
   * @handle_StatusApi_Response on change active status of partner api call using @statusApi constant
   * => in this effect
   * 1. @if_statement   that it responded ok then show toast success message
   * 2. @toggleActive   this handler use to close Ative/Inactive Modal
   * 3. @setParnterList update partner object that matched with changed status item id
   * 4. @if_statement  to handle error if api not responded
   */
  useEffect(() => {
    if (statusResponse) {
      showToast(statusResponse.message as string, "success");
      toggleActive();
      const updatedList = partnerList?.map((item: partnersType) =>
        selectedItem?.id === item.id
          ? { ...item, is_active: item.is_active ? 0 : 1 }
          : item
      );
      setParnterList(updatedList);
    }
    if (statusError) {
      showToast(statusError.message as string, "error");
    }
  }, [statusResponse, statusError]);

  /**
   * @constant to store formik initial values
   */
  const initialValues = {
    name: "",
    allow_third_party_integration: 0,
  };

  /**
   * @constant to initialise useFormik hook
   */
  const formik = useFormik({
    initialValues,
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .max(50, "Partner name must be less than 50 characters.")
        .trim()
        .required("Please enter partner name."),
      allow_third_party_integration: yup.number().notRequired(),
    }),
    onSubmit: (values: partnersType) => {
      // Handle form submission here
      try {
        editModal ? editPartnerApi(values) : addPartnerApi(values);
      } catch (e: any) {
        notifyBugsnagError(e, { api: "editPartnerApi || addPartnerApi" });
      }
    },
  });

  /**
   * @Array :- for table headings that passed as a prop to React-Table component
   */
  const tableHeadings = [{ key: "name", label: "Name" }];

  /**
   * @Array :- for action button dropdown values and passes to React table component
   */
  const actionButtonOptions = [
    {
      name: "Edit",
      icon: editIcon,
      onClick: (item: partnersType) => {
        formik.resetForm();
        toggleAddEdit();
        formik.resetForm();
        setEditModal(true);
        setSelectedItem(item);
        formik.setFieldValue("name", item.name);
        formik.setFieldValue(
          "allow_third_party_integration",
          item.allow_third_party_integration
        );
      },
    },
    {
      name: "Inactive",
      icon: crossIcon,
      onClick: (item: partnersType) => {
        toggleActive();
        setSelectedItem(item);
      },
    },
    {
      name: "Delete",
      icon: deleteIcon,
      onClick: (item: partnersType) => {
        toggleDelete();
        setSelectedItem(item);
      },
    },
  ];
  return (
    <DashboardLayout>
      <Container className="h-100">
        <div className={styles.dash_page}>
          <PageHeading
            heading="Partners"
            subHeading="Here is the information about all your partners."
          >
            <div className={styles.search}>
              <div className={styles.searchField}>
                <img
                  src={searchIcon}
                  className={styles.searchIcon}
                  alt="search icon"
                />

                <input
                  type="text"
                  placeholder="Search by Partner"
                  className="me-3"
                  value={searchValue}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setSearchValue(e.target.value);
                  }}
                  autoComplete="off"
                />
                {searchValue ? (
                  <span
                    className={styles.crossIcon}
                    onClick={() => setSearchValue("")}
                  >
                    <RxCross1 />
                  </span>
                ) : null}
              </div>

              <ThemeButton
                onClick={() => {
                  toggleAddEdit();
                  setEditModal(false);
                  formik.resetForm();
                }}
              >
                {" "}
                Add New Partner
              </ThemeButton>
            </div>
          </PageHeading>

          <ReactTable
            headings={tableHeadings}
            data={partnerList || []}
            actionColumn
            statusColumn
            loading={partnerListLoading || !partnerList}
            actionButtonOptions={actionButtonOptions}
            currentPage={currentPage}
            itemPerPage={pageLimit}
            totalItems={totalRecords}
            setPerPageLimit={setPageLimit}
            setCurrentPage={setCurrentPage}
            pageLimit={pageLimit}
            pagination={true}
          />
          <ConfirmationModal
            handleToggle={toggleDelete}
            title="Are you sure you want to delete
                    this partner?"
            show={showDelete}
            confirm={() => deleteApi()}
            loading={deleteLoading}
          />

          <InactiveActiveModal
            loading={statusLoading}
            handleToggle={toggleActive}
            title={`Are you sure you want to ${
              selectedItem?.is_active ? "Deactivate" : "Activate"
            }
                  this partner?`}
            show={showActive}
            heading={selectedItem?.is_active ? "Deactivate" : "Activate"}
            confirm={() =>
              statusApi({
                is_active: selectedItem?.is_active ? 0 : 1,
              })
            }
          />

          <AddEditPartnersModal
            formik={formik}
            show={show}
            handleClose={toggleAddEdit}
            title={editModal ? "Edit Partner " : "Add Partner "}
            editModal={editModal}
            loading={addPartnerLoading || editPartnerLoading}
          />
        </div>
      </Container>
    </DashboardLayout>
  );
};
export default Partners;
