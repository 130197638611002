import { IoEyeOutline } from "react-icons/io5";
import ModalLayout from "../../layouts/ModalLayout";
import styles from "./addEditOrgnaization.module.scss";
import scaner from "../../../assests/scaner.svg";
import copyss from "../../../assests/copyss.svg";
import { qrcodeType } from "../../../pages/adminDashboard/organizations/details/details";
import { copyToClipBoard, openQrCode } from "../../../common/utils/Functions";
import NoDataFound from "../../noDataFound";
interface Iprops {
  handleClose: () => void;
  show: boolean;
  title?: string;
  editModal?: boolean;
  propertyQrCodeList?: qrcodeType[];
}

const QrCodelist = ({
  handleClose,
  show,
  title,
  editModal,
  propertyQrCodeList,
}: Iprops) => {
  const handleModalClose = () => {
    // Set the current step to 1 when closing the modal
    handleClose();
  };

  return (
    <>
      <ModalLayout
        title={title}
        show={show}
        // children={undefined}

        size={655}
        handleToggle={handleModalClose}
      >
        {!!propertyQrCodeList && propertyQrCodeList?.length > 0 ? (
          <div className={styles.copyUrl}>
            {propertyQrCodeList &&
              propertyQrCodeList?.map((item, index) => {
                return (
                  <div key={index + 1} className={styles.qrBox}>
                    <div className={styles.leftQr}>
                      {" "}
                      <img src={scaner} alt="img " />
                      <p className={styles.prop_name}>{item?.name}</p>
                    </div>
                    <div className={styles.rightQr}>
                      {" "}
                      <span
                        onClick={() => {
                          openQrCode(item.qr_code);
                        }}
                      >
                        {" "}
                        <IoEyeOutline /> View QR
                      </span>
                      <button
                        onClick={() => {
                          copyToClipBoard(item.copy_link);
                        }}
                      >
                        <img src={copyss} alt="img " /> Copy URL
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <NoDataFound />
        )}
      </ModalLayout>
    </>
  );
};

export default QrCodelist;
