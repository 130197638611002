import { useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { iIcon } from "../../../../assests/Icons/icon";
import {
  isValidUrl,
  notifyBugsnagError,
  showToast,
} from "../../../../common/utils/Functions";
import useFetch from "../../../../hooks/useFetch";
import { AddEditModalProps } from "../../../../types/modal/addEditModal";
import {
  customServiceSchema,
  propertyServiceSchema,
} from "../../../../validations/propertySchema";
import ModalLayout from "../../../layouts/ModalLayout";
import InputField from "../../../theme/InputField";
import LoadingBars from "../../../theme/LoadingBars";
import ThemeButton from "../../../theme/ThemeButton";
import styles from "../AddProperty.module.scss";
import { propertyServices } from "../initialValues";
import ServicesSelectBox, { optionsType } from "./ServicesSelectBox";
interface props {
  property_uuid?: string;
  serviceApi?: any;
  serviceList: any[];
  orgId?: number;
}
interface customItems {
  name: string;
  url_code: string;
}

export const UrlCodeToolTip = () => {
  const customTooltip = (
    <Tooltip id="serviceIdentifierTooltip" className={styles.customTooltip}>
      URL extension for the service
    </Tooltip>
  );
  return (
    <OverlayTrigger placement="top-start" overlay={customTooltip}>
      <span>{iIcon}</span>
    </OverlayTrigger>
  );
};

const SelectPropertyService = ({
  title,
  show,
  handleClose,
  property_uuid,
  serviceApi,
  serviceList,
  orgId,
}: AddEditModalProps & props) => {
  const [customServiceValidation, setCustomServiceValidation] = useState<any>();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [existedItems, setExistedItems] = useState<string[]>([]);
  const [removeItems, setRemoveItems] = useState<string[]>([]);
  const [showSelectedItems, setShowSelectedItems] = useState<optionsType[]>([]);
  const [customSelectedItems, setCustomSelectedItems] = useState<customItems[]>(
    []
  );
  const [submitError, setFieldError] = useState("");
  const [serviceOptions, setServiceOptions] = useState<optionsType[]>([]);
  const [showAddFields, setShowAddFields] = useState<boolean>(false);
  const [checkValidUrl, setCheckValidUrl] = useState<boolean>(false);

  /** ------- 1. @listApi ------ */
  const [
    listServiceApi,
    { response: serviceListResponse, error: serviceListError },
  ] = useFetch("/services/list", {
    method: "post",
  });

  /**
   * initialise property-service add api
   */
  const [
    serviceAddApi,
    {
      response: serviceAddRes,
      error: serviceAddError,
      loading: serviceAddLoading,
    },
  ] = useFetch("propertyservices/add", {
    method: "post",
  });

  /** Api definition to check service url duplication */
  const [
    validateURLIdentifierApi,
    {
      response: identifierURLres,
      loading: identifierURLLoading,
      error: identifierURLError,
    },
  ] = useFetch("/common/validate-identifier", {
    method: "post",
  });

  /** Api definition */
  const [
    validateNameApi,
    { response: NameResponse, loading: NameLoading, error: NameError },
  ] = useFetch("/common/validate-name", {
    method: "post",
  });

  const formik = useFormik({
    initialValues: propertyServices,
    validationSchema: propertyServiceSchema,
    onSubmit(values: any) {
      if (showSelectedItems?.length > 0) {
        try {
          serviceAddApi({
            property_uuid,
            add_service_uuids: handleCheckIfItemsExist(selectedItems),
            remove_service_uuids: removeItems,
            add_custom_services: customSelectedItems,
          });
        } catch (e: any) {
          notifyBugsnagError(e, {
            api: "serviceAddApi",
          });
        }
      } else {
        setFieldError("Please select atleast one service");
      }
    },
  });

  const customFieldsFormik = useFormik({
    initialValues: {
      name: "",
      url_code: "",
    },
    validationSchema: customServiceValidation,
    onSubmit: (values: any) => {
      setTimeout(() => {
        customFieldsFormik.resetForm();
      }, 1000);
    },
  });
  const handleCheckIfItemsExist = (selectedItems: string[]) => {
    return selectedItems.filter((item) => !existedItems.includes(item));
  };
  /**
   * @Call all service data using @listApi constant
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        listServiceApi({
          start: 0,
          limit: -1,
          search: "",
          is_active: 1,
          property_uuid: "",
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "listServiceApi",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (serviceListResponse) {
      const res = serviceListResponse?.data?.list?.map(
        (item: { uuid: string; name: string }) => {
          return {
            value: item?.uuid,
            label: item?.name,
          };
        }
      );
      setServiceOptions([...res]);
    }
    if (serviceListError) {
      showToast(serviceListError.message as string, "error");
    }
  }, [serviceListResponse, serviceListError]);

  useEffect(() => {
    if (serviceAddRes) {
      showToast(serviceAddRes.message as string, "success");
      try {
        serviceApi({
          property_uuid,
          start: 0,
          limit: -1,
          search: "",
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "serviceApi",
        });
      }
      setSelectedItems([]);
      setCustomSelectedItems([]);
      setShowSelectedItems([]);
      handleCloseModal();
      setExistedItems([]);
    }
    if (serviceAddError) {
      showToast(serviceAddError.message as string, "error");
    }
  }, [serviceAddRes, serviceAddError]);

  useEffect(() => {
    let nameStatus;
    let urlStatus;

    if (identifierURLError) {
      urlStatus = identifierURLError?.status;
      customFieldsFormik.setFieldError("url_code", " URL abbreviation exist.");
      customFieldsFormik.setFieldTouched("url_code", true, false);
      setCheckValidUrl(false);
    }
    if (identifierURLres) {
      urlStatus = identifierURLres?.status;
      customFieldsFormik.setFieldTouched("url_code", false, false);
      setCheckValidUrl(true);
    }
    if (NameError) {
      nameStatus = NameError?.status;
      customFieldsFormik.setFieldError("name", "Service name exist.");
      customFieldsFormik.setFieldTouched("name", true, false);
      setCheckValidUrl(false);
    }
    if (NameResponse) {
      nameStatus = NameResponse?.status;
      customFieldsFormik.setFieldTouched("name", false, false);
      setCheckValidUrl(true);
    }
    const validationSchema = customServiceSchema(nameStatus, urlStatus);
    setCustomServiceValidation(validationSchema);
  }, [NameError, NameResponse, identifierURLError, identifierURLres]);

  useEffect(() => {
    const formikValueName = customFieldsFormik?.values["name"];
    const formikValueUrlCode = customFieldsFormik?.values["url_code"];
    if (
      formikValueName &&
      formikValueUrlCode &&
      identifierURLres &&
      NameResponse
    ) {
      setShowSelectedItems([
        ...showSelectedItems,
        {
          value: formikValueName,
          label: formikValueName,
        },
      ]);
      setCustomSelectedItems([
        ...customSelectedItems,
        {
          name: formikValueName,
          url_code: formikValueUrlCode,
        },
      ]);
      const validationSchema = customServiceSchema(1, 1);
      setCustomServiceValidation(validationSchema);
      customFieldsFormik.resetForm();
      setShowAddFields(false);
      setTimeout(() => {
        customFieldsFormik.setFieldTouched("url_code", false);
        customFieldsFormik.setFieldTouched("name", false);
      }, 1000);
    }
    if (NameResponse) {
      if (formikValueName && formikValueUrlCode) {
        customFieldsFormik.setFieldError("name", "");
        customFieldsFormik.setFieldTouched("name", false);
      }
    }
    if (identifierURLres) {
      if (formikValueUrlCode && formikValueName) {
        customFieldsFormik.setFieldError("url_code", "");
        customFieldsFormik.setFieldTouched("url_code", false);
      }
    }
  }, [identifierURLError, identifierURLres, NameError, NameResponse]);

  useEffect(() => {
    if (serviceList) {
      const updateSelectedItemsArray = serviceList?.map((item: any) => {
        return item?.service_details?.uuid;
      });
      setSelectedItems([...updateSelectedItemsArray]);
      setExistedItems([...updateSelectedItemsArray]);
    }
  }, [serviceList]);

  const handleCloseModal = () => {
    if (!serviceAddLoading) {
      setShowAddFields(false);
      handleClose();
      setFieldError("");
      customFieldsFormik.setFieldValue("url_code", "");
      customFieldsFormik.setFieldValue("name", "");
      customFieldsFormik.resetForm();
      setCustomSelectedItems([]);
      setSelectedItems([]);
      setShowSelectedItems([]);
    }
  };

  useEffect(() => {
    if (showSelectedItems?.length > 0) {
      setFieldError("");
    }
  }, [showSelectedItems]);

  /**
   * function tu  handle custom services logic
   */
  const handleCustomServiceSubmit = () => {
    let nameStatus = 1;
    let urlStatus = 1;
    const formikValueUrlCode: string = customFieldsFormik.values["url_code"];
    const formikValueName: string = customFieldsFormik.values["name"];
    if (
      customSelectedItems?.some(
        (item) =>
          item.name.toLocaleLowerCase() === formikValueName.toLocaleLowerCase()
      )
    ) {
      customFieldsFormik.setFieldError("name", "Service name exist.");
      customFieldsFormik.setFieldTouched("name", true);
      nameStatus = 0;
      // customFieldsFormik.setFieldTouched("url_code", true);
    } else if (
      customSelectedItems?.some(
        (item) =>
          item.url_code.toLocaleLowerCase() ===
          formikValueUrlCode.toLocaleLowerCase()
      )
    ) {
      customFieldsFormik.setFieldError("url_code", "URL abbreviation exist.");
      customFieldsFormik.setFieldTouched("url_code", true);

      urlStatus = 0;
      // customFieldsFormik.setFieldTouched("url_code", true);
    } else {
      if (isValidUrl(formikValueName)) {
        validateNameApi({
          type: 2,
          name: formikValueName,
          organization_id: orgId,
        })
          .then(() => {})
          .catch((err) => {
            customFieldsFormik?.setFieldError("name", err?.message);
          });
      }
      if (isValidUrl(formikValueUrlCode)) {
        validateURLIdentifierApi({
          type: 2,
          url_code: formikValueUrlCode,
        })
          .then(() => {})
          .catch((err) => {
            customFieldsFormik?.setFieldError("url_code", err?.message);
          });
      }
    }
    const validationSchema = customServiceSchema(nameStatus, urlStatus);
    setCustomServiceValidation(validationSchema);
    setTimeout(() => {
      customFieldsFormik.handleSubmit();
    }, 1000);
  };

  useEffect(() => {
    if (show) {
      if (serviceList) {
        const updateSelectedItemsArray = serviceList?.map((item: any) => {
          return item?.service_details?.uuid;
        });
        setSelectedItems([...updateSelectedItemsArray]);
        setExistedItems([...updateSelectedItemsArray]);
      }
    }
  }, [show]);

  return (
    <ModalLayout
      title={title}
      show={show}
      // children={undefined}
      size={450}
      handleToggle={handleCloseModal}
    >
      <Container>
        <Row className="g-3">
          <Col sm={12}>
            <ServicesSelectBox
              setCustomSelectedItems={setCustomSelectedItems}
              customSelectedItems={customSelectedItems}
              label="Services"
              setShowSelectedItems={setShowSelectedItems}
              showSelectedItems={showSelectedItems}
              options={serviceOptions}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              setRemoveItems={setRemoveItems}
              removeItems={removeItems}
              setExistedItems={setExistedItems}
              existedItems={existedItems}
            />
          </Col>
          <Col
            style={{
              position: "relative",
              marginBottom: "5px",
            }}
          >
            {submitError && (
              <span
                className={styles.error}
                style={{
                  position: "absolute",
                  right: "9px",
                  color: "red",
                  fontSize: "12px",
                  top: "-18px",
                }}
              >
                {submitError}
              </span>
            )}
          </Col>
          {!showAddFields && (
            <Col
              sm={12}
              style={{
                margin: 0,
              }}
            >
              <div className={styles.addServices}>
                <p>To add custom services, click below</p>
                <Button
                  variant="link"
                  onClick={() => setShowAddFields(true)}
                  disabled={serviceAddLoading}
                >
                  {" "}
                  Add Service
                </Button>
              </div>
            </Col>
          )}
        </Row>
        {showAddFields && (
          <Row
            className="g-3"
            style={{
              margin: 0,
            }}
          >
            <Col
              sm={12}
              style={{
                margin: 0,
              }}
            >
              <InputField
                name="name"
                label="Service Name"
                formik={customFieldsFormik}
                onChange={(e) => {
                  if (customFieldsFormik.errors.name) {
                    customFieldsFormik?.setFieldError("name", "");
                    customFieldsFormik?.setFieldTouched("name", false, false);
                  }
                  customFieldsFormik?.setFieldValue("name", e.target.value);
                }}
                placeholder="Enter Service Name"
              >
                {NameLoading && (
                  <div className="input_child">
                    <LoadingBars color="#676d7a" />
                  </div>
                )}
              </InputField>
              {/* </div> */}
            </Col>
            <Col sm={12}>
              <div className={styles.addCateg}>
                <label>
                  {" "}
                  Service URL Abbreviation <UrlCodeToolTip />
                </label>
                <label
                  htmlFor="url_code"
                  className={styles.uploadInput}
                  style={{
                    borderColor:
                      formik.touched.url_code && formik.errors.url_code
                        ? "red"
                        : "",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Service URL Abbreviation"
                    style={{
                      display: "inline-block",
                      outline: "none",
                      border: "none",
                    }}
                    name={"url_code"}
                    onChange={(e) => {
                      customFieldsFormik.setFieldValue(
                        "url_code",
                        e.target.value
                      );
                      customFieldsFormik?.setFieldTouched(
                        "url_code",
                        false,
                        false
                      );
                      customFieldsFormik?.setFieldError("url_code", "");
                    }}
                    // value={formValue.url_code}

                    value={customFieldsFormik.values.url_code}
                  />
                  <ThemeButton
                    className={`primary footerModalBtn ${styles.btnBrowse}`}
                    onClick={handleCustomServiceSubmit}
                    style={{
                      minHeight: 0,
                    }}
                    disabled={serviceAddLoading || identifierURLLoading}
                    loading={identifierURLLoading}
                  >
                    Add
                  </ThemeButton>
                </label>
                {customFieldsFormik.touched.url_code &&
                  customFieldsFormik.errors.url_code && (
                    <span
                      className={styles.error}
                      style={{
                        position: "absolute",
                        right: 0,
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {(customFieldsFormik.errors.url_code as string) ?? ""}
                    </span>
                  )}
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <div className="d-flex gap-3 py-4 justify-content-center">
            <ThemeButton
              variant="secondary"
              onClick={handleCloseModal}
              disabled={serviceAddLoading}
            >
              Cancel
            </ThemeButton>
            <ThemeButton
              loading={serviceAddLoading}
              onClick={() => {
                formik.setFieldTouched("add_service_uuids", true);
                formik.setFieldValue("add_service_uuids", []);
                formik.handleSubmit();
              }}
            >
              Done
            </ThemeButton>
          </div>
        </Row>
      </Container>
    </ModalLayout>
  );
};

export default SelectPropertyService;
