import React, { useEffect } from "react";
import ModalLayout from "../../../layouts/ModalLayout";
import { Col, Container, Row } from "react-bootstrap";
import InputField from "../../../theme/InputField";
import { AddEditModalProps } from "../../../../types/modal/addEditModal";
import ThemeButton from "../../../theme/ThemeButton";
import { useFormik } from "formik";
import * as yup from "yup";
import useFetch from "../../../../hooks/useFetch";
import { toast } from "react-toastify";
import {
  notifyBugsnagError,
  showToast,
} from "../../../../common/utils/Functions";
interface prop {
  room: any;
}
const EditRoomModal = ({
  show,
  handleClose,
  room,
}: AddEditModalProps & prop) => {
  /**
   * initialise property-EMPLOYEE add api
   */
  const [
    PropertyRoomEditApi,
    {
      response: PropertyRoomRes,
      error: PropertyRoomError,
      loading: PropertyRoomLoading,
    },
  ] = useFetch(`propertyrooms/update/${room?.uuid}`, {
    method: "put",
  });

  const formik = useFormik({
    initialValues: { name: "" },
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .trim()
        .max(50, "Room name must be less than 50 characters.")
        .required("Please enter room name."),
    }),
    onSubmit(values: any) {
      try {
        PropertyRoomEditApi({
          ...values,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "PropertyRoomEditApi",
        });
      }
    },
  });

  useEffect(() => {
    if (room) {
      formik.setFieldValue("name", room?.name);
    }
  }, [room]);

  /** Handle @PropertyEmployeeAddApi response */
  useEffect(() => {
    if (PropertyRoomRes) {
      showToast(PropertyRoomRes.message as string, "success");
      formik.resetForm();
      handleClose();
    }
    if (PropertyRoomError) {
      showToast(PropertyRoomError.message as string, "error");
    }
  }, [PropertyRoomRes, PropertyRoomError]);

  return (
    <ModalLayout
      title={"Edit Room"}
      show={show}
      size={450}
      handleToggle={handleClose}
    >
      <Container>
        <Row className="mb-4">
          <Col xs={12}>
            <InputField
              name="name"
              formik={formik}
              label="Room name"
              placeholder="Enter room name"
            />
          </Col>
        </Row>
        <Row className="mt-4 d-flex justify-content-center">
          <div className="d-flex gap-3 py-4 justify-content-center">
            <ThemeButton variant="secondary" onClick={handleClose}>
              Cancel
            </ThemeButton>
            <ThemeButton
              loading={PropertyRoomLoading}
              onClick={() => formik.handleSubmit()}
            >
              Save
            </ThemeButton>
          </div>
        </Row>
      </Container>
    </ModalLayout>
  );
};

export default EditRoomModal;
