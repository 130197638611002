import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { useParams } from "react-router-dom";
import { tipIcon } from "../../../../assests/Icons/icon";
import searchIcon from "../../../../assests/svg/searchIcon.svg";
import DashboardLayout from "../../../../components/layouts/DashboardLayout";
import QrCodelist from "../../../../components/modals/addEditOrgnaization/qrCodelist";
import ReactTable from "../../../../components/reactTable/ReactTable";
import InputField from "../../../../components/theme/InputField";
import ThemeButton from "../../../../components/theme/ThemeButton";
import TipAmountModal from "../../../../components/tipAmountModal/tipAmountModal";
import useFetch from "../../../../hooks/useFetch";
import styles from "../tippers/tippers.module.scss";

const ViewTips = () => {
  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [tipList, setTipList] = useState<any>([]);
  const [showTip, setShowTip] = useState(false);
  const [dashboardTipItems, setDashboardTipItems] = useState<any>([]);
  const handleTipClose = () => setShowTip(false);
  const { tip_uuid } = useParams();

  /** Api definition */
  const [
    getTipperData,
    {
      response: getTipperDataResponse,
      error: getTipperDataError,
      loading: getTipperDataLoading,
    },
  ] = useFetch("/tippers/tip-list", {
    method: "POST",
  });
  // Function to handle closing the add/edit modal
  const handleClose = () => setShow(false);

  /**
   * @Array :- for table headings that passed as a prop to React-Table component
   */
  const headings = [
    { key: "org_name", label: "Organization Name" },
    { key: "name", label: "Property Name" },
    {
      key: "amount",
      label: "Net Tip Amount",
      textAlign: "right",
    },
    { key: "date", label: "Date", textAlign: "center" },
  ];

  /**
   * @Call all partner data using @listApi constant
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      setTipList([]);
      getTipperData({
        start: (currentPage - 1) * pageLimit,
        limit: pageLimit,
        search: searchValue,
        tipper_uuid: tip_uuid,
      });
    }, 500);
    return () => clearTimeout(timer);
  }, [currentPage, pageLimit, searchValue]);

  useEffect(() => {
    if (getTipperDataResponse) {
      setTotalRecords(getTipperDataResponse?.data?.totalRecords);
      const setTipData = getTipperDataResponse?.data?.list?.map((item: any) => {
        return {
          name: item?.property_name,
          org_name: item?.organization_details?.name,
          amount: item?.net_tip_amount ? (
            <span
              style={{
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              {item?.net_tip_amount?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
              <span
                onClick={() => {
                  setDashboardTipItems([
                    {
                      label: "Total Trans. Amount",
                      value: item?.total_amount
                        ? item?.total_amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        : "--",
                    },
                    {
                      label: "Stripe Fee",
                      value: item?.stripe_fee
                        ? item?.stripe_fee.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        : "--",
                    },
                    {
                      label: "TopTipper Fee",
                      value: item?.top_tipper_fees
                        ? item?.top_tipper_fees.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        : "--",
                    },
                    {
                      label: "Net Tip Amount",
                      value: item?.net_tip_amount
                        ? item?.net_tip_amount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        : "--",
                    },
                  ]);
                  setShowTip(true);
                }}
              >
                {" "}
                {tipIcon}
              </span>
            </span>
          ) : (
            "--"
          ),
          date: moment(item?.createdAt).format("MM/DD/YYYY") ?? "--",
        };
      });
      setTipList([...setTipData]);
    }
  }, [getTipperDataError, getTipperDataResponse]);

  return (
    <DashboardLayout>
      <div className={styles.dash_page}>
        <Container>
          <Row className="g-xl-4 g-3">
            <Col xxl={12} xl={12}>
              <div
                className={`${styles.dashboardHeading} ${styles.orgnaizationDetails}`}
              >
                <div className={styles.alltips}>
                  <h2>View Tips</h2>
                  <p>Here is the information about all tips.</p>
                </div>
                <div className={styles.search}>
                  <div className={styles.searchField}>
                    <InputField
                      name="search_property_name"
                      icon={searchIcon}
                      placeholder="Search by Property Name"
                      value={searchValue}
                      onChange={(e) => {
                        setCurrentPage(1);
                        setSearchValue(e.target.value);
                      }}
                      autoComplete="off"
                    />
                    {searchValue ? (
                      <span
                        className={styles.crossIcon}
                        onClick={() => setSearchValue("")}
                      >
                        <RxCross1 />
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </Col>

            <Col xxl={12} xl={12}>
              <div className={`${styles.userNameMain} `}>
                <div className={`${styles.userNameLeft} `}>
                  <div className={`${styles.userNameRight} `}>
                    <h5>
                      <span>S</span>{" "}
                      {getTipperDataResponse?.data?.stripe_customer_id ?? "--"}
                    </h5>
                  </div>{" "}
                  <div className={styles.emailsName}>
                    {/* <h2>{getTipperDataResponse?.data?.name ?? "--"}</h2> */}
                    <div
                      style={{
                        color: "#676D7A",
                      }}
                    >
                      {" "}
                      <span style={{ fontWeight: 500 }}>Email - </span>{" "}
                      {getTipperDataResponse?.data?.email ?? "--"}
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col xxl={12} xl={12}>
              <ReactTable
                actionColumn={false}
                statusColumn={false}
                data={tipList || []}
                headings={headings}
                currentPage={currentPage}
                totalItems={totalRecords}
                setCurrentPage={setCurrentPage}
                pagination={true}
                setPerPageLimit={setPageLimit}
                itemPerPage={pageLimit}
                loading={!tipList || getTipperDataLoading}
              />
              <TipAmountModal
                show={showTip}
                handleClose={handleTipClose}
                title="Tip Amount Information"
                tipItems={dashboardTipItems}
              >
                <ThemeButton onClick={() => handleTipClose()}>
                  Close
                </ThemeButton>
              </TipAmountModal>
              <QrCodelist
                show={show}
                handleClose={handleClose}
                title="Property Level QR Codes"
                // editModal={editModal}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </DashboardLayout>
  );
};
export default ViewTips;
