import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { globalErrorMesg } from "../common/globalVariables";
import { clearUserLocalData, getUserToken } from "../common/utils/Functions";

interface extraProps {
  url?: string;
}

const useFetch = (
  url: string,
  config?: AxiosRequestConfig,
  formdata?: string,
  isSecretKey?: boolean
): [
  (
    data?: any,
    rest?: AxiosRequestConfig & extraProps
  ) => Promise<AxiosResponse<any>>,
  { response: any; loading: boolean; error: AxiosError; errorMessage: string }
] => {
  const [response, setResponse] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(undefined);
  const [errorMessage, setErrorMessage] = useState<string>("");
  // const pathname = useLocation().pathname;
  // const uuid = pathname.split("/")[0];
  // console.log("uuid", uuid);
  const { uuid } = useParams();

  const navigate = useNavigate();
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });
  const loadQuery = async (
    data?: any,
    rest?: AxiosRequestConfig & extraProps
  ) => {
    const token = getUserToken(`${uuid}`);
    const headers =
      token && !isSecretKey
        ? {
            Authorization: token,
            secretkey: process.env.REACT_APP_SECRET_KEY,
            "Content-Type": formdata
              ? "multipart/form-data"
              : "application/json",
          }
        : {
            secretkey: process.env.REACT_APP_SECRET_KEY,
            "Content-Type": formdata
              ? "multipart/form-data"
              : "application/json",
          };
    return new Promise<AxiosResponse<any>>((resolve, reject) => {
      setLoading(true);
      instance({
        url: `${rest?.url ?? url}`,
        ...config,
        data,
        headers,
        ...rest,
      })
        .then((response: AxiosResponse) => {
          if (response.data.code === 401 || response.data.code === 403) {
            clearUserLocalData(`${uuid}`);
            setTimeout(() => {
              navigate("/");
            }, 1000);
          }
          if (
            response.data.code === 200 ||
            response.data.code === 201 ||
            response.data.code === 204
          ) {
            resolve(response.data);
            setError(undefined);
            response.data != null && setResponse(response.data);
          } else {
            setError(response?.data);
            setErrorMessage(response?.data?.message ?? globalErrorMesg);
            setResponse(undefined);
          }
          setLoading(false);
        })
        .catch((e) => {
          if (e.response?.status === 401 || e.response?.status === 403) {
            clearUserLocalData(`${uuid}`);
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else if (e.response?.status === 404) {
            setResponse(undefined);
            navigate("/not-found");
          } else {
            setResponse(undefined);
          }
          if (e?.response?.status === 400) {
            reject(e?.response?.data);
          }
          setErrorMessage(e?.response?.data?.message ?? globalErrorMesg);
          setError(e.response?.data);
          setTimeout(() => {
            setLoading(false);
          }, 1);
        });
    });
  };
  return [loadQuery, { response, loading, error, errorMessage }];
};
export default useFetch;
