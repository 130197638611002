import { FormikProps } from "formik";
import { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import styles from "../../../src/components/theme/MultiSelect.module.scss";
import { downArrow } from "../../assests/Icons/icon";

export interface Property {
  label: string;
  value: string;
  loading?: boolean;
  isChecked?: boolean;
}
interface MultiSelectFieldProps {
  properties: Property[];
  formik: FormikProps<any>;
  name: string;
  loading?: boolean;
  value?: string[];
  editModal?: boolean;
  updatedPropertyItems: Property[];
  removeItems: Property[];
  setUpdatedPropertyItems: React.Dispatch<React.SetStateAction<Property[]>>;
  setRemoveItems: React.Dispatch<React.SetStateAction<Property[]>>;
  formikErrorMesg?: any;
  propertyUuid?: {
    label: string;
    value: string;
  };
}
const MultiSelectField: React.FC<MultiSelectFieldProps> = ({
  properties,
  formik,
  name,
  editModal,
  updatedPropertyItems,
  removeItems,
  setUpdatedPropertyItems,
  setRemoveItems,
  formikErrorMesg,
  propertyUuid,
}) => {
  /** this state is used to display the dropdown items */
  const [dropdownItems, setDropdownnItems] = useState<Property[]>([]);
  const formikError = formik?.touched[name] ? formik?.errors[name] : null;
  /**This state is used to display the selected items by user */
  const [selectedItems, setSelectedItems] = useState<Property[]>([]);
  const [newProperty, setnewProperty] = useState<Property[]>([]);
  const [initialSelectedItems, setInitialSelectedItems] = useState<Property[]>(
    []
  );

  /**@function helps to be checked items if it is selected in option field */
  const handleChange = (property: Property, index: number) => {
    const res = [...dropdownItems];
    res[index] = {
      ...property,
      isChecked: !property?.isChecked,
    };
    setDropdownnItems([...res]);
  };

  /** @function that helps to display properties and remove properties */
  const handleCheckboxChange = (property: Property) => {
    if (selectedItems.some((item) => item.value === property.value)) {
      // Remove the item if it's already selected
      const updatedSelectedItems = selectedItems.filter(
        (item) => item.value !== property.value
      );

      setSelectedItems(updatedSelectedItems);
    } else {
      // Add the item if it's not selected
      const isAlreadyRemoved = removeItems.some(
        (removed) => removed.value === property.value
      );

      if (!isAlreadyRemoved) {
        // If the item was not previously removed, add it to the updated items
        const updatedItems = [
          ...selectedItems,
          { ...property, isChecked: true },
        ];
        setSelectedItems(updatedItems);
      } else {
        // If the item was previously removed, remove it from removeItems
        const updatedRemoveItems = removeItems.filter(
          (removed) => removed.value !== property.value
        );
        setRemoveItems(updatedRemoveItems);
      }
    }
  };

  /** useEffect which sets the value in formik */
  useEffect(() => {
    const values = selectedItems?.map((item) => {
      return item?.value;
    });
    const updatedItems = [...selectedItems];
    const filterData = updatedItems.filter(
      (item: any) => !newProperty.map((it) => it.value).includes(item.value)
    );
    setUpdatedPropertyItems(filterData);
    formik?.setFieldValue(name, [...values]);
  }, [selectedItems]);

  /** @function to handle removal of selectedItem from array */
  const removeSelectedItem = (property: Property) => {
    // Add the removed item to the removeItems state
    setRemoveItems((prevRemoveItems) => [...(prevRemoveItems ?? []), property]);
    const updatedItems = selectedItems.filter(
      (item) => item.value !== property.value
    );
    setSelectedItems(updatedItems);
  };

  /** if editModal is open than set selected items in formik */
  useEffect(() => {
    if (editModal) {
      setSelectedItems([...formik?.values[name]]);
      setnewProperty([...formik?.values[name]]);
    }
  }, [editModal]);

  useEffect(() => {
    setInitialSelectedItems(
      formik?.values[name]?.map((value: string) => ({
        label: value,
        value,
        isChecked: true, // Set isChecked to true for initial selected items
      })) ?? []
    );
  }, [formik?.values[name]]);

  useEffect(() => {
    const setDropItems = properties?.map((item) => {
      const isRemoved =
        removeItems.some((removed) => removed.value === item.value) &&
        !editModal;
      const isChecked =
        isRemoved ||
        initialSelectedItems.some(
          (selected) => selected.value === item.value
        ) ||
        updatedPropertyItems.some((updated) => updated.value === item.value);
      return {
        ...item,
        isChecked: isChecked || false,
      };
    });
    setDropdownnItems([...setDropItems]);
  }, [
    properties,
    editModal,
    initialSelectedItems,
    updatedPropertyItems,
    removeItems,
  ]);

  useEffect(() => {
    if (propertyUuid?.value && !editModal) {
      setSelectedItems([
        {
          label: propertyUuid.label,
          value: propertyUuid.value,
          isChecked: true,
        },
      ]);
    }
  }, [propertyUuid?.value]);
  return (
    <>
      <div className={styles.prop_label}>Properties</div>
      <Dropdown>
        <Dropdown.Toggle
          variant="light"
          className={styles.dropdownToggle}
          style={{ gap: "4px" }}
        >
          <span style={{ color: "#a9b0bf" }}>
            Select Properties for Manager Access
          </span>
          {downArrow}
        </Dropdown.Toggle>
        {formikError && formikErrorMesg ? (
          <span className={styles.error}>
            {typeof formikErrorMesg?.value === "string"
              ? formikErrorMesg?.value
              : ""}
          </span>
        ) : (
          <span className={styles.error}>
            {typeof formikError === "string" ? formikError : ""}
          </span>
        )}

        <Dropdown.Menu
          className={styles.dropdownMenu}
          style={{ minWidth: "0%" }}
        >
          {dropdownItems?.length > 0 ? (
            dropdownItems?.map((item, index: number) => {
              return (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: 10,
                      padding: "12px 10px",
                    }}
                    onClick={() => {
                      handleCheckboxChange(item);
                    }}
                  >
                    <Form.Check
                      type="checkbox"
                      onChange={() => handleChange(item, index)}
                      checked={selectedItems.some(
                        (item2) => item2.value === item.value
                      )}
                    />
                    <span style={{ wordBreak: "break-all" }}>{item.label}</span>
                  </div>
                  {properties?.length - 1 !== index && <Dropdown.Divider />}
                </>
              );
            })
          ) : (
            <span className=" d-block text-center p-0">No Options</span>
          )}
        </Dropdown.Menu>
      </Dropdown>
      {selectedItems?.length > 0 && (
        <div className={styles.selectedItems}>
          {selectedItems.map((property) => {
            return (
              <p
                key={property?.value}
                style={{
                  flexShrink: 1,
                  wordBreak: "break-word",
                  minHeight: "inherit",
                  // padding: "22px 5px",
                }}
              >
                {property?.label}
                <IoMdClose
                  style={{
                    fontSize: "0.875rem",
                    cursor: "pointer",
                    flexShrink: 0,
                    textAlign: "end",
                  }}
                  onClick={() => {
                    removeSelectedItem(property);
                  }}
                />
              </p>
            );
          })}
        </div>
      )}
    </>
  );
};

export default MultiSelectField;
