import { ReactElement } from "react";
import { Dropdown, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { checkIcon, tipIcon, unPause } from "../../assests/Icons/icon";
import {
  actionButtonOptionsType,
  reactTableHeadingType,
  reactTablePropsType,
} from "../../types/reactTable";
import NoDataFound from "../noDataFound";
import ReactPagination from "../paginations/Pagination";
import ReactLoader from "../reactLoader/ReactLoader";
import styles from "./ReactTable.module.scss";

/**
 * @param row  : a string value either "Active" or "Inactive"
 * @returns  Status heading if @statusColumn is true
 */
const renderStatus = (row: string): ReactElement => {
  return (
    <td>
      <div className={styles.statusDiv}>
        <span className={row ? styles.green : styles.red}> </span>
        {row ? "Active" : "Inactive"}
      </div>
    </td>
  );
};

/**
 * @param row  : a string value either "Complete" or "Restricted" and anything else
 * @returns  actve  row when @activeColumn key in heading is true
 */
const renderCustomActiveColumn = (row: number): ReactElement => {
  let statusText = "";
  let tooltipText = "";

  switch (row) {
    case 1:
      statusText = "Complete";
      tooltipText = "Can keep receiving payments without any problem.";
      break;
    case 2:
      statusText = "Enabled";
      tooltipText =
        "Can receive the payments for now but soon they will have to provide more information to keep receiving the payments.";
      break;
    case 3:
      statusText = "Error";
      tooltipText =
        "There was an error with the Stripe account, preventing payments, so payments cannot be received until the setup is complete.";
      break;
    case 4:
      statusText = "Pending";
      tooltipText =
        "Stripe account is under review. Payments cannot be received until the verification is completed.";
      break;
    case 5:
      statusText = "InProgress";
      tooltipText =
        "Stripe account setup is in progress. Payments will be enabled once the setup is complete.";
      break;
    default:
      statusText = "Not Setup";
      tooltipText =
        "Stripe account is not set up yet, so payments cannot be received until the setup is complete.";
      break;
  }

  const urlCodeToolTip = (tooltipText: string) => {
    return (
      <OverlayTrigger
        placement="top-start"
        // delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip
            className={styles.customTooltip}
            id="button-tooltip"
            style={{ maxWidth: "350px" }}
          >
            {tooltipText}
          </Tooltip>
        }
      >
        <span>{tipIcon}</span>
      </OverlayTrigger>
    );
  };

  return (
    <div className={styles.statusDivs}>
      <span
        className={
          row === 1 ? styles.green : row === 2 ? styles.green : styles.gray
        }
      />
      <div style={{ whiteSpace: "nowrap", display: "flex", gap: "5px" }}>
        {statusText} {urlCodeToolTip(tooltipText)}
      </div>
    </div>
  );
};
/**
 * @param actionButtonOptions :  A Array of objects that render links in action button dropdown
 * @returns Action heading if @actionColumn is true
 */

const renderAction = (
  actionButtonOptions: actionButtonOptionsType[],
  rootItem: any
): ReactElement => {
  return (
    <td
      style={{
        textAlign: "center",
      }}
    >
      <Dropdown
        align="end"
        // onClick={(e) => e.stopPropagation()}
        className={styles.dropDots}
      >
        <Dropdown.Toggle variant="light" id="dropdown-basic">
          ...
        </Dropdown.Toggle>

        <Dropdown.Menu
          flip
          style={{
            maxWidth: "max-content",
            minWidth: "150px",
            zIndex: "99",
          }}
        >
          {actionButtonOptions.map(
            (item: actionButtonOptionsType, index: number) => {
              return (
                <Dropdown.Item
                  onClick={() => item.onClick(rootItem)}
                  key={index}
                >
                  {item.name === "Inactive"
                    ? rootItem.is_active
                      ? item.icon
                      : checkIcon
                    : item.name === "Pause Tipping"
                    ? rootItem.tip_status
                      ? item.icon
                      : unPause
                    : item.icon}

                  {item.name === "Inactive"
                    ? rootItem.is_active
                      ? "Inactivate"
                      : "Activate"
                    : item.name === "Pause Tipping"
                    ? rootItem.tip_status
                      ? "Pause Tipping"
                      : "Unpause Tipping"
                    : (item.name as string)?.trim()}
                </Dropdown.Item>
              );
            }
          )}
        </Dropdown.Menu>
      </Dropdown>
    </td>
  );
};

/**
 *
 * @param param0
 *  1. Data                :- Which is use to render table rows ,
 *  2. Heading             :- To render table headings
 *  3. statusColumn        :- Boolean value check if want Status column or not
 *  4. actionColumn        :- Boolean value check if want Action column or not
 *  5. actionButtonOptions :- A Array of objects that render links in action button dropdown
 *  6. currentPage         :- For Pagination current page value,
 *  7. itemPerPage         :- total item count you wann list in table
 *  8. totalItems          :- total records in data
 *  9. setPerPageLimit     :- change item count you wann list in table
 * 10. setCurrentPage      :- change current page number
 * 11. loading             :- loading state when data is processing
 * 12. pageLimit           :- limit per value
 * @returns Table component
 */
const ReactTable = ({
  data,
  headings,
  statusColumn,
  actionColumn,
  actionButtonOptions,
  currentPage,
  itemPerPage,
  totalItems,
  setPerPageLimit,
  setCurrentPage,
  loading,
  pageLimit,
  children,
  pagination,
}: reactTablePropsType<any>) => {
  const location = useLocation();
  return (
    <div className={styles.dashboardTable_outer}>
      {loading && data.length <= 0 ? (
        <ReactLoader />
      ) : (
        <div className={styles.dashboardTable}>
          {children && children}
          <div>
            <Table hover={data.length ? true : false} className="calc-height">
              <thead>
                <tr>
                  {headings?.map(
                    (heading: reactTableHeadingType, headingIndex: number) => {
                      return (
                        <th
                          key={headingIndex}
                          style={{
                            textAlign: heading.textAlign as any,
                          }}
                        >
                          {heading.label}
                        </th>
                      );
                    }
                  )}
                  {statusColumn && (
                    <th style={{ textAlign: "center" }}>Status</th>
                  )}
                  {actionColumn && (
                    <th
                      style={{
                        textAlign: "center",
                      }}
                    >
                      Actions
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data?.map((row, rowIndex) => (
                    <tr key={row.id}>
                      {headings.map(
                        (heading: reactTableHeadingType, colIndex) => (
                          <td
                            key={colIndex}
                            style={{
                              textAlign: heading.textAlign as any,
                              wordBreak: "break-word",
                              cursor: heading.onClick ? "pointer" : "text",
                            }}
                            onClick={() =>
                              heading.onClick ? heading.onClick(row) : () => {}
                            }
                          >
                            <p
                              style={{
                                wordBreak: "break-word",
                                whiteSpace:
                                  heading?.key === "phone_number" ||
                                  heading?.key === "show_phone" ||
                                  heading?.key === "netTipAmount" ||
                                  heading?.key === "date"
                                    ? "nowrap"
                                    : "none",
                                maxWidth:
                                  heading?.key === "netTipAmount" ? 100 : "",
                                textAlign:
                                  heading?.key === "netTipAmount"
                                    ? "end"
                                    : "inherit",
                              }}
                            >
                              {heading.activeColumn
                                ? renderCustomActiveColumn(row[heading?.key])
                                : heading?.key === "phone_number"
                                ? row[heading?.key]
                                  ? row[heading?.key]
                                  : "--"
                                : row[heading?.key]
                                ? row[heading?.key]
                                : "--"}
                            </p>
                            {/* {heading.key === "address" && formatAddress(row)} */}
                          </td>
                        )
                      )}
                      {statusColumn && renderStatus(row["is_active"])}
                      {actionColumn && renderAction(actionButtonOptions, row)}
                    </tr>
                  ))
                ) : (
                  <tr>
                    {location.pathname.includes("/QR-Codes-&-links") ? (
                      <td colSpan={headings.length + 2}>
                        <NoDataFound viewHeight={"calc(100vh - 600px)"} />
                      </td>
                    ) : (
                      <td colSpan={headings.length + 2}>
                        <NoDataFound viewHeight={"calc(100vh - 500px)"} />
                      </td>
                    )}
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          {!!totalItems && totalItems > 10 && pagination && (
            <div className="paginations">
              <p>
                Showing {(currentPage - 1) * itemPerPage + 1} to{" "}
                {currentPage * itemPerPage > totalItems
                  ? totalItems
                  : currentPage * itemPerPage}{" "}
                of {totalItems} entries
              </p>

              <ReactPagination
                current={currentPage}
                total={totalItems}
                pageSize={itemPerPage}
                setCurrentPage={setCurrentPage}
              />

              <label>
                Show Entries
                <select
                  onChange={(e) => {
                    setCurrentPage(1);
                    setPerPageLimit(+e.target.value);
                  }}
                  value={pageLimit}
                >
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                </select>
              </label>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReactTable;
