import { ReactNode } from "react";
import { RotatingLines } from "react-loader-spinner";

interface IProps {
  title: string;
  icon?: ReactNode;
  onClick?: () => void;
  loading?: boolean;
}

const CommonButton: React.FC<
  IProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({ title, icon, onClick, loading, ...rest }) => {
  return (
    <button
      className={`btn btn-secondary commonBtnTransparnt colorBg mt-0  ${
        loading ? "loading" : ""
      } ${rest.className ?? ""}`}
      type="submit"
      style={{ minWidth: 120, border: "0.5px solid #aeb1c2" }}
      onClick={onClick}
    >
      {/* <span>{icon}</span>
      {title} */}
      {loading ? (
        <span className="loader2">
          <RotatingLines visible={true} strokeColor="#fff" />
        </span>
      ) : (
        <>
          <span>{icon}</span>
          {title}
        </>
      )}
    </button>
  );
};

export default CommonButton;
