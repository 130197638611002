import {
  editPropertyFormType,
  initialValuesTypes,
  propertyEmployeesType,
  propertyServicesType,
} from "../../../types/manageProperty";

export const initialValues: initialValuesTypes = {
  name: "",
  phone_code_id: null,
  country_id: "",
  phone_number: null,
  media_id: null,
  address: null,
  city: null,
  state: null,
  zip_code: null,
  manager_name: null,
  manager_email: null,
  manager_phone_code_id: null,
  manager_phone_number: null,
  existing_manager_id: "",
};

export const editPropertyFormValues: editPropertyFormType = {
  name: "",
  phone_code_id: null,
  country_id: "",
  phone_number: null,
  media_id: null,
  address: "",
  city: "",
  state: "",
  zip_code: null,
  trip_advisor_url: "",
  google_site_url: "",
  facebook_url: "",
  yelp_url: "",
  theme_color: "",
  font_color: "",
};

export const propertyEmployees: propertyEmployeesType = {
  add_property_service_uuids: "",
  name: "",
  email: null,
  phone_number: null,
  phone_code_id: null,
};

export const propertyRoom = {
  property_uuid: "",
  rooms_name: [""],
};

export const propertyServices: propertyServicesType = {
  property_uuid: "",
  add_service_uuids: [],
  remove_service_uuids: [],
  add_custom_services: [
    {
      name: "",
      url_code: "",
    },
  ],
};
