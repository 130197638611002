import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import useFetch from "../../../../hooks/useFetch";
import { stepFormPropsType } from "../../../../types/form/stepForm";
import { initialValuesTypes } from "../../../../types/manageProperty";
import InputField from "../../../theme/InputField";
import LoadingBars from "../../../theme/LoadingBars";
import PhoneIntputField from "../../../theme/PhoneIntputField";
import ReactSelectField from "../../../theme/ReactSelectField";
import styles from "../AddProperty.module.scss";
import UrlCodeToolTip from "../../../theme/UrlCodeToolTip";
import {
  notifyBugsnagError,
  showToast,
} from "../../../../common/utils/Functions";
interface props {
  emailLoading: boolean;
}
const Step2 = ({
  formik,
  emailLoading,
}: stepFormPropsType<initialValuesTypes> & props) => {
  const [managersOptions, setManagersOptions] = useState<
    { label: string; value: number }[]
  >([]);

  /** Api definition for managers select */
  const [listManagersApi, { response: managerResponse, error: managerError }] =
    useFetch("/manager/list", {
      method: "post",
    });

  /** Call @listManagersApi api */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        listManagersApi({
          start: 0,
          limit: -1,
          is_active: 1,
          search: "",
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "listManagersApi",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  /** Handle @listManagersApi response */
  useEffect(() => {
    if (managerResponse) {
      const updatedList = managerResponse.data.list.map((item: any) => ({
        value: item.id,
        label: item.first_name,
      }));
      setManagersOptions(updatedList);
      formik.setFieldTouched("manager_email", false, false);
      formik.setFieldTouched("manager_name", false, false);
      formik.setFieldTouched("manager_phone_number", false, false);
    }
    if (managerError) {
      showToast(managerError.message as string, "error");
    }
  }, [managerResponse, managerError]);

  return (
    <>
      <Row className="g-xl-4 g-3 mt-1">
        <h1 className={styles.inputHeading}>
          Select an existing property manager (Optional)
        </h1>
      </Row>
      <Row className="g-xl-4 g-3">
        <Col xxl={6} xl={6}>
          <ReactSelectField
            name="existing_manager_id"
            options={managersOptions}
            label="Manager"
            placeholder="Select Manager"
            className="form_select"
            formik={formik}
            isMulti
          />
        </Col>
      </Row>
      <Row className="g-xl-4 g-3 mt-1">
        <h1 className={styles.inputHeading}>
          Or you can add a new property manager account (Optional){" "}
          <UrlCodeToolTip text="This will send an email to this email address and allow this property manager to create a TopTipper Account with access to the Property Manager Dashboard for this property." />
        </h1>
      </Row>
      <Row className="g-xl-4 g-3">
        <Col xxl={6} xl={6}>
          <InputField
            type="text"
            name="manager_name"
            placeholder="Enter Manager Name"
            label="Manager Name"
            formik={formik}
          />
        </Col>
        <Col xxl={6} xl={6}>
          <InputField
            name="manager_email"
            placeholder="Enter Email"
            label="Manager Email"
            formik={formik}
            // onChange={(e) =>
            //   formik.setFieldValue("email", removeInitialSpace(e.target.value))
            // }
          >
            {emailLoading && (
              <div className="input_child">
                <LoadingBars color="#676d7a" />
              </div>
            )}
          </InputField>
        </Col>
        <Col xxl={6} xl={6}>
          <PhoneIntputField
            label="Manager Phone Number (Optional)"
            name="manager_phone_number"
            formik={formik}
            phoneCodeId={(phoneId: string) =>
              formik.setFieldValue("manager_phone_code_id", phoneId)
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default Step2;
