import { FormikProps } from "formik";
import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  notifyBugsnagError,
  removeInitialSpace,
  showToast,
} from "../../../../common/utils/Functions";
import useFetch from "../../../../hooks/useFetch";
import ModalLayout from "../../../layouts/ModalLayout";
import InputField from "../../../theme/InputField";
import MultiSelectField, { Property } from "../../../theme/MultiSelectField";
import PhoneIntputField from "../../../theme/PhoneIntputField";
import ThemeButton from "../../../theme/ThemeButton";

interface Iprops {
  handleClose: () => void;
  show: boolean;
  title?: string;
  editModal?: boolean;
  formik: FormikProps<any>;
  loading?: boolean;
  formikErrorMesg?: any;
  updatedPropertyItems: Property[];
  removeItems: Property[];
  setUpdatedPropertyItems: React.Dispatch<React.SetStateAction<Property[]>>;
  setRemoveItems: React.Dispatch<React.SetStateAction<Property[]>>;
  setValidationSchema?: React.Dispatch<React.SetStateAction<any>>;
  propertyUuid?: {
    label: string;
    value: string;
  };
}

const AddAccountModal = ({
  handleClose,
  title,
  show,
  editModal,
  formik,
  loading,
  updatedPropertyItems,
  removeItems,
  setUpdatedPropertyItems,
  setRemoveItems,
  setValidationSchema,
  formikErrorMesg,
  propertyUuid,
}: Iprops) => {
  const [searchValue, setSearchValue] = useState("");
  const [propertyList, setPropertyList] = useState<Property[] | undefined>();

  /**
   * @Pagination states
   * 1. pageLimit    :- store limit of data that will render on current page
   * 2. totalRecords :- store total number of data in partners api
   * 3. currentPage  :- store current page number
   */
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  /** -----@propertyListApi ------ */
  const [
    propertyListApi,
    {
      response: propertyResponse,
      loading: propertyLoading,
      error: propertyError,
    },
  ] = useFetch("/property/list", {
    method: "post",
  });

  /** @Call all property account data using @propertyListApi constant */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        propertyListApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          search: searchValue,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "propertyListApi",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [currentPage, pageLimit, searchValue]);

  /**
   * @handle_Response of above api call using @propertyListApi constant
   * => in this effect
   * 1. @setTotalRecords setState updates with total records
   * 2. @if_statement    to handle error if api not responded
   */
  useEffect(() => {
    if (propertyResponse) {
      setTotalRecords(propertyResponse.data?.totalRecords);
      const updatedList = propertyResponse.data.list.map((item: any) => ({
        label: item?.name,
        value: item.uuid,
      }));
      setPropertyList(updatedList);
    }
    if (propertyError) {
      showToast(propertyError.message as string, "error");
    }
  }, [propertyResponse, propertyError]);

  return (
    <ModalLayout
      title={title}
      show={show}
      size={700}
      handleToggle={handleClose}
    >
      <Row className="g-xl-4 g-3">
        <Col xxl={6} xl={6}>
          <InputField
            name="first_name"
            label="Manager Name"
            placeholder="Enter Manager Name"
            formik={formik}
          />
        </Col>
        <Col xxl={6} xl={6}>
          <InputField
            style={{
              cursor: editModal ? "not-allowed" : "default",
            }}
            disabled={editModal}
            name="email"
            label="Manager Email"
            placeholder="Enter Manager Email"
            formik={formik}
            onChange={(e) => {
              formik.setFieldTouched("email", false, false);
              formik.setFieldValue("email", removeInitialSpace(e.target.value));
            }}
          ></InputField>
        </Col>
        <Col xxl={6} xl={6}>
          <PhoneIntputField
            label="Manager Phone Number (Optional)"
            name="phone_number"
            placeholder="Enter Phone Number"
            formik={formik}
            phoneCodeId={(phoneId: string) =>
              formik.setFieldValue("phone_code_id", phoneId)
            }
          />
        </Col>
        <Col xxl={6} xl={6} style={{ position: "relative" }}>
          <MultiSelectField
            propertyUuid={propertyUuid}
            properties={propertyList || []}
            formik={formik}
            editModal={editModal}
            name={"assign_property_uuids"}
            loading={propertyLoading}
            updatedPropertyItems={updatedPropertyItems}
            removeItems={removeItems}
            setUpdatedPropertyItems={setUpdatedPropertyItems}
            setRemoveItems={setRemoveItems}
          />
        </Col>
      </Row>
      <Modal.Footer>
        <ThemeButton loading={loading} onClick={() => formik.handleSubmit()}>
          {" "}
          {editModal ? "Save" : "Add"}{" "}
        </ThemeButton>
      </Modal.Footer>
    </ModalLayout>
  );
};

export default AddAccountModal;
