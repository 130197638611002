import { useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { GoSearch } from "react-icons/go";
import { MdInfoOutline } from "react-icons/md";
import {
  DashboardTipItems,
  dataTransactionDetail,
  tipItemsViewMore,
} from "../../../../../common/dummyData";
import DashboardLayout from "../../../../../components/layouts/DashboardLayout";
import PageHeading from "../../../../../components/pageHeading";
import CommonPagination from "../../../../../components/paginations";
import RangeDateModal from "../../../../../components/rangeDateModal/rangeDateModal";
import ThemeButton from "../../../../../components/theme/ThemeButton";
import TipAmountModal from "../../../../../components/tipAmountModal/tipAmountModal";
import styles from "./tipTransactionDetail.module.scss";
import {
  TipAmountIcon,
  TipNumberIcon,
} from "../../../../../assests/Icons/dashboardIcons";
import ReactSelectField from "../../../../../components/theme/ReactSelectField";

// toast

const TipTransactionDetail = () => {
  const initialCountData = [
    {
      icon: TipNumberIcon,
      title: "Total Number of Tips",
      amount: 0,
      key: "todayTipTransaction",
    },
    {
      icon: TipAmountIcon,
      title: "Total Net Amount",
      amount: 0,
      key: "organizations",
    },
  ];
  const [countData] = useState(initialCountData);
  const [show, setShow] = useState(false);
  const [showRange, setShowRange] = useState(false);
  const [selectedFilter] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseRange = () => setShowRange(false);

  // Options for Sort By and Filter Byimport Select from "react-select";

  const filters = [
    { value: "All", label: "All" },
    { value: "Today", label: "Today" },
    { value: "ThisWeek", label: "This Week" },
    { value: "ThisMonth", label: "This Month" },
    { value: "Custom", label: "Custom" },
  ];

  const options = [
    { value: "Date", label: "Date" },
    { value: "HighestTipAmount", label: "Highest Tip Amount" },
    { value: "LowestTipAmount", label: "Lowest Tip Amount" },
  ];

  return (
    <DashboardLayout>
      <div className={styles.dash_page}>
        <Container>
          <Row className="g-xl-4 g-3">
            <Col xxl={12} xl={12}>
              <PageHeading
                heading="Tip Transactions Detail of Tokeet Property"
                subHeading="Here is the information about all tips."
              >
                <div className={styles.search}>
                  <GoSearch />

                  <input
                    type="search"
                    placeholder="Search by Tipper Name"
                    autoComplete="off"
                  />
                </div>
              </PageHeading>

              <div className={styles.tipsWrap}>
                <ReactSelectField
                  name="sortBy"
                  options={options}
                  label="Sort by"
                  onChange={(e) => {}}
                />

                <ReactSelectField
                  name="sortBy"
                  options={filters}
                  label="Filter By"
                  onChange={(e) => {}}
                />

                <ThemeButton>Export CSV</ThemeButton>
              </div>
            </Col>
          </Row>

          <div className={styles.cardsWrap}>
            {countData.map((item) => (
              <div className={styles.card_outer}>
                <div key={item.key} className={styles.card}>
                  <div className={styles.icon}>{item.icon}</div>
                  <div className={styles.content}>
                    <div className={styles.title}>{item.title}</div>
                    <div className={styles.amount}>${item.amount}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Row className="g-xl-4 g-3">
            <Col xxl={12} xl={12}>
              <div className={styles.dashboardTable}>
                <div className={styles.dashboartabless}>
                  <Table hover responsive>
                    <thead>
                      <tr>
                        <th>Tipper Name</th>
                        <th>Tipper Email</th>
                        <th>Net Tip Amount</th>
                        <th>Tip Date</th>
                        <th>Service</th>
                        <th>Employee</th>
                        <th>Room</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataTransactionDetail.map((item, index) => (
                        <tr key={index}>
                          <td>{item.tipperName}</td>
                          <td>{item.tipperEmail}</td>
                          <td>
                            {item.netTipAmount}{" "}
                            <MdInfoOutline onClick={handleShow} />
                          </td>

                          <td>{item.date}</td>
                          <td>{item.service}</td>
                          <td>{item.Employee}</td>
                          <td>{item.Room}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <div className="paginations">
                    <p>Showing 1 to 10 of 10 entries</p>
                    <CommonPagination
                      itemPerPage={1}
                      totalItems={3}
                      paginate={function (page: number): void {
                        throw new Error("Function not implemented.");
                      }}
                      currentPage={1}
                    />
                    <label>
                      Show Entries
                      <select>
                        <option>10</option>
                        <option>20</option>
                        <option>30</option>
                      </select>
                    </label>
                  </div>

                  {selectedFilter?.value === "Custom" && (
                    <RangeDateModal
                      show={showRange}
                      handleClose={handleCloseRange}
                    />
                  )}

                  <TipAmountModal
                    show={show}
                    handleClose={handleClose}
                    title="Tip Amount Information"
                    tipItems={DashboardTipItems}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </DashboardLayout>
  );
};
export default TipTransactionDetail;
