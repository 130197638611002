import { ReactNode } from "react";
import styles from "./pageHeading.module.scss";

interface IProps {
  heading: string;
  subHeading: string | React.ReactElement;
  children?: ReactNode;
}

const PageHeading = ({ heading, subHeading, children }: IProps) => {
  return (
    <div className={styles.pageHeading}>
      <div className={styles.contentWrap}>
        <h2>{heading}</h2>
        <p>{subHeading}</p>
      </div>
      <div className={styles.childWrap}>{children}</div>
    </div>
  );
};

export default PageHeading;
