import { configureStore } from "@reduxjs/toolkit";
import commonSlice from "./common";
import breadcrumb from "./breadcrumb";
import toaster from "./toaster";
export const store = configureStore({
  reducer: {
    userData: commonSlice,
    breadcrumb,
    toaster,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
