import { Col, Container, Row } from "react-bootstrap";
import { cardDataType } from "../../types/common";
import styles from "./reportingCards.module.scss";

const ReportingCards = ({ cardData }: { cardData: cardDataType[] }) => {
  return (
    <Container>
      <Row>
        {cardData?.map((card: any, index: any) => (
          <Col key={index} lg={cardData?.length >= 3 ? 4 : 6} md={6}>
            <div className={styles.card}>
              <div className={styles.cardIcon}>{card.icon}</div>
              <div>
                <div className={styles.cardTitle}>{card.title}</div>
                <div className={styles.cardAmount}>
                  {card.amount ? card.amount : card?.value}{" "}
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ReportingCards;
