// import { NumericFormat } from "react-number-format";
// import React from "react";
// import { FormikProps } from "formik";
// import { Form } from "react-bootstrap";

// interface AmountFieldProps {
//   name: string;
//   formik?: FormikProps<any>;
//   label: string;
//   placeholder: string;
//   icon?: string;
// }

// const AmountField = ({
//   name,
//   formik,
//   label,
//   placeholder,
//   icon,
//   onChange,
//   ...rest
// }: AmountFieldProps & React.InputHTMLAttributes<HTMLInputElement>) => {
//   const formikValue = formik?.values[name];
//   const formikError = formik?.touched[name] ? formik?.errors[name] : null;

//   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { value } = e.target;
//     const formattedValue = value.startsWith(".") ? `0${value}` : value;

//     if (formik) {
//       formik.setFieldValue(name, formattedValue);
//     }

//     if (onChange) {
//       onChange({
//         ...e,
//         target: {
//           ...e.target,
//           value: formattedValue,
//         },
//       });
//     }
//   };

//   return (
//     <Form.Group className={`field_wrap ${rest.className}`} controlId={name}>
//       {label && <Form.Label>{label}</Form.Label>}
//       <div className={`input_wrap amount_input`}>
//         {icon && <img className={`field_icon`} src={icon} alt="icon" />}
//         <NumericFormat
//           decimalScale={2}
//           fixedDecimalScale={true}
//           type="text"
//           placeholder={placeholder}
//           name={name}
//           value={formikValue}
//           onChange={handleChange}
//           allowLeadingZeros={true}
//           className={`${formikError ? "has_error" : ""}`}
//         />
//       </div>
//       {formikError && (
//         <span className="error">
//           {typeof formikError === "string" ? formikError : ""}
//         </span>
//       )}
//     </Form.Group>
//   );
// };

// export default AmountField;
import React, { useState, useEffect } from "react";
import { FormikProps } from "formik";
import { Form } from "react-bootstrap";

interface AmountFieldProps {
  name: string;
  formik?: FormikProps<any>;
  label: string;
  placeholder: string;
  icon?: string;
}

const AmountField = ({
  name,
  formik,
  label,
  placeholder,
  icon,
  ...rest
}: AmountFieldProps & React.InputHTMLAttributes<HTMLInputElement>) => {
  const [inputValue, setInputValue] = useState<string>(
    formik?.values[name] != null ? `${formik.values[name]}` : ""
  );
  const formikError = formik?.touched[name] ? formik?.errors[name] : null;
  useEffect(() => {
    setInputValue(
      formik?.values[name] != null ? `${formik?.values[name]}` : ""
    );
  }, [formik?.values[name]]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    // Remove any non-numeric characters except the decimal point
    value = value.replace(/[^0-9.]/g, "");

    // If the value starts with a decimal point, prepend '0' to it
    if (value.startsWith(".")) {
      value = "0" + value;
    }

    // Ensure there is at most one decimal point and only two decimal places
    const decimalIndex = value.indexOf(".");
    if (decimalIndex !== -1) {
      value =
        value.substring(0, decimalIndex + 1) +
        value.substring(decimalIndex + 1).replace(/[^0-9]/g, "");
      const parts = value.split(".");
      if (parts[1] && parts[1].length > 2) {
        value = `${parts[0]}.${parts[1].substring(0, 2)}`;
      }
    }

    setInputValue(value);

    // Update Formik value
    if (formik) {
      formik.setFieldValue(name, value);
    }
  };

  const handleBlur = () => {
    let value = inputValue;

    // Add .00 if there's no decimal point
    if (value && !value.includes(".")) {
      value += ".00";
    }

    // If there's a decimal point but not two decimal places, add zeros
    const parts = value?.split(".");
    if (parts[1] && parts[1].length === 1) {
      value += "0";
    }

    setInputValue(value);

    // Update Formik value on blur
    if (formik) {
      formik.setFieldValue(name, value);
    }
  };

  return (
    <Form.Group className={`field_wrap ${rest.className}`} controlId={name}>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <div className={`input_wrap amount_input`}>
        {icon && <img className={`field_icon`} src={icon} alt="icon" />}
        <Form.Control
          type="text"
          placeholder={placeholder}
          name={name}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          className={`${
            formik?.touched[name] && formik?.errors[name] ? "has_error" : ""
          }`}
        />
      </div>
      {formik?.touched[name] && formik?.errors[name] ? (
        <span className="error">
          {formikError && (
            <span className="error">
              {typeof formikError === "string" ? formikError : ""}
            </span>
          )}
        </span>
      ) : null}
    </Form.Group>
  );
};

export default AmountField;
