import { Link } from "react-router-dom";
import emailCheck from "../../../assests/emailCheck.svg";
import AuthLayout from "../../../components/layouts/AuthLayout";
import ThemeButton from "../../../components/theme/ThemeButton";
import styles from "./emailSent.module.scss";
import React, { ReactNode } from "react";

interface Iprops {
  title?: string;
  description?: ReactNode;
  buttonTitle?: string;
}

const EmailSent = ({ title, description, buttonTitle }: Iprops) => {
  return (
    <AuthLayout>
      <div className={styles.login}>
        <div className={styles.login_ByGoogle}>
          <div className={styles.formTitle}>
            <img src={emailCheck} alt="check Email" />
            <h2>{title}</h2>
            <p>
              {description}
              {/* Password reset link has been sent to <b>{email}</b> Please check
              your email. */}
            </p>
          </div>
        </div>
        <div className={styles.formFields}>
          <div className={styles.goButtons}>
            <Link to={"/"}>
              <ThemeButton>{buttonTitle}</ThemeButton>
            </Link>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default EmailSent;
