import React from "react";
import { Navigate, useParams } from "react-router-dom";
import {
  getCurrentUserData,
  getUserToken
} from "../../common/utils/Functions";

interface IProps {
  children: React.ReactNode;
}
const PrivateRoute = ({ children }: IProps) => {
  const { uuid } = useParams();
  const userInfo = getCurrentUserData(`${uuid}`);
  const userToken = getUserToken(uuid as string);
  return <>{userInfo && userToken ? children : <Navigate to="/" />}</>;
};

export default PrivateRoute;
