import Excel from "exceljs";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";

/** @interface  >> for sheets coulmns type  */
interface sheetsCoulmns {
  header: string;
  key: string;
  width?: number;
}

/** @interface  >> for React Excel Component porp types  */
interface ExportExcelProps {
  excelData: Array<any>;
  fileName: string;
  fileType: "xlsx" | "csv";
  coulmns: sheetsCoulmns[];
  buttonTitle: string;
  onClick: () => void;
  loading: boolean;
  setExcelData: React.Dispatch<React.SetStateAction<any>>;
}

/**
 *
 * @param param0
 * @returns
 */
const ReactExcel: React.FC<ExportExcelProps> = ({
  excelData,
  fileName,
  fileType,
  buttonTitle,
  coulmns,
  onClick,
  loading,
  setExcelData,
}) => {
  /**
   * @function >> function to excel export logic on the basis of
   * @fileType >> "xlsx" | "csv"
   */
  const excelExportHandler = () => {
    const workbook = new Excel.Workbook(); // initalise Excel Workbook
    const sheet = workbook.addWorksheet("My Sheet"); // initialise excel sheet

    /** Define columns for sheet */
    sheet.columns = [...coulmns];

    sheet.getRow(1).eachCell((cell, index) => {
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "A4CE95" },
      };
    });

    /** set row data dynamic from api response */
    excelData?.forEach((item) => {
      const rowData: any = {}; // Initialize an empty object for row data
      coulmns?.forEach((header) => {
        rowData[header.key] = item[header.key]; // Assign item properties to rowData
      });
      sheet.addRow(rowData); // Add row with rowData
    });

    /** Below set export on the basis of file type */
    workbook[fileType].writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type:
          fileType === "xlsx"
            ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            : "text/csv",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = fileName + "." + fileType;
      anchor.click();
      window.URL.revokeObjectURL(url); // revoke url after onclick
      setExcelData([]); // empty state after downloading
    });
  };

  /** @useEffect to call @excelExportHandler if excelData exist*/
  useEffect(() => {
    if (excelData) {
      if (excelData?.length > 0) {
        excelExportHandler();
      }
    }
  }, [excelData]);

  return (
    <Button
      onClick={onClick}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: 110,
        color: "white",
        border: "none",
        outline: "none",
        fontWeight: 500,
        fontSize: 14,
      }}
    >
      {!loading && buttonTitle}
      {loading && (
        <span>
          <RotatingLines visible={true} strokeColor="#fff" width="20" />
        </span>
      )}
    </Button>
  );
};

export default ReactExcel;
