import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { useLocation, useParams } from "react-router-dom";
import {
  TipAmountIcon,
  TipNumberIcon,
} from "../../../../../assests/Icons/dashboardIcons";
import { tipIcon } from "../../../../../assests/Icons/icon";
import searchIcon from "../../../../../assests/svg/searchIcon.svg";
import {
  getCurrentUserData,
  notifyBugsnagError,
  showToast,
} from "../../../../../common/utils/Functions";
import DashboardLayout from "../../../../../components/layouts/DashboardLayout";
import PageHeading from "../../../../../components/pageHeading";
import RangeDateModal from "../../../../../components/rangeDateModal/rangeDateModal";
import ReactExcel from "../../../../../components/reactExcel/ReactExcel";
import ReactTable from "../../../../../components/reactTable/ReactTable";
import InputField from "../../../../../components/theme/InputField";
import ReactSelectField from "../../../../../components/theme/ReactSelectField";
import ThemeButton from "../../../../../components/theme/ThemeButton";
import TipAmountModal from "../../../../../components/tipAmountModal/tipAmountModal";
import useFetch from "../../../../../hooks/useFetch";
import styles from "./tipTransactionDetail.module.scss";
// toast

const ManagePropertyViewTip = () => {
  const [tipTransactionData, setTipTransactionData] = useState();
  const [sortBy, setSortBy] = useState<1 | 2 | 3 | null>(null);
  const [showRange, setShowRange] = useState(false);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [excelData, setExcelData] = useState<any>([]);
  const { prop_name } = useParams();
  const data = getCurrentUserData("");
  const [searchValue, setSearchValue] = useState("");
  const { state } = useLocation();
  // const [show, setShow] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showTip, setShowTip] = useState(false);
  const [dashboardTipItems, setDashboardTipItems] = useState<any>([]);
  const handleTipClose = () => setShowTip(false);

  const handleCloseRange = () => setShowRange(false);
  const handleShowRange = () => setShowRange(true);

  /** View Tip transaction api */
  const [
    getViewTipTransactionData,
    {
      response: tipTransactionDataResponse,
      error: tipTransactionDataError,
      loading: tipTransactionDataLoading,
    },
  ] = useFetch("/dashboard/all-tips", {
    method: "POST",
  });

  const [
    getExcelData,
    {
      response: excelDataResponse,
      error: excelDataError,
      loading: excelDataLoading,
    },
  ] = useFetch("/dashboard/all-tips", {
    method: "POST",
  });

  const handleExcelApi = () => {
    try {
      getExcelData({
        start: 0,
        limit: -1,
        startDate,
        endDate,
        organization_uuid: null,
        property_uuid: state?.uuid,
        sortedBy: sortBy,
        search: searchValue,
        type: 1,
      });
    } catch (e: any) {
      notifyBugsnagError(e, {
        api: "getExcelData",
      });
    }
  };

  useEffect(() => {
    if (excelDataResponse) {
      const updateOrgExcel = excelDataResponse.data.list?.map((item: any) => {
        return {
          tipperEmail: item?.tipper_details?.email ?? "--",
          netTipAmount: item?.net_tip_amount
            ? item?.net_tip_amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
            : "--",
          date: moment(item?.createdAt).format("MM/DD/YYYY") ?? "--",
          service:
            item?.property_service_details?.service_details?.name ?? "--",
          Employee: item?.property_employee_details?.name ?? "--",
          Room: item?.property_room_details?.name ?? "--",
        };
      });
      setExcelData([...updateOrgExcel]);
    }
    if (excelDataError) {
      showToast(excelDataError.message as string, "error");
    }
  }, [excelDataResponse, excelDataError]);

  const initialCountData = [
    {
      icon: TipNumberIcon,
      title: "Total Number of Tips",
      amount: tipTransactionDataResponse?.data?.totalRecords
        ? tipTransactionDataResponse?.data?.totalRecords
        : "--",
      key: "todayTipTransaction",
    },
    {
      icon: TipAmountIcon,
      title: "Total Net Amount",
      amount: tipTransactionDataResponse?.data?.net_tip_amount
        ? tipTransactionDataResponse?.data?.net_tip_amount?.toLocaleString(
            "en-US",
            {
              style: "currency",
              currency: "USD",
            }
          )
        : "--",
      key: "organizations",
    },
  ];
  useEffect(() => {
    if (tipTransactionDataResponse) {
      setTotalRecords(tipTransactionDataResponse.data?.totalRecords);
      const updatedList = tipTransactionDataResponse.data?.list?.map(
        (item: any) => ({
          propertyUuid: item?.property_details?.uuid,
          tipperEmail: item?.tipper_details?.email,
          netTipAmount: item?.net_tip_amount ? (
            <span
              style={{
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              {item?.net_tip_amount?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
              {data?.role_id === 1 && (
                <span
                  onClick={() => {
                    setDashboardTipItems([
                      {
                        label: "Total Trans. Amount",
                        value: item?.total_amount
                          ? item?.total_amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          : "--",
                      },
                      {
                        label: "Stripe Fee",
                        value: item?.stripe_fee
                          ? item?.stripe_fee.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          : "--",
                      },
                      {
                        label: "TopTipper Fee",
                        value: item?.top_tipper_fees
                          ? item?.top_tipper_fees.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          : "--",
                      },
                      {
                        label: "Net Tip Amount",
                        value: item?.net_tip_amount
                          ? item?.net_tip_amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          : "--",
                      },
                    ]);
                    setShowTip(true);
                  }}
                >
                  {" "}
                  {tipIcon}
                </span>
              )}
            </span>
          ) : (
            "--"
          ),
          date: moment(item?.createdAt).format("MM/DD/YYYY"),
          service: item?.property_service_details?.service_details?.name,
          Employee: item?.property_employee_details?.name,
          Room: item?.property_room_details?.name,
          property_name: item?.property_name,
        })
      );
      setTipTransactionData(updatedList);
    }
    if (tipTransactionDataError) {
      showToast(tipTransactionDataError.message as string, "error");
    }
  }, [tipTransactionDataResponse, tipTransactionDataError]);
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        getViewTipTransactionData({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          startDate,
          endDate,
          organization_uuid: null,
          property_uuid: state?.uuid,
          sortedBy: sortBy,
          search: searchValue,
          type: 1,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "getViewTipTransactionData",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchValue, currentPage, pageLimit, sortBy, startDate, endDate]);

  const handleDateFilter = (value: string) => {
    const today = moment().format("MM/DD/YYYY");
    let startDate = null;
    let endDate = null;
    switch (value) {
      case "week":
        startDate = moment().startOf("week").format("MM/DD/YYYY");
        break;
      case "month":
        startDate = moment().startOf("month").format("MM/DD/YYYY");
        break;
      case "today":
        startDate = today;
        break;
      case "all":
        break;
      default:
        break;
    }
    if (value !== "all") {
      endDate = today;
    }
    setStartDate(startDate);
    setEndDate(endDate);
  };

  // Options for Sort By and Filter Byimport Select from "react-select";

  const filters = [
    { value: "all", label: "All" },
    { value: "today", label: "Today" },
    { value: "week", label: "This Week" },
    { value: "month", label: "This Month" },
    { value: "Custom", label: "Custom" },
  ];

  const options = [
    { value: 1, label: "Date" },
    { value: 2, label: "Highest Tip Amount" },
    { value: 3, label: "Lowest Tip Amount" },
  ];
  const tableHeadings = [
    // { key: "tipperName", label: "Tipper Name" },
    { key: "tipperEmail", label: " Tipper Email" },
    { key: "netTipAmount", label: "Net Tip Amount" },
    { key: "date", label: "Tip Date" },
    { key: "service", label: "Service" },
    { key: "Employee", label: "Employee" },
    { key: "Room", label: "Room" },
  ];

  const excelHeaders = [
    { key: "tipperEmail", header: "Tipper Email	", width: 40 },
    { key: "netTipAmount", header: "Net Tip Amount", width: 40 },
    { key: "date", header: "Tip Date", width: 40 },
    { key: "service", header: "Service", width: 40 },
    { key: "Employee", header: "Employee", width: 40 },
    { key: "Room", header: "Room", width: 40 },
  ];

  return (
    <DashboardLayout>
      <div className={styles.dash_page}>
        <Container>
          <Row className="g-xl-4 g-3">
            <Col xxl={12} xl={12}>
              <PageHeading
                heading={`Tip Transactions Detail of ${
                  state?.name ?? "--"
                } Property`}
                subHeading="Here is the information about all tips."
              >
                <div className={styles.search}>
                  <div className={styles.searchField}>
                    <InputField
                      name="tipperEmail"
                      icon={searchIcon}
                      placeholder="Search by Tipper Email"
                      value={searchValue}
                      onChange={(e) => {
                        setCurrentPage(1);
                        setSearchValue(e.target.value);
                      }}
                      autoComplete="off"
                    />
                    {searchValue ? (
                      <span
                        className={styles.crossIcon}
                        onClick={() => setSearchValue("")}
                      >
                        <RxCross1 />
                      </span>
                    ) : null}
                  </div>
                </div>
              </PageHeading>

              <div className={styles.tipsWrap}>
                <ReactSelectField
                  name="sortBy"
                  options={options}
                  label="Sort by"
                  onChange={(e: any) => {
                    setCurrentPage(1);
                    setSortBy(e.value);
                  }}
                />

                <ReactSelectField
                  name="sortBy"
                  options={filters}
                  label="Filter By"
                  onChange={(e: any) => {
                    if (e.value === "Custom") {
                      handleShowRange();
                    }
                    handleDateFilter(e.value);
                    setSelectedFilter(e);
                  }}
                />

                <ReactExcel
                  setExcelData={setExcelData}
                  loading={excelDataLoading}
                  onClick={handleExcelApi}
                  buttonTitle="Export CSV"
                  fileType="xlsx"
                  fileName="Tips List"
                  coulmns={excelHeaders}
                  excelData={excelData}
                />
              </div>
            </Col>
          </Row>

          <div className={styles.cardsWrap}>
            {initialCountData.map((item) => (
              <div className={styles.card_outer}>
                <div key={item.key} className={styles.card}>
                  <div className={styles.icon}>{item.icon}</div>
                  <div className={styles.content}>
                    <div className={styles.title}>{item.title}</div>
                    <div className={styles.amount}>{item.amount}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Row className="g-xl-4 g-3">
            <ReactTable
              headings={tableHeadings}
              data={tipTransactionData || []}
              actionColumn={false}
              currentPage={currentPage}
              itemPerPage={pageLimit}
              totalItems={totalRecords}
              setPerPageLimit={setPageLimit}
              setCurrentPage={setCurrentPage}
              pagination={true}
              loading={!tipTransactionData || tipTransactionDataLoading}
            />
          </Row>
          <TipAmountModal
            show={showTip}
            handleClose={handleTipClose}
            title="Tip Amount Information"
            tipItems={dashboardTipItems}
          >
            <ThemeButton onClick={() => handleTipClose()}>Close</ThemeButton>
          </TipAmountModal>
          {selectedFilter?.value === "Custom" && (
            <RangeDateModal
              show={showRange}
              handleClose={handleCloseRange}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
            />
          )}
        </Container>
      </div>
    </DashboardLayout>
  );
};
export default ManagePropertyViewTip;
