import { FormikProps } from "formik";
import { Col, Row } from "react-bootstrap";
import ReactSelectField from "../../../theme/ReactSelectField";
import ThemeButton from "../../../theme/ThemeButton";
import styles from "../addEditOrgnaization.module.scss";
import {
  organizationAddEditType,
  partnerSelectType,
} from "../../../../types/organization";
import useFetch from "../../../../hooks/useFetch";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import InputField from "../../../theme/InputField";
import {
  notifyBugsnagError,
  removeInitialSpace,
  showToast,
} from "../../../../common/utils/Functions";
interface Iprops {
  formik: FormikProps<organizationAddEditType>;
  currentStep?: number;
  setCurrentStep: (step: number) => void;
  editModal: boolean;
  partner_details?: any;
}

const Step2 = ({
  formik,
  currentStep,
  setCurrentStep,
  editModal,
  partner_details,
}: Iprops) => {
  const [listOptions, setListOptions] = useState([]);
  /** Api definition */
  const [partnerListApi, { response, error }] = useFetch("/partners/list", {
    method: "post",
  });

  /** Handle api response */
  useEffect(() => {
    if (response) {
      setListOptions(
        response.data.list.map((item: partnerSelectType) => ({
          value: item.id,
          label: item.name,
          isDisabled: !!item?.allow_third_party_integration,
        }))
      );
    }
    if (error) {
      showToast(error.message as string, "error");
    }
  }, [response, error]);

  useEffect(() => {
    try {
      partnerListApi({
        start: 0,
        limit: -1,
        search: "",
        is_active: 1,
      });
    } catch (e: any) {
      notifyBugsnagError(e, { api: "partnerlist" });
    }
  }, []);

  return (
    <Row className="g-xl-4 g-3">
      <Col
        xs={12}
        style={{
          cursor: editModal ? "not-allowed" : "pointer",
        }}
      >
        <ReactSelectField
          name="partner"
          options={listOptions}
          label="Partner (Optional)"
          placeholder="Select Partner"
          className="form_select"
          formik={formik}
          isDisabled={
            editModal && partner_details?.allow_third_party_integration == 1
          }
        />
      </Col>
      <Col xs={12}>
        {formik.values.partner && (
          <InputField
            formik={formik}
            style={{
              cursor: editModal ? "not-allowed" : "",
            }}
            name="apiKey"
            label="Api Key (Optional)"
            placeholder="Enter Api Key"
            disabled={editModal}
            onChange={(e) =>
              formik.setFieldValue("apiKey", removeInitialSpace(e.target.value))
            }
          />
        )}
      </Col>
      <Col xxl={12} xl={12} className="text-center">
        <div className={styles.butonSteps}>
          <ThemeButton variant="secondary" onClick={() => setCurrentStep(1)}>
            Back
          </ThemeButton>
          <ThemeButton onClick={() => formik.handleSubmit()}>Next</ThemeButton>
        </div>
      </Col>
    </Row>
  );
};

export default Step2;
