import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { parseJwt, setOrgLocalUserData } from "../../common/utils/Functions";
import ReactLoader from "../../components/reactLoader/ReactLoader";

const RedirectAsAdminPage = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  useEffect(() => {
    if (token) {
      const data = parseJwt(token);
      setOrgLocalUserData(data?.uuid, token);
      // dispatch(setUserData(data || null));
      setTimeout(() => {
        navigate(`/${data?.uuid}/dashboard`);
      }, 2000);
      // if (process.env.NODE_ENV === "development") {
      // } else {
      //   navigate(`/${data?.uuid}/dashboard`);
      // }
    }
  }, [token]);
  return <ReactLoader />;
};

export default RedirectAsAdminPage;
