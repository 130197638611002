import { FormikProps } from "formik";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import {
  isValidEmail,
  isValidUrl,
  removeInitialSpace,
} from "../../../../common/utils/Functions";
import useFetch from "../../../../hooks/useFetch";
import { organizationAddEditType } from "../../../../types/organization";
import { addOrganizationSchema } from "../../../../validations/OrganizationSchema";
import InputField from "../../../theme/InputField";
import LoadingBars from "../../../theme/LoadingBars";
import PhoneIntputField from "../../../theme/PhoneIntputField";
import ThemeButton from "../../../theme/ThemeButton";
import UrlCodeToolTip from "../../../theme/UrlCodeToolTip";
import styles from "../addEditOrgnaization.module.scss";
import { validationHandler } from "./stepUtils";

interface Iprops {
  show?: boolean;
  formik: FormikProps<organizationAddEditType>;
  handleClose?: () => void;
  editModal: boolean;
  setValidationSchema?: React.Dispatch<React.SetStateAction<any>>;
  editNameValue?: string;
}

const Step1 = ({
  show,
  formik,
  handleClose,
  editModal,
  setValidationSchema,
  editNameValue,
}: Iprops) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [emailStatus, setEmailStatus] = useState<number | null>();
  const [nameStatus, setNameStatus] = useState<number | null>();
  const [identifierStatus, setIdentifierStatus] = useState<number | null>();
  const [reloadStatus, setReloadStatus] = useState<boolean>(false);

  /** Api definition */
  const [
    validateNameApi,
    { response: NameResponse, loading: NameLoading, error: NameError },
  ] = useFetch("/common/validate-name", {
    method: "post",
  });

  /** Api definition */
  const [
    validateEmailApi,
    { response: emailResponse, loading: emailLoading, error: emailError },
  ] = useFetch("/common/validate-email", {
    method: "post",
  });

  const [
    validateIdentifierApi,
    {
      response: identifierResponse,
      loading: identifierLoading,
      error: identifierError,
    },
  ] = useFetch("/common/validate-identifier", {
    method: "post",
  });

  const callNameAPi = () => {
    if (
      editNameValue !== formik.values.organization_name &&
      isValidUrl(formik.values.organization_name)
    ) {
      return validateNameApi({
        type: 1,
        name: formik.values.organization_name,
      });
    }
  };

  const callUrlAPi = () => {
    if (!editModal && isValidUrl(formik.values.organization_identifier)) {
      return validateIdentifierApi({
        type: 1,
        url_code: formik.values.organization_identifier,
      });
    }
  };

  const callEmailAPi = async () => {
    if (!editModal && isValidEmail(formik.values.email))
      return validateEmailApi({ email: formik.values.email });
  };

  /**
   *@function to check if email and url are valid or not
   */
  const handleValidatesApi = async () => {
    setIsDisabled(true);
    if (editModal && editNameValue === formik.values.organization_name) {
      setNameStatus(1);
      formik.setFieldTouched("organization_name", false, false);
      formik.setFieldError("organization_name", "");
    }
    Promise.all([callNameAPi(), callEmailAPi(), callUrlAPi()])
      .then((res) => {
        setTimeout(() => {
          formik.handleSubmit();
          setIsDisabled(false);
        }, 500);
      })
      .catch((err) => {
        setIsDisabled(false);
      });
    setTimeout(() => {
      setIsDisabled(false);
    }, 500);
  };

  useEffect(() => {
    if (identifierStatus === 1) {
      formik.setFieldTouched("organization_identifier", false, false);
      formik.setFieldError("organization_identifier", "");
    }

    if (nameStatus === 1) {
      formik.setFieldTouched("organization_name", false, false);
      formik.setFieldError("organization_name", "");
    }

    if (emailStatus === 1) {
      formik.setFieldTouched("email", false, false);
      formik.setFieldError("email", "");
    }

    if (isValidUrl(formik.values.organization_name) && nameStatus === 0) {
      validationHandler({
        formik,
        fieldName: "organization_name",
        errorMesg: "Organization name exist.",
        status: nameStatus,
      });
    }

    if (
      isValidUrl(formik.values.organization_identifier) &&
      identifierStatus === 0
    ) {
      validationHandler({
        formik,
        fieldName: "organization_identifier",
        errorMesg: "URL Abbreviation exist.",
        status: identifierStatus,
      });
    }

    if (emailStatus === 0) {
      validationHandler({
        formik,
        fieldName: "email",
        errorMesg:
          emailError.message === "Email must be less than 70 characters."
            ? emailError.message
            : "Organization email exist.",
        status: emailStatus,
      });
    }

    let checkIdentifierStatus;
    let checkNameStatus;

    if (isValidUrl(formik.values.organization_name)) {
      checkNameStatus = nameStatus;
    } else {
      checkNameStatus = 1;
    }

    if (isValidUrl(formik.values.organization_identifier)) {
      checkIdentifierStatus = identifierStatus;
    } else {
      checkIdentifierStatus = 1;
    }
    const emailst =
      emailError?.message === "Email must be less than 70 characters."
        ? 2
        : emailStatus;

    const validation = addOrganizationSchema(
      1,
      checkNameStatus,
      checkIdentifierStatus,
      emailst
    );
    !!setValidationSchema && setValidationSchema(validation);
  }, [nameStatus, identifierStatus, emailStatus, reloadStatus]);

  useEffect(() => {
    const validation = addOrganizationSchema(1, 1, 1, 1);
    setValidationSchema && setValidationSchema(validation);
  }, [show]);

  useEffect(() => {
    if (NameError?.status === 0) {
      setNameStatus(0);
    }
    if (NameResponse?.status === 1) {
      setNameStatus(1);
    }
    setReloadStatus((prev) => !prev);
  }, [NameError, NameResponse]);

  useEffect(() => {
    if (emailError?.status === 0) {
      setEmailStatus(0);
    }
    if (emailResponse?.status === 1) {
      setEmailStatus(1);
    }
    setReloadStatus((prev) => !prev);
  }, [emailError, emailResponse]);

  useEffect(() => {
    if (identifierError?.status === 0) {
      setIdentifierStatus(0);
    }
    if (identifierResponse?.status === 1) {
      setIdentifierStatus(1);
    }
    setReloadStatus((prev) => !prev);
  }, [identifierError, identifierResponse]);

  return (
    <Row className="g-xl-4 g-3">
      <Col xxl={6} xl={6}>
        <div className={styles.addCateg}>
          <InputField
            formik={formik}
            name="organization_name"
            label="Organization Name"
            placeholder="Enter Name"
            onChange={(e) => {
              formik.setFieldTouched("organization_name", false, false);
              formik.setFieldValue(
                "organization_name",
                removeInitialSpace(e.target.value)
              );
            }}
          >
            {NameLoading && (
              <div className="input_child">
                <LoadingBars color="#676d7a" />
              </div>
            )}
          </InputField>
        </div>
      </Col>

      <Col xxl={6} xl={6}>
        <div className={styles.addCateg}>
          <InputField
            style={{
              cursor: editModal ? "not-allowed" : "default",
            }}
            disabled={identifierLoading || editModal}
            formik={formik}
            name="organization_identifier"
            label="Organization URL Abbreviation"
            labelIcon={
              <UrlCodeToolTip text="URL extension for this organization" />
            }
            placeholder="Enter Organization URL Abbreviation"
            onChange={(e) => {
              formik.setFieldTouched("organization_identifier", false, false);
              formik.setFieldValue(
                "organization_identifier",
                e.target.value.replace(/\s/g, "")
              );
            }}
          >
            {identifierLoading && (
              <div className="input_child">
                <LoadingBars color="#676d7a" />
              </div>
            )}
          </InputField>
        </div>
      </Col>

      <Col xxl={6} xl={6}>
        <div className={styles.addCateg}>
          <InputField
            formik={formik}
            name="first_name"
            label="Admin First Name"
            placeholder="Admin First Name"
          />
        </div>
      </Col>

      <Col xxl={6} xl={6}>
        <div className={styles.addCateg}>
          <InputField
            formik={formik}
            name="last_name"
            label="Admin Last Name"
            placeholder="Admin Last Name"
          />
        </div>
      </Col>

      <Col xxl={6} xl={6}>
        <div className={styles.addCateg}>
          <InputField
            style={{
              cursor: editModal ? "not-allowed" : "default",
            }}
            disabled={emailLoading || editModal}
            formik={formik}
            name="email"
            label="Admin Email"
            placeholder="Admin Email"
            onChange={(e) => {
              formik.setFieldTouched("email", false, false);
              formik.setFieldValue("email", removeInitialSpace(e.target.value));
            }}
          >
            {emailLoading && (
              <div className="input_child">
                <LoadingBars color="#676d7a" />
              </div>
            )}
          </InputField>
        </div>
      </Col>

      <Col xxl={6} xl={6}>
        <PhoneIntputField
          formik={formik}
          name="phone_number"
          label="Admin Phone (Optional)"
          placeholder="Enter Phone Number"
          phoneCodeId={(phoneId: string) =>
            formik.setFieldValue("phone_code_id", phoneId)
          }
        />
      </Col>

      <Col xxl={12} xl={12} className="text-center">
        <div className={styles.butonSteps}>
          <ThemeButton variant="secondary" onClick={handleClose}>
            Cancel
          </ThemeButton>

          <ThemeButton onClick={handleValidatesApi} disabled={isDisabled}>
            Next
          </ThemeButton>
        </div>
      </Col>
    </Row>
  );
};

export default Step1;
