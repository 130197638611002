import { Hourglass } from "react-loader-spinner";
const ReactLoader = ({
  color,
  height,
  width,
  viewHeight,
}: {
  color?: string;
  height?: number;
  width?: number;
  viewHeight?: string;
}) => {
  return (
    <div
      style={{
        minHeight: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      // className={`${viewHeight ? styles.pageBox : styles.loaderBox}`}
    >
      <Hourglass
        visible={true}
        height="80"
        width="80"
        ariaLabel="hourglass-loading"
        wrapperStyle={{}}
        wrapperClass=""
        colors={["#2549E8", "#2549E8"]}
      />
    </div>
  );
};

export default ReactLoader;
