import { useEffect, useState } from "react";
import { Button, Col, Container, Dropdown, Row } from "react-bootstrap";
import { IoEyeOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import {
  checkIcon,
  copyIconNew,
  crossIcon,
  deleteIcon,
  editIcon,
  mail,
  phone,
} from "../../../../assests/Icons/icon";
import {
  copyToClipBoard,
  getCurrentUserData,
  notifyBugsnagError,
  openQrCode,
  showToast,
} from "../../../../common/utils/Functions";
import useFetch from "../../../../hooks/useFetch";
import { propertyEmployeeListType } from "../../../../types/manageProperty";
import { actionButtonOptionsType } from "../../../../types/reactTable";
import ConfirmationModal from "../../../modals/ConfirmationModal/ConfirmationModal";
import styles2 from "../../../reactTable/ReactTable.module.scss";
import styles from "../AddProperty.module.scss";
import EmployeeView from "./EmployeeView";
import { IoMdClose } from "react-icons/io";

import InactiveActiveModal from "../../../modals/InactiveActiveModal/InactiveActiveModal";

interface props {
  name?: string;
  serviceName?: string;
  email?: string;
  phoneNo?: number;
  propertyEmployeeListRes: any[] | undefined;
  propertyEmployeeListErr: {
    message: string;
  };
  PropertyEmployeeListApi: any;
  property_uuid?: string;
  isModal?: boolean;
  setEmployeeValues?: any;
  openEditModal?: () => void;
  setIsEditEmployee?: any;
  setEmployeeUuid?: any;
  limit?: any;
  setPropertyEmployeeList?: any;
}

const ListEmployess = ({
  name,
  serviceName,
  email,
  phoneNo,
  propertyEmployeeListRes,
  propertyEmployeeListErr,
  PropertyEmployeeListApi,
  property_uuid,
  isModal,
  setEmployeeValues,
  openEditModal,
  setIsEditEmployee,
  setPropertyEmployeeList,
  setEmployeeUuid,
  limit,
}: props) => {
  const [uuid2, setUuid2] = useState("");
  const [openDelete, setOpenDelete] = useState<boolean>(false); // state to handle Delete Property modal
  const { uuid } = useParams();
  const [employeeData, setEmployeeData] = useState<any>(null);
  const [viewDetails, setViewDetails] = useState(false);
  const data = getCurrentUserData(`${uuid}`);
  /**
   * @function :-  to handle delete Property Modal on/open
   */
  const handleDelete = () => setOpenDelete(false);

  /** Active inactive modal*/
  const [showActive, setShowActive] = useState(false);
  const toggleActive = () => setShowActive(!showActive);

  // Function to handle closing the active confirmation modal
  const handleCloseActive = () => setShowActive(false);

  /**
   * @function to handle active-inactive propertyemployees api
   */
  const [
    statusApi,
    { response: statusResponse, loading: statusLoading, error: statusError },
  ] = useFetch(`/propertyemployees/active-inactive/${employeeData?.uuid}`, {
    method: "post",
  });

  /**
   * initialise property-EMPLOYEE delete api
   */
  /**
   * @Array :- for action button dropdown values and passes to React table component
   */

  const actionButtonOptions =
    data?.role_id === 1
      ? [
          {
            name: " View Details",
            icon: <IoEyeOutline />,
            onClick: (item: propertyEmployeeListType) => {
              setEmployeeData(item);
              setViewDetails(true);
            },
          },
          {
            name: " View Qr Code",
            icon: <IoEyeOutline />,
            onClick: (item: propertyEmployeeListType) => {
              openQrCode(item.qr_code);
            },
          },
          {
            name: " Copy Tip URL",
            icon: copyIconNew,
            onClick: (item: propertyEmployeeListType) => {
              copyToClipBoard(item.copy_link);
            },
          },
          {
            name: "Inactive",
            icon: <IoMdClose />,
            onClick: (item: propertyEmployeeListType) => {
              toggleActive();
              setEmployeeData(item);
            },
          },
          {
            name: "Delete",
            icon: deleteIcon,
            onClick: (item: propertyEmployeeListType) => {
              setUuid2(item?.uuid);
              setOpenDelete(true);
            },
          },
        ]
      : [
          {
            name: " View Details",
            icon: <IoEyeOutline />,
            onClick: (item: propertyEmployeeListType) => {
              setEmployeeData(item);
              setViewDetails(true);
            },
          },
          {
            name: " View Qr Code",
            icon: <IoEyeOutline />,
            onClick: (item: propertyEmployeeListType) => {
              openQrCode(item.qr_code);
            },
          },
          {
            name: " Copy Tip URL",
            icon: copyIconNew,
            onClick: (item: propertyEmployeeListType) => {
              copyToClipBoard(item.copy_link);
            },
          },
          {
            name: "Edit",
            icon: editIcon,
            onClick: (item: propertyEmployeeListType) => {
              !!openEditModal && openEditModal();
              setEmployeeValues(item);
              setIsEditEmployee(true);
            },
          },
          {
            name: "Inactive",
            icon: <IoMdClose />,
            onClick: (item: propertyEmployeeListType) => {
              toggleActive();
              setEmployeeData(item);
            },
          },
          {
            name: "Delete",
            icon: deleteIcon,
            onClick: (item: propertyEmployeeListType) => {
              setUuid2(item?.uuid);
              setOpenDelete(true);
            },
          },
        ];

  const [
    PropertyEmployeeDeleteApi,
    {
      response: PropertyEmployeeDelRes,
      error: PropertyEmployeeDelError,
      loading: PropertyEmployeeDelLoading,
    },
  ] = useFetch(`propertyemployees/delete/${uuid2}`, {
    method: "delete",
  });

  useEffect(() => {
    if (PropertyEmployeeDelRes) {
      showToast(PropertyEmployeeDelRes.message as string, "success");
      try {
        PropertyEmployeeListApi({
          property_uuid,
          start: 0,
          limit: -1,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "PropertyEmployeeListApi",
        });
      }
      handleDelete();
    }
    if (PropertyEmployeeDelError) {
      showToast(PropertyEmployeeDelError.message as string, "error");
    }
  }, [PropertyEmployeeDelError, PropertyEmployeeDelRes]);

  /**
   *  useEffectto handle the status response active-inactive
   */

  useEffect(() => {
    if (statusResponse) {
      showToast(statusResponse.message as string, "success");
      toggleActive();
      const updatedList = propertyEmployeeListRes?.map((item: any) =>
        employeeData?.id === item.id
          ? { ...item, is_active: item.is_active ? 0 : 1 }
          : item
      );
      setPropertyEmployeeList(updatedList);
    }
    if (statusError) {
      showToast(statusError.message as string, "error");
    }
  }, [statusResponse, statusError]);

  return (
    <div
      style={{
        width: "100%",
        // overflow: "auto",
      }}
    >
      {isModal && (
        <Row>
          <h1 className={styles.head1}>Employees List</h1>
        </Row>
      )}
      {isModal && (
        <Container
          style={{
            width: "100%",
          }}
        >
          {propertyEmployeeListRes && propertyEmployeeListRes?.length > 0
            ? propertyEmployeeListRes?.map(
                (employee: propertyEmployeeListType) => {
                  return (
                    <div key={employee?.id}>
                      <Row className={styles.employeeList}>
                        <Col
                          xs={2}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "center",
                          }}
                        >
                          <h4
                            style={{
                              wordBreak: "break-word",
                              marginTop: 2,
                            }}
                          >
                            {employee?.name}
                          </h4>
                          <span
                            style={{
                              wordBreak: "break-word",
                            }}
                          >
                            {
                              employee?.property_employees_services_details[0]
                                ?.property_service_name
                            }
                          </span>
                        </Col>
                        <Col xs={5} className={styles.info}>
                          <div>
                            <span>{mail}</span>
                            <span
                              style={{
                                wordBreak: "break-word",
                                marginTop: 2,
                              }}
                            >
                              {employee?.email ? employee?.email : "--"}
                            </span>
                          </div>
                        </Col>
                        <Col xs={3} className={styles.info}>
                          <div>
                            <span>{phone}</span>
                            <span
                              style={{
                                wordBreak: "break-word",
                              }}
                            >
                              {employee?.phone_number &&
                                employee?.property_employee_phone_code_details
                                  ?.phone_code}{" "}
                              {employee?.phone_number
                                ? employee?.phone_number
                                : "--"}
                            </span>
                          </div>
                        </Col>
                        <Col
                          xs={2}
                          className={styles.info}
                          style={{
                            justifyContent: "flex-end",
                          }}
                        >
                          {isModal && (
                            <Button
                              variant="outline-secondary"
                              style={{
                                backgroundColor: "white",
                                color: "black",
                                fontSize: "14px",
                                fontWeight: 600,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 4,
                              }}
                              onClick={() => {
                                setUuid2(employee?.uuid);
                                setOpenDelete(true);
                              }}
                            >
                              {deleteIcon}
                              Delete
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  );
                }
              )
            : "no data found"}
          <ConfirmationModal
            handleToggle={handleDelete}
            title="Are you sure you want to delete
              this Employee?"
            show={openDelete}
            loading={PropertyEmployeeDelLoading}
            confirm={() => PropertyEmployeeDeleteApi()}
          />
        </Container>
      )}
      {!isModal && (
        <Container
          className={styles.roomList}
          style={{
            maxHeight: 700,
          }}
        >
          {propertyEmployeeListRes?.map((employee: any) => {
            return (
              <Row
                key={employee?.id}
                className={styles.room}
                style={{
                  padding: 0,
                  paddingLeft: 12,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  // setViewDetails(true);
                  // setEmployeeData(employee);
                }}
              >
                <Col xs={11}>
                  <p
                    style={{
                      wordBreak: "break-word",
                      fontWeight: 500,
                      color: "black",
                    }}
                  >
                    {employee?.name ? employee?.name : "--"}
                  </p>
                </Col>
                <Col
                  xs={1}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Dropdown
                    align="end"
                    // onClick={(e) => e.stopPropagation()}
                    className={styles2.dropDots}
                  >
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                      ...
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      flip
                      style={{
                        maxWidth: "max-content",
                        minWidth: "150px",
                        zIndex: 999,
                      }}
                    >
                      {actionButtonOptions.map(
                        (item: actionButtonOptionsType, index: number) => {
                          return (
                            <Dropdown.Item
                              onClick={(e) => item.onClick(employee)}
                              key={index}
                            >
                              {item?.name === "Inactive"
                                ? employee?.is_active == 0
                                  ? checkIcon
                                  : crossIcon
                                : item.icon}
                              {item.name === "Inactive"
                                ? employee?.is_active == 0
                                  ? "Activate"
                                  : "Inactivate"
                                : item?.name}
                            </Dropdown.Item>
                          );
                        }
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>

                {/* <Col xs={6}>
                  <h3
                    style={{
                      fontSize: 16,
                    }}
                  >
                    Email
                  </h3>
                  <p
                    style={{
                      wordBreak: "break-word",
                    }}
                  >
                    {employee?.email ? employee?.email : "--"}
                  </p>
                </Col>
                <Col xs={5}>
                  <h3
                    style={{
                      fontSize: 16,
                    }}
                  >
                    Phone
                  </h3>
                  <p
                    style={{
                      wordBreak: "break-word",
                    }}
                  >
                    {" "}
                    {employee?.phone_number &&
                      employee?.property_employee_phone_code_details
                        ?.phone_code}{" "}
                    {employee?.phone_number ? employee?.phone_number : "--"}
                  </p>
                </Col> */}
              </Row>
            );
          })}
          <ConfirmationModal
            handleToggle={handleDelete}
            title="Are you sure you want to delete
              this employee?"
            show={openDelete}
            loading={PropertyEmployeeDelLoading}
            confirm={() => PropertyEmployeeDeleteApi()}
          />
          <EmployeeView
            data={employeeData}
            setData={setEmployeeData}
            handleShow={setViewDetails}
            show={viewDetails}
            property_uuid={property_uuid}
            openEditModal={openEditModal}
            setEmployeeValues={setEmployeeValues}
            setIsEditEmployee={setIsEditEmployee}
            PropertyEmployeeListApi={PropertyEmployeeListApi}
          />

          <InactiveActiveModal
            loading={statusLoading}
            handleToggle={handleCloseActive}
            title={`Are you sure you want to ${
              employeeData?.is_active ? "deactivate" : "activate"
            }
                    this employee ?`}
            show={showActive}
            heading={employeeData?.is_active ? "Deactivate" : "Activate"}
            confirm={() =>
              statusApi({
                is_active: employeeData?.is_active ? 0 : 1,
              })
            }
          />
        </Container>
      )}
    </div>
  );
};

export default ListEmployess;
