import { Button, ButtonProps } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";

interface IProps extends ButtonProps {
  loading?: boolean;
}

const ThemeButton: React.FC<IProps> = ({ loading, ...rest }) => {
  return (
    <Button
      disabled={loading}
      type="button"
      {...rest}
      className={`custom_btn ${loading ? "loading" : ""} ${
        rest.className ?? ""
      }`}
    >
      {rest.children}
      {loading && (
        <span className="loader">
          <RotatingLines visible={true} strokeColor="#fff" />
        </span>
      )}
    </Button>
  );
};

export default ThemeButton;
