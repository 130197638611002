import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { errorMsgForCommonDropdown, getCurrentUserData, getSignMultiSelectDropdown, isObject, notifyBugsnagError, requiredErrorMsgForCommonDropdown, showToast } from "../../../common/utils/Functions";
import useFetch from "../../../hooks/useFetch";
import { signModalSchema } from "../../../validations/signModalSchema";
import ModalLayout from "../../layouts/ModalLayout";
import ReactSelectField from "../../theme/ReactSelectField";
import ThemeButton from "../../theme/ThemeButton";

interface Iprops {
    handleClose: () => void;
    show: boolean;
    title?: string;
    editModal?: boolean;
    signsList?: any
    handleSignAdd?: any
    // formik: FormikProps<any>;
    loading?: boolean;
    formikErrorMesg?: any;
}

const SignsModal = ({
    handleClose,
    title,
    show,
    loading,
    signsList,
    handleSignAdd,
}: Iprops) => {

    const [propertyError, setPropertyError] = useState('')
    const [commonDropDownError, setCommonDropDownError] = useState('')
    const [selectedSignSize, setSelectedSignSize] = useState<{ label: string, value: any }>()
    const [selectedSignStyle, setSelectedSignStyle] = useState<{ label: string, value: any }>()
    const [propertyListDropdown, setPropertyListDropdown] = useState<{ label: string, value: number }[]>([]);
    const [selectedProperty, setSelectedProperty] = useState<any>();
    const [selectedSignFor, SetSelectedSignFor] = useState<{ label: string, value: any }>()
    const [commondropDownOptions, setCommondropdownOptions] = useState<any>([{ label: '', value: '' }])
    const [selectedCommondropDownOptions, SetSelectedCommondropDownOptions] = useState<any>();

    const { uuid } = useParams();
    const orgData = getCurrentUserData(`${uuid}`)
    /** -----@signscardApi ------ */
    const [
        signsCardApi,
        {
            response: signsCardsResponse,
            loading: signsCardLoading,
            error: signsCardError,
        },
    ] = useFetch("/common/qr-sign/add", {
        method: "post",
    });

    /** -----@signscardApi ------ */
    const [
        propertyListApi,
        {
            response: propertyListResponse,
            loading: propertyListLoading,
            error: propertyListError,
        },
    ] = useFetch("/property/list", {
        method: "post",
    });

    /**
      * initialise property-services list api
      */
    const [PropertyServiceListApi, { response: propertyServiceListRes, error: propertyServiceListErr }] =
        useFetch("propertyservices/list", {
            method: "post",
        });

    /**
    * initialise property-employees list api
    */
    const [
        PropertyEmployeeListApi,
        { response: propertyEmployeeListRes, error: propertyEmployeeListErr },
    ] = useFetch("propertyemployees/list", {
        method: "post",
    });

    /**
    * initialise property-room list api
    */
    const [
        PropertyRoomListApi,
        { response: propertyRoomListRes, error: propertyRoomListError, loading: propertyRoomListLoading },
    ] = useFetch("propertyrooms/list", {
        method: "post",
    });

    /**
  * @Call all property data using @listApi constant
  */
    useEffect(() => {
        const timer = setTimeout(() => {
            try {
                propertyListApi({
                    start: 0,
                    limit: -1,
                    search: '',
                });
            } catch (e: any) {
                notifyBugsnagError(e, {
                    api: "propertyListApi",
                });
            }
        }, 500);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const propertyData = propertyListResponse?.data?.list?.map((property: any) => {
            return {
                label: property?.name,
                value: property?.uuid,
                id: property?.id
            }
        })
        setPropertyListDropdown(propertyData)
    }, [propertyListResponse])

    const handleChangeSignFor = (e: any) => {
        const signForValue = e;
        SetSelectedSignFor(signForValue);
        setSelectedProperty(null)
        SetSelectedCommondropDownOptions(null)
    };

    const handleChangeProperty = (e: any) => {
        const propertyValue = e;
        setSelectedProperty(propertyValue);
        SetSelectedCommondropDownOptions(null)
        setPropertyError('')
        setCommonDropDownError('')
    };

    const handleCommonDropDownChange = (e: any) => {
        const commonDropDownValue = e;
        SetSelectedCommondropDownOptions(commonDropDownValue)
        setCommonDropDownError('')
    }


    useEffect(() => {
        const dropdownsPayload = {
            property_uuid: selectedProperty?.value,
            start: 0,
            limit: -1,
            search: ''
        }

        switch ((formik.values.sign_for as any)?.value) {
            case 3: PropertyServiceListApi(dropdownsPayload)
                break;
            case 5: PropertyEmployeeListApi(dropdownsPayload)
                break;
            case 4: PropertyRoomListApi(dropdownsPayload)
        }
    }, [selectedProperty?.value])

    useEffect(() => {
        if (propertyServiceListRes && (formik.values.sign_for as any)?.value === 3) {
            const propertyServiceData = propertyServiceListRes?.data?.list?.map((service: any) => {
                return {
                    label: service?.service_details?.name,
                    value: service?.id
                }
            })
            setCommondropdownOptions(propertyServiceData)
        }
        if (propertyEmployeeListRes && (formik.values.sign_for as any)?.value === 5) {
            const propertyEmployeeData = propertyEmployeeListRes?.data?.list?.map((employee: any) => {
                return {
                    label: employee?.name,
                    value: employee?.id
                }
            })
            setCommondropdownOptions(propertyEmployeeData)
        }
        if (propertyRoomListRes && (formik.values.sign_for as any)?.value === 4) {
            const propertyRoomData = propertyRoomListRes?.data?.list?.map((room: any) => {
                return {
                    label: room?.name,
                    value: room?.id
                }
            })
            setCommondropdownOptions(propertyRoomData)
        }
    }, [propertyServiceListRes, propertyEmployeeListRes, propertyRoomListRes])

    useEffect(() => {
        if (signsCardsResponse) {
            showToast(signsCardsResponse.message as string, "success");
            formik.setFieldValue('sign_size', '');
            formik.setFieldValue('sign_style', '');
            formik.setFieldValue('sign_for', '');
            handleClose()
            formik.resetForm({ values: formik.initialValues, errors: {} });
            signsList({
                start: 0,
                limit: 10
            })
            setPropertyError('')
            setCommonDropDownError('')

        }
        if (signsCardError) {
            showToast(signsCardError.message as string, "error");
        }
    }, [signsCardsResponse, signsCardError])

    // Define static options for the "Sign Style" dropdown
    const signSizeOptions = [
        { label: "28 X 44", value: 1.0 },
    ];

    // Define static options for the "Sign Style" dropdown
    const signStyleOptions = [
        { label: "Carwash wind sign", value: 1 },
    ];

    // Define static options for the "Sign For" dropdown
    const signForOptions = [
        { label: "Organization", value: 1 },
        { label: "Property", value: 2 },
        { label: "Service", value: 3 },
        { label: "Employee", value: 5 },
        { label: "Room", value: 4 },
    ];

    const formik = useFormik({
        initialValues: {
            sign_size: "",
            sign_style: "",
            sign_for: null,
        },
        validationSchema: signModalSchema,
        onSubmit: (values) => {
            const formValues: any = values
            const payload: any = {}
            const propertyValue: any = propertyListDropdown?.filter((item: any) => item?.value === selectedProperty?.value);
            Object.keys(formValues).forEach((key) => {
                payload[key] = isObject(formValues[key]) ? formValues[key]?.value : formValues[key]
            })
            if ((formik.values.sign_for as any)?.value === 1) {
                payload['ids'] = [orgData?.organization_details?.id]
            }
            if ((formik.values.sign_for as any)?.value === 2) {
                payload['ids'] = [propertyValue[0]?.id]
            }
            if ((formik.values.sign_for as any)?.value !== 1 && (formik.values.sign_for as any)?.value !== 2) {
                payload['ids'] = [selectedCommondropDownOptions?.value]
            }
            delete payload["property"];
            if ((formik.values.sign_for as any)?.value !== 1 && !selectedProperty?.value) {
                setPropertyError('Property is required.')
            } else if ((formik.values.sign_for as any)?.value !== 1 && (formik.values.sign_for as any)?.value !== 2 && !selectedCommondropDownOptions?.value) {
                setCommonDropDownError(requiredErrorMsgForCommonDropdown((formik.values.sign_for as any)?.value))
            } else {
                signsCardApi(payload)
            }
        },
    });



    const isAnyValue = () => {
        const formikValues: any = formik?.values
        // Define your condition for a "valid" value
        const isValid = (value: any) => value !== null && value !== undefined && value !== '';
        const hasValidValue = Object.values(formikValues).some(isValid);
        return hasValidValue
    }

    return (
        <ModalLayout
            title={title}
            show={show}
            size={500}
            handleToggle={() => {
                handleClose()
                setPropertyError('')
                setCommonDropDownError('')

                formik.resetForm({ values: formik.initialValues, errors: {} });
            }}
        >
            <Row className="g-xl-4 g-3">
                <Col xxl={12} xl={12} style={{ position: "relative" }}>
                    <ReactSelectField
                        name="sign_size"
                        options={signSizeOptions}
                        label="Sign Size"
                        placeholder="Select Size"
                        className="form_select"
                        formik={formik}
                        margin={true}
                        formikErrorMesg={formik.errors?.sign_size}
                    />
                </Col>
                <Col xxl={12} xl={12} style={{ position: "relative" }}>
                    <ReactSelectField
                        name="sign_style"
                        options={signStyleOptions}
                        label="Sign Style"
                        // value={selectedSignStyle}
                        // onChange={(e) => handleChangeSignStyle(e)}
                        placeholder="Select Style"
                        className="form_select"
                        formik={formik}
                        margin={true}
                        formikErrorMesg={formik.errors?.sign_style}
                    />
                </Col>

                <Col xxl={12} xl={12} style={{ position: "relative" }}>
                    <ReactSelectField
                        name="sign_for"
                        options={signForOptions}
                        // value={selectedSignFor}
                        label="Sign For"
                        placeholder="Select Sign For"
                        onChange={(e) => {
                            // handleChangeSignFor(e)
                            formik.setFieldValue('sign_for', e)
                            const signForValue = e;
                            setSelectedProperty(null)
                            setCommonDropDownError('')
                            SetSelectedCommondropDownOptions(null)
                        }}
                        className="form_select"
                        formik={formik}
                        margin={true}
                        formikErrorMesg={isObject(formik.errors?.sign_for) ? formik.errors?.sign_for : { value: formik.errors?.sign_for }}
                    />
                </Col>
                {
                    formik.values.sign_for && (formik.values.sign_for as any)?.value != 1 && <Col xxl={12} xl={12} style={{ position: "relative" }}>
                        <ReactSelectField
                            name="property"
                            options={propertyListDropdown}
                            value={selectedProperty}
                            label="Select Property"
                            placeholder="Select Property"
                            margin={true}
                            onChange={(e) => handleChangeProperty(e)}
                            className="form_select"
                        // formik={formik}
                        />

                    </Col>
                }
                {!(propertyListDropdown?.length > 0) &&
                    <span style={{ color: '#d22b2b', fontSize: '12px', textAlign: 'right', marginTop: '5px' }}>
                        {errorMsgForCommonDropdown((formik.values.sign_for as any)?.value, true)}
                    </span>
                }
                {
                    getSignMultiSelectDropdown((formik.values.sign_for as any)?.value).length > 0 && selectedProperty?.value && getSignMultiSelectDropdown((formik.values.sign_for as any)?.value)?.map((item: any) => {
                        return <Col xxl={12} xl={12} style={{ position: "relative" }} key={item?.sign_for}>
                            <ReactSelectField

                                name={item.name}
                                value={selectedCommondropDownOptions}
                                options={commondropDownOptions}
                                label={item.label}
                                margin={true}
                                placeholder={item?.placeholder}
                                onChange={(e) => handleCommonDropDownChange(e)}
                                className="form_select"
                            // formik={formik}
                            />
                        </Col>
                    })
                }
                {(commondropDownOptions.length > 0) && commonDropDownError && !selectedCommondropDownOptions?.value && <span style={{ color: '#d22b2b', fontSize: '12px', textAlign: 'right', marginTop: '5px' }}>
                    {commonDropDownError}
                </span>}

                {propertyError && !selectedProperty?.value && <span style={{ color: '#d22b2b', fontSize: '12px', textAlign: 'right', marginTop: '5px' }}>
                    {propertyError}
                </span>}
                {!(commondropDownOptions?.length > 0) && selectedProperty?.value &&
                    <span style={{ color: '#d22b2b', fontSize: '12px', textAlign: 'right', marginTop: '5px' }}>
                        {errorMsgForCommonDropdown((formik.values.sign_for as any)?.value)}
                    </span>
                }
            </Row>
            <Modal.Footer>
                <ThemeButton loading={signsCardLoading} onClick={() => formik.handleSubmit()
                } disabled={!isAnyValue() || signsCardLoading}>
                    {" "}
                    Create
                </ThemeButton>
            </Modal.Footer>
        </ModalLayout >
    );
};

export default SignsModal;
