/* eslint-disable no-useless-escape */
import * as yup from "yup";
import {
  colorYup,
  emailRegExp,
  phoneYup,
  twoDigisAfterCommaRegExp,
} from "./CommonConstants";

export const addOrganizationSchema = (
  currentStep: number,
  nameStatus?: number | null,
  urlStatus?: number | null,
  emailStatus?: number | null
) => {
  return yup.object().shape(
    currentStep === 1
      ? {
          organization_name: yup
            .string()
            .max(50, "Organization name must be less than 50 characters.")
            .test("is-exist", " Organization name exist.", () =>
              !(nameStatus === 0) ? true : false
            )
            .required("Please enter organization name."),
          organization_identifier: yup
            .string()
            .trim()
            .max(50, "URL Abbreviation must be less than 50 characters.")
            .test("is-exist", " URL Abbreviation exist.", () =>
              !(urlStatus === 0) ? true : false
            )
            .required("Please enter url abbreviation."),
          first_name: yup
            .string()
            .max(50, "First name must be less than 50 characters.")
            .required("Please enter first name."),
          last_name: yup
            .string()
            .max(50, "Last name must be less than 50 characters.")
            .required("Please enter last name."),
          email: yup
            .string()
            .trim()
            .matches(emailRegExp, "Please enter a valid email.")
            .test(
              "is-exist",
              emailStatus === 2
                ? "Email must be less than 70 characters."
                : "Organization email exist.",
              () => (!(emailStatus === 0 || emailStatus === 2) ? true : false)
            )
            .email("Invalid email.")
            .required("Please enter email."),
          phone_number: phoneYup,
        }
      : currentStep === 2
      ? {
          partner: yup
            .object()
            .nullable()
            .shape({
              value: yup
                .number()
                .nullable()
                .notRequired()
                .typeError("Select valid data."),
              label: yup.string().trim().notRequired(),
            }),
          apiKey: yup
            .string()
            .trim()
            .nullable()
            .trim()
            .max(50, "Api key must be less than 50 characters.")
            .matches(/^[^\s]+$/, "Api Key cannot contain spaces."),
        }
      : {
          theme_color: colorYup("Color Theme is required.").matches(
            /^[^\s]+$/,
            "Color Theme cannot contain spaces."
          ),
          font_color: colorYup("Font Color is required.").matches(
            /^[^\s]+$/,
            "Color Theme cannot contain spaces."
          ),
          top_tipper_fees: yup
            .number()
            .min(0.0, "Amount should be greater than or equal to 0.00.")
            .max(999999, "Entered amount must be less than 6 digits.")
            .test(
              "is-decimal",
              "Maximum two digits after decimal.",
              (val: any) => {
                if (val !== undefined) {
                  return twoDigisAfterCommaRegExp.test(val);
                }
                return true;
              }
            )
            .required("TopTipper fee is required.")
            .typeError("Please enter amount in numbers."),
        }
  );
};
