import * as yup from "yup";

export const signModalSchema = yup.object({
    sign_size: yup
        .object({
            label: yup.string().required("Sign Size label is required."),
            value: yup.number().required("Sign Size  is required."),
        })
        .required("Sign Size is required."),
    sign_style: yup
        .object({
            label: yup.string().required("Sign Style label is required."),
            value: yup.number().required("Sign Style  is required."),
        })
        .required("Sign Style is required."),
    sign_for: yup
        .object({
            label: yup.string().required("Sign For label is required."),
            value: yup.number().required("Sign For is required."),
        })
        .required("Sign For is required."),

});