import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GoSearch } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import { IoEyeOutline } from "react-icons/io5";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  notifyBugsnagError,
  showToast,
} from "../../../../common/utils/Functions";
import DashboardLayout from "../../../../components/layouts/DashboardLayout";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal/ConfirmationModal";
import InactiveActiveModal from "../../../../components/modals/InactiveActiveModal/InactiveActiveModal";
import PageHeading from "../../../../components/pageHeading";
import ReactTable from "../../../../components/reactTable/ReactTable";
import useFetch from "../../../../hooks/useFetch";
import styles from "./tippers.module.scss";
import ReactSelectField from "../../../../components/theme/ReactSelectField";
import { RxCross1 } from "react-icons/rx";
import InputField from "../../../../components/theme/InputField";
import searchIcon from "../../../../assests/svg/searchIcon.svg";

const filters = [
  { value: "DESC", label: "Highest No. of Tips" },
  { value: "ASC", label: "Lowest No. of Tips" },
];

const Tippers = () => {
  /** Pagination */
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState("DESC");
  const [tipperList, setTipperList] = useState<any>();
  /** Modals */
  const [showDelete, setShowDelete] = useState(false);
  const [showActive, setShowActive] = useState(false);

  // Function to handle closing the delete confirmation modal
  const handleCloseDelete = () => setShowDelete(false);

  // Function to handle closing the active confirmation modal
  const handleCloseActive = () => setShowActive(false);

  // Function to handle closing the add/edit modal

  // Function to handle confirming the delete action
  const handleConfirmDelete = () => {
    handleCloseDelete();
  };

  // Function to handle confirming the active action
  const handleConfirmActive = () => {
    handleCloseActive();
  };

  // Options for Sort By and Filter Byimport Select from "react-select";
  const navigate = useNavigate();

  /** Api definition */
  const [
    getTipperData,
    {
      response: getTipperDataResponse,
      error: getTipperDataError,
      loading: getTipperDataLoading,
    },
  ] = useFetch("/tippers/list", {
    method: "POST",
  });

  /**
   * @Array :- for table headings that passed as a prop to React-Table component
   */
  const headings = [
    // { key: "name", label: "Name" },
    { key: "email", label: "Email" },
    {
      key: "stripeId",
      label: "Stripe ID",
    },
    { key: "tipCount", label: "Number of Tips", textAlign: "right" },
  ];

  /**
   * @Array :- for action button dropdown values and passes to React table component
   */
  const actionButtonOptions = [
    {
      name: "View Tips",
      icon: <IoEyeOutline />,
      onClick: (item: any) => {
        navigate(`/tippers/view-tips/${item?.uuid}`);
      },
    },
    // {
    //   name: "Inactive",
    //   icon: <IoMdClose />,
    //   onClick: () => {
    //     setShowActive(true);
    //   },
    // },

    // {
    //   name: "Delete",
    //   icon: <RiDeleteBinLine />,
    //   onClick: () => setShowDelete(true),
    // },
  ];

  /**
   * @Call all partner data using @listApi constant
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        getTipperData({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          search: searchValue,
          sort_by_number_of_tips: sortValue,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "getTipperData",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [sortValue, currentPage, pageLimit, searchValue]);

  useEffect(() => {
    if (getTipperDataResponse) {
      setTotalRecords(getTipperDataResponse?.data?.totalRecords);
      const setTipperData = getTipperDataResponse?.data?.list?.map(
        (item: any) => {
          return {
            // name: item?.name,
            email: item?.email,
            stripeId: item?.stripe_customer_id,
            tipCount: item?.tip_count,
            uuid: item?.uuid,
            is_active: item?.is_active,
          };
        }
      );
      setTipperList([...setTipperData]);
    }
    if (getTipperDataError) {
      showToast(getTipperDataError?.message, "error");
    }
  }, [getTipperDataError, getTipperDataResponse]);

  return (
    <DashboardLayout>
      <div className={styles.dash_page}>
        <Container>
          <Row className="g-xl-4 g-3">
            <Col xxl={12} xl={12}>
              <PageHeading
                heading="Tippers"
                subHeading="Here is the information about all your tippers."
              >
                <div className={styles.search}>
                  <div className={styles.searchField}>
                    <InputField
                      name="search_email"
                      icon={searchIcon}
                      placeholder="Search by Email"
                      value={searchValue}
                      onChange={(e) => {
                        setCurrentPage(1);
                        setSearchValue(e.target.value);
                      }}
                      autoComplete="off"
                    />
                    {searchValue ? (
                      <span
                        className={styles.crossIcon}
                        onClick={() => setSearchValue("")}
                      >
                        <RxCross1 />
                      </span>
                    ) : null}
                  </div>
                </div>
              </PageHeading>
            </Col>

            <Col xxl={12} xl={12} className="mt-0">
              <div className={styles.dashboardTabless}>
                <div className={styles.dashboardHeadings}>
                  {/* Filter By Dropdown */}
                  <ReactSelectField
                    name="sortBy"
                    options={filters}
                    label="Sort By"
                    onChange={(e: any) => {
                      setCurrentPage(1);
                      setSortValue(e.value);
                    }}
                    defaultValue={{
                      value: "DESC",
                      label: "Highest No. of Tips",
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col xxl={12} xl={12}>
              <ReactTable
                data={tipperList || []}
                headings={headings}
                actionColumn
                statusColumn
                actionButtonOptions={actionButtonOptions}
                currentPage={currentPage}
                itemPerPage={pageLimit}
                setPerPageLimit={setPageLimit}
                totalItems={totalRecords}
                loading={!tipperList || getTipperDataLoading}
                setCurrentPage={setCurrentPage}
                pagination={true}
              />

              <div className={styles.dashboartabless}>
                <ConfirmationModal
                  handleToggle={handleCloseDelete}
                  title="Are you sure you want to delete
                    this user?"
                  show={showDelete}
                  confirm={() => {
                    handleConfirmDelete();
                  }}
                />

                <InactiveActiveModal
                  handleToggle={handleCloseActive}
                  title="Are you sure you want to deactivate
                    this user?"
                  show={showActive}
                  heading="Deactivate"
                  confirm={() => {
                    handleConfirmActive();
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </DashboardLayout>
  );
};
export default Tippers;
