import { createSlice } from "@reduxjs/toolkit";

const toasterSlice = createSlice({
  name: "toaster",
  initialState: {
    autoClose: false,
  },
  reducers: {
    setToaster(state, { payload }) {
      state.autoClose = payload;
    },
  },
});
export const { setToaster } = toasterSlice.actions;
export default toasterSlice.reducer;
