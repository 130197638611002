import { Modal } from "react-bootstrap";
import remove from "../../../assests/noto_warning.svg";
import checkActive from "../../../assests/svg/checkActive.svg";
import ThemeButton from "../../theme/ThemeButton";
import styles from "./InactiveActiveModal.module.scss";
interface IProps {
  confirm: (id: any) => void;
  handleToggle: () => void;
  title: string;
  show: boolean;
  size?: any;
  heading?: string;
  loading?: boolean;
}
const InactiveActiveModal = ({
  confirm,
  handleToggle,
  title,
  heading,
  show,
  size = "lg",
  loading,
}: IProps) => {
  return (
    <Modal
      show={show}
      onHide={handleToggle}
      size={size}
      className={styles.confirmationModal}
      centered
    >
      <Modal.Body>
        <div className={styles.modalContent}>
          <div className={styles.modalTitle}>
            <span className={styles.icn}>
              <img
                src={heading === "Activate" ? checkActive : remove}
                alt="Delete "
              />
            </span>
            <h2>{heading}</h2>
            <h4>{title}</h4>
          </div>
          <Modal.Footer className={styles.FooterModal}>
            <ThemeButton
              variant="secondary"
              className="primary  commonBtn"
              onClick={handleToggle}
            >
              Cancel
            </ThemeButton>
            <ThemeButton
              variant="primary"
              className="primary footerModalBtn commonBtn"
              onClick={confirm}
              loading={loading}
            >
              Yes
            </ThemeButton>
          </Modal.Footer>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default InactiveActiveModal;
