import { useEffect } from "react";
import { setupIndication } from "../../assests/Icons/icon";
import ThemeButton from "../theme/ThemeButton";
import styles from "./StripeSetUp.module.scss";
import { notifyBugsnagError, showToast } from "../../common/utils/Functions";
import useFetch from "../../hooks/useFetch";

const SetUpSuccess = ({ status }: { status?: number | string | null }) => {
  const [
    initiateStripeSetup,
    { response: paymentResponse, loading: paymentLoading, error: paymentError },
  ] = useFetch("organization/stripe-integration");

  useEffect(() => {
    if (paymentResponse) {
      const url = paymentResponse?.data?.url;
      if (url) window.open(url, "_blank");
    }
    if (paymentError) {
      showToast(paymentError.message as string, "error");
    }
  }, [paymentResponse, paymentError]);

  const getTitle = () => {
    switch (status) {
      case 4:
        return "Stripe Account Under Review";
      case 5:
        return "Complete Your Stripe Account Setup";
      case 0:
      default:
        return "Set Up Your Stripe Account";
    }
  };

  const getDescription = () => {
    switch (status) {
      case 4:
        return (
          <>
            Your Stripe account is currently under review. Please check back in
            sometime. Thank you for your patience.
          </>
        );
      case 5:
        return (
          <>
            It looks like you started setting up your Stripe account but didn't
            finish. Please complete the process by clicking the{" "}
            <span style={{ fontWeight: 600 }}>Complete Setup</span> button.
          </>
        );
      case 0:
      default:
        return (
          <>
            You haven't set up your Stripe account yet. Please set up your
            payment details by clicking the{" "}
            <span style={{ fontWeight: 600 }}>Setup Stripe Account</span>{" "}
            button.
          </>
        );
    }
  };

  const getButtonText = () => {
    switch (status) {
      case 4:
        return "Update Information";
      case 5:
        return "Complete Setup";
      case 0:
      default:
        return "Setup Stripe Account";
    }
  };

  return (
    <div className={styles.payment_wrap}>
      <div className={styles.setup_left}>
        <div className={styles.payment_content}>
          <span className={styles.innerContent}>{setupIndication}</span>
        </div>
        <div className={styles.setup_content}>
          <h4>{getTitle()}</h4>
          <p>{getDescription()}</p>
        </div>
      </div>
      <div>
        <ThemeButton
          onClick={() => {
            initiateStripeSetup();
          }}
          style={{ whiteSpace: "nowrap" }}
        >
          {getButtonText()}
        </ThemeButton>
      </div>
    </div>
  );
};

export default SetUpSuccess;
