import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { IoEyeOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { useNavigate, useParams } from "react-router-dom";
import { crossIcon, deleteIcon, edit } from "../../../assests/Icons/icon";
import searchIcon from "../../../assests/svg/searchIcon.svg";
import { notifyBugsnagError, showToast } from "../../../common/utils/Functions";
import DashboardLayout from "../../../components/layouts/DashboardLayout";
import ConfirmationModal from "../../../components/modals/ConfirmationModal/ConfirmationModal";
import InactiveActiveModal from "../../../components/modals/InactiveActiveModal/InactiveActiveModal";
import PageHeading from "../../../components/pageHeading";
import AddAccountModal from "../../../components/pagesComponents/managerAccounts/addAccountModal/AddAccountModal";
import ReactTable from "../../../components/reactTable/ReactTable";
import InputField from "../../../components/theme/InputField";
import { Property } from "../../../components/theme/MultiSelectField";
import ThemeButton from "../../../components/theme/ThemeButton";
import useFetch from "../../../hooks/useFetch";
import {
  managerAccountType,
  userProprtyType,
} from "../../../types/managerAccountType";
import { managerAccounts } from "../../../validations/managerAccountSchema";
import styles from "../managerAccounts/managerAccounts.module.scss";
import { useDispatch } from "react-redux";
import { setReloadManagerList } from "../../../redux/common";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const initialValues: managerAccountType = {
  first_name: "",
  email: "",
  phone_number: "",
  assign_property_uuids: [""],
};

const ManagerAccounts = () => {
  const { uuid } = useParams();
  /**
   * @State to store searched value
   */
  const [searchValue, setSearchValue] = useState("");
  // const [uuid, setUuid] = useState<string>();
  /**
   * @Action states to open @Active @Delete @Add_Edit Modals
   */
  const [showDelete, setShowDelete] = useState(false); //  It's use to open "Delete" modal
  const [show, setShow] = useState(false); //  It's use to open "Add/Edit" modal
  const [editModal, setEditModal] = useState(false); //  It's use to change edit-form condition
  const [showActive, setShowActive] = useState(false); //  It's use to open "In-active/Active" modal

  /** states to store updated value and removed value  */
  const [updatedPropertyItems, setUpdatedPropertyItems] = React.useState<
    Property[]
  >([]);
  const [removeItems, setRemoveItems] = React.useState<Property[]>([]);

  /**
   * @functions  to handle above ⬆️  @action states
   * 1. toggleAddEdit  :- Used to handle "Add/Edit" modal
   * 2. toggleDelete   :- Used to handle "Delete" modal
   * 3. toggleActive   :- Used to handle "In-active/Active"
   */
  const toggleAddEdit = () => setShow(!show);
  const toggleDelete = () => setShowDelete(!showDelete);
  const toggleActive = () => setShowActive(!showActive);

  /**
   * @Data_list states  these below states are used to store data which will render in React-table
   * 1. ManagerAccountList  :- Array of manager account data list
   * 2. selectedItem :- Object of selceted manager account data
   */
  const [managerAccountList, setManagerAccountList] =
    useState<managerAccountType[]>();
  const [selectedItem, setSelectedItem] = useState<managerAccountType>();

  /**
   * @Pagination states
   * 1. pageLimit    :- store limit of data that will render on current page
   * 2. totalRecords :- store total number of data in partners api
   * 3. currentPage  :- store current page number
   */
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { reloadManagerList } = useSelector(
    (store: RootState) => store.userData
  );
  /**
   * @Api_Definition variables
   * 1. addManagerAccountApi  :- Constant to handle Add manager account
   * 2. listManagerAccountApi :- Constant to handle list all manager account data
   * 3. editManagerAccountApi :- Constant to handle edit manager account
   * 4. deleteManagerAccountApi :- Constant to handle delete manager account
   * 5. statusManagerAccountApi :- Constant to handle status manager account
   * 6. viewManagerAccountApi :- Constant to handle view manager account
   */

  /**----------1. @addManagerAccountApi ------- */
  const [
    addManagerAccountApi,
    {
      response: addManagerAccountResponse,
      loading: addManagerAccountLoading,
      error: addManagerAccountError,
    },
  ] = useFetch("/manager/add", {
    method: "POST",
  });

  /** --------2. @listManagerAccountApi ------- */
  const [
    listManagerAccountApi,
    {
      response: listManagerAccountResponse,
      loading: listManagerAccountLoading,
      error: listManagerAccountError,
    },
  ] = useFetch("/manager/list", {
    method: "POST",
  });

  /** --------3. @editManagerAccountApi ------ */
  const [
    editManagerAccountApi,
    {
      response: editManagerAccountResponse,
      loading: editManagerAccountLoading,
      error: editManagerAccountError,
    },
  ] = useFetch(`/manager/update/${selectedItem?.uuid}`, {
    method: "put",
  });

  /** --------4. @deleteManagerAccountApi ------ */
  const [
    deleteManagerAccountApi,
    {
      response: deleteManagerAccountResponse,
      loading: deleteManagerAccountLoading,
      error: deleteManagerAccountError,
    },
  ] = useFetch(`/manager/delete/${selectedItem?.uuid}`, {
    method: "delete",
  });

  /** -------5. @statusManagerAccountApi ------ */
  const [
    statusManagerAccountApi,
    {
      response: statusManagerAccountResponse,
      loading: statusManagerAccountLoading,
      error: statusManagerAccountError,
    },
  ] = useFetch(`/manager/active-inactive/${selectedItem?.uuid}`, {
    method: "post",
  });

  /** -------6. @viewManagerAccountApi ------ */
  const [viewManagerAccountApi] = useFetch(
    `/manager/view-assigned-properties${selectedItem?.uuid}`
  );

  /**
   * @Call_apis by useEffect hook
   */

  /**
   * @Call all manager account data  using @listManagerAccountApi constant
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        listManagerAccountApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          search: searchValue,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "listManagerAccountApi",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [currentPage, pageLimit, searchValue]);

  /**
   * @handle_Response of above api call using @listManagerAccountApi constant
   * => in this effect
   * 1. @setTotalRecords setState updates with total records
   * 2. @setServiceList  setState updates with incoming manager-account-data
   * 3. @if_statement    to handle error if api not responded
   */
  useEffect(() => {
    if (listManagerAccountResponse) {
      setTotalRecords(listManagerAccountResponse.data?.totalRecords);
      const updatedList = listManagerAccountResponse.data?.list?.map(
        (item: any) => ({
          first_name: item?.first_name,
          email: item?.email,
          phone_code: item?.phone_code_details?.phone_code,
          phone_number: item?.phone_number,
          createdByName: item?.createdByName,
          uuid: item?.uuid,
          is_active: item?.is_active,
          assign_property_uuids: item?.user_properties?.map(
            (item: userProprtyType) => {
              return {
                label: item?.property_detail.name,
                value: item?.property_detail?.uuid,
              };
            }
          ),
        })
      );
      setManagerAccountList(updatedList);
    }
    if (listManagerAccountError) {
      showToast(listManagerAccountError.message as string, "error");
    }
  }, [listManagerAccountResponse, listManagerAccountError]);

  /**
   * @handle_AddApi_Response of form-submition api call using @addManagerAccountApi constant
   * => in this effect
   * 1. @if_statement  that it responded ok then show toast success message
   * 2. @toggleAddEdit this handler use to close Add/Edit Modal
   * 3. @if_statement  to handle error if api not responded
   */
  useEffect(() => {
    if (addManagerAccountResponse) {
      showToast(addManagerAccountResponse.message as string, "success");
      dispatch(setReloadManagerList(!reloadManagerList));
      try {
        listManagerAccountApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "listManagerAccountApi",
        });
      }
      setCurrentPage(1);
      toggleAddEdit();
      formik.resetForm();
    }
    if (addManagerAccountError) {
      showToast(addManagerAccountError.message as string, "error");
    }
  }, [addManagerAccountResponse, addManagerAccountError]);

  /**
   * @handle_editApi_Response of form-submition api call using @editManagerAccountApi constant
   * => in this effect
   * 1. @if_statement  that it responded ok then show toast success message
   * 2. @toggleAddEdit this handler use to close Add/Edit Modal
   * 3. @if_statement  to handle error if api not responded
   */
  useEffect(() => {
    if (editManagerAccountResponse) {
      showToast(editManagerAccountResponse.message as string, "success");
      try {
        listManagerAccountApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "listManagerAccountApi",
        });
      }
      setCurrentPage(1);
      toggleAddEdit();
      formik.resetForm();
    }
    if (editManagerAccountError) {
      showToast(editManagerAccountError.message as string, "error");
    }
  }, [editManagerAccountResponse, editManagerAccountError]);

  /**
   * @handle_DeleteManagerAccountApi_Response on delete manager account api call using @deleteManagerAccountApi constant
   * => in this effect
   * 1. @if_statement   that it responded ok then show toast success message
   * 2. @toggleDelete   this handler use to close Delete Modal
   * 3. @if_statement   to handle error if api not responded
   */
  useEffect(() => {
    if (deleteManagerAccountResponse) {
      showToast(deleteManagerAccountResponse.message as string, "success");
      try {
        listManagerAccountApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          search: searchValue,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "listManagerAccountApi",
        });
      }
      toggleDelete();
    }
    if (deleteManagerAccountError) {
      showToast(deleteManagerAccountError.message as string, "error");
    }
  }, [deleteManagerAccountResponse, deleteManagerAccountError]);

  /**
   * @handle_StatusManagerApi_Response on change active status of manager account api call using @statusManagerAccountApi constant
   * => in this effect
   * 1. @if_statement   that it responded ok then show toast success message
   * 2. @toggleActive   this handler use to close Ative/Inactive Modal
   * 3. @if_statement  to handle error if api not responded
   */
  useEffect(() => {
    if (statusManagerAccountResponse) {
      showToast(statusManagerAccountResponse.message as string, "success");
      toggleActive();
      const updatedList = managerAccountList?.map((item: managerAccountType) =>
        statusManagerAccountResponse?.data?.uuid === item.uuid
          ? { ...item, is_active: item.is_active ? 0 : 1 }
          : item
      );
      setManagerAccountList(updatedList);
    }
    if (statusManagerAccountError) {
      showToast(statusManagerAccountError.message as string, "error");
    }
  }, [statusManagerAccountResponse, statusManagerAccountError]);

  /** @handle_viewManagerAccountApi_Response  using @viewManagerAccountApi constant  */
  // useEffect(() => {
  //   if (uuid) {
  //     viewManagerAccountApi();
  //   }
  // }, [uuid]);

  /**
   * @constant to initialise useFormik hook
   */
  const formik = useFormik({
    initialValues,
    validationSchema: managerAccounts,
    onSubmit: (values: managerAccountType) => {
      try {
        if (editModal) {
          const requestBody = {
            ...values,
            assign_property_uuids: updatedPropertyItems.map(
              (item) => item?.value
            ),
            remove_property_uuids: removeItems.map((item) => item?.value),
          };
          delete requestBody.email;
          editManagerAccountApi(requestBody);
        } else {
          addManagerAccountApi({
            ...values,
          });
        }
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "editManagerAccountApi ||addManagerAccountApi ",
        });
      }
    },
  });

  const tableHeadings = [
    { key: "first_name", label: "Manager Name" },
    { key: "email", label: "Manager Email" },
    { key: "createdByName", label: "Added By" },
  ];

  const actionButtonOptions = [
    {
      icon: <IoEyeOutline />,
      name: "View Assigned Properties",
      onClick: (item: managerAccountType) => {
        if (item) {
          navigate(
            uuid
              ? "/" +
                  uuid +
                  `/manager-accounts/view-assigned-properties/${item?.uuid}`
              : `/manager-accounts/view-assigned-properties/${item?.uuid}`,
            {
              state: {
                first_name: item?.first_name,
                email: item?.email,
                phone_code: item?.phone_code,
                phone_number: item?.phone_number,
              },
            }
          );
        }
      },
    },
    {
      icon: edit,
      name: "Edit",
      onClick: (item: managerAccountType) => {
        setSelectedItem(item);
        formik.resetForm();
        toggleAddEdit();
        setEditModal(true);
        formik.setFieldValue("first_name", item?.first_name);
        formik.setFieldValue("email", item?.email);
        formik.setFieldValue("phone_number", item?.phone_number);
        formik.setFieldValue(
          "assign_property_uuids",
          item.assign_property_uuids || []
        );
      },
    },
    {
      icon: crossIcon,
      name: "Inactive",
      onClick: (item: managerAccountType) => {
        toggleActive();
        setSelectedItem(item);
      },
    },
    {
      icon: deleteIcon,
      name: "Delete",
      onClick: (item: managerAccountType) => {
        toggleDelete();
        setSelectedItem(item);
      },
    },
  ];

  return (
    <DashboardLayout>
      <div className={styles.dash_page}>
        <Container>
          <PageHeading
            heading="Manager Accounts"
            subHeading="Here is the information about your all manager accounts."
          >
            <div className={styles.search}>
              <div className={styles.searchField}>
                {/* <GoSearch className={styles.searchIcon} /> */}
                <InputField
                  name="search_manager_name"
                  icon={searchIcon}
                  placeholder="Search by Manager Name"
                  value={searchValue}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setSearchValue(e.target.value);
                  }}
                  autoComplete="off"
                />
                {searchValue ? (
                  <span
                    className={styles.crossIcon}
                    onClick={() => setSearchValue("")}
                  >
                    <RxCross1 />
                  </span>
                ) : null}
              </div>
              <ThemeButton
                variant="primary"
                onClick={() => {
                  toggleAddEdit();
                  setEditModal(false);
                  formik.resetForm();
                }}
              >
                Add New Account
              </ThemeButton>
            </div>
          </PageHeading>
          <ReactTable
            headings={tableHeadings}
            data={managerAccountList || []}
            actionColumn
            statusColumn
            loading={listManagerAccountLoading || !managerAccountList}
            actionButtonOptions={actionButtonOptions}
            currentPage={currentPage}
            itemPerPage={pageLimit}
            totalItems={totalRecords}
            setPerPageLimit={setPageLimit}
            setCurrentPage={setCurrentPage}
            pageLimit={pageLimit}
            pagination={true}
          />

          <AddAccountModal
            show={show}
            handleClose={toggleAddEdit}
            title={editModal ? "Edit Account" : "Add Account"}
            editModal={editModal}
            loading={addManagerAccountLoading || editManagerAccountLoading}
            formik={formik}
            updatedPropertyItems={updatedPropertyItems}
            removeItems={removeItems}
            setUpdatedPropertyItems={setUpdatedPropertyItems}
            setRemoveItems={setRemoveItems}
          />
          <ConfirmationModal
            handleToggle={toggleDelete}
            title={
              "Deleting this manager may result in their removal from the properties they are currently assigned to."
            }
            show={showDelete}
            loading={deleteManagerAccountLoading}
            confirm={() => deleteManagerAccountApi()}
          />
          <InactiveActiveModal
            title={`Are you sure you want to ${
              selectedItem?.is_active ? "deactivate" : "activate"
            }
                  this manager account?`}
            handleToggle={toggleActive}
            show={showActive}
            heading={selectedItem?.is_active ? "Deactivate" : "Activate"}
            loading={statusManagerAccountLoading}
            confirm={() =>
              statusManagerAccountApi({
                is_active: selectedItem?.is_active ? 0 : 1,
              })
            }
          />
        </Container>
      </div>
    </DashboardLayout>
  );
};

export default ManagerAccounts;
