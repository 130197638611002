import { Col, Container, Row, Table } from "react-bootstrap";
import { dataAssigenedPropertyAccounts } from "../../../../common/dummyData";
import DashboardLayout from "../../../../components/layouts/DashboardLayout";
import styles from "../property/view-assigned-properties.module.scss";
import CommonPagination from "../../../../components/paginations";

const ViewAssignedProperties = () => {
  return (
    <DashboardLayout>
      <div className={styles.dash_page}>
        <Container>
          <Row className="g-xl-4 g-3">
            <Col xxl={12} xl={12}>
              <div
                className={`${styles.dashboardHeading} ${styles.orgnaizationDetails}`}
              >
                <div className={styles.alltips}>
                  <h2>View Assigned Properties</h2>
                  <p>Here you can view all properties of manager.</p>
                </div>
              </div>
            </Col>

            <Col xxl={12} xl={12}>
              <div className={styles.manageCards}>
                <div className={styles.cardsCards}>
                  <h2>Manager Name</h2>
                  <p>Itika Sharma.</p>
                </div>
                <div className={styles.cardsCards}>
                  <h2>Manager Email</h2>
                  <p>itika@gmail.com.</p>
                </div>
                <div className={styles.cardsCards}>
                  <h2>Manager Phone</h2>
                  <p>+1 365554520</p>
                </div>
              </div>
            </Col>

            <Col xxl={12} xl={12}>
              <div className={styles.dashboardTable}>
                <div className={styles.dashboartabless}>
                  <Table hover responsive>
                    <thead>
                      <tr>
                        <th> Organization Name</th>
                        <th>Property Name</th>
                        <th style={{ width: "43%" }}>Property Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataAssigenedPropertyAccounts.map((item, index) => (
                        <tr key={index}>
                          <td>{item.PropertyID}</td>
                          <td>{item.PropertyName}</td>
                          <td>{item.PropertyAddress}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <div className={`paginations ${styles.pagnNew}`}>
                    <p>Showing 1 to 10 of 10 entries</p>
                    <CommonPagination
                      itemPerPage={1}
                      totalItems={3}
                      paginate={function (page: number): void {
                        throw new Error("Function not implemented.");
                      }}
                      currentPage={1}
                    />
                    <label>
                      Show Entries
                      <select>
                        <option>10</option>
                        <option>20</option>
                        <option>30</option>
                      </select>
                    </label>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </DashboardLayout>
  );
};
export default ViewAssignedProperties;
