import { TipIconEle, copy, qr, tipIcon } from "../assests/Icons/icon";
import ThemeButton from "../components/theme/ThemeButton";

export const dataViewMore = [
  {
    organizationName: "Frank Murlo.",
    propertyName: "312 S Wilmette Ave",
    tipperName: "John Doe",
    tipperEmail: "infotip@gmail.com ",
    netTipAmount: "$100",
    date: "2023-01-01",
  },
  {
    organizationName: "Thomas Bleir.",
    propertyName: "Lathrop Ave, Harvey",
    tipperName: "Jane Doe",
    tipperEmail: "infotip@gmail.com",
    netTipAmount: "$150",
    date: "2023-02-15",
  },
  {
    organizationName: "Bill Norton.",
    propertyName: "5685 Bruce Ave, Portage",
    tipperName: "Bob Smith",
    tipperEmail: "infotip@gmail.com",
    netTipAmount: "$75",
    date: "2023-03-10",
  },
  {
    organizationName: "Thomas Bleir.",
    propertyName: "Lathrop Ave, Harvey",
    tipperName: "Jane Doe",
    tipperEmail: "infotip@gmail.com",
    netTipAmount: "$150",
    date: "2023-02-15",
  },
  {
    organizationName: "Bill Norton.",
    propertyName: "5685 Bruce Ave, Portage",
    tipperName: "Bob Smith",
    tipperEmail: "infotip@gmail.com",
    netTipAmount: "$75",
    date: "2023-03-10",
  },
];

export const dataServicesCategory = [
  {
    id: "#451",
    Name: "Frank Murlo",
    services: "51",
    is_active: 0,
  },
  {
    id: "#452",
    Name: "Frank Murlo",
    services: "51",
    is_active: 1,
  },
  {
    id: "#453",
    Name: "Frank Murlo",
    services: "51",
    is_active: 1,
  },
  {
    id: "#454",
    Name: "Frank Murlo",
    services: "51",
    is_active: 0,
  },
  {
    id: "#455",
    Name: "Frank Murlo",
    services: "51",
    is_active: 1,
  },
];

export const tipItemsViewMore = [
  { label: "Net Tip Amount", value: "$30" },
  { label: "Stripe Fee", value: "$5" },
  { label: "TopTipper Fee", value: "$10" },
  { label: "Total Trans. Amount", value: "$20" },
];

export const highttipItemsViewMore = [
  { label: "Total Trans. Amount", value: "$20" },
  { label: "TopTipper Fee", value: "$10" },
  { label: "Stripe Fee", value: "$5" },
  { label: "Net Tip Amount", value: "$30" },
  { label: "TopTipper Fee", value: "$10" },
  { label: "Stripe Fee", value: "$5" },
];

export const DashboardData = [
  {
    organizationName: "Frank Murlo.",
    propertyName: "312 S Wilmette Ave",
    netTipAmount: "$100",
    tipperEmail: "infotip@gmail.com ",
  },
  {
    organizationName: "Thomas Bleir.",
    propertyName: "Lathrop Ave, Harvey",
    netTipAmount: "$150",
    tipperEmail: "infotip@gmail.com",
  },
  {
    organizationName: "Bill Norton.",
    propertyName: "5685 Bruce Ave, Portage",
    netTipAmount: "$75",
    tipperEmail: "infotip@gmail.com",
  },
  {
    organizationName: "Thomas Bleir.",
    propertyName: "Lathrop Ave, Harvey",
    netTipAmount: "$150",
    tipperEmail: "infotip@gmail.com",
  },
  {
    organizationName: "Bill Norton.",
    propertyName: "5685 Bruce Ave, Portage",
    netTipAmount: "$75",
    tipperEmail: "infotip@gmail.com",
  },
];

export const DashboardTipItems = [
  { label: "Total Trans. Amount", value: "$20" },
  { label: "Stripe Fee", value: "$10" },
  { label: "TopTipper Fee", value: "$5" },
  { label: "Net Tip Amount", value: "$30" },
];

export const dataOrgnaization = [
  {
    organizationId: "org2350215",
    organizationName: "Frank Murlo.",
    propertyName: "312 S Wilmette Ave",
    tipperName: "Complete",
    tipperEmail: "infotip@gmail.com ",
    status: "Active",
    id: "1",
  },
  {
    organizationId: "org02515",
    organizationName: "Thomas Bleir.",
    propertyName: "Lathrop Ave, Harvey",
    tipperName: "Restricted",
    tipperEmail: "infotip@gmail.com",
    status: "InActive",
    id: "2",
  },
  {
    organizationId: "org0243515",
    organizationName: "Bill Norton.",
    propertyName: "Restricted Soon",
    tipperName: "Restricted Soon",
    tipperEmail: "infotip@gmail.com",
    status: "Active",
    id: "3",
  },
  {
    organizationId: "org0345215",
    organizationName: "Bill Norton.",
    propertyName: "5685 Bruce Ave, Portage",
    tipperName: "Enabled",
    tipperEmail: "infotip@gmail.com",
    status: "InActive",
    id: "4",
  },
  {
    organizationId: "org05235215",
    organizationName: "Bill Norton.",
    propertyName: "5685 Bruce Ave, Portage",
    tipperName: "Not Setup",
    tipperEmail: "infotip@gmail.com",
    status: "Active",
  },
  {
    organizationId: "org03252215",
    organizationName: "Frank Murlo.",
    propertyName: "312 S Wilmette Ave",
    tipperName: "Complete",
    tipperEmail: "infotip@gmail.com ",
    status: "Active",
    id: "1",
  },
  {
    organizationId: "org23520215",
    organizationName: "Frank Murlo.",
    propertyName: "312 S Wilmette Ave",
    tipperName: "Complete",
    tipperEmail: "infotip@gmail.com ",
    status: "Active",
    id: "1",
  },
];

export const dataOrgnaizationDetails = [
  {
    organizationId: "org05325215",
    organizationName: "Madalin Corp..",
    propertyName: "47 W 13th St, New York, NY 10011, USA",

    status: "Active",
    id: "1",
  },
  {
    organizationId: "org0232515",
    organizationName: "Madalin Corp..",
    propertyName: "47 W 13th St, New York, NY 10011, USA",

    status: "Inactive",
    id: "1",
  },
  {
    organizationId: "org05533215",
    organizationName: "Madalin Corp..",
    propertyName: "47 W 13th St, New York, NY 10011, USA",

    status: "Inactive",
    id: "1",
  },
  {
    organizationId: "org235320215",
    organizationName: "Ma23532dalin Corp..",
    propertyName: "47 W 13th St, New York, NY 10011, USA",

    status: "Active",
    id: "1",
  },
];

export const dataPropertyAccounts = [
  {
    Name: "Frank Murlo",
    Email: "infotip@gmail.com",
    PhoneNumber: "+1 2154852140",
    id: "1",
  },
  {
    Name: "Frank ",
    Email: "infotip@gmail.com",
    PhoneNumber: "+1 2154852140",
    id: "2",
  },
  {
    Name: " Murlo",
    Email: "infotip@gmail.com",
    PhoneNumber: "+1 2154852140",
    id: "3",
  },
  {
    Name: "Frank Murlo",
    Email: "infotip@gmail.com",
    PhoneNumber: "+1 2154852140",
    id: "4",
  },
  {
    Name: "Frank ",
    Email: "infotip@gmail.com",
    PhoneNumber: "+1 2154852140",
    id: "5",
  },

  {
    Name: "Frank Murlo",
    Email: "infotip@gmail.com",
    PhoneNumber: "+1 2154852140",
    id: "6",
  },
];

export const dataAssigenedPropertyAccounts = [
  {
    PropertyID: "or352g0215",
    PropertyName: "Frank Murlo",
    PropertyAddress: "47 W 13th St, New York, NY 10011, USA",
  },
  {
    PropertyID: "org0235215",
    PropertyName: "Frank Murlo",
    PropertyAddress: "20 Cooper Square, New York, NY 10003, USA",
  },
  {
    PropertyID: "o352rg0215",
    PropertyName: "Frank Murlo",
    PropertyAddress: "70 Washington Square, New York, NY 10012, United States",
  },
  {
    PropertyID: "org0215355",
    PropertyName: "Frank Murlo",
    PropertyAddress: "47 W 13th St, New York, NY 10011, USA",
  },
  {
    PropertyID: "org02353215",
    PropertyName: "Frank Murlo",
    PropertyAddress: "20 Cooper Square, New York, NY 10003, USA",
  },
  {
    PropertyID: "org2230215",
    PropertyName: "Frank Murlo",
    PropertyAddress: "47 W 13th St, New York, NY 10011, USA",
  },
  {
    PropertyID: "org0212535",
    PropertyName: "Frank Murlo",
    PropertyAddress: "20 Cooper Square, New York, NY 10003, USA",
  },
];

export const dataPartners = [
  {
    id: "#451",
    Name: "Frank Murlo",
    status: "Active",
  },
  {
    id: "#452",
    Name: "Frank Murlo",
    status: "Inactive",
  },
  {
    id: "#453",
    Name: "Frank Murlo",
    status: "Active",
  },
  {
    id: "#454",
    Name: "Frank Murlo",
    status: "Inactive",
  },
  {
    id: "#455",
    Name: "Frank Murlo",
    status: "Active",
  },
];

export const dataTippers = [
  {
    Name: "Thomas Bleir",
    Email: "infotip@gmail.com.",
    StripeID: "cus_Ot0xaRP518pe7F",
    NumberofTips: "87",
    is_active: 1,
    id: "1",
  },
  {
    Name: "Thomas Bleir",
    Email: "infotip@gmail.com.",
    StripeID: "cus_Ot0xaRP518pe7F",
    NumberofTips: "87",
    is_active: 0,
    id: "2",
  },
  {
    Name: "Thomas Bleir",
    Email: "infotip@gmail.com.",
    StripeID: "cus_Ot0xaRP518pe7F",
    NumberofTips: "87",
    is_active: 0,
    id: "3",
  },
  {
    Name: "Thomas Bleir",
    Email: "infotip@gmail.com.",
    StripeID: "cus_Ot0xaRP518pe7F",
    NumberofTips: "87",
    is_active: 1,
    id: "4",
  },
  {
    Name: "Thomas Bleir",
    Email: "infotip@gmail.com.",
    StripeID: "cus_Ot0xaRP518pe7F",
    NumberofTips: "87",
    is_active: 1,
    id: "5",
  },
];

export const dataViewTips = [
  {
    organizationId: "or2352g0215",
    organizationName: "Madalin Corp..",
    propertyName: "$8,479",

    Date: "12/14/2023",
  },
  {
    organizationId: "org3250215",
    organizationName: "Madalin Corp..",
    propertyName: "$8,479",

    Date: "12/14/2023",
  },
  {
    organizationId: "org0235215",
    organizationName: "Madalin Corp..",
    propertyName: "$8,479",

    Date: "12/14/2023",
  },
  {
    organizationId: "org0235215",
    organizationName: "Madalin Corp..",
    propertyName: "$8,479",

    Date: "12/14/2023",
  },
];

export const organizationPropertyDetailsData = [
  {
    heading: "Property ID",
    name: "tokeettest",
  },
  {
    heading: "Organization Name",
    name: "Hospitality",
  },
  {
    heading: "Email",
    name: "tokeettest@yopmail.com",
  },

  {
    heading: "Phone Number",
    name: "--",
  },

  {
    heading: "Address",
    name: "55 East 10th Street, New York, NY 10003, United States",
  },

  {
    heading: "Trip Advisor URL",
    name: "https://www.tripadvisor.com/Hotel_Review-g295424-d...",
  },
  {
    heading: "Google Site URL",
    name: "https://www.tripadvisor.com/Hotel_Review-g295424-d...",
  },
  {
    heading: "Background Color",
    name: "#0A0E18",
  },
  {
    heading: "Font Color",
    name: "#FD7014",
  },
];
export const PropertiesData = [
  {
    id: "223535",
    name: "Madalin Corp.",
    address: "123 Main Street Anytown, USA 12345",
    tipsNo: 67,
    is_active: 1,
  },
  {
    id: "org021325235",
    name: "Madalin Corp.",
    address: "123 Main Street Anytown, USA 12345",
    tipsNo: 67,
    is_active: 0,
  },
  {
    id: "org235230215",
    name: "Madalin Corp.",
    address: "123 Main Street Anytown, USA 12345",
    tipsNo: 67,
    is_active: 1,
  },
  {
    id: "org0223415",
    name: "Madalin Corp.",
    address: "123 Main Street Anytown, USA 12345",
    tipsNo: 67,
    is_active: 1,
  },
  {
    id: "org0212335",
    name: "Madalin Corp.",
    address: "123 Main Street Anytown, USA 12345",
    tipsNo: 67,
    is_active: 0,
  },
  {
    id: "org0215",
    name: "Madalin Corp.",
    address: "123 Main Street Anytown, USA 12345",
    tipsNo: 67,
    is_active: 0,
  },
];

export const ServiceData = [
  {
    category: "Custom",
    service: "Front Desk",
  },
  {
    category: "Logistics & Delivery",
    service: "Package Delivery",
  },
  // Add more service data as needed
];

export const PropertyEmployesData = [
  {
    name: "Itika Sharma",
    service: "Cred Talk",
    email: "itika@gmail.com",
    phone: "+1 85948723645",
  },
  {
    name: "Itika Sharma",
    service: "Cred Talk",
    email: "itika@gmail.com",
    phone: "+1 85948723645",
  },
  // Add more user data as needed
];

export const PropertyRoomsData = [
  {
    RoomName: "Room 1",
  },
  {
    RoomName: "Room 2",
  },
  {
    RoomName: "Room 3",
  },
  {
    RoomName: "Room 4",
  },
  {
    RoomName: "Room 5",
  },
];

export const dataTransactionDetail = [
  {
    tipperName: "John Doe",
    tipperEmail: "infotip@gmail.com ",
    netTipAmount: "$100",
    date: "12/14/2023",
    service: "Front Desk",
    Employee: "Itika Sharma",
    Room: "15",
  },
  {
    tipperName: "John Doe",
    tipperEmail: "infotip@gmail.com ",
    netTipAmount: "$100",
    date: "12/14/2023",
    service: "Front Desk",
    Employee: "Itika Sharma",
    Room: "15",
  },
  {
    tipperName: "John Doe",
    tipperEmail: "infotip@gmail.com ",
    netTipAmount: "$100",
    date: "12/14/2023",
    service: "Front Desk",
    Employee: "Itika Sharma",
    Room: "15",
  },
  {
    tipperName: "John Doe",
    tipperEmail: "infotip@gmail.com ",
    netTipAmount: "$100",
    date: "12/14/2023",
    service: "Front Desk",
    Employee: "Itika Sharma",
    Room: "15",
  },
  {
    tipperName: "John Doe",
    tipperEmail: "infotip@gmail.com ",
    netTipAmount: "$100",
    date: "12/14/2023",
    service: "Front Desk",
    Employee: "Itika Sharma",
    Room: "15",
  },
];
export const qrCodeOrganizationDetail = [
  {
    orgID: "org0215",
    orgName: "Frank Murlo ",
    qrCode: qr,
    url: copy,
  },
];
export const qrCodePropertyDetail = [
  {
    propID: "org0215",
    propName: "Frank Murlo ",
    qrCode: qr,
    url: copy,
  },
  {
    propID: "org0215",
    propName: "Frank Murlo ",
    qrCode: qr,
    url: copy,
  },
  {
    propID: "org0215",
    propName: "Frank Murlo ",
    qrCode: qr,
    url: copy,
  },
  {
    propID: "org0215",
    propName: "Frank Murlo ",
    qrCode: qr,
    url: copy,
  },
];
export const qrCodeServicesDetail = [
  {
    serviceCategory: "Frank Murlo",
    serviceName: "Hospitality ",
    propertyName: "Philadelphia Marriott Downtown",
    qrCode: qr,
    url: copy,
  },
  {
    serviceCategory: "Frank Murlo",
    serviceName: "Hospitality",
    propertyName: "Philadelphia Marriott Downtown",
    qrCode: qr,
    url: copy,
  },
  {
    serviceCategory: "Frank Murlo",
    serviceName: "Hospitality ",
    propertyName: "Philadelphia Marriott Downtown",
    qrCode: qr,
    url: copy,
  },
  {
    serviceCategory: "Frank Murlo",
    serviceName: "Hospitality ",
    propertyName: "Philadelphia Marriott Downtown",
    qrCode: qr,
    url: copy,
  },
];
export const qrCodeEmployeeDetail = [
  {
    employeeName: "Frank Murlo",
    employeeEmail: "infotip@gmail.com ",
    propertyName: "Philadelphia Marriott Downtown",
    qrCode: qr,
    url: copy,
  },
  {
    employeeName: "Frank Murlo",
    employeeEmail: "infotip@gmail.com ",
    propertyName: "Philadelphia Marriott Downtown",
    qrCode: qr,
    url: copy,
  },
  {
    employeeName: "Frank Murlo",
    employeeEmail: "infotip@gmail.com ",
    propertyName: "Philadelphia Marriott Downtown",
    qrCode: qr,
    url: copy,
  },
  {
    employeeName: "Frank Murlo",
    employeeEmail: "infotip@gmail.com ",
    propertyName: "Philadelphia Marriott Downtown",
    qrCode: qr,
    url: copy,
  },
];
export const qrCodeRoomsDetail = [
  {
    roomID: "org0215",
    roomName: "Frank Murlo ",
    propertyName: "Philadelphia Marriott Downtown",
    qrCode: qr,
    url: copy,
  },
  {
    roomID: "org0215",
    roomName: "Frank Murlo ",
    propertyName: "Philadelphia Marriott Downtown",
    qrCode: qr,
    url: copy,
  },
  {
    roomID: "org0215",
    roomName: "Frank Murlo ",
    propertyName: "Philadelphia Marriott Downtown",
    qrCode: qr,
    url: copy,
  },
  {
    roomID: "org0215",
    roomName: "Frank Murlo ",
    propertyName: "Philadelphia Marriott Downtown",
    qrCode: qr,
    url: copy,
  },
];

export const OrganizationDashData = [
  {
    propertyId: 1,
    name: "312 S Wilmette Ave",
    netTipAmount: <span> $100 {tipIcon} </span>,
    tipperEmail: "infotip@gmail.com ",
  },
  {
    propertyId: 2,
    name: "Lathrop Ave, Harvey",
    netTipAmount: <span> $150 {tipIcon} </span>,
    tipperEmail: "infotip@gmail.com",
  },
  {
    propertyId: 33,
    name: "5685 Bruce Ave, Portage",
    netTipAmount: <span> $75 {tipIcon} </span>,
    tipperEmail: "infotip@gmail.com",
  },
  {
    propertyId: 4,
    name: "Lathrop Ave, Harvey",
    netTipAmount: <span> $150 {tipIcon} </span>,
    tipperEmail: "infotip@gmail.com",
  },
  {
    propertyId: 4,
    name: "5685 Bruce Ave, Portage",
    netTipAmount: <span> $75 {tipIcon} </span>,
    tipperEmail: "infotip@gmail.com",
  },
];
export const managerAccountData = [
  {
    managerName: "Frank Murlo",
    managerEmail: "infotip@gmail.com",
    addedBy: "Super Admin",
  },
  {
    managerName: "Frank Murlo",
    managerEmail: "infotip@gmail.com",
    addedBy: "Super Admin",
  },
  {
    managerName: "Frank Murlo",
    managerEmail: "infotip@gmail.com",
    addedBy: "Super Admin",
  },
  {
    managerName: "Frank Murlo",
    managerEmail: "infotip@gmail.com",
    addedBy: "Super Admin",
  },
  {
    managerName: "Frank Murlo",
    managerEmail: "infotip@gmail.com",
    addedBy: "Super Admin",
  },
  {
    managerName: "Frank Murlo",
    managerEmail: "infotip@gmail.com",
    addedBy: "Super Admin",
  },
  {
    managerName: "Frank Murlo",
    managerEmail: "infotip@gmail.com",
    addedBy: "Super Admin",
  },
  {
    managerName: "Frank Murlo",
    managerEmail: "infotip@gmail.com",
    addedBy: "Super Admin",
  },
  {
    managerName: "Frank Murlo",
    managerEmail: "infotip@gmail.com",
    addedBy: "Super Admin",
  },
  {
    managerName: "Frank Murlo",
    managerEmail: "infotip@gmail.com",
    addedBy: "Super Admin",
  },
];

export const reportingData = [
  {
    name: "Frank Murlo",
    email: "infotip@gmail.com",
    frequency: "Super Admin",
  },
  {
    name: "Frank Murlo",
    email: "infotip@gmail.com",
    frequency: "Super Admin",
  },
  {
    name: "Frank Murlo",
    email: "infotip@gmail.com",
    frequency: "Super Admin",
  },
  {
    name: "Frank Murlo",
    email: "infotip@gmail.com",
    frequency: "Super Admin",
  },
  {
    name: "Frank Murlo",
    email: "infotip@gmail.com",
    frequency: "Super Admin",
  },
  {
    name: "Frank Murlo",
    email: "infotip@gmail.com",
    frequency: "Super Admin",
  },
  {
    name: "Frank Murlo",
    email: "infotip@gmail.com",
    frequency: "Super Admin",
  },
  {
    name: "Frank Murlo",
    email: "infotip@gmail.com",
    frequency: "Super Admin",
  },
  {
    name: "Frank Murlo",
    email: "infotip@gmail.com",
    frequency: "Super Admin",
  },
  {
    name: "Frank Murlo",
    email: "infotip@gmail.com",
    frequency: "Super Admin",
  },
];
