import { FormikProps } from "formik";
import { useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import dollarIcon from "../../../assests/svg/dollarIcon.svg";
import { isValidUrl } from "../../../common/utils/Functions";
import useFetch from "../../../hooks/useFetch";
import { serviceValidations } from "../../../validations/serviceSchema";
import ModalLayout from "../../layouts/ModalLayout";
import InputField from "../../theme/InputField";
import LoadingBars from "../../theme/LoadingBars";
import ThemeButton from "../../theme/ThemeButton";
import UrlCodeToolTip from "../../theme/UrlCodeToolTip";
import AmountField from "../../theme/amountField";
import styles from "./addEditServicse.module.scss";

interface Iprops {
  handleClose: () => void;
  show: boolean;
  title?: string;
  editModal?: boolean;
  formik: FormikProps<any>;
  loading?: boolean;
  setValidationSchema?: React.Dispatch<React.SetStateAction<any>>;
}

const AddEditServicseModal = ({
  handleClose,
  show,
  title,
  editModal,
  formik,
  loading,
  setValidationSchema,
}: Iprops) => {
  // const [serviceCategoryOptions, setServiceCategoryOptions] = useState<
  //   { label: string; value: number }[]
  // >([]);

  // /** Api definition for category select */
  // const [listCategoryApi, { response: listResponse, error: listError }] =
  //   useFetch("/servicecategories/list", {
  //     method: "post",
  //   });

  /** Api definition to check service url duplication */
  const [
    validateURLIdentifierApi,
    {
      response: identifierURLres,
      loading: identifierURLLoading,
      error: identifierURLError,
    },
  ] = useFetch("/common/validate-identifier", {
    method: "post",
  });

  // /** Call @listCategoryApi api */
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     listCategoryApi({
  //       start: 0,
  //       limit: -1,
  //       is_active: 1,
  //       search: "",
  //     });
  //   }, 500);
  //   return () => clearTimeout(timer);
  // }, []);

  /** Handle @listCategoryApi response */
  // useEffect(() => {
  //   if (listResponse) {
  //     const updatedList = listResponse.data.list.map((item: any) => ({
  //       value: item.id,
  //       label: item.name,
  //     }));
  //     setServiceCategoryOptions(updatedList);
  //   }
  //   if (listError) {
  //     toast.error(listError.message);
  //   }
  // }, [listResponse, listError]);

  const handleSubmit = async () => {
    if (isValidUrl(formik.values.url_code) && !editModal) {
      await validateURLIdentifierApi({
        type: 2,
        url_code: formik.values.url_code,
      }).catch(() => {});
      setTimeout(() => {
        formik.handleSubmit();
      }, 10);
    } else {
      formik.handleSubmit();
    }
  };

  useEffect(() => {
    if (identifierURLError && !editModal) {
      formik.setFieldError("url_code", " URL abbreviation exist.");
      formik.setFieldTouched("url_code", true, false);
      formik.setFieldTouched("name", true, false);
      formik.setFieldTouched("suggested_amount_1", true, false);
      formik.setFieldTouched("suggested_amount_2", true, false);
      formik.setFieldTouched("suggested_amount_3", true, false);
      // formik.setFieldTouched("category_id", true, false);
      const validation = serviceValidations(identifierURLError?.status);
      setValidationSchema && setValidationSchema(validation);
    } else if (identifierURLres && !editModal) {
      formik.setFieldTouched("url_code", false, false);
      const validation = serviceValidations(identifierURLres?.status);
      setValidationSchema && setValidationSchema(validation);
    }
  }, [identifierURLError, identifierURLres]);

  useEffect(() => {
    const validation = serviceValidations(1);
    setValidationSchema && setValidationSchema(validation);
  }, [show]);

  return (
    <ModalLayout
      title={title}
      show={show}
      // children={undefined}
      size={700}
      handleToggle={handleClose}
    >
      <Row className="g-xl-4 g-3">
        <Col xxl={6} xl={6}>
          <InputField
            name="name"
            label="Service Name"
            formik={formik}
            placeholder="Enter Service Name"
          />
        </Col>
        <Col xxl={6} xl={6}>
          <InputField
            disabled={identifierURLLoading || editModal}
            name="url_code"
            labelIcon={<UrlCodeToolTip text="URL extension for the service" />}
            label="Service URL Abbreviation"
            placeholder="Service URL Abbreviation"
            formik={formik}
            onChange={(e) => {
              formik.setFieldTouched("url_code", false, false);
              formik.setFieldValue(
                "url_code",
                e.target.value.replace(/\s/g, "")
              );
            }}
            style={{
              cursor: editModal ? "not-allowed" : "default",
            }}
          >
            {identifierURLLoading && (
              <div className="input_child">
                <LoadingBars color="#676d7a" />
              </div>
            )}
          </InputField>
        </Col>
        {/* <Col xxl={6} xl={6}>
          <ReactSelectField
            name="category_id"
            options={serviceCategoryOptions}
            label="Service Category"
            placeholder="Select Service Category"
            className="form_select"
            formik={formik}
            formikErrorMesg={formik.errors?.category_id}
          />
        </Col> */}
        <Col xxl={6} xl={6}>
          <AmountField
            name="suggested_amount_1"
            label="First Suggested Amount (Optional)"
            formik={formik}
            type="number"
            icon={dollarIcon}
            onChange={(e) =>
              formik.setFieldValue("suggested_amount_1", e.target.value)
            }
            placeholder="First Suggested Amount"
          />
        </Col>

        <Col xxl={6} xl={6}>
          <AmountField
            name="suggested_amount_2"
            label="Second Suggested Amount (Optional)"
            formik={formik}
            type="number"
            icon={dollarIcon}
            onChange={(e) =>
              formik.setFieldValue("suggested_amount_2", e.target.value)
            }
            placeholder="Second Suggested Amount"
          />
        </Col>
        <Col xxl={6} xl={6}>
          <AmountField
            name="suggested_amount_3"
            label="Third Suggested Amount (Optional)"
            type="number"
            formik={formik}
            onChange={(e) =>
              formik.setFieldValue("suggested_amount_3", e.target.value)
            }
            icon={dollarIcon}
            placeholder="Third Suggested Amount"
          />
        </Col>
      </Row>

      <Modal.Footer className={styles.FooterModal}>
        <ThemeButton loading={loading} onClick={handleSubmit}>
          {" "}
          {editModal ? "Save" : "Add"}{" "}
        </ThemeButton>
      </Modal.Footer>
    </ModalLayout>
  );
};

export default AddEditServicseModal;
