import React from "react";
import { RotatingLines } from "react-loader-spinner";

interface IProps {
  color?: string;
}

const LoadingBars = ({ color }: IProps) => {
  return (
    <RotatingLines
      strokeColor={color ? color : "white"}
      strokeWidth="5"
      animationDuration="0.75"
      width="20"
      visible={true}
    />
  );
};

export default LoadingBars;
