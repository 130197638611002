import * as yup from "yup";
export const serviceValidations = (status?: number) => {
  return yup.object().shape({
    name: yup
      .string()
      .trim()
      .max(50, "Service name must be less than 50 characters.")
      .required("Please enter service name."),
    url_code:
      status === 0
        ? yup
            .string()
            .trim()
            .max(50, "URL abbreviation must be less than 50 characters.")
            .test("is-exist", "URL abbreviation exist.", () => {
              if (status === 0) {
                return false;
              } else if (status === 1) {
                return true;
              }
            })
            .required("Please enter  url abbreviation.")
        : yup
            .string()
            .trim()
            .max(50, "URL abbreviation must be less than 50 characters.")
            .required("Please enter  url abbreviation."),
    // category_id: yup.object().shape({
    //   value: yup
    //     .number()
    //     .nullable()
    //     .required("Please select category.")
    //     .typeError("Select valid data."),
    //   label: yup.string().trim().notRequired(),
    // }),
    suggested_amount_1: yup
      .number()
      .min(0.0, "Amount should be greater than or equal to 0.00.")
      .max(999999, "Entered amount must be less than 6 digits.")
      .test(
        "maxDecimalPlaces",
        "Amount must have at most two decimal places.",
        (value) =>
          value === null ||
          value === undefined ||
          /^\d+(\.\d{1,2})?$/.test(value as any)
      )
      .nullable()
      .notRequired()
      .typeError("Please enter amount in numbers."),
    suggested_amount_2: yup
      .number()
      .min(0.0, "Amount should be greater than or equal to 0.00.")
      .max(999999, "Entered amount must be less than 6 digits.")
      .test(
        "maxDecimalPlaces",
        "Amount must have at most two decimal places.",
        (value) =>
          value === null ||
          value === undefined ||
          /^\d+(\.\d{1,2})?$/.test(value as any)
      )
      .nullable()
      .notRequired()
      .typeError("Please enter amount in numbers."),
    suggested_amount_3: yup
      .number()
      .min(0.0, "Amount should be greater than or equal to 0.00.")
      .max(999999, "Entered amount must be less than 6 digits.")
      .test(
        "maxDecimalPlaces",
        "Amount must have at most two decimal places.",
        (value) =>
          value === null ||
          value === undefined ||
          /^\d+(\.\d{1,2})?$/.test(value as any)
      )
      .nullable()
      .notRequired()
      .typeError("Please enter amount in numbers."),
  });
};
