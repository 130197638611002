import { FormikProps } from "formik";
import { organizationAddEditType } from "../../../../types/organization";

interface props {
  formik: FormikProps<organizationAddEditType>;
}

interface validateHanlder extends props {
  errorMesg: string;
  fieldName: string;
  status: number;
}

export function setIsTouchedFields({ formik }: props) {
  formik.setFieldTouched("organization_identifier", true, false);
  formik.setFieldTouched("organization_name", true, false);
  formik.setFieldTouched("first_name", true, false);
  formik.setFieldTouched("last_name", true, false);
  formik.setFieldTouched("email", true, false);
  formik.setFieldTouched("phone_number", true, false);
}

export const validationHandler = ({
  formik,
  errorMesg,
  fieldName,
  status,
}: validateHanlder) => {
  if (status === 0) {
    formik.setFieldError(fieldName, errorMesg);
  }
  setIsTouchedFields({ formik });
};
