import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCurrentUserData,
  notifyBugsnagError,
  showToast,
} from "../../../common/utils/Functions";
import styles from "../../../components/pagesComponents/manageProperty/AddProperty.module.scss";
import useFetch from "../../../hooks/useFetch";
import { changeOwnerType } from "../../../types/managerAccountType";
import ModalLayout from "../../layouts/ModalLayout";
import InputField from "../../theme/InputField";
import LoadingBars from "../../theme/LoadingBars";
import PhoneIntputField from "../../theme/PhoneIntputField";
import ReactSelectField from "../../theme/ReactSelectField";
import ThemeButton from "../../theme/ThemeButton";
import UrlCodeToolTip from "../../theme/UrlCodeToolTip";
import { changeOwnerSchema } from "../../../validations/managerAccountSchema";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
// import UrlCodeToolTip from "../../../theme/UrlCodeToolTip";
// import { showToast } from "../../../../common/utils/Functions";
interface changeOwnerModalProps {
  handleClose: () => void;
  show: boolean;
  title?: string;
  allState?: any;
  setValidationSchema?: React.Dispatch<React.SetStateAction<any>>;
  emailLoading?: boolean;
  formik?: any;
  showChangeOwner?: any;
  setOrgUuid?: any;
}
const ChangeOwnerModal = ({
  emailLoading,
  handleClose,
  show,
  title,
  setOrgUuid,
}: changeOwnerModalProps) => {
  const [managersOptions, setManagersOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const [disableField, setDisableField] = useState<boolean>();

  const navigate = useNavigate();
  const initialValues: changeOwnerType = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    existing_manager_uuid: "",
  };
  const { uuid } = useParams();
  const { reloadManagerList } = useSelector(
    (store: RootState) => store.userData
  );
  const userData = getCurrentUserData(`${uuid}`);
  /** Api definition for managers select */
  const [listManagersApi, { response: managerResponse, error: managerError }] =
    useFetch("/manager/list", {
      method: "post",
    });

  const [
    changeOwnerApi,
    {
      response: changeOwnerResponse,
      loading: changeOwnerLoading,
      error: changeOwnerError,
    },
  ] = useFetch(
    `/organization/change-owner/${
      userData?.role_id === 2 ? userData?.uuid : uuid
    }`,
    {
      method: "post",
    }
  );

  const formik = useFormik({
    initialValues,
    validationSchema: changeOwnerSchema,
    onSubmit: (values: changeOwnerType) => {
      try {
        let payload;
        if (values?.existing_manager_uuid?.value) {
          payload = {
            ...values,
            existing_manager_uuid: values?.existing_manager_uuid?.value,
          };
        } else {
          payload = {
            ...values,
          };
        }

        changeOwnerApi({
          ...payload,
        });
      } catch (e: any) {
        notifyBugsnagError(e, { api: "changeOwnerApi" });
      }
    },
  });
  useEffect(() => {
    if (changeOwnerResponse) {
      showToast(changeOwnerResponse.message as string, "success");
      handleClose();
      navigate(
        `/organizations/view/${changeOwnerResponse?.data?.user_details?.uuid}`
      );
      // setOrgUuid(changeOwnerResponse?.data?.user_details?.uuid);

      formik.resetForm();
    }
    if (changeOwnerError) {
      showToast(changeOwnerError.message as string, "error");
    }
  }, [changeOwnerResponse, changeOwnerError]);

  /** Call @listManagersApi api */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        listManagersApi({
          start: 0,
          limit: -1,
          is_active: 1,
          search: "",
          organization_uuid: uuid,
        });
      } catch (e: any) {
        notifyBugsnagError(e, { api: "listManagersApi" });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [reloadManagerList]);

  /** Handle @listManagersApi response */
  useEffect(() => {
    if (managerResponse) {
      const updatedList = managerResponse.data.list.map((item: any) => ({
        value: item.uuid,
        label: item.first_name,
      }));
      setManagersOptions(updatedList);
      //   formik.setFieldTouched("manager_email", false, false);
      //   formik.setFieldTouched("manager_name", false, false);
      //   formik.setFieldTouched("manager_phone_number", false, false);
    }
    if (managerError) {
      showToast(managerError.message as string, "error");
    }
  }, [managerResponse, managerError]);

  // useEffect(() => {
  //   if (formik?.values["existing_manager_id"]) {
  //     const arr: Array<number> = [];
  //     const formikValues: any = formik?.values["existing_manager_id"];
  //     formikValues?.forEach((item: { value: number }) => {
  //       arr.push(item?.value);
  //     });
  //     setExistingManagerId([...arr]);
  //   }
  // }, [formik?.values["existing_manager_id"]]);

  useEffect(() => {
    if (formik.values.existing_manager_uuid) {
      setDisableField(true);
      formik.setFieldValue("first_name", "");
      formik.setFieldValue("last_name", "");
      formik.setFieldValue("email", "");
      formik.setFieldValue("phone_number", "");
    }
  }, [formik.values.existing_manager_uuid]);

  useEffect(() => {
    if (!show) {
      formik.resetForm();
      setDisableField(false);
    }
  }, [show]);

  return (
    <>
      <ModalLayout
        title={title}
        show={show}
        size={700}
        handleToggle={handleClose}
      >
        <Row className="g-xl-4 g-3 mt-1">
          <h1 className={styles.inputHeading}>
            Select an existing manager as the organization owner
          </h1>
        </Row>
        <Row className="g-xl-4 g-3">
          <Col xxl={6} xl={6}>
            <ReactSelectField
              name="existing_manager_uuid"
              options={managersOptions}
              label="Manager"
              placeholder="Select Manager"
              className="form_select"
              formik={formik}
            />
          </Col>
        </Row>
        <Row className="g-xl-4 g-3 mt-1">
          <h1 className={styles.inputHeading}>
            Or you can add a new organization Owner account (Optional){" "}
            {/* <UrlCodeToolTip text="This will send an email to this email address and allow this property manager to create a TopTipper Account with access to the Property Manager Dashboard for this property." /> */}
          </h1>
        </Row>
        <Row className="g-xl-4 g-3">
          <Col xxl={6} xl={6}>
            <InputField
              type="text"
              name="first_name"
              placeholder="Enter First Name"
              label="Admin First Name"
              formik={formik}
              disabled={disableField}
              style={{
                cursor: disableField ? "not-allowed" : "pointer",
              }}
            />
          </Col>
          <Col xxl={6} xl={6}>
            <InputField
              type="text"
              name="last_name"
              placeholder="Enter Last Name"
              label="Admin Last Name"
              formik={formik}
              disabled={disableField}
              style={{
                cursor: disableField ? "not-allowed" : "pointer",
              }}
            />
          </Col>
          <Col xxl={6} xl={6}>
            <InputField
              name="email"
              placeholder="Enter Email"
              label="Admin Email"
              formik={formik}
              disabled={disableField}
              style={{
                cursor: disableField ? "not-allowed" : "pointer",
              }}
              // onChange={(e) =>
              //   formik.setFieldValue("email", removeInitialSpace(e.target.value))
              // }
            >
              {emailLoading && (
                <div className="input_child">
                  <LoadingBars color="#676d7a" />
                </div>
              )}
            </InputField>
          </Col>
          <Col xxl={6} xl={6}>
            <PhoneIntputField
              label="Admin Phone (Optional)"
              name="phone_number"
              formik={formik}
              phoneCodeId={(phoneId: string) =>
                formik.setFieldValue("phone_code_id", phoneId)
              }
              disabled={disableField}
              style={{
                cursor: disableField ? "not-allowed" : "pointer",
              }}
              disableField={disableField}
            />
          </Col>
          <Col xxl={12}>
            <div className={styles.butonSteps}>
              <Button
                variant="secondary"
                className="secondary  commonBtn"
                // type="submit"
                onClick={handleClose}
              >
                Cancel
              </Button>

              <ThemeButton
                // variant="primary"
                className="primary footerModalBtn commonBtn "
                loading={changeOwnerLoading}
                onClick={() => formik.handleSubmit()}
                disabled={changeOwnerLoading}
              >
                Change
              </ThemeButton>
            </div>
          </Col>
        </Row>
      </ModalLayout>
    </>
  );
};

export default ChangeOwnerModal;
