import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";
import * as XLSX from "xlsx";

interface TableData {
  name: string;
  data: (string | number)[][];
}

interface ExportToExcelProps {
  buttonTitle: string;
  loading: boolean;
  tables: TableData[];
  setOrgDetail: React.Dispatch<React.SetStateAction<any>>;
  columnWidths?: number[];
  onClick: () => void;
  isQrCodes?: boolean;
  setExcelData: React.Dispatch<React.SetStateAction<any>>;
  orgDetails: any; // Organization details
}

const ExcelForOrgDetails: React.FC<ExportToExcelProps> = ({
  tables,
  columnWidths,
  onClick,
  buttonTitle,
  loading,
  setOrgDetail,
  setExcelData,
  isQrCodes,
  orgDetails,
}) => {
  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    let orgSheetData;
    // Add organization details to the first sheet
    if (isQrCodes) {
      orgSheetData = [
        ["Organization Name", "QR Code", "Link"],
        [
          // orgDetails[0].id,
          orgDetails[0].name,
          orgDetails[0].qr_code,
          orgDetails[0].link,
        ],
      ];
    } else {
      orgSheetData = [
        [
          // "Organization ID",
          "Organization Name",
          "Status",
          "Admin Name",
          "Admin Email",
          "Admin Phone",
          "URL Abbreviation",
          "TopTipper Fee",
          "Theme Color",
          "Font Color",
          "Partner",
          "API Key",
          "QR Code",
          "Link",
          "Stripe Id",
        ],
        [
          // orgDetails[0].id,
          orgDetails[0].name,
          orgDetails[0].status === "Active" ? "Active" : "In Active",
          orgDetails[0].admin_name,
          orgDetails[0]?.admin_email,
          orgDetails[0]?.admin_no,
          orgDetails[0].url,
          orgDetails[0].fee,
          orgDetails[0].theme_color,
          orgDetails[0].font_color,
          orgDetails[0].partner_name,
          orgDetails[0].api_key,
          orgDetails[0].qr_code,
          orgDetails[0].link,
          orgDetails[0]?.stripe_id,
        ],
      ];
    }
    // convertToExcel(orgSheetData);
    const orgSheetWS = XLSX.utils.aoa_to_sheet(orgSheetData);

    if (columnWidths && columnWidths.length > 0) {
      // Customize column widths for all sheets
      orgSheetWS["!cols"] = columnWidths.map((width) => ({ width }));
    }
    XLSX.utils.book_append_sheet(wb, orgSheetWS, "Organization Details");

    // Add other sheets
    tables.forEach((table, index) => {
      const ws = XLSX.utils.aoa_to_sheet(table.data);
      if (columnWidths && columnWidths.length > 0) {
        // Customize column widths for all sheets
        ws["!cols"] = columnWidths.map((width) => ({ width }));
      }
      XLSX.utils.book_append_sheet(wb, ws, table.name || `Sheet${index + 1}`);
    });

    const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });
    // Convert the binary string to a Blob
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });
    // Create a download link
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${orgDetails[0].name + " " + new Date().toLocaleDateString()}.xlsx`
    );
    // Trigger the download
    document.body.appendChild(link);
    link.click();
    // Clean up
    setTimeout(() => {
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }, 0);

    setExcelData([]);
    setOrgDetail([]);
  };
  const s2ab = (s: string) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };
  /** @useEffect to call @excelExportHandler if excelData exist*/
  useEffect(() => {
    if (orgDetails?.length > 0) {
      exportToExcel();
    }
  }, [tables, orgDetails]);

  return (
    <Button
      onClick={onClick}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: 110,
        color: "white",
        border: "none",
        outline: "none",
        fontWeight: 500,
        fontSize: 14,
      }}
    >
      {!loading && buttonTitle}
      {loading && (
        <span>
          <RotatingLines visible={true} strokeColor="#fff" width="20" />
        </span>
      )}
    </Button>
  );
};

export default ExcelForOrgDetails;
