import { Col, Dropdown, Row } from "react-bootstrap";
import styles from "../property/view-property.module.scss";
import {
  PropertyEmployesData,
  PropertyRoomsData,
  ServiceData,
} from "../../../../common/dummyData";
import { empty } from "../../../../assests/Icons/icon";

const PropertyServices = () => {
  const isEmpty = ServiceData.length === 0;

  return (
    <>
      <Row className="mt-5">
        <Col xxl={6} xl={6}>
          <div className={styles.services}>
            <p>Services</p>
            <div className={styles.dashboardTable}>
              {isEmpty ? (
                // Render empty message if no services
                <div className={styles.emptydiv}>
                  <span>{empty}</span>
                  <h2>No Services</h2>
                  <p>There is no data in the services to show.</p>
                </div>
              ) : (
                // Render service data if available
                ServiceData.map((service, index) => (
                  <div key={index} className={styles.catServ}>
                    <ul>
                      <li>
                        <div className={styles.mainDev}>
                          <h4>Category </h4>
                          <p>{service.category}</p>
                        </div>
                      </li>
                      <li>
                        <div className={styles.mainDev}>
                          <h4>Service </h4>
                          <p>{service.service}</p>
                        </div>
                      </li>
                    </ul>

                    <div className={styles.dropDowns}>
                      <Dropdown
                        align="end"
                        onClick={(e) => e.stopPropagation()}
                        className={styles.dropDots}
                      >
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                          ...
                        </Dropdown.Toggle>
                      </Dropdown>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </Col>

        <Col xxl={6} xl={6}>
          <div className={styles.services}>
            <p>Employees</p>

            <div className={styles.dashboardTable}>
              {PropertyEmployesData.length === 0 ? (
                // Render empty message if no user data
                <div className={styles.emptydiv}>
                  <span>{empty}</span>
                  <h2>No Users</h2>
                  <p>There is no user data to show.</p>
                </div>
              ) : (
                // Render user data if available
                PropertyEmployesData.map((user, index) => (
                  <div key={index} className={styles.catServ}>
                    <ul>
                      <li>
                        <div className={styles.mainDev}>
                          <h4>Name </h4>
                          <p>{user.name}</p>
                        </div>
                      </li>
                      <li>
                        <div className={styles.mainDev}>
                          <h4>Service </h4>
                          <p>{user.service}</p>
                        </div>
                      </li>
                      <li>
                        <div className={styles.mainDev}>
                          <h4>Email </h4>
                          <p>{user.email}</p>
                        </div>
                      </li>
                      <li>
                        <div className={styles.mainDev}>
                          <h4>Phone </h4>
                          <p>{user.phone}</p>
                        </div>
                      </li>
                    </ul>

                    <div className={styles.dropDowns}>
                      <Dropdown
                        align="end"
                        onClick={(e) => e.stopPropagation()}
                        className={styles.dropDots}
                      >
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                          ...
                        </Dropdown.Toggle>
                      </Dropdown>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </Col>

        <Col xxl={6} xl={6}>
          <div className={`${styles.services} ${styles.servicesRooms}`}>
            <p>Rooms</p>
            <div className={styles.dashboardTable}>
              {PropertyRoomsData.length === 0 ? (
                // Render empty message if no room data
                <div className={styles.emptydiv}>
                  <span>{empty}</span>
                  <h2>No Rooms</h2>
                  <p>There are no rooms to show.</p>
                </div>
              ) : (
                // Render room data if available
                PropertyRoomsData.map((room, index) => (
                  <div key={index} className={styles.catServ}>
                    <ul>
                      <li>
                        <div className={styles.mainDev}>
                          <h4>{room.RoomName}</h4>
                          {/* Assuming you have a 'name' property for each room */}
                        </div>
                      </li>
                    </ul>

                    <div className={styles.dropDowns}>
                      <Dropdown
                        align="end"
                        onClick={(e) => e.stopPropagation()}
                        className={styles.dropDots}
                      >
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                          ...
                        </Dropdown.Toggle>
                      </Dropdown>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default PropertyServices;
