import DashboardLayout from "../../../components/layouts/DashboardLayout";
import { Col, Container, Row } from "react-bootstrap";
import PageHeading from "../../../components/pageHeading";
import ThemeButton from "../../../components/theme/ThemeButton";
import styles from "../paymentManagement/payment.module.scss";
import { paymentStripe, successTick } from "../../../assests/Icons/icon";
import { useEffect } from "react";
import useFetch from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import { notifyBugsnagError, showToast } from "../../../common/utils/Functions";

const Success = () => {
  const [
    paymentApi,
    { response: paymentResponse, loading: paymentLoading, error: paymentError },
  ] = useFetch("organization/stripe-integration");

  useEffect(() => {
    if (paymentResponse) {
      const url = paymentResponse?.data?.url;
      window.open(url, "_blank");
    }
    if (paymentError) {
      showToast(paymentError.message as string, "error");
    }
  }, [paymentResponse, paymentError]);
  return (
    // <DashboardLayout>
    <Container>
      <PageHeading
        heading="Payment Management"
        subHeading="Here you can manage all your payments."
      />
      <div className={styles.payment_wrap}>
        <Row>
          <Col xs={12} xl={4} xxl={2}>
            <div className={styles.payment_content}>
              <span className={styles.innerContent}>{paymentStripe}</span>
              <div className={styles.successDiv}>
                <span className={styles.successIcon}>{successTick}</span>
              </div>
            </div>
          </Col>
          <Col xs={12} xxl={10} xl={8} className={styles.right}>
            <h5 className={styles.head}>Your Stripe Account is Set Up</h5>
            <p>
              You may update your Stripe payment information at any point. Click
              the button below to do so.
            </p>

            <ThemeButton
              loading={paymentLoading}
              onClick={() => {
                paymentApi();
              }}
            >
              Edit Stripe Payment Information
            </ThemeButton>
          </Col>
        </Row>
      </div>
    </Container>
    // </DashboardLayout>
  );
};

export default Success;
