import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { getLocalUserData, getUserToken } from "../../common/utils/Functions";

interface IProps {
  children: React.ReactNode;
}
const AuthPublicRoute = ({ children }: IProps) => {
  const userInfo = getLocalUserData();
  const { uuid } = useParams();
  const userToken = getUserToken(uuid as string);
  return <>{userInfo && userToken ? <Navigate to="/dashboard" /> : children}</>;
};

export default AuthPublicRoute;
