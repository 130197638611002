import * as yup from "yup";
import { colorYup, emailYup, phoneYup, zipCodeYup } from "./CommonConstants";

export const ProfileSchema = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .max(50, "First name must be less than 50 characters.")
    .required("Please enter first name."),
  last_name: yup
    .string()
    .trim()
    .max(50, "Last name must be less than 50 characters.")
    .required("Please enter last name."),
  email: emailYup.notRequired(),
  phone_number: phoneYup,
  organization_name: yup
    .string()
    .max(50, "Organization name must be less than 50 characters.")
    .required("Please enter Organization name."),
  address: yup
    .string()
    .trim()
    .max(50, "Organization address must be less than 50 characters.")
    .notRequired(),
  city: yup
    .string()
    .trim()
    .max(50, "City must be less than 50 characters.")
    .notRequired(),
  state: yup
    .string()
    .trim()
    .max(50, "Name must be less than 50 characters.")
    .notRequired(),
  zip_code: zipCodeYup.notRequired(),
  country_id: yup.object().shape({
    value: yup
      .number()
      .nullable()
      .required("Please select country.")
      .typeError("Select valid data."),
    label: yup.string().trim().notRequired(),
  }),
  theme_color: colorYup("Color Theme is required."),
  font_color: colorYup("Font Color is required."),
});

export const managerProfileSchema = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .max(50, "Your name must be less than 50 characters.")
    .required("Please enter your name."),
  last_name: yup
    .string()
    .trim()
    .max(50, "Last name must be less than 50 characters.")
    .notRequired(),
  email: emailYup.notRequired(),
  phone_number: phoneYup,
});
