import { Fragment, useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import { downArrow } from "../../../../assests/Icons/icon";
import styles from "../AddProperty.module.scss";

export interface optionsType {
  value: string;
  label: string;
}
interface props {
  selectedItems: string[];
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
  showSelectedItems: optionsType[];
  setShowSelectedItems: React.Dispatch<React.SetStateAction<optionsType[]>>;
  options: optionsType[];
  label: string;
  setRemoveItems: React.Dispatch<React.SetStateAction<string[]>>;
  removeItems: string[];
  setExistedItems: React.Dispatch<React.SetStateAction<string[]>>;
  existedItems: string[];
  setCustomSelectedItems?: any;
  customSelectedItems?: any;
}

const ServicesSelectBox = ({
  selectedItems,
  setSelectedItems,
  options,
  setShowSelectedItems,
  showSelectedItems,
  label,
  existedItems,
  removeItems,
  customSelectedItems,
  setCustomSelectedItems,
  setExistedItems,
  setRemoveItems,
}: props) => {
  const [dropdownOptions, setDropdownOptions] = useState<optionsType[]>([]);

  /**
   * @function to update selected array
   * @param item
   */
  const handleAllCheckBox = (item: optionsType) => {
    if (existedItems.includes(item?.value)) {
      handleCheckIsExistRemove(item?.value);
    }

    const updateSelectedItems = [...selectedItems];
    if (selectedItems.includes(item.value)) {
      const res = updateSelectedItems.filter((selectedItem: string) => {
        return item.value !== selectedItem;
      });
      setSelectedItems([...res]);
      const showRes = showSelectedItems.filter((showResItem: optionsType) => {
        return item.value !== showResItem.value;
      });
      setShowSelectedItems([...showRes]);
    } else {
      updateSelectedItems.push(item.value);
      setSelectedItems([...updateSelectedItems]);
      setShowSelectedItems([...showSelectedItems, item]);
    }
  };

  const checkIsInclude = (value: string) => {
    return selectedItems.includes(value);
  };

  const checkIsDisabled = (value: string) => {
    return existedItems.includes(value);
  };
  const handleRemoveValue = (item: string) => {
    if (existedItems?.includes(item)) {
      handleCheckIsExistRemove(item);
    }
    if (customSelectedItems.some((val: any) => val?.name === item)) {
      const res = customSelectedItems?.filter((val: any) => {
        return item !== val.name;
      });
      setCustomSelectedItems([...res]);
    }
    if (
      selectedItems.includes(item) ||
      showSelectedItems.some((showVal) => showVal.value === item)
    ) {
      const res = selectedItems.filter((value) => {
        return item !== value;
      });
      setSelectedItems([...res]);
      const showRes = showSelectedItems.filter((showResItem: optionsType) => {
        return item !== showResItem.value;
      });
      setShowSelectedItems([...showRes]);
    }
  };

  const handleCheckIsExistRemove = (value: string) => {
    if (removeItems?.includes(value)) {
      const res = removeItems?.filter((item) => {
        return item !== value;
      });
      setRemoveItems([...res]);
    } else {
      setRemoveItems([...removeItems, value]);
    }
  };

  useEffect(() => {
    setDropdownOptions([...options]);
  }, [options]);

  return (
    <Form.Group className={`field_wrap`}>
      {label ? <Form.Label>{label}</Form.Label> : null}
      <Dropdown>
        <Dropdown.Toggle variant="light" className={styles.dropdownToggle}>
          <span>Select Service</span>
          {downArrow}
        </Dropdown.Toggle>

        <Dropdown.Menu
          className={styles.dropdownMenu}
          style={{
            maxHeight: 300,
          }}
        >
          {dropdownOptions?.length > 0 ? (
            dropdownOptions?.map((item, index) => {
              const isDisabled = checkIsDisabled(item.value);
              return (
                <Fragment key={item?.value}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: 10,
                      margin: "15px 10px",
                    }}
                  >
                    <Form.Check
                      type="checkbox"
                      disabled={isDisabled}
                      checked={checkIsInclude(item.value)}
                      onChange={(e) => {
                        handleAllCheckBox(item);
                      }}
                    />
                    <span
                      style={{
                        wordBreak: "break-word",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        !isDisabled && handleAllCheckBox(item);
                      }}
                    >
                      {item.label}
                    </span>
                  </div>
                  {index !== dropdownOptions?.length - 1 && (
                    <Dropdown.Divider />
                  )}
                </Fragment>
              );
            })
          ) : (
            <Dropdown.Item>No data found</Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <div className={styles.selectedItems}>
        {showSelectedItems?.map((item: optionsType) => {
          return (
            <p key={item.value}>
              <span
                style={{
                  flexShrink: 1,
                }}
              >
                {item.label}{" "}
              </span>
              <IoMdClose
                style={{
                  fontSize: "0.875rem",
                  cursor: "pointer",
                  flexShrink: 0,
                  textAlign: "end",
                }}
                onClick={() => handleRemoveValue(item.value)}
              />
            </p>
          );
        })}
      </div>
    </Form.Group>
  );
};

export default ServicesSelectBox;
