import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { crossIcon, deleteIcon, edit } from "../../../assests/Icons/icon";
import searchIcon from "../../../assests/svg/searchIcon.svg";
import {
  getFrequency,
  notifyBugsnagError,
  showToast,
} from "../../../common/utils/Functions";
import DashboardLayout from "../../../components/layouts/DashboardLayout";
import AddEditReportingModal from "../../../components/modals/reportingAutomationModal/AddEditReportingAutomation";
import PageHeading from "../../../components/pageHeading";
import ReactTable from "../../../components/reactTable/ReactTable";
import InputField from "../../../components/theme/InputField";
import ThemeButton from "../../../components/theme/ThemeButton";
import useFetch from "../../../hooks/useFetch";
import { reportAutomationType } from "../../../types/reportAutomationType";
import styles from "./reportAutomation.module.scss";
import ConfirmationModal from "../../../components/modals/ConfirmationModal/ConfirmationModal";
import InactiveActiveModal from "../../../components/modals/InactiveActiveModal/InactiveActiveModal";
const ReportAutomation = () => {
  const [reportAutomationData, setReportAutomationData] =
    useState<reportAutomationType[]>();
  /**
   * @State to store searched value
   */
  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState(false); //  It's use to open "Add/Edit" modal
  const [editModal, setEditModal] = useState(false); //  It's use to change edit-form condition
  const [showDelete, setShowDelete] = useState(false); //  It's use to open "Delete" modal
  const [showActive, setShowActive] = useState(false); //  It's use to open "In-active/Active" modal
  const [selectedItem, setSelectedItem] = useState<any>();
  const toggleDelete = () => setShowDelete(!showDelete);
  const toggleActive = () => setShowActive(!showActive);
  /**
   * @Pagination states
   * 1. pageLimit    :- store limit of data that will render on current page
   * 2. totalRecords :- store total number of data in partners api
   * 3. currentPage  :- store current page number
   */
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const toggleAddEdit = () => {
    setShow(!show);
  };
  const tableHeadings = [
    { key: "name", label: "Name" },
    { key: "email", label: "Email" },
    { key: "frequency", label: "Frequency" },
  ];
  const actionButtonOptions = [
    {
      icon: edit,
      name: "Edit",
      onClick: (item: any) => {
        setSelectedItem(item);
        setEditModal(true);
        toggleAddEdit();
      },
    },
    {
      icon: crossIcon,
      name: "Inactive",
      onClick: (item: any) => {
        toggleActive();
        setSelectedItem(item);
      },
    },
    {
      icon: deleteIcon,
      name: "Delete",
      onClick: (item: any) => {
        setSelectedItem(item);
        toggleDelete();
      },
    },
  ];
  /** -------- ------- */
  const [
    listReportingAutomationApi,
    {
      response: listReportingAutomationResponse,
      loading: listReportingAutomationLoading,
      error: listReportingAutomationError,
    },
  ] = useFetch("/reports/list", {
    method: "POST",
  });
  const [
    deleteReportingAutomationApi,
    {
      response: deleteReportingAutomationResponse,
      loading: deleteReportingAutomationLoading,
      error: deleteReportingAutomationError,
    },
  ] = useFetch(`/reports/delete/${selectedItem?.uuid}`, {
    method: "Delete",
  });
  const [
    activeReportingAutomationApi,
    {
      response: activeReportingAutomationResponse,
      loading: activeReportingAutomationLoading,
      error: activeReportingAutomationError,
    },
  ] = useFetch(`/reports/active-inactive/${selectedItem?.uuid}`, {
    method: "put",
  });
  /**
   * @Call all report automation data  using @listReportingAutomationApi constant
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        listReportingAutomationApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          search: searchValue,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "listReportingAutomationApi",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [currentPage, pageLimit, searchValue]);
  useEffect(() => {
    if (listReportingAutomationResponse) {
      setTotalRecords(listReportingAutomationResponse.data?.totalRecords);
      const updatedList = listReportingAutomationResponse.data?.list?.map(
        (item: any) => ({
          name: item?.name,
          email: item?.email,
          uuid: item?.uuid,
          is_active: item?.is_active,
          frequency: getFrequency(item?.frequency),
        })
      );
      setReportAutomationData(updatedList);
    }
    if (listReportingAutomationError) {
      showToast(listReportingAutomationError.message as string, "error");
    }
  }, [listReportingAutomationResponse, listReportingAutomationError]);
  useEffect(() => {
    if (deleteReportingAutomationResponse) {
      showToast(deleteReportingAutomationResponse?.message, "success");
      toggleDelete();
      try {
        listReportingAutomationApi({
          start: 0,
          limit: pageLimit,
          search: "",
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "listReportingAutomationApi",
        });
      }
    }
    if (deleteReportingAutomationError) {
      showToast(deleteReportingAutomationError?.message, "error");
    }
  }, [deleteReportingAutomationError, deleteReportingAutomationResponse]);
  useEffect(() => {
    if (activeReportingAutomationResponse) {
      showToast(activeReportingAutomationResponse.message as string, "success");
      toggleActive();
      const updatedList = reportAutomationData?.map((item: any) =>
        selectedItem?.uuid === item.uuid
          ? { ...item, is_active: item.is_active ? 0 : 1 }
          : item
      );
      setReportAutomationData(updatedList);
    }
    if (activeReportingAutomationError) {
      showToast(activeReportingAutomationError.message as string, "error");
    }
  }, [activeReportingAutomationResponse, activeReportingAutomationError]);
  return (
    <DashboardLayout>
      <div className={styles.dash_page}>
        <Container>
          <PageHeading
            heading="Reporting Automation"
            subHeading={
              <span>
                This is an automated email export of all the information in the
                "Reporting Section" at your desired frequency.
              </span>
            }
          >
            <div className={styles.search}>
              <div className={styles.searchField}>
                {/* <GoSearch className={styles.searchIcon} /> */}
                <InputField
                  name="search_manager_name"
                  icon={searchIcon}
                  placeholder="Search by Name"
                  value={searchValue}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setSearchValue(e.target.value);
                  }}
                  autoComplete="off"
                />
                {searchValue ? (
                  <span
                    className={styles.crossIcon}
                    onClick={() => setSearchValue("")}
                  >
                    <RxCross1 />
                  </span>
                ) : null}
              </div>
              <ThemeButton
                variant="primary"
                onClick={() => {
                  toggleAddEdit();
                  setEditModal(false);
                }}
                style={{
                  minWidth: "fit-content",
                }}
              >
                Create New Alert
              </ThemeButton>
            </div>
          </PageHeading>
          <ReactTable
            headings={tableHeadings}
            data={reportAutomationData || []}
            actionColumn
            statusColumn
            loading={!reportAutomationData || listReportingAutomationLoading}
            actionButtonOptions={actionButtonOptions}
            currentPage={currentPage}
            itemPerPage={pageLimit}
            totalItems={totalRecords}
            setPerPageLimit={setPageLimit}
            setCurrentPage={setCurrentPage}
            pageLimit={pageLimit}
            pagination={true}
          />
          <AddEditReportingModal
            show={show}
            handleClose={toggleAddEdit}
            title={
              editModal ? "Edit Report Automation" : "Add Report Automation"
            }
            editModal={editModal}
            callGetApi={() => {
              listReportingAutomationApi({
                start: 0,
                limit: pageLimit,
                search: "",
              });
            }}
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
          />
          <ConfirmationModal
            handleToggle={toggleDelete}
            title="Are you sure you want to delete
                    this Report?"
            show={showDelete}
            confirm={() => deleteReportingAutomationApi()}
            loading={deleteReportingAutomationLoading}
          />
          <InactiveActiveModal
            loading={activeReportingAutomationLoading}
            handleToggle={toggleActive}
            title={`Are you sure you want to ${
              selectedItem?.is_active ? "Deactivate" : "Activate"
            }
                  this report?`}
            show={showActive}
            heading={selectedItem?.is_active ? "Deactivate" : "Activate"}
            confirm={() =>
              activeReportingAutomationApi({
                is_active: selectedItem?.is_active ? 0 : 1,
              })
            }
          />
        </Container>
      </div>
    </DashboardLayout>
  );
};
export default ReportAutomation;
