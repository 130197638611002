import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import email from "../../../assests/email.svg";
import AuthLayout from "../../../components/layouts/AuthLayout";
import InputField from "../../../components/theme/InputField";
import ThemeButton from "../../../components/theme/ThemeButton";
import useFetch from "../../../hooks/useFetch";
import { forgotPassValSchema } from "../../../validations/AuthSchema";
import EmailSent from "../emailSent";
import styles from "./forgotPassword.module.scss";
import { notifyBugsnagError, showToast } from "../../../common/utils/Functions";

const ForgotPassword = () => {
  const isAdmin = !window.location.href.includes("mydashboard");
  const [sentEmail, setSentEmail] = useState("");
  /** Api definition */
  const [forgotPasswordApi, { response, loading, error }] = useFetch(
    "/auth/forgot-password",
    {
      method: "post",
    }
  );

  /** Handle api response */
  useEffect(() => {
    if (response) {
      showToast(response.message as string, "success");
      setSentEmail(response.data);
    }
    if (error) {
      showToast(error.message as string, "error");
    }
  }, [response, error]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPassValSchema,
    onSubmit: (values) => {
      try {
        forgotPasswordApi({ ...values, role_id: isAdmin ? 1 : 2 });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "forgotPasswordApi",
        });
      }
    },
  });
  return sentEmail ? (
    <EmailSent
      title={"Email Sent"}
      description={
        <p>
          {" "}
          Password reset link has been sent to
          <b> {response?.data} </b>
          Please check your email.
        </p>
      }
      buttonTitle="Go Back to Login"
    />
  ) : (
    <AuthLayout>
      <div className={styles.login}>
        <div className={styles.login_ByGoogle}>
          <div className={styles.formTitle}>
            <h2>Forgot Your Password</h2>
            <p>
              Please enter your email below. We will send you a link to reset
              your password.
            </p>
          </div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className={styles.formFields}
          autoComplete="off"
        >
          <InputField
            formik={formik}
            name="email"
            label="Email"
            placeholder="Enter Email"
            icon={email}
          />

          <div className={styles.goButtons}>
            <Link to="/" className={"linkText primary"}>
              Go Back
            </Link>

            <ThemeButton
              variant="primary"
              loading={loading}
              type="submit"
              onClick={() => formik.handleSubmit()}
            >
              Get Link
            </ThemeButton>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;
