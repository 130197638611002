// import { useEffect, useState } from "react";
// import { Breadcrumb } from "react-bootstrap";
// import { Link, useLocation } from "react-router-dom";
// import styles from "./customBreadCrumb.module.scss";

// const BreadCrumb = () => {
//   const [label, setLabel] = useState<Array<string>>([]);
//   const [path, setPath] = useState<string>("");
//   const location = useLocation();
//   const { pathname } = location;

//   useEffect(() => {
//     const array1 = pathname.split("/");
//     let array2 = array1
//       .map(function (element) {
//         return element.replace(/-/g, " ");
//       })
//       .filter((item) => item.length > 0);

//     setLabel([...array2]);
//   }, [pathname]);

//   useEffect(() => {
//     const pathhh = pathname.split("/");
//     const setpath = pathhh
//       .map(function (element) {
//         return element;
//       })
//       .filter((item) => item.length > 0);
//     setPath(setpath[0]);
//   }, [pathname]);

//   return (
//     <Breadcrumb className={styles.breadcrumb}>
//       <Breadcrumb.Item>
//         <Link
//           to={"/" + path}
//           style={{
//             textDecoration: "none",
//           }}
//         >
//           {label[0]}
//         </Link>
//       </Breadcrumb.Item>

//       {label[1] && (
//         <Breadcrumb.Item
//           style={{
//             cursor: "text",
//           }}
//         >
//           {label[1]}
//         </Breadcrumb.Item>
//       )}
//     </Breadcrumb>
//   );
// };

// export default BreadCrumb;

import { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import styles from "./customBreadCrumb.module.scss";
import { isValidUUID } from "../../common/utils/Functions";

const BreadCrumb = () => {
  const [label, setLabel] = useState<Array<string>>([]);
  const [path, setPath] = useState<string>("");
  const location = useLocation();
  const params = useParams<{ uuid?: string; id?: string }>();

  useEffect(() => {
    const array1 = location.pathname.split("/");
    const filteredArray = array1.filter(
      (item) => item.length > 0 && item !== params.uuid && item !== params.id
    );
    setLabel(filteredArray.map((element) => element.replace(/-/g, " ")));
  }, [location.pathname, params.uuid, params.id]);

  useEffect(() => {
    const pathhh = location.pathname.split("/");
    const setpath = pathhh.filter(
      (item) => item.length > 0 && item !== params.uuid && item !== params.id
    );
    setPath(setpath[0]);
  }, [location.pathname, params.uuid, params.id]);
  const isAdminAsOrg =
    window.location.href.includes("mydashboard") && isValidUUID(params.uuid);
  return (
    <Breadcrumb className={styles.breadcrumb}>
      <Breadcrumb.Item>
        <Link
          to={
            isAdminAsOrg && params?.uuid
              ? "/" + params?.uuid + "/" + path
              : "/" + path
          }
          style={{
            textDecoration: "none",
          }}
        >
          {label[0]}
        </Link>
      </Breadcrumb.Item>

      {label[1] && (
        <Breadcrumb.Item
          style={{
            cursor: "text",
          }}
        >
          {label[1]}
        </Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
};

export default BreadCrumb;
