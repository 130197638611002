import { useState } from "react";
import { Col, Container, Dropdown, Row, Table } from "react-bootstrap";
import { GoSearch } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import { IoEyeOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { copy, eyes } from "../../../../assests/Icons/icon";
import logoNew from "../../../../assests/logoNew.svg";
import {
  DashboardTipItems,
  dataPropertyAccounts,
  organizationPropertyDetailsData,
} from "../../../../common/dummyData";
import DashboardLayout from "../../../../components/layouts/DashboardLayout";
import CommonButton from "../../../../components/theme/button";
import TipAmountModal from "../../../../components/tipAmountModal/tipAmountModal";
import styles from "../property/view-property.module.scss";
import PropertyServices from "./propertyservices";
import {
  TipNumberIcon,
  TransactionIcon,
} from "../../../../assests/Icons/dashboardIcons";
import ThemeButton from "../../../../components/theme/ThemeButton";

const ViewProperty = () => {
  const initialCountData = [
    {
      icon: TipNumberIcon,
      title: "Total Number of Tips",
      amount: 0,
      key: "todayTipTransaction",
    },
    {
      icon: TransactionIcon,
      title: "Total Tip Transaction Amount",
      amount: 0,
      key: "organizations",
    },
  ];
  const [countData] = useState(initialCountData);

  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  // Function to handle closing the add/edit modal
  const handleClose = () => setShow(false);

  return (
    <DashboardLayout>
      <div className={styles.dash_page}>
        <Container>
          <Row className="g-xl-4 g-3">
            <Col xxl={12} xl={12}>
              <div
                className={`${styles.dashboardHeading} ${styles.orgnaizationDetails}`}
              >
                <div className={styles.alltips}>
                  <h2>Property Information</h2>
                  <p>Here is the information about all your properties.</p>
                </div>
                <div className={styles.search}>
                  <CommonButton title="  View QR Code" icon={eyes} />
                  <CommonButton title="  Copy Tip URL" icon={copy} />
                  <Link to="/organizations/tip-transaction-detail">
                    <CommonButton title="  View Tip Transactions" icon={eyes} />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <div className={styles.cardsWrap}>
            {countData.map((item) => (
              <div className={styles.card_outer}>
                <div key={item.key} className={styles.card}>
                  <div className={styles.icon}>{item.icon}</div>
                  <div className={styles.content}>
                    <div className={styles.title}>{item.title}</div>
                    <div className={styles.amount}>${item.amount}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Row className="g-xl-4 g-3">
            <Col xxl={12} xl={12}>
              <div className={styles.dashboardTable}>
                <Row>
                  <Col sm={12} md={3}>
                    <div className={styles.LogoName}>
                      <div className={styles.LogoBg}>
                        <img src={logoNew} alt="logos" />
                      </div>
                    </div>
                  </Col>
                  <Col sm={12} md={9}>
                    <div className={styles.rightSides}>
                      <div className={styles.rightName}>
                        <h6>
                          Property Name <span></span> Active
                        </h6>
                        <h2>Tokeet Property</h2>
                      </div>

                      <div className={styles.cards}>
                        {organizationPropertyDetailsData?.map((item: any) => (
                          <div className={styles.cardsBox_outer}>
                            <div className={styles.cardsBox} key={item.id}>
                              {item.heading !== "Background Color" &&
                                item.heading !== "Font Color" && (
                                  <>
                                    <h5>{item?.heading}</h5>
                                    <h4>{item?.name}</h4>
                                  </>
                                )}

                              {(item.heading === "Background Color" ||
                                item.heading === "Font Color") && (
                                <div className={styles.colorTheme}>
                                  <div className={styles.colorLeft}>
                                    <h5>
                                      {item.heading === "Background Color"
                                        ? "Background Color"
                                        : "Font Color"}
                                    </h5>
                                    <h4>{item?.name}</h4>
                                  </div>
                                  <div
                                    className={`${styles.colorRight} ${
                                      item.heading === "Font Color"
                                        ? styles.fontColorBox
                                        : ""
                                    }`}
                                  >
                                    <span></span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col xxl={12} xl={12}>
              <div
                className={`${styles.dashboardTable} ${styles.tableRemoveShadow}`}
              >
                <div className={styles.dashboardHeadings}>
                  <h3>Property Accounts</h3>
                  <div className={`${styles.search} ${styles.searchQr}`}>
                    <GoSearch />

                    <input
                      type="search"
                      placeholder="Search by Name, Email, Phone"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className={styles.dashboartabless}>
                  <Table hover responsive>
                    <thead>
                      <tr>
                        <th> Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataPropertyAccounts.map((item, index) => (
                        <tr key={index}>
                          <td>{item.Name}</td>
                          <td>{item.Email}</td>
                          <td>{item.PhoneNumber}</td>

                          <td className="text-end">
                            <Dropdown
                              align="end"
                              onClick={(e) => e.stopPropagation()}
                              className={styles.dropDots}
                            >
                              <Dropdown.Toggle
                                variant="light"
                                id="dropdown-basic"
                              >
                                ...
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    navigate(
                                      `/Organizations/view-assigned-properties`
                                    );
                                  }}
                                >
                                  <IoEyeOutline />
                                  View Assigned Properties
                                </Dropdown.Item>

                                <Dropdown.Item
                                //   onClick={() => {
                                //     setShowActive(true);
                                //   }}
                                >
                                  <IoMdClose />
                                  Inactive
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <TipAmountModal
                    show={show}
                    handleClose={handleClose}
                    title="Tip Amount Information"
                    tipItems={DashboardTipItems}
                  >
                    <ThemeButton>Ok</ThemeButton>
                  </TipAmountModal>
                </div>
              </div>
            </Col>
            <div>
              <PropertyServices />
            </div>
          </Row>
        </Container>
      </div>
    </DashboardLayout>
  );
};
export default ViewProperty;
