import { useParams } from "react-router-dom";
import { getCurrentUserData } from "../../common/utils/Functions";
import AdminDashboard from "../../pages/adminDashboard/dashboard/index";
import UserDashboard from "../../pages/userDashboard/index";

const UserDashBoardCommon = () => {
  const { uuid } = useParams();
  const localData = getCurrentUserData(uuid as string);
  /** Set user data in redux */

  if (localData?.role_id === 1) {
    return <AdminDashboard />;
  } else if (
    localData?.role_id === 2 ||
    localData?.role_id === 3 ||
    localData?.data?.role_id === 3
  ) {
    return <UserDashboard />;
  }else{
    return <AdminDashboard/>
  }
};
export default UserDashBoardCommon;
