import { useEffect } from "react";
import { setupError, setupIndication } from "../../assests/Icons/icon";
import { notifyBugsnagError, showToast } from "../../common/utils/Functions";
import ThemeButton from "../theme/ThemeButton";
import styles from "./stripeError.module.scss";
import useFetch from "../../hooks/useFetch";

const SetUpError = () => {
  const [
    paymentApi,
    { response: paymentResponse, loading: paymentLoading, error: paymentError },
  ] = useFetch("organization/stripe-integration");

  useEffect(() => {
    if (paymentResponse) {
      const url = paymentResponse?.data?.url;
      window.open(url, "_blank");
    }
    if (paymentError) {
      showToast(paymentError.message as string, "error");
    }
  }, [paymentResponse, paymentError]);
  return (
    <div className={styles.payment_wrap}>
      <div className={styles.setup_left}>
        <div className={styles.payment_content}>
          <span className={styles.innerContent}>{setupError}</span>
        </div>
        <div className={styles.setup_content}>
          <h4>Update Your Stripe Account Information</h4>
          <p>
            There seems to be some missing information or an error in your
            Stripe account details. Please update your information by clicking
            the <span style={{ fontWeight: "600" }}>Update Information</span>{" "}
            button.
          </p>
        </div>
      </div>
      <div>
        <ThemeButton
          className={styles.btn_color}
          onClick={() => {
            paymentApi();
          }}
        >
          Update Information
        </ThemeButton>
      </div>
    </div>
  );
};

export default SetUpError;
