import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { editPayment, paymentStripe } from "../../../assests/Icons/icon";
import { notifyBugsnagError, showToast } from "../../../common/utils/Functions";
import DashboardLayout from "../../../components/layouts/DashboardLayout";
import PageHeading from "../../../components/pageHeading";
import ReactLoader from "../../../components/reactLoader/ReactLoader";
import ThemeButton from "../../../components/theme/ThemeButton";
import useFetch from "../../../hooks/useFetch";
import styles from "../paymentManagement/payment.module.scss";
import Reject from "./reject";
import Success from "./success";

const PaymentManagement = () => {
  const [stripeStatus, setStripeStatus] = useState<number | null>(null);
  const [
    paymentStatusApi,
    { response: statusResponse, loading: statusLoading, error: statusError },
  ] = useFetch("organization/check-charge-enable");

  const [
    paymentApi,
    { response: paymentResponse, loading: paymentLoading, error: paymentError },
  ] = useFetch("organization/stripe-integration");

  useEffect(() => {
    if (paymentResponse) {
      const url = paymentResponse?.data?.url;
      window.open(url, "_blank");
    }
    if (paymentError) {
      showToast(paymentError.message as string, "error");
    }
  }, [paymentResponse, paymentError]);

  /** useEffect for  list */
  useEffect(() => {
    const timer = setTimeout(() => {
      paymentStatusApi();
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (statusResponse) {
      setStripeStatus(
        statusResponse?.data?.organization_details?.stripe_status
      );
    }
    if (statusError) {
      showToast(statusError.message as string, "error");
    }
  }, [statusResponse, statusError]);
  return (
    <DashboardLayout>
      {statusLoading && !stripeStatus ? (
        <ReactLoader />
      ) : stripeStatus === 0 ? (
        <Container>
          <PageHeading
            heading="Payment Management"
            subHeading="Here you can manage all your payments."
          />
          <div className={styles.payment_wrap}>
            <Row>
              <Col xs={12} xl={4} xxl={2}>
                <div className={styles.payment_content}>
                  <span className={styles.innerContent}>{paymentStripe}</span>
                  <div className={styles.iconDiv}>
                    <span className={styles.addIcon}>{editPayment}</span>
                  </div>
                </div>
              </Col>
              <Col xs={12} xl={8} xxl={10} className={styles.right}>
                <h5 className={styles.head}>Setup Your Stripe Account</h5>
                <p>
                  Click the button to finish setting up your payment
                  information. “Know Your Customer” (KYC) obligations Stripe to
                  collect and maintain information on all Stripe account
                  holders. These requirements come from regulators and are
                  intended to prevent abuse of the financial system. The
                  information also enables us to protect our TopTipper community
                  and users.{" "}
                </p>
                <p>
                  <span className={styles.note}>Please note -</span> Customers
                  will not be able to send tips to your staff until this portion
                  of the sign-up process is completed.
                </p>
                <p>
                  For more information on Stripe please visit{" "}
                  <Link to="https://stripe.com/in" target="_blank">
                    {" "}
                    www.stripe.com
                  </Link>
                </p>

                <ThemeButton
                  loading={paymentLoading}
                  onClick={() => {
                    paymentApi();
                  }}
                >
                  Setup Stripe Account
                </ThemeButton>
              </Col>
            </Row>
          </div>
        </Container>
      ) : stripeStatus === 1 ? (
        <Success />
      ) : stripeStatus === 2 ? (
        <Success />
      ) : stripeStatus === 3 ? (
        <Reject status={stripeStatus} />
      ) : stripeStatus === 4 ? (
        <Reject status={stripeStatus} />
      ) : stripeStatus === 5 ? (
        <Reject status={stripeStatus} />
      ) : null}
    </DashboardLayout>
  );
};

export default PaymentManagement;
