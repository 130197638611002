/* eslint-disable no-useless-escape */
import * as yup from "yup";

export const loginValSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email("Please enter valid email.")
    .required("Please enter your email address."),
  password: yup.string().required("Please enter your password."),
});

export const resetValSchema = (isSetPassword: boolean) =>
  yup.object().shape({
    newPassword: yup
      .string()
      .trim()
      .required(`Please enter ${isSetPassword ? "" : "new"} password.`)
      .max(20, "Maximum 20 characters.")
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/,
        "Minimum 8 Characters(1 upper & lower case, no. and special character)."
      ),
    confirmPassword: yup
      .string()
      .trim()
      .oneOf(
        [yup.ref("newPassword"), ""],
        `${isSetPassword ? "Password" : "New password"} and confirm ${
          isSetPassword ? "" : "new"
        } password does not match.`
      )
      .required("Please enter confirm password."),
  });

export const forgotPassValSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email("Please enter valid email.")
    .required("Please enter your email address."),
});

export const changePassValSchema = (isSetPassword: boolean) =>
  yup.object().shape({
    new_password: yup
      .string()
      .trim()
      .oneOf(
        [yup.ref("new_password"), ""],
        `${isSetPassword ? "Password" : "New password"} and confirm ${
          isSetPassword ? "" : "new"
        } password do not match.`
      )
      .max(20, "Maximum 20 characters.")
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/,
        "Minimum 8 Characters (1 upper & lower case, number, and special character)."
      )
      .required(`Please enter ${isSetPassword ? "" : "new"} password.`),

    old_password: yup
      .string()
      .trim()
      .required("Please enter the current password."),
  });
