import { useDispatch } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { AppDispatch } from "../../redux/store";
import { handleBreadCrumb } from "../../redux/breadcrumb";
import { getCurrentUserData } from "../../common/utils/Functions";

// * Logic to render nav-links on the basis of role
export const HandleNavItems = ({ item, role }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const { uuid } = useParams();
  const data = getCurrentUserData(uuid as string);
  const handleBreadCrumbValue = (label: string) => {
    dispatch(
      handleBreadCrumb({
        label,
      })
    );
  };

  //* check if nav-link role matches current login role
  if (item?.role === role) {
    return (
      <NavLink
        to={data?.admin_uuid ? "/" + uuid + item?.link : item?.link}
        onClick={() => handleBreadCrumbValue(item?.title)}
      >
        <li>
          {item?.icon}
          {item?.title}
        </li>
      </NavLink>
    );
  } else return <></>;
};
