import { Form } from "react-bootstrap";
import ThemeButton from "../../../theme/ThemeButton";
import React from "react";
import { noDataIcon } from "../../../../assests/Icons/icon";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  buttonTitle: string;
  content: string;
  customClass?: string;
}
const ListPropertyBox = ({
  label,
  buttonTitle,
  content,
  children,
  customClass,
  ...rest
}: IProps) => {
  const isOrg = window.location.href.includes("organizations");

  const hasChildren = React.Children.count(children) > 0;
  return (
    <div>
      <Form.Label htmlFor="exampleColorInput" id={"label"}>
        {label}
      </Form.Label>

      <main
        className={customClass ?? "buttonBox"}
        style={{
          alignItems: hasChildren ? "flex-start" : "center",
          justifyContent: hasChildren ? "space-between" : "center",
          minHeight: hasChildren ? 100 : 250,
          padding: isOrg ? "20px 0px 0px 20px" : "",
        }}
      >
        {hasChildren ? (
          children
        ) : (
          <>
            {isOrg && noDataIcon}
            {isOrg && (
              <h4
                style={{
                  color: "black",
                  marginTop: 8,
                  fontWeight: 600,
                }}
              >
                No {label}
              </h4>
            )}
            <p
              className={"content"}
              style={{
                textAlign: "center",
              }}
            >
              {content}
            </p>
          </>
        )}
        <br />
        {!isOrg && <ThemeButton {...rest}>{buttonTitle}</ThemeButton>}
      </main>
    </div>
  );
};

export default ListPropertyBox;
