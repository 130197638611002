import { FormikProps } from "formik";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import useFetch from "../../../../hooks/useFetch";
import { organizationAddEditType } from "../../../../types/organization";
import InputField from "../../../theme/InputField";
import LoadingBars from "../../../theme/LoadingBars";
import ThemeButton from "../../../theme/ThemeButton";
import dollarIcon from "../../../../assests/svg/dollarIcon.svg";
import styles from "../addEditOrgnaization.module.scss";
import AmountField from "../../../theme/amountField";
import {
  notifyBugsnagError,
  removeInitialSpace,
  showToast,
} from "../../../../common/utils/Functions";

interface Iprops {
  formik: FormikProps<organizationAddEditType>;
  currentStep?: number;
  setCurrentStep: (step: number) => void;
  loading: boolean;
  editModal: boolean;
}

const Step3 = ({
  editModal,
  formik,
  currentStep,
  setCurrentStep,
  loading: mainApiLoading,
}: Iprops) => {
  /** Api definition */
  const [mediaApi, { response, loading, error }] = useFetch(
    "/common/upload-media",
    {
      method: "post",
    },
    "formdata",
    true
  );

  /** Handle api response */
  useEffect(() => {
    if (response) {
      formik.setFieldValue("logo_data", response.data[0]);
    }
    if (error) {
      showToast(error.message as string, "error");

      formik.setFieldValue("logo_data", null);
    }
  }, [response, error]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const formdata = new FormData();
        formdata.append("files", file);
        formdata.append("type", "2");
        mediaApi(formdata);
      } catch (e: any) {
        notifyBugsnagError(e, { api: "mediaApi" });
      }
    }
  };
  useEffect(() => {
    formik.setFieldTouched("top_tipper_fees", true, false);
  }, [currentStep]);
  return (
    <Row className="g-xl-4 g-3">
      <Col xxl={6} xl={6}>
        <InputField
          formik={formik}
          name="theme_color"
          label="Color Theme (HEX)"
          placeholder="Color Theme"
          onChange={(e) =>
            formik.setFieldValue(
              "theme_color",
              removeInitialSpace(e.target.value)
            )
          }
          type="color"
        />
        {/* {!formik.errors.theme_color && (
            <div
              className="input_child"
              style={{
                width: "24px",
                height: "24px",
                borderRadius: "4px",
                border: "0.5px solid #a9b0bf",
                backgroundColor: formik.values.theme_color,
              }}
            />
          )} */}
        {/* </InputField> */}
      </Col>

      <Col xxl={6} xl={6}>
        <InputField
          formik={formik}
          name="font_color"
          label="Font Color (HEX)"
          placeholder="Font Color"
          type="color"
          onChange={(e) =>
            formik.setFieldValue(
              "font_color",
              removeInitialSpace(e.target.value)
            )
          }
        >
          {/* {!formik.errors.font_color && (
            <div
              className="input_child"
              style={{
                width: "24px",
                height: "24px",
                borderRadius: "4px",
                border: "0.5px solid #a9b0bf",
                backgroundColor: formik.values.font_color,
              }}
            />
          )} */}
        </InputField>
      </Col>

      <Col xxl={6} xl={6}>
        <AmountField
          formik={formik}
          name="top_tipper_fees"
          label="TopTipper Fee"
          placeholder="Enter Fee"
          type="number"
          onChange={(e) =>
            formik.setFieldValue("top_tipper_fees", e.target.value)
          }
          icon={dollarIcon}
        />
      </Col>

      <Col xxl={6} xl={6}>
        <div className={styles.addCateg}>
          <label>Logo (Optional)</label>
          <label htmlFor="fileUpload" className={styles.uploadInput}>
            <input
              id="fileUpload"
              type="file"
              name="fileLogo"
              accept="image/png, image/jpeg"
              placeholder="Upload Logo"
              onChange={handleFileChange}
            />
            <h6>
              {" "}
              {formik.values.logo_data?.name ? (
                formik.values.logo_data.name
              ) : (
                <span className={styles.placeColor}>Upload Logo</span>
              )}
            </h6>

            <span
              className={`primary footerModalBtn commonBtn ${styles.btnBrowse}`}
            >
              {loading ? <LoadingBars /> : "Browse "}
            </span>
          </label>
        </div>

        {formik.values.logo_data?.url && (
          <Col xxl={6} xl={6} className="mt-3">
            <div className={`${styles.addCateg} ${styles.addCategBox}`}>
              <div
                className={styles.closeIcon}
                onClick={() => formik.setFieldValue("logo_data", null)}
              >
                <AiOutlineClose />
              </div>
              <img
                src={formik.values.logo_data.url}
                alt="Preview"
                style={{ maxWidth: "100%" }}
              />
            </div>
          </Col>
        )}
      </Col>

      <Col xxl={12} xl={12} className="text-center">
        <div className={styles.butonSteps}>
          <ThemeButton variant="secondary" onClick={() => setCurrentStep(2)}>
            Back
          </ThemeButton>
          <ThemeButton
            loading={mainApiLoading}
            disabled={loading || mainApiLoading}
            onClick={() => {
              formik.setFieldTouched("top_tipper_fees", true, false);
              formik.handleSubmit();
            }}
          >
            {currentStep === 3 ? (editModal ? "Save" : "Add") : "Next"}
          </ThemeButton>
        </div>
      </Col>
    </Row>
  );
};

export default Step3;
