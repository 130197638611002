import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { paymentStripe, rejectCross } from "../../../assests/Icons/icon";
import { notifyBugsnagError, showToast } from "../../../common/utils/Functions";
import PageHeading from "../../../components/pageHeading";
import ThemeButton from "../../../components/theme/ThemeButton";
import useFetch from "../../../hooks/useFetch";
import styles from "../paymentManagement/payment.module.scss";

const Reject = ({ status }: { status?: number | string | null }) => {
  const [
    initiateStripeAction,
    { response: paymentResponse, loading: paymentLoading, error: paymentError },
  ] = useFetch("organization/stripe-integration");

  useEffect(() => {
    if (paymentResponse) {
      const url = paymentResponse?.data?.url;
      if (url) window.open(url, "_blank");
    }
    if (paymentError) {
      showToast(paymentError.message as string, "error");
    }
  }, [paymentResponse, paymentError]);

  const getTitle = () => {
    switch (status) {
      case 3:
        return "Update Your Stripe Account Information";
      case 4:
        return "Stripe Account Under Review";
      case 5:
        return "Complete Your Stripe Account Setup";
      default:
        return "";
    }
  };

  const getDescription = () => {
    switch (status) {
      case 3:
        return (
          <>
            There seems to be some missing information or an error in your
            Stripe account details. Please update your information by clicking
            the <span style={{ fontWeight: 600 }}>Update Information</span>{" "}
            button.
          </>
        );
      case 4:
        return (
          <>
            Your Stripe account is currently under review. Please check back in
            sometime. Thank you for your patience.
          </>
        );
      case 5:
        return (
          <>
            It looks like you started setting up your Stripe account but didn't
            finish. Please complete the process by clicking the{" "}
            <span style={{ fontWeight: 600 }}>Complete Setup</span> button.
          </>
        );
      default:
        return (
          <>
            Click the button to finish setting up your payment information.
            “Know Your Customer” (KYC) obligations require Stripe to collect and
            maintain information on all Stripe account holders. These
            requirements come from regulators and are intended to prevent abuse
            of the financial system. The information also enables us to protect
            our TopTipper community and users.
          </>
        );
    }
  };

  const getButtonText = () => {
    switch (status) {
      case 3:
      case 4:
        return "Update Information";
      case 5:
        return "Complete Setup";
      default:
        return "Setup Stripe Account";
    }
  };

  return (
    <Container>
      <PageHeading
        heading="Payment Management"
        subHeading="Here you can manage all your payments."
      />
      <div className={styles.payment_wrap}>
        <Row>
          <Col xs={12} xl={4} xxl={2}>
            <div className={styles.payment_content}>
              <span className={styles.innerContent}>{paymentStripe}</span>
              <div className={styles.rejectDiv}>
                <span className={styles.rejectIcon}>{rejectCross}</span>
              </div>
            </div>
          </Col>
          <Col xs={12} xxl={10} xl={8} className={styles.right}>
            <h5 className={styles.head}>{getTitle()}</h5>
            <p>{getDescription()}</p>
            <ThemeButton
              loading={paymentLoading}
              onClick={() => {
                initiateStripeAction();
              }}
            >
              {getButtonText()}
            </ThemeButton>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Reject;
