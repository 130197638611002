import React from "react";
import styles from "./noDataFound.module.scss";

interface IProps {
  viewHeight?: string;
}

const NoDataFound = ({ viewHeight }: IProps) => {
  return (
    <div style={{ height: viewHeight }} className={styles.mainWrap}>
      <h3>No Data Found</h3>
      {/* <p>Please refresh the data and try again.</p> */}
    </div>
  );
};

export default NoDataFound;
