import { useFormik } from "formik";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import changePassword from "../../../assests/changePassword.svg";
import DashboardLayout from "../../../components/layouts/DashboardLayout";
import PageHeading from "../../../components/pageHeading";
import InputField from "../../../components/theme/InputField";
import ThemeButton from "../../../components/theme/ThemeButton";
import useFetch from "../../../hooks/useFetch";
import { changePassValSchema } from "../../../validations/AuthSchema";
import styles from "./changePassword.module.scss";
import { useNavigate } from "react-router-dom";
import { notifyBugsnagError, showToast } from "../../../common/utils/Functions";

const ChangePassword = () => {
  const navigate = useNavigate();
  /**  ---- @changePasswordApi ------ */
  const [
    changePasswordApi,
    {
      response: addChangeResponse,
      loading: addChangeLoading,
      error: addChangeError,
    },
  ] = useFetch("/common/change-password", {
    method: "POST",
  });

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
    },
    validationSchema: changePassValSchema(false),
    onSubmit: (values) => {
      try {
        changePasswordApi({ ...values });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "changePasswordApi",
        });
      }
    },
  });

  /** Handle error and response coming from api */
  useEffect(() => {
    if (addChangeResponse) {
      showToast(addChangeResponse.message as string, "success");
      formik.resetForm();
      navigate("/dashboard");
    }
    if (addChangeError) {
      showToast(addChangeError.message as string, "error");
    }
  }, [addChangeError, addChangeResponse]);
  return (
    <DashboardLayout>
      <div className={styles.dash_page}>
        <Container>
          <Row className="g-xl-4 g-3">
            <Col xxl={12} xl={12}>
              <PageHeading
                heading="Change Password"
                subHeading="Here you can change your password below."
              ></PageHeading>
            </Col>
          </Row>

          <div className={styles.mainPassword}>
            <Row>
              <Col xxl={5} xl={6} lg={6} className="mb-5">
                <div className={styles.changePassword}>
                  <img src={changePassword} alt="Change Password" />
                </div>
              </Col>
              <Col xxl={7} xl={6} lg={6} className={`mb-5 ${styles.chng}`}>
                <div className={styles.passwordText}>
                  <h4>Change Your Password</h4>
                  <div className={styles.formFields}>
                    <div className={styles.formchange}>
                      <InputField
                        formik={formik}
                        name="old_password"
                        label="Current Password"
                        placeholder="Enter Current Password"
                        type="password"
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className={styles.formchange}>
                      <InputField
                        formik={formik}
                        name="new_password"
                        label="New Password"
                        placeholder="Enter New Password"
                        type="password"
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>

                    <div className={`mt-2 pt-1 ${styles.btnWrap}`}>
                      <ThemeButton
                        loading={addChangeLoading}
                        disabled={addChangeLoading}
                        onClick={() => formik.handleSubmit()}
                      >
                        Change
                      </ThemeButton>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </DashboardLayout>
  );
};
export default ChangePassword;
