import Modal from "react-bootstrap/Modal";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import styles from "./rangeDateModal.module.scss";

import { addDays } from "date-fns";
import moment from "moment";
import { useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import ThemeButton from "../theme/ThemeButton";

interface Iprops {
  handleClose: () => void;
  show: boolean;
  setEndDate?: React.Dispatch<React.SetStateAction<any>>;
  setStartDate?: React.Dispatch<React.SetStateAction<any>>;
}

const RangeDateModal = ({
  handleClose,
  show,
  setStartDate,
  setEndDate,
}: Iprops) => {
  const [state, setState] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleApplyDates = () => {
    if (setStartDate) {
      setStartDate(moment(state[0].startDate).format("MM/DD/YYYY"));
    }
    if (setEndDate) {
      setEndDate(moment(state[0].endDate).format("MM/DD/YYYY"));
    }
    handleClose();
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className={`modalranges ${styles.modalranges}`}
      >
        <Modal.Header className={styles.heading}>
          <Modal.Title>
            {moment(state[0]?.startDate).format("MM/DD/YYYY")}
            <FaArrowRightLong />
            {moment(state[0]?.endDate).format("MM/DD/YYYY")}
          </Modal.Title>
          <div className="">
            <ThemeButton
              variant="secondary"
              className="primary  commonBtn me-2"
              onClick={handleClose}
            >
              Cancel
            </ThemeButton>
            <ThemeButton onClick={handleApplyDates}>Apply</ThemeButton>
          </div>
        </Modal.Header>
        <Modal.Body>
          <DateRangePicker
            onChange={(item: any) => {
              if (item?.selection?.startDate && item?.selection?.endDate) {
                setState([item?.selection]);
                // Other actions you want to perform
              }
            }}
            // showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="horizontal"
            renderStaticRangeLabel={() => null}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RangeDateModal;
