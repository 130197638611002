import * as yup from "yup";
import { colorYup, emailYup, phoneYup, zipCodeYup } from "./CommonConstants";

export const propertySchema1 = (step: number) => {
  return yup.object().shape(
    step === 1
      ? {
          name: yup
            .string()
            .trim()
            .max(50, "Property name must be less than 50 characters.")
            .required("Please enter property name."),
          email: emailYup,
          country_id: yup.object().shape({
            value: yup
              .number()
              .nullable()
              .required("Please select country.")
              .typeError("Select valid data."),
            label: yup.string().trim().notRequired(),
          }),
          phone_number: phoneYup,
          address: yup
            .string()
            .trim()
            .max(50, "Street address must be less than 50 characters.")
            .required("Please enter street  address."),
          city: yup
            .string()
            .trim()
            .max(50, "City must be less than 50 characters.")
            .required("Please enter city."),
          state: yup
            .string()
            .trim()
            .max(50, "State must be less than 50 characters.")
            .required("Please enter state name."),
          zip_code: zipCodeYup,
          media_id: yup.number().nullable().notRequired(),
        }
      : {}
  );
};
export const propertySchemaStep1 = (nameStatus?: number | null) => {
  return yup.object().shape({
    name: yup
      .string()
      .trim()
      .max(50, "Property name must be less than 50 characters.")
      .test("is-exist", "Property already exist.", () =>
        !(nameStatus === 0) ? true : false
      )
      .required("Please enter property name."),
    country_id: yup.object().shape({
      value: yup
        .number()
        .nullable()
        .required("Please select country.")
        .typeError("Select valid data."),
      label: yup.string().trim().notRequired(),
    }),
    phone_number: phoneYup,
    address: yup
      .string()
      .trim()
      .max(50, "Street address must be less than 50 characters.")
      .notRequired(),
    city: yup
      .string()
      .trim()
      .max(50, "City must be less than 50 characters.")
      .notRequired(),
    state: yup
      .string()
      .trim()
      .max(50, "State must be less than 50 characters.")
      .notRequired(),
    zip_code: zipCodeYup.notRequired(),
    media_id: yup.number().nullable().notRequired(),
  });
};

export const propertySchemaStep2 = () => {
  return yup.object().shape(
    {
      manager_phone_number: phoneYup,
      existing_manager_id: yup.array().of(
        yup.object().shape({
          value: yup
            .number()
            .nullable()
            .typeError("Select valid data.")
            .notRequired(),
          label: yup.string().trim().notRequired(),
        })
      ),
      manager_name: yup.string().when("manager_email", {
        is: (value: string) => {
          return !!value;
        },
        then: (schema) =>
          schema
            .required("Manager name is required")
            .trim()
            .max(50, "Manager name must be less than 50 characters."),
        otherwise: (schema) =>
          schema
            .trim()
            .max(50, "Manager name must be less than 50 characters.")
            .notRequired(),
      }),
      manager_email: yup.string().when("manager_name", {
        is: (name: string) => {
          return !!name;
        },
        then: (schema) => emailYup,
        otherwise: (schema) => emailYup.notRequired(),
      }),
    },
    [["manager_name", "manager_email"]]
  );
};

// export const propertySchema = (step: number) => {
//   if (step === 1) {
//     return yup.object().shape({
//       name: yup
//         .string()
//         .trim()
//         .max(50, "Property name must be less than 50 characters.")
//         .required("Please enter property name."),
//       email: emailYup,
//       country_id: yup.object().shape({
//         value: yup
//           .number()
//           .nullable()
//           .required("Please select country.")
//           .typeError("Select valid data."),
//         label: yup.string().trim().notRequired(),
//       }),
//       phone_number: phoneYup,
//       address: yup
//         .string()
//         .trim()
//         .max(50, "Street address must be less than 50 characters.")
//         .required("Please enter street  address."),
//       city: yup
//         .string()
//         .trim()
//         .max(50, "City must be less than 50 characters.")
//         .required("Please enter city."),
//       state: yup
//         .string()
//         .trim()
//         .max(50, "State must be less than 50 characters.")
//         .required("Please enter state name."),
//       zip_code: zipCodeYup,
//       media_id: yup.number().nullable().notRequired(),
//     });
//   } else if (step === 2) {
//     return yup.object().shape({
//       manager_phone_number: phoneYup,
//       existing_manager_id: yup.object().shape({
//         value: yup.number().nullable().typeError("Select valid data."),
//         label: yup.string().trim().notRequired(),
//       }),
//       manager_name: yup
//         .string()
//         .when("manager_email", (manager_email) =>
//           manager_email
//             ? yup
//                 .string()
//                 .trim()
//                 .required("This field is required")
//                 .max(50, "Manager name must be less than 50 characters.")
//             : yup
//                 .string()
//                 .trim()
//                 .notRequired()
//                 .max(50, "Manager name must be less than 50 characters.")
//         ),
//       manager_email: yup
//         .string()
//         .trim()
//         .notRequired()
//         .email("Invalid email.")
//         .matches(
//           /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
//           "Please enter a valid email."
//         ),
//     });
//   }
// };

export const propertyEditSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .max(50, "Property name must be less than 50 characters.")
    .required("Please enter property name."),
  country_id: yup.object().shape({
    value: yup
      .number()
      .nullable()
      .required("Please select country.")
      .typeError("Select valid data."),
    label: yup.string().trim().notRequired(),
  }),
  phone_number: phoneYup,
  address: yup
    .string()
    .trim()
    .max(50, "Street address must be less than 50 characters.")
    .notRequired(),
  city: yup
    .string()
    .trim()
    .max(50, "City must be less than 50 characters.")
    .notRequired(),
  state: yup
    .string()
    .trim()
    .max(50, "State must be less than 50 characters.")
    .notRequired(),
  zip_code: zipCodeYup.notRequired(),
  media_id: yup.number().nullable().notRequired(),
  trip_advisor_url: yup.string().trim().nullable(),

  google_site_url: yup.string().trim().nullable(),
  yelp_url: yup.string().trim().nullable(),
  facebook_url: yup.string().trim().nullable(),

  theme_color: colorYup("Color Theme is required.")
    .matches(/^[^\s]+$/, "Color Theme cannot contain spaces.")
    .nullable()
    .notRequired(),
  font_color: colorYup("Font Color is required.")
    .matches(/^[^\s]+$/, "Color Theme cannot contain spaces.")
    .nullable()
    .notRequired(),
});

export const propertyEmployeeSchema = yup.object().shape({
  // add_property_service_uuids: yup.array().of(yup.string()).notRequired(),
  name: yup
    .string()
    .trim()
    .max(50, "Employee name must be less than 50 characters.")
    .required("Please enter employee name."),
  email: emailYup.notRequired(),
  phone_number: phoneYup,
  add_property_service_uuids: yup
    .object()
    .shape({
      value: yup
        .string()
        .nullable()
        .typeError("Select valid data.")
        .notRequired(),
      label: yup.string().trim().notRequired(),
    })
    .nullable()
    .notRequired(),
});

export const propertyRoomSchema = yup.object().shape({
  rooms_name: yup
    .array()
    .of(yup.string())
    .required("Please enter number of rooms.")
    .typeError("Please enter number of rooms."),
});
export const propertyServiceSchema = yup.object().shape({
  // add_property_service_uuids: yup.array().of(yup.string()).notRequired(),
  add_service_uuids: yup
    .array()
    .of(yup.string())
    .required("This field is required."),
});

export const customServiceSchema = (
  nameStatus?: number | null,
  urlStatus?: number | null
) => {
  return yup.object().shape({
    name: yup
      .string()
      .trim()
      .max(50, "Service name must be less than 50 characters.")
      .test("is-name", "Service name exist.", () =>
        !(nameStatus === 0) ? true : false
      )
      .required("Please enter service name."),
    url_code: yup
      .string()
      .trim()
      .max(50, "URL abbreviation must be less than 50 characters.")
      .test("is-exist", "URL abbreviation exist.", () =>
        !(urlStatus === 0) ? true : false
      )
      .required("Please enter service url abbreviation."),
  });
};
