import React, { ReactElement } from "react";
import { Modal } from "react-bootstrap";
import styles from "../../styles/layouts/ModalLayout.module.scss";
interface IProps {
  children: React.ReactNode;
  handleToggle: () => void;
  title?: string | ReactElement;
  show: boolean;
  size?: number;
  dropDowns?: boolean;
}
const ModalLayout = ({
  children,
  handleToggle,
  title,
  dropDowns,
  show,
  size = 340,
}: IProps) => {
  return (
    <Modal
      show={show}
      onHide={handleToggle}
      backdrop="static"
      keyboard={false}
      // centered
      className={styles.modal_wrapper}
    >
      <div
        className={
          dropDowns
            ? `${styles.addStepTwo} ${styles.modalLayout}`
            : styles.modalLayout
        }
        // className={styles.modalLayout}
        style={{ width: `${size}px` }}
      >
        <Modal.Header closeButton closeVariant="black">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${dropDowns && styles.addDropOrgnaization}`}>
          {children}
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ModalLayout;
