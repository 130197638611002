import { useEffect, useState } from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { IoEyeOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import {
  copyIconNew,
  deleteIcon,
  editIcon,
} from "../../../../assests/Icons/icon";
import {
  copyToClipBoard,
  getCurrentUserData,
  notifyBugsnagError,
  openQrCode,
  showToast,
} from "../../../../common/utils/Functions";
import useFetch from "../../../../hooks/useFetch";
import { propertyRoomListType } from "../../../../types/manageProperty";
import { actionButtonOptionsType } from "../../../../types/reactTable";
import ConfirmationModal from "../../../modals/ConfirmationModal/ConfirmationModal";
import styles2 from "../../../reactTable/ReactTable.module.scss";
import styles from "../AddProperty.module.scss";
import EditRoomModal from "./EditRoomModal";

interface props {
  roomList: propertyRoomListType[];
  roomsApi: any;
  property_uuid?: string;
}
const RoomList = ({ roomList, roomsApi, property_uuid }: props) => {
  const [uuid2, setUuid2] = useState("");
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openEDit, setOpenEDit] = useState<boolean>(false);
  const [roomData, setRoomData] = useState<propertyRoomListType>();
  const { uuid } = useParams();
  const data = getCurrentUserData(`${uuid}`);
  const [
    PropertyRoomDeleteApi,
    {
      response: PropertyRoomDelRes,
      error: PropertyRoomDelError,
      loading: PropertyRoomDelLoading,
    },
  ] = useFetch(`propertyrooms/delete/${uuid2}`, {
    method: "delete",
  });

  /**
   * @function :-  to handle delete Property Modal on/open
   */
  const handleDelete = () => setOpenDelete(false);
  /**
   * @Array :- for action button dropdown values and passes to React table component
   */
  const actionButtonOptions =
    data?.role_id === 2 || data?.role_id === 3
      ? [
          {
            name: " View Qr Code",
            icon: <IoEyeOutline />,
            onClick: (item: propertyRoomListType) => {
              openQrCode(item.qr_code);
            },
          },
          {
            name: " Copy Tip URL",
            icon: copyIconNew,
            onClick: (item: propertyRoomListType) => {
              copyToClipBoard(item.copy_link);
            },
          },
          {
            name: "Edit",
            icon: editIcon,
            onClick: (item: propertyRoomListType) => {
              setOpenEDit(true);
              setRoomData(item);
            },
          },

          {
            name: "Delete",
            icon: deleteIcon,
            onClick: (item: propertyRoomListType) => {
              setUuid2(item?.uuid);
              setOpenDelete(true);
            },
          },
        ]
      : [
          {
            name: " View Qr Code",
            icon: <IoEyeOutline />,
            onClick: (item: propertyRoomListType) => {
              openQrCode(item.qr_code);
            },
          },
          {
            name: " Copy Tip URL",
            icon: copyIconNew,
            onClick: (item: propertyRoomListType) => {
              copyToClipBoard(item.copy_link);
            },
          },

          {
            name: "Delete",
            icon: deleteIcon,
            onClick: (item: propertyRoomListType) => {
              setUuid2(item?.uuid);
              setOpenDelete(true);
            },
          },
        ];

  useEffect(() => {
    if (PropertyRoomDelRes) {
      showToast(PropertyRoomDelRes.message as string, "success");
      try {
        roomsApi({
          property_uuid,
          start: 0,
          limit: -1,
          search: "",
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "roomsApi",
        });
      }
      handleDelete();
    }
    if (PropertyRoomDelError) {
      showToast(PropertyRoomDelError.message as string, "error");
    }
  }, [PropertyRoomDelError, PropertyRoomDelRes]);

  useEffect(() => {
    roomsApi({
      property_uuid,
      start: 0,
      limit: -1,
      search: "",
    });
  }, [openEDit]);

  return (
    <Container className={styles.roomList}>
      {roomList?.map((item: any, index: number) => {
        return (
          <Row className={styles.room} key={item?.uuid}>
            <Col
              key={index}
              xs={11}
              style={{
                wordBreak: "break-word",
              }}
            >
              {item.name}
            </Col>
            <Col
              xs={1}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Dropdown
                align="end"
                // onClick={(e) => e.stopPropagation()}
                className={styles2.dropDots}
              >
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  ...
                </Dropdown.Toggle>

                <Dropdown.Menu
                  flip
                  style={{
                    maxWidth: "max-content",
                    minWidth: "150px",
                    zIndex: 999,
                  }}
                >
                  {actionButtonOptions.map(
                    (item1: actionButtonOptionsType, index: number) => {
                      return (
                        <Dropdown.Item
                          onClick={() => item1.onClick(item)}
                          key={index}
                        >
                          {item1?.icon}
                          {item1.name}
                        </Dropdown.Item>
                      );
                    }
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        );
      })}
      <ConfirmationModal
        handleToggle={handleDelete}
        title="Are you sure you want to delete
              this room?"
        show={openDelete}
        loading={PropertyRoomDelLoading}
        confirm={() => PropertyRoomDeleteApi()}
      />

      <EditRoomModal
        handleClose={() => setOpenEDit(false)}
        show={openEDit}
        room={roomData}
      />
    </Container>
  );
};

export default RoomList;
