import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { countries } from "../../../../common/globalVariables";
import {
  notifyBugsnagError,
  removeInitialSpace,
  showToast,
} from "../../../../common/utils/Functions";
import useFetch from "../../../../hooks/useFetch";
import { propertyEditSchema } from "../../../../validations/propertySchema";
import DashboardLayout from "../../../layouts/DashboardLayout";
import PageHeading from "../../../pageHeading";
import ReactLoader from "../../../reactLoader/ReactLoader";
import InputField from "../../../theme/InputField";
import LoadingBars from "../../../theme/LoadingBars";
import PhoneIntputField from "../../../theme/PhoneIntputField";
import ReactSelectField from "../../../theme/ReactSelectField";
import ThemeButton from "../../../theme/ThemeButton";
import EmployeeModal from "../employeeModal/EmployeeModal";
import ListEmployess from "../employeeModal/ListEmployess";
import { editPropertyFormValues } from "../initialValues";
import ListPropertyBox from "../listPropertyBox/ListPropertyBox";
import SelectPropertyService from "../propertyServiceModal/AddPropertyServiceModal";
import ServiceList from "../propertyServiceModal/ServiceList";
import RoomList from "../roomEmployee/RoomList";
import RoomModal from "../roomEmployee/RoomModal";
import styles from "./EditProperty.module.scss";

const EditProperty = () => {
  /** get uuid */
  const { uuid, id } = useParams();
  const navigate = useNavigate();

  const [openServiceSelecModal, setOpenServiceSelecModal] =
    useState<boolean>(false);
  const [openEmployeeModal, setOpenEmployeeModal] = useState<boolean>(false);
  const [employeeValues, setEmployeeValues] = useState<any>({});
  const [isEditEmployee, setIsEditEmployee] = useState(false);
  const [allState, setState] = useState<any>([]);

  const [openRoomModal, setOpenRoomModal] = useState<boolean>(false);

  /** state to image preview data */
  const [imagePreview, setImagePreview] = useState({
    url: "",
    name: "",
  });

  /** Api definition to get media response */
  const [mediaApi, { response, loading, error }] = useFetch(
    "/common/upload-media",
    {
      method: "post",
    },
    "formdata",
    true
  );

  /**
   * initialise property-employees list api
   */
  const [ServiceListApi, { response: serviceListRes, error: serviceListErr }] =
    useFetch("propertyservices/list", {
      method: "post",
    });

  /**
   * initialise property-employees list api
   */
  const [
    PropertyEmployeeListApi,
    { response: PropertyEmployeeListRes, error: PropertyEmployeeListErr },
  ] = useFetch("propertyemployees/list", {
    method: "post",
  });

  /**
   * initialise property-room list api
   */
  const [
    PropertyRoomListApi,
    { response: listRoomRes, error: listRoomError, loading: listRoomLoading },
  ] = useFetch("propertyrooms/list", {
    method: "post",
  });

  /** ------- 1. @listApi ------ */
  const [
    propertyViewApi,
    { response: listResponse, error: listError, loading: PropertyViewLoading },
  ] = useFetch(`/property/view/${id}`, {
    method: "get",
  });

  /** ------2. @editAPi */

  const [
    editPropertyApi,
    { response: editResponse, loading: editLoading, error: editError },
  ] = useFetch(`/property/update/${id}`, {
    method: "put",
  });

  /** Api for fetching state */
  const [
    stateApi,
    { response: stateresponse, loading: stateLoading, error: stateError },
  ] = useFetch(`/auth/get-states`, {
    method: "get",
  });

  //* initialise useformik hook
  const formik = useFormik({
    initialValues: editPropertyFormValues,
    validationSchema: propertyEditSchema,
    onSubmit: (values: any) => {
      try {
        editPropertyApi({ ...values, country_id: values?.country_id?.value });
      } catch (e: any) {
        notifyBugsnagError(editError, { api: "editPropertyApi" });
      }
    },
  });

  /**
   * @param e :- React change event to handle image preview logic
   */
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const formdata = new FormData();
        formdata.append("files", file);
        formdata.append("type", "3");
        mediaApi(formdata);
      } catch (e: any) {
        notifyBugsnagError(e, { api: "mediaApi" });
      }
    }
  };

  /** Call @ServiceListApi api */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        ServiceListApi({
          property_uuid: id,
          start: 0,
          limit: -1,
          search: "",
        });
      } catch (e: any) {
        notifyBugsnagError(e, { api: "ServiceListApi" });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  /** Call @PropertyEmployeeListApi api */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        PropertyEmployeeListApi({
          property_uuid: id,
          start: 0,
          limit: -1,
        });
      } catch (e: any) {
        notifyBugsnagError(e, { api: "PropertyEmployeeListApi" });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  /** Call @PropertyRoomListApi api */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        PropertyRoomListApi({
          property_uuid: id,
          start: 0,
          limit: -1,
          search: "",
        });
      } catch (e: any) {
        notifyBugsnagError(e, { api: "PropertyRoomListApi" });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [openRoomModal]);

  /** Handle api response */
  useEffect(() => {
    if (response) {
      formik.setFieldValue("media_id", response.data[0]?.id);
      setImagePreview(response.data[0]);
    }
    if (error) {
      showToast(error.message as string, "error");

      formik.setFieldValue("media_id", null);
    }
  }, [response, error]);

  /** handle view property api */
  useEffect(() => {
    try {
      propertyViewApi();
    } catch (e: any) {
      notifyBugsnagError(listError, { api: "propertyViewApi" });
    }
  }, []);

  useEffect(() => {
    if (listResponse) {
      const array = [
        "name",
        "email",
        "phone_number",
        "address",
        "state",
        "city",
        "zip_code",
        "trip_advisor_url",
        "google_site_url",
        "yelp_url",
        "facebook_url",
        "theme_color",
        "font_color",
      ];
      array.forEach((item) =>
        formik.setFieldValue(item, listResponse?.data[item])
      );
      formik.setFieldValue(
        "theme_color",
        listResponse?.data?.theme_color
          ? listResponse?.data?.theme_color
          : listResponse?.data?.property_organization_details?.theme_color
      );
      formik.setFieldValue(
        "font_color",
        listResponse?.data?.font_color
          ? listResponse?.data?.font_color
          : listResponse?.data?.property_organization_details?.font_color
      );
      formik.setFieldValue("country_id", {
        label: listResponse?.data?.property_country_details?.name,
        value: listResponse?.data?.property_country_details?.id,
      });
      // formik.setFieldValue("state", {
      //   label: listResponse?.data?.property_country_details?.name,
      //   value: listResponse?.data?.property_country_details?.id,
      // });
      formik.setFieldValue(
        "media_id",
        listResponse?.data?.property_media_details?.id
      );
      setImagePreview({
        name: listResponse?.data?.property_media_details?.name
          ? listResponse?.data?.property_media_details?.name
          : listResponse?.data?.property_organization_details
            ?.organization_media_details?.name,
        url: listResponse?.data?.property_media_details?.url
          ? listResponse?.data?.property_media_details?.url
          : listResponse?.data?.property_organization_details
            ?.organization_media_details?.url,
      });
    }
    if (listError) {
      showToast(listError.message as string, "error");
    }
  }, [listResponse, listError]);

  useEffect(() => {
    if (editResponse) {
      showToast(editResponse.message as string, "success");
      navigate(uuid ? "/" + uuid + "/manage-properties" : "/manage-properties");
    }
    if (editError) {
      showToast(editError.message as string, "error");
    }
  }, [editResponse, editError]);

  useEffect(() => {
    stateApi();
  }, []);
  useEffect(() => {
    const allNewstate: any = [];
    if (stateresponse) {
      stateresponse?.data?.map((item: any) => {
        allNewstate.push({ value: item?.code, label: item.name, id: item.id });
      });
      setState([
        { value: "", label: "Select State", id: null },
        ...allNewstate,
      ]);
    }
  }, [stateresponse]);
  return (
    <DashboardLayout>
      {PropertyViewLoading ? (
        <ReactLoader />
      ) : (
        <Container className={styles.container}>
          <Row>
            <Col xxl={12} xl={12}>
              <PageHeading
                heading="Edit Property"
                subHeading="Here you can edit your property details."
              />
            </Col>
          </Row>
          <Row className="pb-5">
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <Container>
                <Row className="">
                  <Col
                    sm={12}
                    style={{
                      display: "flex",
                      justifyContent: " end",
                      alignItems: "center",
                    }}
                  >
                    <ThemeButton type="submit" loading={editLoading}>
                      Save Changes
                    </ThemeButton>
                  </Col>
                </Row>
                <Row className="lg-my-3 g-3">
                  <Col md={6} lg={4}>
                    <InputField
                      formik={formik}
                      name="name"
                      label="Property Name"
                      placeholder="Enter Property Name"
                    />
                  </Col>
                  {/* <Col md={6} lg={4}>
                    <InputField
                      formik={formik}
                      name="email"
                      label="Property Email"
                      placeholder="Enter Email"
                    />
                  </Col> */}
                  <Col md={6} lg={4}>
                    <PhoneIntputField
                      label="Property Phone Number (Optional)"
                      name="phone_number"
                      formik={formik}
                      phoneCodeId={(phoneId: string) =>
                        formik.setFieldValue("phone_code_id", phoneId)
                      }
                    />
                  </Col>
                  <Col md={6} lg={4}>
                    <ReactSelectField
                      name="country_id"
                      options={countries}
                      label="Country"
                      placeholder="Select Country"
                      className="form_select"
                      formik={formik}
                      formikErrorMesg={formik.errors?.country_id}
                    />
                  </Col>
                </Row>
                <Row className="mt-1 lg-my-3 g-3">
                  <Col md={6} lg={4}>
                    <InputField
                      formik={formik}
                      name="address"
                      label="Street Address (Optional)"
                      placeholder="Enter Street Address"
                    />
                  </Col>
                  <Col md={6} lg={4}>
                    <ReactSelectField
                      name="state"
                      className="form_select"
                      options={allState}
                      placeholder="Enter State Name"
                      label="State (Optional)"
                      formik={formik}
                    />
                  </Col>
                  <Col md={6} lg={4}>
                    <InputField
                      formik={formik}
                      name="city"
                      label="City (Optional)"
                      placeholder="Enter City"
                    />
                  </Col>
                </Row>
                <Row className="mt-1 lg-my-3 g-3">
                  {" "}
                  <Col md={6} lg={4}>
                    <InputField
                      formik={formik}
                      name="zip_code"
                      label="Zip Code (Optional)"
                      placeholder="Enter Zip Code"
                    />
                  </Col>
                  <Col md={6} lg={4}>
                    <InputField
                      formik={formik}
                      name="trip_advisor_url"
                      label="Trip Advisor URL (Optional)"
                      placeholder="Enter URL"
                    />
                  </Col>{" "}
                  {/* <Row className="mt-1 lg-my-3 g-3"> */}
                  <Col md={6} lg={4}>
                    <InputField
                      formik={formik}
                      name="google_site_url"
                      label="Google Site URL (Optional)"
                      placeholder="Enter URL"
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Col>
                  <Col md={6} lg={4}>
                    <InputField
                      formik={formik}
                      name="yelp_url"
                      label="Yelp URL (Optional)"
                      placeholder="Enter URL"
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Col>
                  <Col md={6} lg={4}>
                    <InputField
                      formik={formik}
                      name="facebook_url"
                      label="Facebook URL (Optional)"
                      placeholder="Enter URL"
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Col>
                  {/* </Row> */}
                  <Col
                    md={6}
                    lg={4}
                    style={{
                      position: "relative",
                    }}
                  >
                    <div className={styles.addCateg}>
                      <label>Property Logo (Optional)</label>
                      <label
                        htmlFor="fileUpload"
                        className={styles.uploadInput}
                      >
                        <input
                          id="fileUpload"
                          type="file"
                          accept="image/png, image/jpeg"
                          name="propertyLogo"
                          placeholder="Upload property Logo"
                          onChange={handleFileChange}
                        />
                        {formik.touched.media_id && formik.errors.media_id && (
                          <div className={styles.error}>
                            {formik.errors.media_id as string}
                          </div>
                        )}
                        <h6>
                          {" "}
                          {imagePreview?.name === "" ? (
                            <span className={styles.placeColor}>
                              Upload Logo
                            </span>
                          ) : (
                            imagePreview?.name
                          )}{" "}
                        </h6>
                        <span
                          className={`primary footerModalBtn ${styles.btnBrowse}`}
                        >
                          {loading ? <LoadingBars /> : "Browse "}
                        </span>
                      </label>
                    </div>

                    {imagePreview?.url && (
                      <Col xxl={6} xl={6} className="mt-3 position-absolute">
                        <div
                          className={`${styles.addCateg} ${styles.addCategBox}`}
                        >
                          <div
                            className={styles.closeIcon}
                            onClick={() => {
                              setImagePreview({
                                name: "",
                                url: "",
                              });
                              formik.setFieldValue("media_id", null);
                            }}
                          >
                            <AiOutlineClose />
                          </div>
                          <img
                            src={imagePreview?.url}
                            alt="Preview"
                            style={{ maxWidth: "100%", padding: "4px" }}
                          />
                        </div>
                      </Col>
                    )}
                  </Col>
                </Row>

                <Row className="mt-1 lg-my-3 g-3">
                  <h1 className={styles.inputHeading}>
                    Color theme for your QR code cards (Optional){" "}
                  </h1>
                </Row>
                <Row className="mt-1 lg-my-3 g-3">
                  <Col lg={4}>
                    <InputField
                      formik={formik}
                      name="theme_color"
                      label="Background Color (HEX)"
                      placeholder="Color Theme"
                      onChange={(e) =>
                        formik.setFieldValue(
                          "theme_color",
                          removeInitialSpace(e.target.value)
                        )
                      }
                      type="color"
                    />
                  </Col>
                  <Col lg={4}>
                    <InputField
                      formik={formik}
                      name="font_color"
                      label="Font Color (HEX)"
                      placeholder="Font Color"
                      type="color"
                      onChange={(e) =>
                        formik.setFieldValue(
                          "font_color",
                          removeInitialSpace(e.target.value)
                        )
                      }
                    />
                  </Col>
                  {/* <Col
                    lg={4}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "end",
                    }}
                  >
                    <ThemeButton>Design Card in Canva</ThemeButton>
                  </Col> */}
                </Row>

                <Row className="mt-1 lg-my-3 g-3">
                  <Col lg={6}>
                    <ListPropertyBox
                      label="Services (Optional)"
                      buttonTitle={
                        serviceListRes?.data?.list?.length > 0
                          ? "Add New Services"
                          : "Add Services"
                      }
                      content="You have not added any service yet. To add
                    services click on below button."
                      onClick={() => setOpenServiceSelecModal(true)}
                    >
                      {serviceListRes?.data?.list?.length > 0 ? (
                        <ServiceList
                          serviceList={serviceListRes?.data?.list}
                          property_uuid={id}
                          serviceApi={ServiceListApi}
                        />
                      ) : null}
                    </ListPropertyBox>
                  </Col>
                  <Col
                    lg={6}
                    style={{
                      position: "relative",
                    }}
                  >
                    <ListPropertyBox
                      customClass="employeeCustom"
                      label="Employees (Optional)"
                      buttonTitle={
                        PropertyEmployeeListRes?.data?.list?.length > 0
                          ? "Add New Employees"
                          : "Add Employees"
                      }
                      content="You have not added any employee yet. To add
                    employees click on below button."
                      onClick={() => {
                        setIsEditEmployee(false);
                        setOpenEmployeeModal(true);
                      }}
                    >
                      {PropertyEmployeeListRes?.data?.list?.length > 0 ? (
                        <ListEmployess
                          setEmployeeValues={setEmployeeValues}
                          setIsEditEmployee={setIsEditEmployee}
                          propertyEmployeeListRes={
                            PropertyEmployeeListRes?.data?.list
                          }
                          propertyEmployeeListErr={PropertyEmployeeListErr}
                          PropertyEmployeeListApi={PropertyEmployeeListApi}
                          property_uuid={id}
                          isModal={false}
                          openEditModal={() => {
                            setOpenEmployeeModal(true);
                          }}
                        />
                      ) : null}
                    </ListPropertyBox>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col lg={6}>
                    <ListPropertyBox
                      label="Rooms (Optional)"
                      buttonTitle={
                        listRoomRes?.data?.list?.length > 0
                          ? "Add New Rooms"
                          : "Add Rooms"
                      }
                      content="You have not added any room yet. To add rooms click on below button."
                      onClick={() => setOpenRoomModal(true)}
                    >
                      {listRoomRes?.data?.list?.length > 0 ? (
                        <RoomList
                          roomList={listRoomRes?.data?.list}
                          property_uuid={id}
                          roomsApi={PropertyRoomListApi}
                        />
                      ) : null}
                    </ListPropertyBox>
                  </Col>
                </Row>

                <SelectPropertyService
                  orgId={listResponse?.data?.property_organization_details?.id}
                  serviceList={serviceListRes?.data?.list}
                  property_uuid={id}
                  handleClose={() => setOpenServiceSelecModal(false)}
                  show={openServiceSelecModal}
                  title="Select Services"
                  serviceApi={ServiceListApi}
                />
                <EmployeeModal
                  serviceOption={serviceListRes?.data?.list}
                  PropertyEmployeeListApi={PropertyEmployeeListApi}
                  isEditEmployee={isEditEmployee}
                  employeeValues={employeeValues}
                  property_uuid={id ?? ""}
                  handleClose={() => {
                    setEmployeeValues(null);
                    setOpenEmployeeModal(false);
                  }}
                  show={openEmployeeModal}
                  title={isEditEmployee ? "Edit Employee" : "Add Employees"}
                />
                <RoomModal
                  roomsCount={listRoomRes?.data?.list?.length}
                  property_uuid={id ?? ""}
                  handleClose={() => setOpenRoomModal(false)}
                  show={openRoomModal}
                  title="Add Rooms"
                />
              </Container>
            </form>
          </Row>
        </Container>
      )}
    </DashboardLayout>
  );
};

export default EditProperty;
