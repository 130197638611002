import { AxiosRequestConfig, AxiosResponse } from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  getCurrentUserData,
  getLocalUserData,
  isValidEmail,
  isValidUrl,
  notifyBugsnagError,
  showToast,
} from "../../../common/utils/Functions";
import useFetch from "../../../hooks/useFetch";
import { AddEditModalProps } from "../../../types/modal/addEditModal";
import {
  propertySchemaStep1,
  propertySchemaStep2,
} from "../../../validations/propertySchema";
import FormSteps from "../../formSteps/FormSteps";
import ModalLayout from "../../layouts/ModalLayout";
import ThemeButton from "../../theme/ThemeButton";
import styles from "./AddProperty.module.scss";
import { initialValues } from "./initialValues";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import { useParams } from "react-router-dom";

const AddEditPropertyModal = ({
  handleClose,
  show,
  title,
  editModal,
  currentStep,
  propertyListApi,
  setCurrentStep,
  setCurrentPage,
  allState,
}: AddEditModalProps & {
  propertyListApi: (
    data?: any,
    rest?: AxiosRequestConfig<any> | undefined
  ) => Promise<AxiosResponse<any, any>>;
}) => {
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [validationSchema, setValidationSchema] = useState<any>();
  const [mediaLoader, setMediaLoader] = useState(false);
  const [existingManagerId, setExistingManagerId] = useState<Array<number>>([]);
  const [imagePreview, setImagePreview] = useState({
    url: "",
    name: "",
  });
  const [finishPropertyLoader, setFinishPropertyLoader] = useState(false);
  const { uuid } = useParams();

  const userData = getCurrentUserData(`${uuid}`);

  const orgID = userData?.organization_details?.id;

  /** Api definition */
  const [
    validateNameApi,
    { response: NameResponse, loading: NameLoading, error: NameError },
  ] = useFetch("/common/validate-name", {
    method: "post",
  });

  /** Api definition */
  const [
    validateEmailApi,
    { response: emailResponse, loading: emailLoading, error: emailError },
  ] = useFetch("/common/validate-email", {
    method: "post",
  });

  /** -------. @addPropertyApi ------ */
  const [
    addPropertyApi,
    { response: addResponse, loading: addLoading, error: addError },
  ] = useFetch("/property/add", {
    method: "post",
  });

  /**
   * @function  :-  To handle current step value
   * 1. If current step value is greater than 1 than it will back one step
   * 2. If current step value 1 it will behave like cancel button
   */
  const handleBack = () => {
    if (setCurrentStep && currentStep && currentStep > 1) {
      setCurrentStep((currentStep: number) => currentStep - 1);
    } else if (currentStep && currentStep === 1) {
      handleModal();
    }
  };

  /**
   * handler to handle modal close/open and reset form
   */
  const handleModal = () => {
    handleClose();
    setFinishPropertyLoader(false);
    formik.resetForm();
    setCurrentStep && setCurrentStep(1);
    setImagePreview({
      name: "",
      url: "",
    });
  };
  const handleNameApi = () => {
    setDisableButton(true);
    const validation = propertySchemaStep1(1);
    setValidationSchema(validation);

    if (isValidUrl(formik.values?.name)) {
      validateNameApi({
        type: 3,
        name: formik.values?.name,
        organization_id: orgID,
      });
    }

    if (currentStep === 2) {
      setFinishPropertyLoader(true);
    }
    setTimeout(() => {
      formik.handleSubmit();
      setDisableButton(false);
    }, 1000);
  };

  useEffect(() => {
    const validation = propertySchemaStep1(1);
    setValidationSchema(validation);
  }, []);

  //* initialise useformik hook
  const formik = useFormik({
    initialValues,
    validationSchema:
      currentStep === 2 ? propertySchemaStep2 : validationSchema,
    onSubmit: async (values: any) => {
      if (currentStep === 2) {
        if (isValidEmail(values.manager_email)) {
          await validateEmailApi({
            email: formik.values.manager_email,
          })
            .then(async (emailResponse) => {
              if (emailResponse?.status === 1) {
                await addPropertyApi({
                  ...values,
                  country_id: values.country_id.value,
                  existing_manager_id:
                    existingManagerId?.length > 0 ? existingManagerId : [],
                });
                handleModal();
              }
            })
            .catch((err) => {
              formik.setFieldError("manager_email", err?.message);
              formik.setFieldTouched("manager_email", true);
            });
        } else {
          if (!values?.manager_email) {
            values.manager_email = null;
          } else if (!values?.manager_name) {
            values.manager_name = null;
          }
          try {
            await addPropertyApi({
              ...values,
              country_id: values.country_id.value,
              existing_manager_id:
                existingManagerId?.length > 0 ? existingManagerId : [],
            });
          } catch (e: any) {
            notifyBugsnagError(e, { api: "addPropertyApi" });
          }
        }
      }
    },
  });

  useEffect(() => {
    if (NameResponse) {
      formik.setFieldError("name", "");
      formik.setFieldTouched("name", false, false);
      const validation = propertySchemaStep1(1);
      setValidationSchema(validation);
      setTimeout(() => {
        if (Object.keys(formik.errors).length === 0) {
          !!setCurrentStep && setCurrentStep(2);
        }
      }, 1000);
    }
    if (NameError) {
      formik.setFieldError("name", "Property already exist.");
      propertySchemaStep1(NameError?.status);
      const validation = propertySchemaStep1(0);
      setValidationSchema(validation);
    }
  }, [NameError, NameResponse]);

  useEffect(() => {
    if (emailError && !editModal) {
      formik.setFieldError("manager_email", " Email already exist.");
      formik.setFieldTouched("manager_email", true, false);
    } else if (emailResponse && !editModal) {
      formik.setFieldTouched("manager_email", false, false);
    }
  }, [emailError, emailResponse]);

  /**
   * array to define steps values
   */
  const steps = ["Property", "Manager (Optional)"];

  /**
   * @handle_AddApi_Response of form-submition api call using @addSPropertyApi constant
   * => in this effect
   * 1. @if_statement  that it responded ok then show toast success message
   * 2. @toggleAddEdit this handler use to close Add/Edit Modal
   * 3. @if_statement  to handle error if api not responded
   */
  useEffect(() => {
    if (addResponse) {
      showToast(addResponse.message as string, "success");
      try {
        propertyListApi({
          start: 0,
          limit: 10,
          search: "",
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "propertyListApi",
        });
      }

      setCurrentPage && setCurrentPage(1);
      handleModal();
    }

    if (addError) {
      showToast(addError.message as string, "error");

      setFinishPropertyLoader(false);
    }
  }, [addResponse, addError]);

  useEffect(() => {
    if (formik?.values["existing_manager_id"]) {
      const arr: Array<number> = [];
      const formikValues: any = formik?.values["existing_manager_id"];
      formikValues?.forEach((item: { value: number }) => {
        arr.push(item?.value);
      });
      setExistingManagerId([...arr]);
    }
  }, [formik?.values["existing_manager_id"]]);

  useEffect(() => {
    if (JSON.stringify(formik.errors) !== "{}") {
      if (!!formik.errors.manager_email || !!formik.errors?.manager_name) {
        setFinishPropertyLoader(false);
      }
    }
  }, [formik?.errors]);

  return (
    <ModalLayout
      title={title}
      show={show}
      // children={undefined}
      size={700}
      handleToggle={handleModal}
    >
      <FormSteps currentStep={currentStep as number} steps={steps} />
      <form autoComplete="off">
        <div className="">
          {currentStep === 1 && (
            <Step1
              NameLoading={NameLoading}
              setImagePreview={setImagePreview}
              imagePreview={imagePreview}
              allState={allState}
              formik={formik}
              setMediaLoader={setMediaLoader}
            />
          )}
          {currentStep === 2 && (
            <Step2 formik={formik} emailLoading={emailLoading} />
          )}
        </div>
        <Row className="mt-3">
          <Col xxl={12}>
            <div className={styles.butonSteps}>
              <Button
                variant="secondary"
                className="secondary  commonBtn"
                // type="submit"
                onClick={handleBack}
              >
                {(currentStep as number) > 1 ? "Back" : "Cancel"}
              </Button>

              <ThemeButton
                // variant="primary"
                className="primary footerModalBtn commonBtn "
                onClick={handleNameApi}
                loading={finishPropertyLoader}
                disabled={
                  disableButton ||
                  mediaLoader ||
                  NameLoading ||
                  finishPropertyLoader
                }
              >
                {(currentStep as number) > 1 ? "Finish" : "Next"}
              </ThemeButton>
            </div>
          </Col>
        </Row>
      </form>
    </ModalLayout>
  );
};

export default AddEditPropertyModal;
