import React from "react";

interface IProps {
  children: React.ReactNode;
}
const PublicRoute = ({ children }: IProps) => {
  return <>{children}</>;
};

export default PublicRoute;
