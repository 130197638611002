import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import email from "../../../assests/email.svg";
import password from "../../../assests/password.svg";
import {
  notifyBugsnagError,
  parseJwt,
  setLocalUserData,
  showToast,
} from "../../../common/utils/Functions";
import AuthLayout from "../../../components/layouts/AuthLayout";
import InputField from "../../../components/theme/InputField";
import ThemeButton from "../../../components/theme/ThemeButton";
import useFetch from "../../../hooks/useFetch";
import { setUserData } from "../../../redux/common";
import { loginValSchema } from "../../../validations/AuthSchema";
import styles from "./login.module.scss";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAdmin = !window.location.href.includes("mydashboard");

  /** Api definition */
  const [loginApi, { response, loading, error }] = useFetch("/auth/login", {
    method: "post",
  });

  /** Handle api response */
  useEffect(() => {
    if (response) {
      /**Client want not to show this message */
      // showToast(response.message as string, "success");
      const data = parseJwt(response.data.token);
      setLocalUserData(response.data.token);
      dispatch(setUserData(data || null));
      navigate("/dashboard");
    }
    if (error) {
      showToast(error.message as string, "error");
    }
  }, [response, error]);

  /** Formik definition */
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginValSchema,
    onSubmit: (values) => {
      try {
        loginApi({
          ...values,
          role_id: isAdmin ? 1 : 2,
          // role_id: 2,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "loginApi",
        });
      }
    },
  });

  return (
    <AuthLayout>
      <div className={styles.login}>
        <div className={styles.login_ByGoogle}>
          <div className={styles.formTitle}>
            <h2>Login Your Account</h2>
          </div>
        </div>
        <form
          className={styles.formFields}
          onSubmit={formik.handleSubmit}
          autoComplete="off"
        >
          <InputField
            formik={formik}
            name="email"
            label="Email"
            placeholder="Enter Email"
            icon={email}
          />
          <InputField
            formik={formik}
            name="password"
            label="Password"
            placeholder="Enter Password"
            icon={password}
            type="password"
            onKeyDown={(e) => {
              if (e.key === " ") {
                e.preventDefault();
              }
            }}
          />

          <Link className={`linkText`} to="/forgot-password">
            Forgot Password?
          </Link>
          <div className={styles.btnWrap}>
            <ThemeButton
              loading={loading}
              disabled={loading}
              type="submit"
              onClick={() => formik.handleSubmit()}
            >
              Login
            </ThemeButton>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default Login;
