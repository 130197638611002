import { useEffect, useState } from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { IoEyeOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import {
  checkIcon,
  copyIcon,
  copyIconNew,
  crossIcon,
  deleteIcon,
  editIcon,
} from "../../../../assests/Icons/icon";
import {
  copyToClipBoard,
  getCurrentUserData,
  notifyBugsnagError,
  openQrCode,
  showToast,
} from "../../../../common/utils/Functions";
import useFetch from "../../../../hooks/useFetch";
import { propertyServiceListType } from "../../../../types/manageProperty";
import { actionButtonOptionsType } from "../../../../types/reactTable";
import ConfirmationModal from "../../../modals/ConfirmationModal/ConfirmationModal";
import styles2 from "../../../reactTable/ReactTable.module.scss";
import styles from "../AddProperty.module.scss";
import EditPropertyService from "./EditPropertyService";
import { IoMdClose } from "react-icons/io";
import InactiveActiveModal from "../../../modals/InactiveActiveModal/InactiveActiveModal";

interface props {
  serviceList: propertyServiceListType[] | undefined;
  serviceApi: any;
  property_uuid?: string;
  setPropertyServiceList?: any;
}
const ServiceList = ({
  serviceList,
  serviceApi,
  property_uuid,
  setPropertyServiceList,
}: props) => {
  const [uuid2, setUuid2] = useState("");
  const { uuid } = useParams();
  const data = getCurrentUserData(`${uuid}`);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openEDit, setOpenEDit] = useState<boolean>(false);
  const [serviceData, setServiceData] =
    useState<propertyServiceListType | null>();
  const [
    serviceDeleteApi,
    {
      response: serviceDelRes,
      error: serviceDelError,
      loading: serviceDelLoading,
    },
  ] = useFetch(`propertyservices/delete-property-service/${uuid2}`, {
    method: "delete",
  });

  /**
   * @function to handle active-inactive propertyservices api
   */
  const [
    statusApi,
    { response: statusResponse, loading: statusLoading, error: statusError },
  ] = useFetch(`/propertyservices/active-inactive/${serviceData?.uuid}`, {
    method: "post",
  });

  const handleDelete = () => setOpenDelete(false);

  /** Active inactive modal*/
  const [showActive, setShowActive] = useState(false);
  const toggleActive = () => setShowActive(!showActive);

  // Function to handle closing the active confirmation modal
  const handleCloseActive = () => setShowActive(false);

  /**
   * @Array :- for action button dropdown values and passes to React table component
   */
  const actionButtonOptions =
    data?.role_id === 2 || data?.role_id === 3
      ? [
          {
            name: " View Qr Code",
            icon: <IoEyeOutline />,
            onClick: (item: propertyServiceListType) => {
              openQrCode(item?.qr_code);
            },
          },
          {
            name: " Copy Tip URL",
            icon: copyIconNew,
            onClick: (item: propertyServiceListType) => {
              copyToClipBoard(item?.copy_link);
            },
          },
          {
            name: "Edit",
            icon: editIcon,
            onClick: (item: propertyServiceListType) => {
              setOpenEDit(true);
              setServiceData(item);
            },
          },
          {
            name: "Inactive",
            icon: <IoMdClose />,
            onClick: (item: propertyServiceListType) => {
              toggleActive();
              setServiceData(item);
            },
          },
          {
            name: "Delete",
            icon: deleteIcon,
            onClick: (item: propertyServiceListType) => {
              setUuid2(item?.uuid);
              setOpenDelete(true);
            },
          },
        ]
      : [
          {
            name: " View Qr Code",
            icon: <IoEyeOutline />,
            onClick: (item: propertyServiceListType) => {
              openQrCode(item?.qr_code);
            },
          },
          {
            name: " Copy Tip URL",
            icon: copyIconNew,
            onClick: (item: propertyServiceListType) => {
              copyToClipBoard(item?.copy_link);
            },
          },
          {
            name: "Inactive",
            icon: <IoMdClose />,
            onClick: (item: propertyServiceListType) => {
              toggleActive();
              setServiceData(item);
            },
          },
          {
            name: "Delete",
            icon: deleteIcon,
            onClick: (item: propertyServiceListType) => {
              setUuid2(item?.uuid);
              setOpenDelete(true);
            },
          },
        ];

  useEffect(() => {
    if (serviceDelRes) {
      showToast(serviceDelRes.message as string, "success");
      try {
        serviceApi({
          property_uuid,
          start: 0,
          limit: -1,
          search: "",
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "serviceApi",
        });
      }
      handleDelete();
    }
    if (serviceDelError) {
      showToast(serviceDelError.message as string, "error");
    }
  }, [serviceDelError, serviceDelRes]);

  /**
   *  useEffectto handle the status response active-inactive
   */
  useEffect(() => {
    if (statusResponse) {
      showToast(statusResponse.message as string, "success");
      toggleActive();
      const updatedList = serviceList?.map((item: any) =>
        serviceData?.id === item.id
          ? { ...item, is_active: item.is_active ? 0 : 1 }
          : item
      );
      setPropertyServiceList(updatedList);
    }
    if (statusError) {
      showToast(statusError.message as string, "error");
    }
  }, [statusResponse, statusError]);

  const handleIsCustom = (item: any, isCustom: number, serviceData: any) => {
    if (item?.name === "Edit" && isCustom === 0) {
      return null;
    } else {
      return (
        <Dropdown.Item onClick={() => item.onClick(serviceData)}>
          {item?.name === "Inactive"
            ? serviceData?.is_active === 0
              ? checkIcon
              : crossIcon
            : item?.icon}
          {item?.name === "Inactive"
            ? serviceData?.is_active === 0
              ? "Activate"
              : "Inactivate"
            : item?.name}
        </Dropdown.Item>
      );
    }
  };
  return (
    <Container className={styles.roomList}>
      {serviceList?.map((item: propertyServiceListType, index: number) => {
        return (
          <Row className={styles.room} key={item?.uuid}>
            <Col
              key={index}
              xs={11}
              // className="mb-2"
              style={{
                wordBreak: "break-word",
              }}
            >
              {item.service_details?.name}
            </Col>
            <Col
              xs={1}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Dropdown
                align="end"
                // onClick={(e) => e.stopPropagation()}
                className={styles2.dropDots}
              >
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  ...
                </Dropdown.Toggle>

                <Dropdown.Menu
                  flip
                  style={{
                    maxWidth: "max-content",
                    minWidth: "150px",
                    zIndex: 999,
                  }}
                >
                  {actionButtonOptions.map(
                    (item1: actionButtonOptionsType, index: number) => {
                      return handleIsCustom(
                        item1,
                        item?.service_details?.organization_id ?? 0,
                        item
                      );
                    }
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        );
      })}
      <ConfirmationModal
        handleToggle={handleDelete}
        title="Are you sure you want to delete
          this service?"
        show={openDelete}
        loading={serviceDelLoading}
        confirm={() => serviceDeleteApi()}
      />

      <EditPropertyService
        serviceApi={serviceApi}
        handleClose={() => {
          setOpenEDit(false);
          setServiceData(null);
        }}
        show={openEDit}
        service={serviceData}
        property_uuid={property_uuid}
      />

      <InactiveActiveModal
        loading={statusLoading}
        handleToggle={handleCloseActive}
        title={`Are you sure you want to ${
          serviceData?.is_active ? "deactivate" : "activate"
        }
                    this service ?`}
        show={showActive}
        heading={serviceData?.is_active ? "Deactivate" : "Activate"}
        confirm={() =>
          statusApi({
            is_active: serviceData?.is_active ? 0 : 1,
          })
        }
      />
    </Container>
  );
};

export default ServiceList;
