// custom hook imports
import useFetch from "../../../hooks/useFetch";

// components import
import { RxCross1 } from "react-icons/rx";
import searchIcon from "../../../assests/svg/searchIcon.svg";
import DashboardLayout from "../../../components/layouts/DashboardLayout";
import ConfirmationModal from "../../../components/modals/ConfirmationModal/ConfirmationModal";
import InactiveActiveModal from "../../../components/modals/InactiveActiveModal/InactiveActiveModal";
import AddEditServicseModal from "../../../components/modals/addEditServicseModal/addEditServicseModal";
import PageHeading from "../../../components/pageHeading";
import ReactTable from "../../../components/reactTable/ReactTable";
import ThemeButton from "../../../components/theme/ThemeButton";
// react and react-bootstrap imports
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

// type , validation  and style imports
import { serviceFormType, serviceListType } from "../../../types/service";
import { serviceValidations } from "../../../validations/serviceSchema";
import styles from "./service.module.scss";

// toastify and formik imports
import { useFormik } from "formik";
import { crossIcon, deleteIcon, editIcon } from "../../../assests/Icons/icon";
import { notifyBugsnagError, showToast } from "../../../common/utils/Functions";

interface categoryFilter {
  value: number;
  label: string;
}

const Service = () => {
  const [validationSchema, setValidationSchema] = useState(
    serviceValidations(1)
  );
  /**
   * @State to store searched value
   */
  const [searchValue, setSearchValue] = useState("");
  const [filterByValue, setfilterByValue] = useState<categoryFilter>();

  /**
   * @Action states to open @Active @Delete @Add_Edit Modals
   */
  const [showActive, setShowActive] = useState(false); //  It's use to open "In-active/Active" modal
  const [showDelete, setShowDelete] = useState(false); //  It's use to open "Delete" modal
  const [show, setShow] = useState(false); //  It's use to open "Add/Edit" modal
  const [editModal, setEditModal] = useState(false); //  It's use to change edit-form condition

  /**
   * @functions  to handle above ⬆️  @action states
   * 1. toggleActive   :- Used to handle "In-active/Active" modal
   * 2. toggleDelete   :- Used to handle "Delete" modal
   * 3. toggleAddEdit  :- Used to handle "Add/Edit" modal
   */
  const toggleActive = () => setShowActive(!showActive);
  const toggleDelete = () => setShowDelete(!showDelete);
  const toggleAddEdit = () => setShow(!show);

  /**
   * @Data_list states  these below states are used to store data which will render in React-table
   * 1. serviceList  :- Array of services data list
   * 2. selectedItem :- Object of selceted service data
   */
  const [serviceList, setServiceList] = useState<serviceListType[]>();
  const [selectedItem, setSelectedItem] = useState<serviceListType>();
  // const [serviceCategoryOptions, setServiceCategoryOptions] = useState<
  //   { label: string; value: number }[]
  // >([]);

  /**
   * @Pagination states
   * 1. pageLimit    :- store limit of data that will render on current page
   * 2. totalRecords :- store total number of data in service api
   * 3. currentPage  :- store current page number
   */
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  /**
   * @Api_Definition variables
   * 1. listApi        :- Constant to handle list all service data;
   * 2. addServiceApi  :- Constant to handle Add service
   * 3. editServiceApi :- Constant to handle Edit service
   * 4. statusApi      :- Constant to handle status of service
   * 5. deleteApi      :- Constant to handle delete service
   */

  /** ------- 1. @listApi ------ */
  const [
    listApi,
    { response: listResponse, error: listError, loading: serviceListLoading },
  ] = useFetch("/services/list", {
    method: "post",
  });

  /** -------2. @addServiceApi ------ */
  const [
    addServiceApi,
    {
      response: addServiceResponse,
      loading: addServiceLoading,
      error: addServiceError,
    },
  ] = useFetch("/services/add", {
    method: "post",
  });

  /** -------3. @editServiceApi ------ */
  const [
    editServiceApi,
    {
      response: editServiceResponse,
      loading: editServiceLoading,
      error: editServiceError,
    },
  ] = useFetch(`/services/update/${selectedItem?.uuid}`, {
    method: "put",
  });

  /** -------4. @statusApi ------ */
  const [
    statusApi,
    { response: statusResponse, loading: statusLoading, error: statusError },
  ] = useFetch(`/services/active-inactive/${selectedItem?.uuid}`, {
    method: "post",
  });

  /** -------5. @deleteApi ------ */
  const [
    deleteApi,
    { response: deleteResponse, loading: deleteLoading, error: deleteError },
  ] = useFetch(`/services/delete/${selectedItem?.uuid}`, {
    method: "delete",
  });

  /** Api definition for category select */
  const [
    listCategoryApi,
    { response: listCategoryResponse, error: listCategoryError },
  ] = useFetch("/servicecategories/list", {
    method: "post",
  });
  /**
   * @Call_apis by Use-Effect hook
   */

  /**
   * @Call all service data using @listApi constant
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        listApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          search: searchValue,
          // category_id: filterByValue?.value,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "listApi",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchValue, currentPage, pageLimit, filterByValue?.value]);

  /** Call api */
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     listCategoryApi({
  //       start: 0,
  //       limit: -1,
  //       is_active: null,
  //       search: "",
  //     });
  //   }, 500);
  //   return () => clearTimeout(timer);
  // }, []);

  /**
   * @handle_Response of above api call using @listApi constant
   * => in this effect
   * 1. @setTotalRecords setState updates with total records
   * 2. @setServiceList  setState updates with incoming service-data
   * 3. @if_statement    to handle error if api not responded
   */
  useEffect(() => {
    if (listResponse) {
      setTotalRecords(listResponse.data?.totalRecords);
      const updatedList = listResponse.data.list.map((item: any) => ({
        id: item.id,
        name: item.name,
        // category_id: {
        //   value: item.category_details?.id,
        //   label: item.category_details?.name,
        // },
        // category_name: item.category_details?.name,
        is_active: item.is_active,
        uuid: item.uuid,
        url_code: item.url_code,
        suggested_amount_1: item.suggested_amount_1,
        suggested_amount_2: item.suggested_amount_2,
        suggested_amount_3: item.suggested_amount_3,
      }));
      setServiceList(updatedList);
    }
    if (listError) {
      showToast(listError.message as string, "error");
    }
  }, [listResponse, listError]);

  /**
   * @handle_DeleteApi_Response on delete service api call using @deleteApi constant
   * => in this effect
   * 1. @if_statement   that it responded ok then show toast success message
   * 2. @toggleDelete   this handler use to close Delete Modal
   * 3. @setServiceList update service object that matched with deleted item id
   * 4. @if_statement   to handle error if api not responded
   */
  useEffect(() => {
    if (deleteResponse) {
      showToast(deleteResponse.message as string, "success");
      try {
        listApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          search: searchValue,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "listApi",
        });
      }
      toggleDelete();
    }
    if (deleteError) {
      showToast(deleteError.message as string, "error");
    }
  }, [deleteResponse, deleteError]);

  /**
   * @handle_EditApi_Response on form-submition service api call using @editServiceApi constant
   * => in this effect
   * 1. @if_statement   that it responded ok then show toast success message
   * 2. @toggleAddEdit  this handler use to close Add/Edit Modal
   * 3. @setServiceList update service object that matched with edited item id
   * 4. @if_statement   to handle error if api not responded
   */
  useEffect(() => {
    if (editServiceResponse) {
      showToast(editServiceResponse.message as string, "success");

      toggleAddEdit();
      const updatedList = serviceList?.map((item: serviceListType) =>
        item?.uuid === editServiceResponse?.data?.uuid
          ? {
              name: editServiceResponse.data.name,
              // category_id: {
              //   value: editServiceResponse.data.category_details?.id,
              //   label: editServiceResponse.data.category_details?.name,
              // },
              // category_name: editServiceResponse.data.category_details?.name,
              is_active: editServiceResponse.data?.is_active,
              uuid: editServiceResponse.data?.uuid,
              url_code: editServiceResponse.data?.url_code,
              suggested_amount_1: editServiceResponse.data?.suggested_amount_1,
              suggested_amount_2: editServiceResponse.data?.suggested_amount_2,
              suggested_amount_3: editServiceResponse.data?.suggested_amount_3,
            }
          : item
      );
      setServiceList(updatedList);
    }
    if (editServiceError) {
      showToast(editServiceError.message as string, "error");
    }
  }, [editServiceResponse, editServiceError]);

  /**
   * @handle_AddApi_Response of form-submition api call using @addServiceApi constant
   * => in this effect
   * 1. @if_statement  that it responded ok then show toast success message
   * 2. @toggleAddEdit this handler use to close Add/Edit Modal
   * 3. @if_statement  to handle error if api not responded
   */
  useEffect(() => {
    if (addServiceResponse) {
      showToast(addServiceResponse.message as string, "success");
      try {
        listApi({
          start: 0,
          limit: 10,
          search: searchValue,
        });
      } catch (e: any) {
        notifyBugsnagError(addServiceError, {
          api: "listApi",
        });
      }
      setCurrentPage(1);
      toggleAddEdit();
      formik.resetForm();
    }
    if (addServiceError) {
      showToast(addServiceError.message as string, "error");
    }
  }, [addServiceResponse, addServiceError]);

  /**
   * @handle_StatusApi_Response on change active status of service api call using @statusApi constant
   * => in this effect
   * 1. @if_statement   that it responded ok then show toast success message
   * 2. @toggleActive   this handler use to close Ative/Inactive Modal
   * 3. @setServiceList update service object that matched with changed status item id
   * 4. @if_statement  to handle error if api not responded
   */
  useEffect(() => {
    if (statusResponse) {
      showToast(statusResponse.message as string, "success");
      toggleActive();
      const updatedList = serviceList?.map((item: serviceListType) =>
        selectedItem?.id === item.id
          ? { ...item, is_active: item.is_active ? 0 : 1 }
          : item
      );
      setServiceList(updatedList);
    }
    if (statusError) {
      showToast(statusError.message as string, "error");
    }
  }, [statusResponse, statusError]);

  /** Handle response */
  // useEffect(() => {
  //   if (listCategoryResponse) {
  //     const updatedList = listCategoryResponse.data.list.map((item: any) => ({
  //       value: item.id,
  //       label: item.name,
  //     }));
  //     const categoryList = [{ label: "All", value: null }, ...updatedList];
  //     setServiceCategoryOptions(categoryList);
  //   }
  //   if (listCategoryError) {
  //     toast.error(listCategoryError.message);
  //   }
  // }, [listCategoryResponse, listCategoryError]);

  /**
   * @constant to store formik initial values
   */
  const initialValues = {
    name: "",
    url_code: "",
    // category_id: "",
    suggested_amount_1: null,
    suggested_amount_2: null,
    suggested_amount_3: null,
  };

  /**
   * @constant to initialise useFormik hook
   */
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      // Handle form submission here
      try {
        editModal
          ? editServiceApi({
              ...values,
              // category_id: values.category_id?.value,
              suggested_amount_1:
                values.suggested_amount_1 === ""
                  ? null
                  : values.suggested_amount_1,
              suggested_amount_2:
                values.suggested_amount_2 === ""
                  ? null
                  : values.suggested_amount_2,
              suggested_amount_3:
                values.suggested_amount_3 === ""
                  ? null
                  : values.suggested_amount_3,
            })
          : addServiceApi({
              ...values,
              // category_id: values.category_id?.value,
              suggested_amount_1:
                values.suggested_amount_1 === ""
                  ? null
                  : values.suggested_amount_1,
              suggested_amount_2:
                values.suggested_amount_2 === ""
                  ? null
                  : values.suggested_amount_2,
              suggested_amount_3:
                values.suggested_amount_3 === ""
                  ? null
                  : values.suggested_amount_3,
            });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "editServiceApi || addServiceApi",
        });
      }
    },
  });

  /**
   * @Array :- for table headings that passed as a prop to React-Table component
   */
  const headings = [
    { label: "Service Name", key: "name" },
    // { label: "Service Category", key: "category_name" },
  ];

  /**
   * @Array :- for action button dropdown values and passes to React table component
   */
  const actionButtonOptions = [
    {
      name: "Inactive",
      icon: crossIcon,
      onClick: (item: serviceListType) => {
        toggleActive();
        setSelectedItem(item);
      },
    },
    {
      name: "Edit",
      icon: editIcon,
      onClick: (item: serviceFormType) => {
        formik.resetForm();
        toggleAddEdit();
        setEditModal(true);
        setSelectedItem(item);
        formik.setFieldValue("name", item.name);
        // formik.setFieldValue("category_id", item.category_id);
        formik.setFieldValue("url_code", item.url_code);
        formik.setFieldValue("suggested_amount_1", item.suggested_amount_1);
        formik.setFieldValue("suggested_amount_2", item.suggested_amount_2);
        formik.setFieldValue("suggested_amount_3", item.suggested_amount_3);
      },
    },
    {
      name: "Delete",
      icon: deleteIcon,
      onClick: (item: serviceListType) => {
        toggleDelete();
        setSelectedItem(item);
      },
    },
  ];

  return (
    <DashboardLayout>
      <Container>
        <div className={styles.dash_page}>
          <PageHeading
            heading="Default Services"
            subHeading="Here is information about the default services for each service category"
          >
            <div className={styles.search}>
              <div className={styles.searchField}>
                <img
                  src={searchIcon}
                  className={styles.searchIcon}
                  alt="search icon"
                />
                <input
                  type="text"
                  placeholder="Search by Service Name"
                  className="me-3"
                  value={searchValue}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setSearchValue(e.target.value);
                  }}
                  autoComplete="off"
                />
                {searchValue ? (
                  <span
                    className={styles.crossIcon}
                    onClick={() => setSearchValue("")}
                  >
                    <RxCross1 />
                  </span>
                ) : null}
              </div>

              <ThemeButton
                onClick={() => {
                  toggleAddEdit();
                  setEditModal(false);
                  formik.resetForm();
                }}
              >
                {" "}
                Add New Service
              </ThemeButton>
            </div>
          </PageHeading>

          {/* <div className={styles.tipsWrap}>
            <ReactSelectField
              name="sortBy"
              options={serviceCategoryOptions}
              placeholder="All"
              label="Service Category"
              onChange={(newValue) => {
                if (newValue) {
                  setfilterByValue(newValue as categoryFilter);
                }
              }}
            />
          </div> */}

          <ReactTable
            data={serviceList || []}
            headings={headings}
            actionColumn
            loading={serviceListLoading || !serviceList}
            statusColumn
            actionButtonOptions={actionButtonOptions}
            currentPage={currentPage}
            itemPerPage={pageLimit}
            totalItems={totalRecords}
            setPerPageLimit={setPageLimit}
            pageLimit={pageLimit}
            setCurrentPage={setCurrentPage}
            pagination={true}
          />

          <div className={styles.dashboartabless}>
            <ConfirmationModal
              handleToggle={toggleDelete}
              title="Are you sure you want to delete
                    this service?"
              show={showDelete}
              loading={deleteLoading}
              confirm={() => deleteApi()}
            />

            <InactiveActiveModal
              handleToggle={toggleActive}
              title={`Are you sure you want to ${
                selectedItem?.is_active ? "deactivate" : "activate"
              }
                    this service ?`}
              show={showActive}
              heading={selectedItem?.is_active ? "Deactivate" : "Activate"}
              loading={statusLoading}
              confirm={() =>
                statusApi({
                  is_active: selectedItem?.is_active ? 0 : 1,
                })
              }
            />

            <AddEditServicseModal
              show={show}
              handleClose={toggleAddEdit}
              title={editModal ? "Edit Service " : "Add Service "}
              editModal={editModal}
              formik={formik}
              loading={addServiceLoading || editServiceLoading}
              setValidationSchema={setValidationSchema}
            />
          </div>
        </div>
      </Container>
    </DashboardLayout>
  );
};
export default Service;
