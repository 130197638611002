import moment from "moment";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import {
  AllTipRevenueIcon,
  TipAmountIcon,
  TipNumberIcon,
} from "../../../../assests/Icons/dashboardIcons";
import searchIcon from "../../../../assests/svg/searchIcon.svg";
import {
  notifyBugsnagError,
  showToast,
} from "../../../../common/utils/Functions";
import DashboardLayout from "../../../../components/layouts/DashboardLayout";
import PageHeading from "../../../../components/pageHeading";
import RangeDateModal from "../../../../components/rangeDateModal/rangeDateModal";
import ReactExcel from "../../../../components/reactExcel/ReactExcel";
import ReactTable from "../../../../components/reactTable/ReactTable";
import InputField from "../../../../components/theme/InputField";
import ReactSelectField from "../../../../components/theme/ReactSelectField";
import useFetch from "../../../../hooks/useFetch";
import { OrganizationItem } from "../../../../types/organization";
import styles from "./viewMore.module.scss";
import { eye, tipIcon } from "../../../../assests/Icons/icon";
import { useNavigate, useParams } from "react-router-dom";
import TipAmountModal from "../../../../components/tipAmountModal/tipAmountModal";
import ThemeButton from "../../../../components/theme/ThemeButton";

const ViewMore = () => {
  const { uuid } = useParams();
  const [searchValue, setSearchValue] = useState("");
  const [organizationUuid, setOrganizationUuid] = useState(null);
  const [sortBy, setSortBy] = useState<1 | 2 | 3 | null>(null);
  const [showRange, setShowRange] = useState(false);
  const [orgFilter, setOrgFilter] = useState<any>([]);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dashboardDataList, setDashboardDataList] = useState<any>([]);
  const [excelData, setExcelData] = useState<any>([]);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [showTip, setShowTip] = useState(false);
  const [dashboardTipItems, setDashboardTipItems] = useState<any>([]);
  const handleTipClose = () => setShowTip(false);
  const [selectedFilter, setSelectedFilter] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const navigate = useNavigate();
  const handleCloseRange = () => setShowRange(false);
  const handleShowRange = () => setShowRange(true);

  /** Api definition */
  const [
    getDashBoardData,
    {
      response: dashBoardDataResponse,
      error: dashBoardDataError,
      loading: dashBoardDataLoading,
    },
  ] = useFetch("/dashboard/all-tips", {
    method: "POST",
  });

  /** Api definition */
  const [
    getExcelData,
    {
      response: excelDataResponse,
      error: excelDataError,
      loading: excelDataLoading,
    },
  ] = useFetch("/dashboard/all-tips", {
    method: "POST",
  });
  /** Api definition */
  const [orgDataApi, { response: orgDataResponse, error: orgDataError }] =
    useFetch("/organization/list", {
      method: "post",
    });

  const filters = [
    { value: "all", label: "All" },
    { value: "today", label: "Today" },
    { value: "week", label: "This Week" },
    { value: "month", label: "This Month" },
    { value: "Custom", label: "Custom" },
  ];

  const options = [
    { value: 1, label: "Date" },
    { value: 2, label: "Highest Tip Amount" },
    { value: 3, label: "Lowest Tip Amount" },
  ];

  const headings = [
    {
      key: "org_name",
      label: "Organization Name",
    },
    {
      key: "prop_name",
      label: "Property Name",
    },
    {
      key: "tipper_email",
      label: "Tipper Email",
    },
    {
      key: "net_tip_amount",
      label: "Net Tip Amount",
      textAlign: "right",
    },
    {
      key: "date",
      label: "Date",
      textAlign: "center",
    },
  ];

  const initialCountData = [
    {
      icon: TipNumberIcon,
      title: "Total Number of Tips",
      amount: totalRecords?.toLocaleString("en-US"),
      key: "totalTips",
    },
    {
      icon: TipAmountIcon,
      title: "Total Net Tip Amount",
      amount: dashBoardDataResponse?.data?.net_tip_amount
        ? dashBoardDataResponse?.data?.net_tip_amount.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        : "--",
      key: "totalTipperAmt",
    },
    {
      icon: AllTipRevenueIcon,
      title: "Total TopTipper Revenue",
      amount: dashBoardDataResponse?.data?.top_tipper_revenue
        ? dashBoardDataResponse?.data?.top_tipper_revenue?.toLocaleString(
            "en-US",
            {
              style: "currency",
              currency: "USD",
            }
          )
        : "--",
      key: "totalRevenue",
    },
  ];

  const excelHeaders = [
    { key: "org_name", header: "Organization Name	", width: 40 },
    { key: "prop_name", header: "Property Name", width: 40 },
    { key: "email", header: "Tipper Email", width: 40 },
    { key: "amount", header: "Net Tip Amount", width: 40 },
    { key: "time_of_tip", header: "Time Of Tip", width: 40 },
    { key: "date", header: "Date", width: 40 },
  ];

  const actionButtonOptions = [
    {
      name: "View Organization",
      icon: eye,
      onClick: (item: any) => {
        navigate(
          uuid
            ? "/" + uuid + `/organizations/view/${item?.org_uuid}`
            : `/organizations/view/${item?.org_uuid}`
        );
      },
    },
    {
      name: "View Property",
      icon: eye,
      onClick: (item: any) => {
        if (item?.prop_uuid) {
          navigate(
            uuid
              ? "/" + uuid + `/organizations/view-property/${item?.prop_uuid}`
              : `/organizations/view-property/${item?.prop_uuid}`
          );
        } else {
          showToast("No property to view", "error");
        }
      },
    },
    {
      name: " View Tipper",
      icon: eye,
      onClick: (item: any) => {
        navigate(
          uuid
            ? "/" + uuid + `/tippers/view-tips/${item?.tipper_uuid}`
            : `/tippers/view-tips/${item?.tipper_uuid}`
        );
      },
    },
  ];
  useEffect(() => {
    if (dashBoardDataResponse) {
      setTotalRecords(dashBoardDataResponse?.data?.totalRecords);
      const createDashData = dashBoardDataResponse?.data?.list?.map(
        (item: any) => {
          return {
            org_name: item?.org_name,
            org_uuid: item?.organization_details?.user_details?.uuid,
            prop_uuid: item?.property_details?.uuid,
            prop_name: item?.property_name,
            net_tip_amount: item?.net_tip_amount ? (
              <span
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                {item?.net_tip_amount?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
                <span
                  onClick={() => {
                    setDashboardTipItems([
                      {
                        label: "Total Trans. Amount",
                        value: item?.total_amount
                          ? item?.total_amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          : "--",
                      },
                      {
                        label: "Stripe Fee",
                        value: item?.stripe_fee
                          ? item?.stripe_fee.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          : "--",
                      },
                      {
                        label: "TopTipper Fee",
                        value: item?.top_tipper_fees
                          ? item?.top_tipper_fees.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          : "--",
                      },
                      {
                        label: "Net Tip Amount",
                        value: item?.net_tip_amount
                          ? item?.net_tip_amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                          : "--",
                      },
                    ]);
                    setShowTip(true);
                  }}
                >
                  {" "}
                  {tipIcon}
                </span>
              </span>
            ) : (
              "--"
            ),
            tipper_uuid: item?.tipper_details?.uuid,
            tipper_email: item?.tipper_details?.email,
            date: moment(item?.createdAt).format("MM/DD/YYYY"),
          };
        }
      );
      setDashboardDataList([...createDashData]);
    }
    if (dashBoardDataError) {
      showToast(dashBoardDataError.message, "error");
    }
  }, [dashBoardDataError, dashBoardDataResponse]);

  const handleDateFilter = (value: string) => {
    const today = moment().format("MM/DD/YYYY");
    let startDate = null;
    let endDate = null;
    switch (value) {
      case "week":
        startDate = moment().startOf("week").format("MM/DD/YYYY");
        break;
      case "month":
        startDate = moment().startOf("month").format("MM/DD/YYYY");
        break;
      case "today":
        startDate = today;
        break;
      case "all":
        break;
      default:
        break;
    }
    if (value !== "all" && startDate) {
      endDate = today;
    }
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleExcelApi = () => {
    try {
      getExcelData({
        start: 0,
        limit: -1,
        startDate,
        endDate,
        organization_uuid: organizationUuid,
        sortedBy: sortBy,
        search: searchValue,
      });
    } catch (e: any) {
      notifyBugsnagError(e, {
        api: "getExcelData",
      });
    }
  };

  /**
   * @Call all partner data using @listApi constant
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        getDashBoardData({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          startDate,
          endDate,
          organization_uuid: organizationUuid,
          sortedBy: sortBy,
          search: searchValue,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "getDashBoardData",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [
    searchValue,
    currentPage,
    pageLimit,
    sortBy,
    startDate,
    endDate,
    organizationUuid,
  ]);

  /**
   * @Call all partner data using @listApi constant
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        orgDataApi({
          start: 0,
          limit: -1,
          search: "",
          stripe_status: null,
          is_active: 1,
        });
      } catch (e: any) {
        notifyBugsnagError(excelDataError, {
          api: "orgDataApi",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  /** Handle response */
  useEffect(() => {
    if (excelDataResponse) {
      const updateOrgExcel = excelDataResponse.data.list?.map((item: any) => {
        return {
          org_name: item?.org_name ?? "--",
          prop_name: item?.property_name ?? "--",
          email: item?.tipper_details?.email ?? "--",
          amount:
            item?.net_tip_amount.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }) ?? "--",
          time_of_tip: item?.createdAt
            ? moment
                .utc(item?.createdAt)
                .subtract(4, "hours")
                ?.format("HH:mm A")
            : "--",
          date: moment(item?.createdAt).format("MM/DD/YYYY") ?? "--",
        };
      });
      setExcelData([...updateOrgExcel]);
    }
    if (excelDataError) {
      showToast(excelDataError.message as string, "error");
    }
  }, [excelDataResponse, excelDataError]);

  useEffect(() => {
    if (orgDataResponse) {
      const updateOrg = orgDataResponse?.data?.list?.map(
        (item: OrganizationItem) => {
          return {
            value: item?.uuid,
            label: item?.name,
          };
        }
      );
      setOrgFilter([{ label: "All", value: null }, ...updateOrg]);
    }
  }, [orgDataResponse, orgDataError]);
  return (
    <DashboardLayout>
      <div className={styles.dash_page}>
        <Container>
          <PageHeading
            heading="All Tips"
            subHeading="Here is the information about all tips."
          >
            <div className={styles.search}>
              <div className={styles.searchField}>
                <InputField
                  name="search_organization_name"
                  icon={searchIcon}
                  placeholder="Search by Organization,Prop..."
                  value={searchValue}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setSearchValue(e.target.value);
                  }}
                  autoComplete="off"
                />
                {searchValue ? (
                  <span
                    className={styles.crossIcon}
                    onClick={() => setSearchValue("")}
                  >
                    <RxCross1 />
                  </span>
                ) : null}
              </div>
            </div>
          </PageHeading>
          <div className={styles.tipsWrap}>
            <ReactSelectField
              name="sortBy"
              options={options}
              label="Sort By"
              onChange={(e: any) => {
                setCurrentPage(1);
                setSortBy(e.value);
              }}
            />
            <ReactSelectField
              name="Sort By"
              options={filters}
              label="Filter By"
              onChange={(e: any) => {
                if (e.value === "Custom") {
                  handleShowRange();
                }
                handleDateFilter(e.value);
                setSelectedFilter(e);
              }}
            />
            <ReactSelectField
              name="organizations"
              options={orgFilter}
              label="Organizations"
              onChange={(e: any) => {
                setOrganizationUuid(e.value);
              }}
            />

            <ReactExcel
              setExcelData={setExcelData}
              loading={excelDataLoading}
              onClick={handleExcelApi}
              buttonTitle="Export CSV"
              fileType="xlsx"
              fileName="Tips List"
              coulmns={excelHeaders}
              excelData={excelData}
            />
          </div>

          <div className={styles.cardsWrap}>
            {initialCountData?.map((item) => (
              <div className={styles.card_outer}>
                <div key={item.key} className={styles.card}>
                  <div className={styles.icon}>{item.icon}</div>
                  <div className={styles.content}>
                    <div className={styles.title}>{item.title}</div>
                    <div className={styles.amount}>{item.amount}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <ReactTable
            data={dashboardDataList || []}
            headings={headings}
            actionColumn
            actionButtonOptions={actionButtonOptions}
            statusColumn={false}
            currentPage={currentPage}
            itemPerPage={pageLimit}
            totalItems={totalRecords}
            setPerPageLimit={setPageLimit}
            pageLimit={pageLimit}
            setCurrentPage={setCurrentPage}
            loading={dashBoardDataLoading || !dashboardDataList}
            pagination={true}
          />
          <TipAmountModal
            show={showTip}
            handleClose={handleTipClose}
            title="Tip Amount Information"
            tipItems={dashboardTipItems}
          >
            <ThemeButton onClick={() => handleTipClose()}>Close</ThemeButton>
          </TipAmountModal>
          {selectedFilter?.value === "Custom" && (
            <RangeDateModal
              show={showRange}
              handleClose={handleCloseRange}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
            />
          )}
        </Container>
      </div>
    </DashboardLayout>
  );
};
export default ViewMore;
