import { useFormik } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as yup from "yup";
import {
  isValidEmail,
  notifyBugsnagError,
  showToast,
} from "../../../common/utils/Functions";
import styles from "../../../components/pagesComponents/manageProperty/AddProperty.module.scss";
import useFetch from "../../../hooks/useFetch";
import { emailYup } from "../../../validations/CommonConstants";
import ModalLayout from "../../layouts/ModalLayout";
import InputField from "../../theme/InputField";
import ThemeButton from "../../theme/ThemeButton";
import "./reportingAutomationModal.scss";
interface Iprops {
  handleClose: () => void;
  show: boolean;
  title?: string;
  editModal?: boolean;
  callGetApi?: any;
  selectedItem?: any;
  setSelectedItem: any;
}
type Frequency = "1" | "2" | "3" | "4" | "";
const AddEditReportingModal = ({
  handleClose,
  title,
  show,
  editModal,
  callGetApi,
  selectedItem,
  setSelectedItem,
}: Iprops) => {
  const [selectedFrequency, setSelectedFrequency] = useState<Frequency>("1");
  const [emails, setEmails] = useState<string[]>([]);
  /** -------- api  ------- */
  const [
    addReportingAutomationApi,
    {
      response: addReportingAutomationResponse,
      loading: addReportingAutomationLoading,
      error: addReportingAutomationError,
    },
  ] = useFetch("/reports/add", {
    method: "POST",
  });
  /** -------- api  ------- */
  const [
    editReportingAutomationApi,
    {
      response: editReportingAutomationResponse,
      loading: editReportingAutomationLoading,
      error: editReportingAutomationError,
    },
  ] = useFetch(`/reports/edit/${selectedItem?.uuid}`, {
    method: "PUT",
  });

  const handleCloseModal = () => {
    handleClose();
    formik.resetForm();
    setEmails([]);
    setSelectedItem(null);
  };
  const handleEmails = () => {
    const updateEmails = [...emails];
    const email = formik.values.email;
    if (isValidEmail(email) && !updateEmails.includes(email)) {
      setEmails([...emails, email]);
      formik.setFieldValue("email", "");
    } else {
      formik.setFieldTouched("email", true);
    }
  };
  const handleEmailRemove = (index: number, value: string) => {
    const updateEmails = [...emails];
    if (updateEmails.includes(value)) {
      updateEmails.splice(index, 1);
      setEmails([...updateEmails]);
    }
  };
  const handleFrequencyChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value: Frequency = "";
    const { name } = event.target;
    switch (name) {
      case "Daily":
        value = "1";
        break;
      case "Weekly":
        value = "2";
        break;
      case "Bi-Weekly":
        value = "3";
        break;
      case "Monthly":
        value = "4";
        break;
      default:
        break;
    }
    setSelectedFrequency(value);
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .trim()
        .max(50, "Report name must be less than 50 characters.")
        .required("Please enter report name."),
      email: emailYup.notRequired(),
    }),
    onSubmit(values: any) {
      const email = emails.join(",");
      if (editModal) {
        try {
          editReportingAutomationApi({
            ...values,
            email,
            frequency: selectedFrequency,
          });
        } catch (e: any) {
          notifyBugsnagError(editReportingAutomationError, {
            api: "editReportingAutomationApi",
          });
        }
      } else {
        try {
          addReportingAutomationApi({
            ...values,
            email,
            frequency: selectedFrequency,
          });
        } catch (e: any) {
          notifyBugsnagError(e, {
            api: "addReportingAutomationApi",
          });
        }
      }
    },
  });
  useEffect(() => {
    if (addReportingAutomationResponse) {
      showToast(addReportingAutomationResponse?.message, "success");
      formik.resetForm();
      setEmails([]);
      handleCloseModal();
      callGetApi();
    }
    if (addReportingAutomationError) {
      showToast(addReportingAutomationError?.message, "error");
    }
  }, [addReportingAutomationError, addReportingAutomationResponse]);
  useEffect(() => {
    if (editReportingAutomationResponse) {
      showToast(editReportingAutomationResponse?.message, "success");
      formik.resetForm();
      setEmails([]);
      handleCloseModal();
      callGetApi();
    }
    if (editReportingAutomationError) {
      showToast(editReportingAutomationError?.message, "error");
    }
  }, [editReportingAutomationError, editReportingAutomationResponse]);
  useEffect(() => {
    if (selectedItem && editModal) {
      formik.setFieldValue("name", selectedItem?.name);
      setEmails((selectedItem?.email).split(","));
      let value: Frequency = "";
      switch (selectedItem?.frequency) {
        case "Daily":
          value = "1";
          break;
        case "Weekly":
          value = "2";
          break;
        case "Bi-Weekly":
          value = "3";
          break;
        case "Monthly":
          value = "4";
          break;
        default:
          break;
      }
      setSelectedFrequency(value);
    }
  }, [selectedItem, editModal]);
  return (
    <ModalLayout
      title={title}
      show={show}
      size={400}
      handleToggle={handleCloseModal}
    >
      <Row className=" gap-4">
        <Col xs={12}>
          <InputField
            name="name"
            label="Report Name"
            placeholder="Enter Report Name"
            formik={formik}
          />
        </Col>
        <Col xs={12}>
          <div className={styles.addCateg}>
            <label> Email</label>
            <label htmlFor="email" className={styles.uploadInput}>
              <input
                type="email"
                placeholder="Enter Email"
                style={{
                  display: "inline-block",
                  outline: "none",
                  border: "none",
                }}
                value={formik.values.email}
                name={"email"}
                onChange={(e) => {
                  formik.setFieldValue("email", e.target.value);
                }}
              />
              <Button
                className={`primary footerModalBtn ${styles.btnBrowse}`}
                onClick={handleEmails}
                style={{
                  minHeight: 0,
                }}
              // disabled={serviceAddLoading || identifierURLLoading}
              // loading={identifierURLLoading}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.6468 7.78322C13.0401 7.78323 13.3589 7.46438 13.3589 7.07107C13.3589 6.67775 13.0401 6.35891 12.6468 6.35891L7.71216 6.35891L7.71216 1.42431C7.71216 1.031 7.39331 0.712158 7 0.712157C6.60669 0.712158 6.28784 1.031 6.28784 1.42432L6.28784 6.35891L1.35325 6.35891C0.959932 6.35891 0.641089 6.67776 0.641089 7.07107C0.64109 7.46438 0.959933 7.78323 1.35325 7.78323H6.28784L6.28784 12.7178C6.28784 13.1111 6.60669 13.43 7 13.43C7.39331 13.43 7.71216 13.1111 7.71216 12.7178V7.78323L12.6468 7.78322Z"
                    fill="white"
                    stroke="white"
                    stroke-width="0.5"
                  />
                </svg>
              </Button>
            </label>
            {formik.touched.email && formik.errors.email && (
              <span
                className={styles.error}
                style={{
                  position: "absolute",
                  right: 0,
                  color: "red",
                  fontSize: "12px",
                }}
              >
                {(formik.errors.email as string) ?? ""}
              </span>
            )}
          </div>
          <div
            className={
              formik.touched.email && formik.errors.email ? "mt-4" : "mt-1"
            }
          >
            {emails?.length > 0 && (
              <div
                className="emails d-flex flex-column justify-content-start gap-1"
                style={{
                  maxHeight: 200,
                  overflow: "auto",
                  scrollbarWidth: "thin",
                }}
              >
                {emails?.map((item: string, index: number) => {
                  return (
                    <div
                      key={item + index}
                      style={{
                        display: "flex",
                        gap: 5,
                        borderRadius: 6,
                        border: "0.5px solid #A9B0BF",
                        padding: 6,
                        flexShrink: 1,
                        backgroundColor: "#EDEDED",
                        width: "max-content",
                        fontSize: 14,
                        fontWeight: 500,
                        color: "black",
                      }}
                    >
                      <span>{item} </span>
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => handleEmailRemove(index, item)}
                      >
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.3957 1.81286C11.7295 1.47912 11.7295 0.938024 11.3957 0.604286C11.062 0.270548 10.5209 0.270548 10.1871 0.604286L6 4.79143L1.81286 0.604286C1.47912 0.270548 0.938024 0.270548 0.604286 0.604286C0.270548 0.938024 0.270548 1.47912 0.604286 1.81286L4.79143 6L0.604286 10.1871C0.270548 10.5209 0.270548 11.062 0.604286 11.3957C0.938024 11.7295 1.47912 11.7295 1.81286 11.3957L6 7.20857L10.1871 11.3957C10.5209 11.7295 11.062 11.7295 11.3957 11.3957C11.7295 11.062 11.7295 10.5209 11.3957 10.1871L7.20857 6L11.3957 1.81286Z"
                            fill="#0A0E18"
                          />
                        </svg>
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </Col>
        <Col xs={12}>
          <Form.Group className="d-flex flex-column gap-2">
            <Form.Label
              style={{
                fontWeight: 600,
                fontSize: 16,
              }}
            >
              Frequency
            </Form.Label>
            <Form.Check
              name="Daily"
              label="Daily (daily at 2AM EST)"
              type={"radio"}
              checked={selectedFrequency === "1"}
              onChange={handleFrequencyChange}
            />
            <Form.Check
              name="Weekly"
              label="Weekly (each Sunday at 2AM EST)"
              type={"radio"}
              checked={selectedFrequency === "2"}
              onChange={handleFrequencyChange}
            />
            <Form.Check
              name="Bi-Weekly"
              label="Bi-Weekly (1st and 16th of each month at 2AM EST)"
              type={"radio"}
              checked={selectedFrequency === "3"}
              onChange={handleFrequencyChange}
            />
            <Form.Check
              name="Monthly"
              label="Monthly (1st of each month at 2AM EST)"
              type={"radio"}
              checked={selectedFrequency === "4"}
              onChange={handleFrequencyChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Modal.Footer>
        <ThemeButton
          loading={
            editReportingAutomationLoading || addReportingAutomationLoading
          }
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          {" "}
          {editModal ? "Save" : "Add"}
          {""}
        </ThemeButton>
      </Modal.Footer>
    </ModalLayout>
  );
};
export default AddEditReportingModal;
