import { Col, Container } from "react-bootstrap";
import styles from "../../styles/layouts/AuthLayout.module.scss";
import logo from "../../assests/Logo.svg";

interface IProps {
  children: React.ReactNode;
}
const AuthLayout = ({ children }: IProps) => {
  return (
    <div className={styles.layout}>
      <Container>
        <div className={styles.layout_wrap}>
          <Col className="d-flex align-items-center justify-content-center col-lg-6 col-sm-12">
            <div className={styles.layout_right}>
              <div className={styles.layout_Top}>
                <div className={styles.logo}>
                  <img src={logo} alt="logo" />
                </div>
              </div>
              <div className={styles.layout_Bottom}>{children}</div>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
};

export default AuthLayout;
