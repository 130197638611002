import { Link, useParams } from "react-router-dom";
import logo from "../../assests/Logo.svg";
import { getCurrentUserData } from "../../common/utils/Functions";
import NavItems from "../navigation";
import { HandleNavItems } from "./HandleNavItems";
import styles from "./sidebar.module.scss";

interface IProps {
  sidebarToggle: boolean;
}
const Sidebar = ({ sidebarToggle }: IProps) => {
  const { uuid } = useParams();
  const userData: any = getCurrentUserData(uuid as string);
  return (
    <div className={styles.sidebarWrapper}>
      <div className={styles.sidebarList}>
        <div className={styles.logo}>
          <Link to={uuid ? "/" + uuid + "/dashboard" : "/"}>
            <span>
              <img src={logo} alt="dummy" />
            </span>
          </Link>
        </div>
        <ul>
          {NavItems.map((item: any, index: number) => (
            <HandleNavItems
              item={item}
              key={index}
              role={userData.role_id === 3 ? 2 : userData.role_id}
            /> //* Component to check the role and render items
          ))}
        </ul>
      </div>
    </div>
  );
};
export default Sidebar;
