import React, { Fragment, useEffect, useState } from "react";
import { Dropdown, FormControlProps, InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
// import "./phoneInput.scss";
import { phoneNumbers } from "../../common/globalVariables";
import { FormikProps } from "formik";
import { downArrow } from "../../assests/Icons/icon";
import { PatternFormat } from "react-number-format";

interface IProps extends FormControlProps {
  name: string;
  label?: string;
  formik?: FormikProps<any>;
  icon?: string;
  phoneCodeId?: (id: string) => void;
  disabled?: boolean;
  disableField?: any;
}
const PhoneIntputField: React.FC<
  IProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({ phoneCodeId, name, formik, label, icon, disableField, ...rest }) => {
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string>("1");
  const formikValue = formik?.values[name];
  const formikError = formik?.touched[name] ? formik?.errors[name] : null;

  /**
   *
   * @param phoneNumber :- get country code on select dropdown item
   */
  const handlePhoneNumberSelect = (phoneNumber: string) => {
    setSelectedPhoneNumber(phoneNumber);
    phoneCodeId && phoneCodeId(phoneNumber);
  };

  useEffect(() => {
    if (formik?.values[name]?.length > 0) {
      setSelectedPhoneNumber("1");
      phoneCodeId && phoneCodeId("1");
    }
  }, [formik?.values[name]]);

  return (
    <Form.Group className="field_wrap">
      {label ? <Form.Label>{label}</Form.Label> : null}
      <InputGroup id="phoneInputBox">
        <Dropdown>
          <Dropdown.Toggle variant="light" id="dropdown-custom">
            <span>+{selectedPhoneNumber}</span>
            {downArrow}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {phoneNumbers.map((phoneNumber, index: number) => (
              <Fragment key={index}>
                <Dropdown.Item
                  key={phoneNumber.countryCode}
                  onClick={() =>
                    handlePhoneNumberSelect(phoneNumber.countryCode)
                  }
                >
                  <div className="dropDownBox">
                    <span>+{phoneNumber.countryCode}</span>{" "}
                    <p>{phoneNumber.country}</p>
                  </div>
                </Dropdown.Item>
                {phoneNumbers?.length - 1 !== index && (
                  <Dropdown.Divider as={"hr"} />
                )}
              </Fragment>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <PatternFormat
          format="(###) ###-####"
          mask="_"
          placeholder="Enter Phone"
          name={name}
          value={formikValue != null ? formikValue : ""}
          onChange={formik ? formik?.handleChange : () => {}}
          className={`${formikError ? "has_error" : ""} form-control`}
          id="phoneInputField"
          style={{
            paddingLeft: 0,
            cursor: disableField ? "not-allowed" : "pointer",
          }}
          disabled={disableField}
          // {...rest}
        />
        {formikError ? (
          <span className="error">
            {typeof formikError === "string" ? formikError : ""}
          </span>
        ) : null}
      </InputGroup>
    </Form.Group>
  );
};

export default PhoneIntputField;
