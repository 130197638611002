import { FormikProps } from "formik";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import ModalLayout from "../../layouts/ModalLayout";
import InputField from "../../theme/InputField";
import ThemeButton from "../../theme/ThemeButton";
import styles from "./addEditPartners.module.scss";
interface Iprops {
  handleClose: () => void;
  show: boolean;
  title?: string;
  editModal?: boolean;
  formik: FormikProps<any>;
  loading?: boolean;
}

const AddEditPartnersModal = ({
  handleClose,
  show,
  title,
  formik,
  editModal,
  loading,
}: Iprops) => {
  return (
    <ModalLayout
      title={title}
      show={show}
      // children={undefined}
      size={340}
      handleToggle={handleClose}
    >
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Container>
          <Row className="g-3">
            <Col xs={12}>
              <InputField
                label=" Name"
                formik={formik}
                name="name"
                placeholder="Enter Name"
              />
            </Col>
            <Col xs={12}>
              <Form.Check
                type="checkbox"
                name="allow_third_party_integration"
                label={"Onboard via integration only."}
                checked={formik.values?.allow_third_party_integration}
                onChange={(e) =>
                  formik.setFieldValue(
                    "allow_third_party_integration",
                    +e.target.checked
                  )
                }
              />
            </Col>
          </Row>
        </Container>
        <Modal.Footer className={styles.FooterModal}>
          <ThemeButton
            variant="primary"
            className="primary footerModalBtn commonBtn m-auto"
            type="submit"
            loading={loading}
          >
            {editModal ? "Save" : "Add"}
          </ThemeButton>
        </Modal.Footer>
      </form>
    </ModalLayout>
    // </Modal.Body>

    // </Modal>
  );
};

export default AddEditPartnersModal;
