import { Fragment } from "react";
import Dividers from "../../assests/Dividers.png";
import { FormStepsPropType } from "../../types/form/stepForm";
import styles from "./formSteps.module.scss";

/**
 * 
 * @param param0 
 * 1. currentStep :- The value of current form step in number
 * 2. steps       :- Values that will render as form steps as array of string
 * @returns Form step component
 */
const FormSteps = ({ currentStep, steps }: FormStepsPropType) => {
  return (
    <div className={styles.steps}>
      <ul>
        {steps.map((step: string, index: number) => {
          return index + 1 > 1 ? (
            <Fragment key={index}>
              <li style={{ textAlign: "center" }}>
                <img src={Dividers} alt="Dividers" />
              </li>
              <li
                key={index}
                className={currentStep >= index + 1 ? styles.activeStep : ""}
              >
                <div className={styles.numberLabel}>
                  <h4>{index + 1}</h4>
                  <h5>{step}</h5>
                </div>
              </li>
            </Fragment>
          ) : (
            <li
              key={index}
              className={currentStep >= index + 1 ? styles.activeStep : ""}
            >
              <div className={styles.numberLabel}>
                <h4>{index + 1}</h4>
                <h5>{step}</h5>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FormSteps;
